import { ActivityAnalyticsState, PeopleOverTimeFilters } from "../types";
import {
  defaultLastMonthRange,
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";

import { logoutUserAction } from "./authReducer";
import { ClientDateRange, SortDirections } from "api/types/commonTypes";
import {
  ActivityAnalyticsActiveUsersSortBy,
  ActivityAnalyticsPeopleOverTimeSortBy,
  ActivityInsightsDto,
  ActivityMostActiveUsersItemDto,
  PeopleOverTimeItemDto,
  PeopleOverTimeMainFilters,
} from "api/types/activityAnalyticsTypes";

const initialState: ActivityAnalyticsState = {
  activityInsights: defaultListState,
  activityMostActiveUsers: {
    activityMostActiveUsersFilters: {
      dateRange: defaultLastMonthRange,
    },
    activityMostActiveUsersList: defaultStateWithInfiniteLoading,
    sortingActiveUsers: {
      sortBy: ActivityAnalyticsActiveUsersSortBy.AchievementsCount,
      sortDirection: SortDirections.desc,
    },
  },
  peopleOverTime: {
    peopleOverTimeFilters: {
      peopleOverTimeTabFilter: PeopleOverTimeMainFilters.position,
      dateRange: defaultLastMonthRange,
    },
    peopleOverTimeList: defaultStateWithInfiniteLoading,
    sortingPeopleOverTime: {
      sortBy: ActivityAnalyticsPeopleOverTimeSortBy.ActivatedCount,
      sortDirection: SortDirections.desc,
    },
  },
};

const activityAnalyticsSlice = createSlice({
  initialState,
  name: "activityAnalytics",
  reducers: {
    setLoadingPeopleOverTimeList: (state) => ({
      ...state,
      peopleOverTime: {
        ...state.peopleOverTime,
        peopleOverTimeList: setInfiniteLoadLoading(
          state.peopleOverTime.peopleOverTimeList
        ),
      },
    }),
    setPeopleOverTimeList: (
      state,
      action: PayloadAction<{
        peopleOverTimeData: PeopleOverTimeItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let peopleList = state.peopleOverTime.peopleOverTimeList.data || [];
      peopleList = [...peopleList, ...action.payload.peopleOverTimeData];

      return {
        ...state,
        peopleOverTime: {
          ...state.peopleOverTime,
          peopleOverTimeList: setInfiniteLoadData(
            state.peopleOverTime.peopleOverTimeList,
            peopleList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorPeopleOverTimeList: (state, action: PayloadAction<string>) => ({
      ...state,
      peopleOverTime: {
        ...state.peopleOverTime,
        peopleOverTimeList: setInfiniteLoadStateErrorMessage(
          state.peopleOverTime.peopleOverTimeList,
          action.payload
        ),
      },
    }),
    setPeopleOverTimeListSorting: (
      state,
      action: PayloadAction<
        [ActivityAnalyticsPeopleOverTimeSortBy, SortDirections]
      >
    ) => ({
      ...state,
      peopleOverTime: {
        ...state.peopleOverTime,
        sortingPeopleOverTime: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    setPeopleOverTimeFilters: (
      state,
      action: PayloadAction<PeopleOverTimeFilters>
    ) => ({
      ...state,
      peopleOverTime: {
        ...state.peopleOverTime,
        peopleOverTimeFilters: {
          peopleOverTimeTabFilter: action.payload.peopleOverTimeTabFilter,
          dateRange: action.payload.dateRange,
        },
      },
    }),
    resetPeopleOverTimeList: (state) => ({
      ...state,
      peopleOverTime: {
        ...state.peopleOverTime,
        peopleOverTimeList: defaultStateWithInfiniteLoading,
      },
    }),
    setLoadingActivityInsights: (state) => ({
      ...state,
      activityInsights: setStateLoading(state.activityInsights),
    }),
    setActivityInsights: (
      state,
      action: PayloadAction<ActivityInsightsDto>
    ) => ({
      ...state,
      activityInsights: setStateData(state.activityInsights, action.payload),
    }),
    setErrorActivityInsights: (state, action: PayloadAction<string>) => ({
      ...state,
      activityInsights: setStateError(state.activityInsights, action.payload),
    }),
    setLoadingActivityMostActiveUsers: (state) => ({
      ...state,
      activityMostActiveUsers: {
        ...state.activityMostActiveUsers,
        activityMostActiveUsersList: setInfiniteLoadLoading(
          state.activityMostActiveUsers.activityMostActiveUsersList
        ),
      },
    }),
    setActivityMostActiveUsers: (
      state,
      action: PayloadAction<{
        activityMostActiveUsers: ActivityMostActiveUsersItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let usersList =
        state.activityMostActiveUsers.activityMostActiveUsersList.data || [];
      usersList = [...usersList, ...action.payload.activityMostActiveUsers];

      return {
        ...state,
        activityMostActiveUsers: {
          ...state.activityMostActiveUsers,
          activityMostActiveUsersList: setInfiniteLoadData(
            state.activityMostActiveUsers.activityMostActiveUsersList,
            usersList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorActivityMostActiveUsers: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      activityMostActiveUsers: {
        ...state.activityMostActiveUsers,
        activityMostActiveUsersList: setInfiniteLoadStateErrorMessage(
          state.activityMostActiveUsers.activityMostActiveUsersList,
          action.payload
        ),
      },
    }),
    setMostActiveUsersListSorting: (
      state,
      action: PayloadAction<
        [ActivityAnalyticsActiveUsersSortBy, SortDirections]
      >
    ) => ({
      ...state,
      activityMostActiveUsers: {
        ...state.activityMostActiveUsers,
        sortingActiveUsers: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    setActivityMostActiveUsersFilters: (
      state,
      action: PayloadAction<{ dateRange: ClientDateRange }>
    ) => ({
      ...state,
      activityMostActiveUsers: {
        ...state.activityMostActiveUsers,
        activityMostActiveUsersFilters: {
          ...state.activityMostActiveUsers.activityMostActiveUsersFilters,
          dateRange: action.payload.dateRange,
        },
      },
    }),
    resetActivityMostActiveUsers: (state) => ({
      ...state,
      activityMostActiveUsers: {
        ...state.activityMostActiveUsers,
        activityMostActiveUsersList: defaultStateWithInfiniteLoading,
      },
    }),
    resetActivityAnalyticsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default activityAnalyticsSlice.reducer;

export const {
  setLoadingPeopleOverTimeList,
  setPeopleOverTimeList,
  setErrorPeopleOverTimeList,
  setPeopleOverTimeListSorting,
  setPeopleOverTimeFilters,
  resetPeopleOverTimeList,
  setLoadingActivityInsights,
  setActivityInsights,
  setErrorActivityInsights,
  resetActivityAnalyticsState,
  setLoadingActivityMostActiveUsers,
  setActivityMostActiveUsers,
  setErrorActivityMostActiveUsers,
  setMostActiveUsersListSorting,
  setActivityMostActiveUsersFilters,
  resetActivityMostActiveUsers,
} = activityAnalyticsSlice.actions;
