import { AxiosPromise } from "axios";
import {
  base_url,
  countItemsPerPage,
  countReceivedItemsPerPage,
} from "../appConsts";
import { axiosInstance } from "./instance";
import {
  EditBasicInfoRequest,
  ExchangeHistoryResponse,
  FeedbackResponse,
  HistoryMerchDeliveryInfoResponse,
  RequestFeedbackRequest,
  RespondToFeedbackRequestRequest,
  UnresponsedFeedbackRequestsQuery,
  UnresponsedFeedbackRequestsResponse,
  UpdateContactsRequest,
  UpdateSignificantDatesRequest,
  UserCardDto,
  UserCardMerchDeliveryInfoDto,
  CreateTimeOffRequestRequest,
  UserTimeOffBacklogParams,
  UserTimeOffBacklogResponse,
  UserTimeOffBalanceListDto,
  UserTimeOffRequestDto,
  ResponseUserBadges,
  UpdateTimeOffRequestRequest,
} from "./types/employeePageTypes";
import { QueryParams, SortDirections, TotalItems } from "./types/commonTypes";
import { UsersListResponse } from "./types/usersTypes";

//api for main user block
export const changeAvatar = (userId: number, body: FormData): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/user-card/${userId}/avatar`, body);

export const changeUserCoverImg = (
  userId: number,
  body: FormData
): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/user-card/${userId}/cover-image`, body);

// api for Achievements tab
export const loadFeedbackRequests = (): AxiosPromise<FeedbackResponse> =>
  axiosInstance.get(`${base_url}/api/feedback-requests`);

export const loadUnresponsedFeedbackRequests = (
  senderId: number,
  params: UnresponsedFeedbackRequestsQuery
): AxiosPromise<UnresponsedFeedbackRequestsResponse> =>
  axiosInstance.get(
    `${base_url}/api/users/${senderId}/feedback-requests/sent`,
    { params }
  );

export const requestFeedback = (body: RequestFeedbackRequest): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/feedback-requests`, body);

export const respondToRequestFeedback = (
  body: RespondToFeedbackRequestRequest,
  requestId: number
): AxiosPromise =>
  axiosInstance.post(
    `${base_url}/api/feedback-requests/${requestId}/respond`,
    body
  );

export const deleteFeedbackRequest = (requestId: number): AxiosPromise =>
  axiosInstance.delete(`${base_url}/api/feedback-requests/${requestId}`);

export const loadUserBadges = (
  userId: number,
  params: QueryParams
): AxiosPromise<ResponseUserBadges> =>
  axiosInstance.get(`${base_url}/api/users/${userId}/badges/summary`, {
    params,
  });

export const loadBadgesSummaryCount = (
  userId: number
): AxiosPromise<TotalItems> =>
  axiosInstance.get(`${base_url}/api/users/${userId}/badges/summary/count`);

// api for personal info tab
export const loadBasicEmployeeInfo = (
  userId: number
): AxiosPromise<UserCardDto> =>
  axiosInstance.get(`${base_url}/api/user-card/${userId}`);

export const editBasicEmployeeInfo = (
  userId: number,
  body: EditBasicInfoRequest
): AxiosPromise =>
  axiosInstance.put(`${base_url}/api/user-card/${userId}/basic-info`, body);
export const updateContacts = (
  userId: number,
  body: UpdateContactsRequest
): AxiosPromise =>
  axiosInstance.put(`${base_url}/api/user-card/${userId}/contacts`, body);

export const updateSignificantDates = (
  userId: number,
  body: UpdateSignificantDatesRequest
): AxiosPromise =>
  axiosInstance.put(`${base_url}/api/user-card/${userId}/dates`, body);

export const loadUsersStructureData = (
  filterBy: string[],
  userId: number
): AxiosPromise<UsersListResponse> => {
  // this case handle DirectReports tab (we display users which ReportToUserId === currentUserId or  ManagerId === currentUserId
  if (filterBy.length > 1) {
    return axiosInstance.get(
      `${base_url}/api/users/odata?$filter=${filterBy[0]} eq ${userId} or ${filterBy[1]} eq ${userId}`
    );
  }

  return axiosInstance.get(
    `${base_url}/api/users/odata?$filter=${filterBy} eq ${userId}`
  );
};

// Merch delivery tab
export const editMerchDeliveryInfo = (
  userId: number,
  body: UserCardMerchDeliveryInfoDto
): AxiosPromise =>
  axiosInstance.put(
    `${base_url}/api/user-card/${userId}/merch-delivery-info`,
    body
  );

export const loadMerchDeliveryInfoHistory = (
  userId: number
): AxiosPromise<HistoryMerchDeliveryInfoResponse> =>
  axiosInstance.get(
    `${base_url}/api/user-card/${userId}/merch-delivery-info/history`
  );

export const deleteMerchDeliveryInfoHistoryRecord = (
  userId: number,
  recordId: number
) =>
  axiosInstance.delete(
    `${base_url}/api/user-card/${userId}/merch-delivery-info/history/${recordId}`
  );

export const loadEmployeeMerchOrders = (
  userId: number,
  pageNumber: number,
  sortBy: string,
  sortDirection: SortDirections
): AxiosPromise<ExchangeHistoryResponse> => {
  const skipItemsCount = (pageNumber - 1) * countItemsPerPage;

  return axiosInstance.get(
    `${base_url}/api/merch-orders/${userId}/odata?$orderby=${sortBy} ${sortDirection}&$top=${countItemsPerPage}&skip=${skipItemsCount}&$count=true`
  );
};

export const loadEmployeeReceivedItems = (
  userId: number,
  pageNumber: number
): AxiosPromise<ExchangeHistoryResponse> => {
  const skipItemsCount = (pageNumber - 1) * countReceivedItemsPerPage;

  return axiosInstance.get(
    `${base_url}/api/merch-orders/${userId}/odata?$filter=Status eq 'Delivered'&$orderby=CreatedDate desc&$top=${countReceivedItemsPerPage}&skip=${skipItemsCount}&$count=true`
  );
};

// api for timeOff tab
export const loadCurrentUserTimeOffBalances =
  (): AxiosPromise<UserTimeOffBalanceListDto> =>
    axiosInstance.get(`${base_url}/api/time-off/balances`);

export const loadOtherUserTimeOffBalances = (
  userId: number
): AxiosPromise<UserTimeOffBalanceListDto> =>
  axiosInstance.get(`${base_url}/api/users/${userId}/time-off/balances`);

export const loadCurrentUserTimeOffBacklog = (
  params: UserTimeOffBacklogParams
): AxiosPromise<UserTimeOffBacklogResponse> =>
  axiosInstance.get(`${base_url}/api/time-off/requests`, {
    params,
  });

export const loadOtherUserTimeOffBacklog = (
  userId: number,
  params: UserTimeOffBacklogParams
): AxiosPromise<UserTimeOffBacklogResponse> =>
  axiosInstance.get(`${base_url}/api/users/${userId}/time-off/requests`, {
    params,
  });

export const cancelTimeOffRequest = (id: number): AxiosPromise =>
  axiosInstance.delete(`${base_url}/api/time-off/requests/${id}`);

export const createTimeOffRequest = (body: CreateTimeOffRequestRequest) =>
  axiosInstance.post(`${base_url}/api/time-off/requests`, body);

export const loadTimeOffRequest = (
  userId: number,
  requestId: number
): AxiosPromise<UserTimeOffRequestDto> =>
  axiosInstance.get(`${base_url}/api/users/${userId}/time-off/requests/${requestId}`);

export const editTimeOffRequest = (
  userId: number,
  requestId: number,
  body: UpdateTimeOffRequestRequest,
): AxiosPromise =>
  axiosInstance.put(`${base_url}/api/users/${userId}/time-off/requests/${requestId}`, body);
