import { AxiosError } from "axios";
import { Dispatch } from "redux";
import {
  loadCurrentProfileBadgeMessages,
  loadProfileBadgeMessages,
} from "../../../api/badgesApi";
import { countItemsPerPage } from "../../../appConsts";
import { getErrorMessage } from "../../../utils/methods";
import { store } from "../../index";
import {
  resetFR,
  resetProfileMessages,
  setErrorFR,
  setErrorProfileMessages,
  setErrorTotalBadgesCount,
  setErrorUnresponsedFeedbackRequests,
  setFeedbackRequests,
  setLoadingFR,
  setLoadingProfileMessages,
  setLoadingTotalBadgesCount,
  setLoadingUnresponsedFeedbackRequests,
  setLoadingUserBadges,
  setProfileMessages,
  setTotalBadgesCount,
  setUnresponsedFeedbackRequestss,
  setUserBadges,
  setUserBadgesError,
} from "../../reducers/employeePageReducers/employeeAchievementsReducer";
import {
  loadUserBadges,
  loadBadgesSummaryCount,
  loadFeedbackRequests,
  loadUnresponsedFeedbackRequests,
} from "../../../api/employeePageApi";

export const getProfileMessages =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingProfileMessages());
    try {
      const nextPage =
        store.getState().employeeAchievements.profileMessages.page + 1;
      const isCurrentUser = store.getState().auth.profile?.id === userId;
      let response;

      if (isCurrentUser) {
        response = await loadCurrentProfileBadgeMessages(nextPage);
      } else {
        response = await loadProfileBadgeMessages(userId, nextPage);
      }

      const messagesFromApi = response.data.items;
      const noMoreMessages =
        messagesFromApi.length === 0 ||
        messagesFromApi.length < countItemsPerPage;
      dispatch(
        setProfileMessages({
          messages: messagesFromApi,
          hasMoreData: !noMoreMessages,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorProfileMessages(errorMessage));
    }
  };

export const loadFeedbacks =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingFR());
    try {
      const response = await loadFeedbackRequests();

      dispatch(setFeedbackRequests(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorFR(errorMessage));
    }
  };

export const getUnresponsedFeedbackRequests =
  (senderId: number | undefined, receiverId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!senderId) return;

    dispatch(setLoadingUnresponsedFeedbackRequests());
    try {
      const params = {
        receiverId,
        hasResponse: false,
      };
      const response = await loadUnresponsedFeedbackRequests(senderId, params);
      dispatch(setUnresponsedFeedbackRequestss(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorUnresponsedFeedbackRequests(errorMessage));
    }
  };

export const getUserBadges =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingUserBadges());
    try {
      const nextPage =
        store.getState().employeeAchievements.userBadgesData.page + 1;
      const params = {
        pageNumber: nextPage,
        pageSize: countItemsPerPage,
      };
      const response = await loadUserBadges(userId, params);
      const badgesFromApi = response.data.items;

      const noMoreMessages =
        badgesFromApi.length === 0 || badgesFromApi.length < countItemsPerPage;
      const pageNumber = nextPage;

      dispatch(
        setUserBadges({
          badges: badgesFromApi,
          hasMoreData: !noMoreMessages,
          page: pageNumber,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setUserBadgesError(errorMessage));
    }
  };

export const getTotalBadgesCount =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingTotalBadgesCount());
    try {
      const response = await loadBadgesSummaryCount(userId);
      dispatch(setTotalBadgesCount(response.data.totalItems));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorTotalBadgesCount(errorMessage));
    }
  };

export const getEmployeeAchievementsData =
  (userId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const currentUserId = store.getState().auth.profile?.id;
    const isCurrentUser = userId === currentUserId;
    await Promise.all([
      dispatch(getProfileMessages(userId)),
      dispatch(getUserBadges(userId)),
      dispatch(getTotalBadgesCount(userId)),
      isCurrentUser
        ? dispatch(loadFeedbacks())
        : dispatch(getUnresponsedFeedbackRequests(currentUserId, userId)),
    ]);
  };

export const refreshEmployeeAchievementsData =
  (userId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(resetProfileMessages());
    dispatch(getEmployeeAchievementsData(userId));
  };

export const refreshFeedbackRequests =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetFR());
    dispatch(loadFeedbacks());
  };

export const refreshEmployeeProfileMessages =
  (userId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(resetProfileMessages());
    dispatch(getProfileMessages(userId));
  };
