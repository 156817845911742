import { SingleValueProps, components } from "react-select";
import { CountryOption, CustomCountryOptionProps } from "./types";

export const CustomCountryOption = (props: CustomCountryOptionProps) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div className="select__country-option">
        <img src={data?.flag} alt={data?.label} />
        {data?.label}
      </div>
    </components.Option>
  );
};

export const CountrySingleValue = ({
  children,
  ...props
}: SingleValueProps<CountryOption>) => (
  <components.SingleValue {...props} className="select__country-option">
    <div className="select__country-option">
      <img src={props.data?.flag} alt={props.data?.label} />
      {props.data?.label}
    </div>
  </components.SingleValue>
);
