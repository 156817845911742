import AccessDenied from "auth/AccessDenied";
import { useAppDispatch, useAppSelector } from "store";
import { Loader } from "utils/components";
import { AppPermissions, Features } from "utils/consts";
import { useCompanyFeatureCheck, usePermissionCheck } from "utils/hooks";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { resetTimeOffProfileState } from "../../../store/reducers/employeePageReducers/timeOffProfileReducer";
import { getTimeOffProfilePageData } from "../../../store/thunks/employeePage/timeOffProfileThunks";
import CurrentBalancesBlock from "./CurrentBalancesBlock";
import { TUserTimeOffProfilePermissions } from "../../../store/types";
import { useTimeOffProfileViewsPermissions } from "./hooks/useTimeOffProfileViewsPermissions";
import TimeOffsBacklog from "./TimeOffsBacklog";

const TimeOffProfilePage = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const isCurrentUser = useIsCurrentUser();
  const profile = useAppSelector((state) => state.auth.profile);
  const isTimeOffAvailable = useCompanyFeatureCheck(Features.TimeOff);
  const isUserCanViewTimeOffProfile = usePermissionCheck([
    AppPermissions.TimeOff_ViewOtherUserBalance,
    AppPermissions.TimeOff_ViewOtherUserBacklog,
  ]);
  const isAccessToTimeOffProfile =
    isTimeOffAvailable && (isUserCanViewTimeOffProfile || isCurrentUser);
  const permissions: TUserTimeOffProfilePermissions =
    useTimeOffProfileViewsPermissions();

  useEffect(() => {
    if (userId && isAccessToTimeOffProfile) {
      dispatch(getTimeOffProfilePageData(+userId, permissions));
    }

    return () => {
      dispatch(resetTimeOffProfileState());
    };
  }, [userId]);

  if (!profile) {
    return (
      <div className="box">
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (!isAccessToTimeOffProfile) {
    return <AccessDenied />;
  }

  return (
    <div>
      {isCurrentUser || permissions.isViewTimeOffBalances ? (
        <CurrentBalancesBlock />
      ) : null}

      {isCurrentUser || permissions.isViewTimeOffBacklog ? (
        <TimeOffsBacklog />
      ) : null}
    </div>
  );
};

export default TimeOffProfilePage;
