import { AxiosError } from "axios";
import classNames from "classnames";
import isNumber from "lodash/isNumber";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Profile } from "../../api/types/profile";
import useChangeLocalization, {
  companyCultureToCulturesMapping,
  culturesToCompanyCultureMapping,
  isLanguageChosenByUser,
} from "../../localization/useChangeLocalization";
import { useAppDispatch, useAppSelector } from "../../store";
import { getUserCoinsCount, getUserInfo } from "../../store/thunks/authThunks";
import { serverError } from "../../types/serverError";
import { Icon } from "../../utils/components";
import { Features } from "../../utils/consts";
import { useCompanyFeatureCheck } from "../../utils/hooks";
import ImpersonationPanel from "./ImpersonationPanel";
import SearchBar from "./SearchBar";
import Sidebar from "./Sidebar";
import { toastError, toastWarning } from "utils/toasts";
import { CelebrationModal } from "utils/components/celebrationModal/CelebrationModal";
import NotificationsRightPanel from "./notifications/NotificationsRightPanel";
import {
  getTotalCountAndIdUnreadNotifications,
  getLatestUnreadNotifications,
  getAllNotifications,
} from "store/thunks/notificationsThunks";
import MagicButton from "./MagicButton";

const Layout: FC = () => {
  const { t } = useTranslation("layout");

  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.auth.profile);
  const [demoDaysLeft, setDemoDaysLeft] = useState<number | null>(null);
  const userInfo = useAppSelector((state) => state.auth.user);
  const lastVisit = useAppSelector((state) => state.auth.lastVisit);
  const totalCountUnreadNotifications = useAppSelector(
    (state) => state.notifications.unreadNotificationsTotalCount
  );

  const locationPath = useLocation().pathname;
  const isOrgChartPage = locationPath.includes("org-structure");
  const navigate = useNavigate();

  const [, , currentLanguage, handleChangeLanguage] = useChangeLocalization();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const receivedBadges = userInfo ? userInfo.receivedBadges : "...";
  const { data: userCoins } = useAppSelector((state) => state.auth.coinsCount);
  const displayedUserCoins = isNumber(userCoins) ? userCoins : "...";
  const counter = isGamificationAvailable ? displayedUserCoins : receivedBadges;

  const handleProfilePeriod = (profile: Profile) => {
    const daysLeft = profile.pilotPeriodDaysLeft;
    if (demoDaysLeft !== daysLeft) {
      setDemoDaysLeft(daysLeft);

      if (typeof daysLeft === "number") {
        const message =
          daysLeft === 0
            ? t("demoPeriodExpiresToday")
            : t("demoPeriodExpires", { count: daysLeft });
        toastWarning(message);
      }
    }
  };

  const handleCompanyLanguage = (profile: Profile) => {
    if (
      profile.companyCulture ===
      culturesToCompanyCultureMapping[currentLanguage]
    ) {
      return;
    }
    if (isLanguageChosenByUser()) {
      return;
    }
    const language = companyCultureToCulturesMapping[profile.companyCulture];
    handleChangeLanguage(language, false);
  };

  const fetchLatestUnreadNotificationsData = useCallback(() => {
    dispatch(
      getLatestUnreadNotifications(
        setIsNotificationsModalOpen,
        navigate,
        dispatch
      )
    );
  }, [dispatch, navigate]);

  useEffect(() => {
    if (userProfile) {
      handleProfilePeriod(userProfile);
      handleCompanyLanguage(userProfile);
    }
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (userProfile!.id) {
        try {
          await dispatch(getUserInfo(userProfile!.id));
          if (isGamificationAvailable) {
            await dispatch(getUserCoinsCount());
          }
        } catch (e) {
          const axiosError = e as AxiosError;
          const error = axiosError.response?.data as serverError;
          toastError(error?.message || t("errorLoadingUser"));
        }
      } else {
        throw new Error("User profile should be present on Layout");
      }
    })();
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getTotalCountAndIdUnreadNotifications());

    const intervalRef = setInterval(() => {
      if (document.hidden) return;
      // every 10sec we update all notifications in Notifications Center (NC)
      if (isNotificationsModalOpen) {
        dispatch(getAllNotifications(true));
      }
      fetchLatestUnreadNotificationsData();
    }, 10000);

    return () => {
      clearInterval(intervalRef);
    };
  }, [dispatch, fetchLatestUnreadNotificationsData, isNotificationsModalOpen]);

  const toggleSidebar = () => setSidebarOpen((prevState) => !prevState);
  const closeSidebar = () => setSidebarOpen(false);

  return (
    <div>
      <Sidebar
        isSidebarVisible={sidebarOpen}
        closeSidebar={closeSidebar}
        userProfile={userProfile!}
        userInfo={userInfo || { id: userProfile?.id }}
        counter={counter}
      />

      <div
        className={classNames("container", {
          containerOrgChart: isOrgChartPage,
        })}
      >
        <ImpersonationPanel />
        <div
          className={classNames("header", { orgChartHeader: isOrgChartPage })}
        >
          <div
            className="hidden-desktop visible-mobile"
            onClick={toggleSidebar}
          >
            <Icon href="#burger" svgClass="burger" />
          </div>

          <SearchBar />

          <div className="header__info">
            <button
              type="button"
              className="header__btn"
              onClick={() => setIsNotificationsModalOpen(true)}
            >
              {totalCountUnreadNotifications > 0 ? (
                <div className="header__badge" />
              ) : null}
              <Icon href="#bell" svgClass="header__notification" />
            </button>

            <Link to="/merch-store" className="header__count hidden-mobile">
              <Icon href="#coins" svgClass="ic-coins" />
              {counter}
            </Link>
          </div>
        </div>
        <Outlet />
        <MagicButton />
      </div>
      {userInfo && lastVisit && <CelebrationModal />}
      <NotificationsRightPanel
        isModalOpen={isNotificationsModalOpen}
        onCloseModal={() => setIsNotificationsModalOpen(false)}
      />
    </div>
  );
};

export default Layout;
