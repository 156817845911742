import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import React, { useRef } from "react";

//this component used in CropImageModal.tsx now

type CustomFileInputProps = FieldProps & {
  handleChange: () => void;
  errorMessage: string;
};

export const CustomFileInput = ({
  field,
  form,
  handleChange,
  errorMessage,
}: CustomFileInputProps) => {
  const { t: tCommon } = useTranslation("common");
  const fileUpload = useRef<HTMLInputElement | null>(null);

  const showFileUpload = () => {
    if (fileUpload.current) {
      fileUpload.current?.click();
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      form.setFieldValue(field.name, e.target.files[0]);
      handleChange();
    }
  };

  return (
    <>
      <button
        className="btn-regular uploadImage"
        onClick={showFileUpload}
        type="button"
      >
        {tCommon("selectFile")}
      </button>

      <input
        ref={fileUpload}
        type="file"
        onChange={onChange}
        name={field.name}
        accept=".png, .jpeg, .jpg, .gif, .svg"
      />

      {errorMessage ? <p className="error t-center">{errorMessage}</p> : null}
    </>
  );
};
