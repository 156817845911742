import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useState } from "react";
import EssentialsMerch from "./EssentialsMerch";
import EssentialsAchievements from "./EssentialsAchievements";

type Tab = {
  value: EssentialsTabs;
  label: string;
  className: string;
};

export enum EssentialsTabs {
  Events = "events",
  Merch = "merch",
}

const Essentials = () => {
  const { t } = useTranslation("news");
  const { t: tLayout } = useTranslation("layout");
  const [activeTab, setActiveTab] = useState(EssentialsTabs.Events);

  const tabs: Tab[] = [
    {
      value: EssentialsTabs.Events,
      label: t("events"),
      className: "pageTabs__link",
    },
    {
      value: EssentialsTabs.Merch,
      label: tLayout("navigation_merchGroup"),
      className: "pageTabs__link",
    },
  ];

  return (
    <div className="essentials">
      <div className="d-flex essentials__header">
        <h1 className="main-title">{t("essentialsTitle")}</h1>
        <ul className="pageTabs">
          {tabs.map((tab) => (
            <li
              key={tab.value}
              className={classNames(tab.className, {
                selected: tab.value === activeTab,
              })}
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>

      {activeTab === EssentialsTabs.Events ? <EssentialsAchievements /> : null}
      {activeTab === EssentialsTabs.Merch ? <EssentialsMerch /> : null}
    </div>
  );
};

export default Essentials;
