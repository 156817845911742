import AzureIcon from "../../sprite/azureIcon.svg";
import { loginRequest } from "../../auth-config";
import { useMsal } from "@azure/msal-react";

const LoginWithAzure = () => {
  const { instance } = useMsal();

  const handleInitializeSignIn = async () => {
    await instance.logoutRedirect({
      onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false;
      },
    });
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "select_account",
      })
      .catch((error) => console.log(error));
  };

  return (
    <button
      type="button"
      onClick={handleInitializeSignIn}
      className="btn-regular"
    >
      <img src={AzureIcon} alt="azure" className="azureIcon" />
      <span className="sso-text">Sign in with Azure</span>
    </button>
  );
};

export default LoginWithAzure;
