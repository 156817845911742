import { FC, useState } from "react";
import { ErrorMessage, FieldProps } from "formik";
import ReactQuill, { Quill, UnprivilegedEditor } from "react-quill";
import { DeltaStatic, Sources } from "quill";
import quillEmoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import "react-quill/dist/quill.snow.css";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
  {
    "formats/emoji": EmojiBlot,
    "modules/emoji-shortname": ShortNameEmoji,
    "modules/emoji-toolbar": ToolbarEmoji,
    "modules/emoji-textarea": TextAreaEmoji,
  },
  true
);

const richTextInputConfig = {
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
      ["emoji"],
    ],
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
  },
  formats: [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "background",
    "color",
    "align",
    "emoji",
  ],
};
interface CustomRichTextComponentProps extends FieldProps {
  placeholder: string;
  maxLength: number;
  additionalClassName?: string;
}

const CustomRichTextComponent: FC<CustomRichTextComponentProps> = ({
  form,
  field,
  placeholder,
  maxLength,
  additionalClassName = "",
}) => {
  const fieldValue = field?.value;
  const fieldValueLength = Array.isArray(fieldValue) ? fieldValue.length : 0;
  const [charCount, setCharCount] = useState<number>(fieldValueLength || 0);
  const handleQuillChange = (editor: UnprivilegedEditor) => {
    const contents = editor.getContents();
    const firstOps = contents.ops?.[0];

    // Check if the first operation is an emoji insert
    const isFirstEmojiInsert = firstOps?.insert?.emoji !== undefined;

    const newDescription = isFirstEmojiInsert
      ? contents
      : editor.getText().trim() !== ""
      ? contents
      : "";

    const newDescriptionLen = isFirstEmojiInsert
      ? contents.ops?.length ?? 0
      : editor.getText().trim().length || 0;

    form.setFieldValue(field.name, newDescription);
    setCharCount(newDescriptionLen);
  };

  return (
    <div className={`customTextEditor ${additionalClassName}`}>
      <ReactQuill
        value={field.value}
        modules={richTextInputConfig.modules}
        formats={richTextInputConfig.formats}
        onChange={(
          value: string,
          delta: DeltaStatic,
          source: Sources,
          editor
        ) => {
          handleQuillChange(editor);
        }}
        onBlur={() => {
          form.setFieldTouched(field.name, true);
        }}
        placeholder={placeholder}
      />

      <div className="d-flex">
        <ErrorMessage component="div" className="error" name={field.name} />

        <div className="text-counter">
          <span>{charCount}/</span>
          <span>{maxLength}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomRichTextComponent;
