import { FC } from "react";
import { config } from "utils/config";
import icons from "../sprite/templateIcons.svg";

/**
 * Flow description:
 * 1) User using link, constructed below is redirected to Google Auth page
 * 2) After access granted, Google is redirecting to the page, pointed in "redirect_uri" parameter, with "code" or "error" and "scope" query parameters provided
 * 3) On step 2 user is redirected to GoogleCodePage, which send "code" to the server, where it is exchanged to access and refresh token
 *
 * Google Auth related documentation for front-end part is located here:
 * https://developers.google.com/identity/protocols/oauth2/web-server#httprest_2
 */

function getGoogleLoginUrl(returnUrl: string) {
  const clientId = config.authentication.googleClientId;

  const scopes = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
    "openid",
  ];

  const state = returnUrl;

  const redirectUri = `${window.location.origin}/code/`;

  const googleLoginUrl =
    "https://accounts.google.com/o/oauth2/v2/auth" +
    "?" +
    "scope=" +
    encodeURI(scopes.join(" ")) +
    "&" +
    "access_type=offline&" +
    "include_granted_scopes=true&" +
    "response_type=code&" +
    "redirect_uri=" +
    encodeURI(redirectUri) +
    "&" +
    (state ? `state=${encodeURI(state)}&` : "") +
    "client_id=" +
    clientId;

  return googleLoginUrl;
}

interface ILoginWithGoogleProps {
  returnUrl: string;
}

const LoginWithGoogle: FC<ILoginWithGoogleProps> = ({ returnUrl }) => {
  const googleLoginUrl = getGoogleLoginUrl(returnUrl);
  return (
    <a href={googleLoginUrl} className="btn-regular" data-cy="google-btn">
      <svg>
        <use xlinkHref={`${icons}#ic-google`}></use>
      </svg>
      <span className="sso-text">Sign in with Google</span>
    </a>
  );
};

export default LoginWithGoogle;
