import { FC, useEffect, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import { Modal } from "rsuite";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import "cropperjs/dist/cropper.css";
import { CustomFileInput } from "./CustomFileInput";
import RangeZoomInput from "./RangeZoomInput";
import { ALLOWED_FILE_EXTENSIONS, MAX_FILE_SIZE } from "../../../appConsts";
import { validateFileType } from "../../methods/validateFileType";

type CropImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (v: string) => void;
  isAvatarCrop?: boolean;
  aspectRatio?: number;
  viewMode?: Cropper.ViewMode;
  previewImgClassName?: string;
  editableImage?: {
    fileUrl: string;
    file: Blob | null;
  } | null;
};

type CropValues = {
  picture: Blob | File | null;
};

const initialCropValues: CropValues = {
  picture: null,
};

const validFileExtensions = [...ALLOWED_FILE_EXTENSIONS, "gif"];

// reference https://shinerweb.com/how-to-crop-image-in-round-shape-by-using-cropper-js/
function getRoundedCanvas(sourceCanvas: HTMLCanvasElement) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const width = sourceCanvas.width;
  const height = sourceCanvas.height;
  canvas.width = width;
  canvas.height = height;
  if (context) {
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
  }

  return canvas;
}

export const CropImageModal: FC<CropImageModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isAvatarCrop,
  aspectRatio,
  previewImgClassName = "previewImgWrap__img",
  editableImage,
}) => {
  const { t: tCommon } = useTranslation("common");
  const cropperRef = useRef<ReactCropperElement | null>(null);
  const formikRef = useRef<FormikProps<CropValues>>(null);
  const [initialValues, setInitialValues] = useState<CropValues | null>(null);
  const [cropData, setCropData] = useState<null | string>(null);
  const [isCropperActive, setIsCropperActive] = useState(false);
  const [minZoomValue, setMinZoomValue] = useState(0);

  useEffect(() => {
    if (!isOpen) return;

    if (editableImage) {
      setInitialValues({
        picture: editableImage.file,
      });
      setIsCropperActive(true);
    } else {
      setInitialValues(initialCropValues);
    }
  }, [isOpen, editableImage]);

  const ValidationSchema = Yup.object().shape({
    picture: Yup.mixed()
      .nullable()
      .required(tCommon("validation_fieldIsRequired") as string)
      .test("is-valid-type", tCommon("unsupportedImageFormats"), (value) => {
        return value && value.name
          ? validateFileType(value.name.toLowerCase(), validFileExtensions)
          : true;
      })
      .test("is-valid-size", tCommon("maxAllowedImgSizeErr"), (value) =>
        value && value.size ? value.size <= MAX_FILE_SIZE : true
      ),
  });

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      // Crop
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();

      if (isAvatarCrop) {
        // Round
        const roundedCanvas = getRoundedCanvas(croppedCanvas);
        // Show
        setCropData(roundedCanvas.toDataURL());
      } else {
        setCropData(croppedCanvas.toDataURL());
      }
      setIsCropperActive(false);
    }
  };

  const initializeZoom = () => {
    const imageData = cropperRef.current?.cropper.getImageData();
    if (imageData) {
      const minSliderZoom = imageData.width / imageData.naturalWidth;
      setMinZoomValue(minSliderZoom);
    }
  };

  // this function used for initialize crop box size for get banner images
  const initializeCropBox = () => {
    const cropBoxData = cropperRef.current?.cropper.getCropBoxData();

    // according to design banners have 300px height
    const cropBoxHeight = 300;

    if (cropBoxData && !aspectRatio) {
      cropperRef.current?.cropper.setCropBoxData({
        ...cropBoxData,
        height: cropBoxHeight,
      });
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    formikRef.current?.setErrors({});
    setCropData(null);
    setIsCropperActive(false);
    setInitialValues(initialCropValues);
    onClose();
  };

  const handleSubmit = (values: CropValues) => {
    const croppedImg = cropData || URL.createObjectURL(values.picture as File);

    onSubmit(croppedImg);
    handleClose();
  };

  if (!initialValues) return null;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
      innerRef={formikRef}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {(props: FormikProps<CropValues>) => {
        const { values, errors, handleBlur, touched } = props;

        return (
          <Modal
            size="lg"
            open={isOpen}
            onClose={handleClose}
            className="cropImageModal"
          >
            <Form className="form-style cropModal">
              <Modal.Header>
                <Modal.Title className="cropTitle">
                  {tCommon("uploadAndEditImageModal")}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <label>
                  <Field
                    name="picture"
                    component={CustomFileInput}
                    errorMessage={
                      errors["picture"] ? errors["picture"] : undefined
                    }
                    touched={touched["picture"]}
                    onBlur={handleBlur}
                    handleChange={() => setIsCropperActive(true)}
                  />
                </label>
                <p className="imageRequirements">
                  {tCommon("imageRequirements")}
                </p>

                {values.picture && !errors.picture && isCropperActive ? (
                  <div className="m-top">
                    <Cropper
                      ref={cropperRef}
                      preview=".img-preview"
                      src={URL.createObjectURL(values.picture)}
                      background={false}
                      responsive={true}
                      className={isAvatarCrop ? "avatarCropper" : "cropper"}
                      cropBoxResizable={false}
                      dragMode={"move"}
                      toggleDragModeOnDblclick={false}
                      guides={false}
                      autoCropArea={1}
                      center={false}
                      aspectRatio={aspectRatio}
                      viewMode={1}
                      zoomOnTouch={false}
                      zoomOnWheel={false}
                      ready={() => {
                        initializeZoom();
                        initializeCropBox();
                      }}
                    />

                    <RangeZoomInput
                      picture={values.picture}
                      handleChangeZoom={(ratio: number) =>
                        cropperRef.current?.cropper.zoomTo(ratio)
                      }
                      minZoomValue={minZoomValue}
                    />

                    <button
                      type="button"
                      onClick={() => getCropData()}
                      className="btn-primary cropImageBtn"
                    >
                      {tCommon("cropImage")}
                    </button>
                  </div>
                ) : null}

                {cropData && !isCropperActive && (
                  <div className="previewImgWrap m-top">
                    <h6 className="m-btm previewImgWrap__title">
                      {tCommon("croppedImage")}
                    </h6>
                    <img
                      src={cropData}
                      alt="croppedImg"
                      className={previewImgClassName}
                    />
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <div className="d-flex cropModal__footer">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="btn-tertiary"
                  >
                    {tCommon("form_cancel")}
                  </button>

                  <button type="submit" className="btn-primary">
                    {tCommon("form_save")}
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CropImageModal;
