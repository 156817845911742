import { dateTimeTemplates } from "appConsts";
import { DateTimeTemplate } from "../../types/dateTemplateType";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import i18next from "i18next";
import { parseDateFromString } from "./parseDateFromString";

type ValidFormatKey = keyof DateTimeTemplate["en"];

export function getDateTimeString(
  dateTime: string,
  formatKey: ValidFormatKey,
  locale: Locale
): string {
  const dateTimeParsed = parseDateFromString(dateTime);
  const language = locale.code && locale.code.substring(0, 2);
  if (!dateTimeTemplates || !language || !dateTimeTemplates[language]) {
    return "";
  }
  const template = dateTimeTemplates[language];
  const formatString = template[formatKey];
  if (formatKey === "fullDateTime") {
    const today = isToday(dateTimeParsed);
    const yesterday = isYesterday(dateTimeParsed);
    const localizedToday = i18next.t("today", { ns: "common" }) as string;
    const localizedYesterday = i18next.t("yesterday", {
      ns: "common",
    }) as string;
    const period = language === "en" ? "a" : "";
    const hour = dateTimeParsed.getHours();
    const formattedHour = language === "en" ? hour % 12 || 12 : hour;
    const formatDate = format(dateTimeParsed, `${formattedHour}:mm ${period}`);
    if (today) {
      return `${formatDate} ${localizedToday} `;
    }

    if (yesterday) {
      return `${formatDate} ${localizedYesterday} `;
    }
  }

  return format(dateTimeParsed, formatString, {
    locale,
  }).replace(/[.]/g, "");
}
