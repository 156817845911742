import { AxiosError } from "axios";
import i18next from "i18next";
import cloneDeep from "lodash/cloneDeep";
import toPath from "lodash/toPath";
import { serverError } from "../../types/serverError";

const camelCaseItem = (k: string): string => {
  return k.length > 1 ? k[0].toLowerCase() + k.slice(1) : k.toLowerCase();
};

const camelCaseKey = (key: string): string => {
  return key.split(".").map(camelCaseItem).join(".");
};

/**
 * helper for handling errors in form fields
 * check unit tests for work examples
 */
export const handleServerError = (
  e: serverError,
  prefix?: string | undefined
): Record<string, string> => {
  let errorsFormFields: Record<string, string> = {};

  const errors = cloneDeep(e.errors) || {};
  const prefixLocal = (prefix = prefix || "");
  let prefixPath = toPath(prefix);

  let errorKeys = Object.keys(errors);
  for (let key of errorKeys
    .sort((a, b) => b.length - a.length)
    .filter((k) => prefixLocal === "" || k.startsWith(prefixLocal))) {
    let path = toPath(key);

    // take errors from single level, exclude nested objects
    if (prefixPath.length + 1 < path.length) {
      continue;
    }

    // check if key in a complex object (errors have keys with same beginning path)
    // if complex object - exclude
    if (
      key !== "" &&
      prefixPath.length !== path.length &&
      errorKeys.some((errorKey) => {
        let errorKeyPath = toPath(errorKey);
        if (
          errorKeyPath.length > path.length &&
          path.every((pi, i) => errorKeyPath[i] === pi)
        ) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      continue;
    }

    let keyHasDifference = prefixPath.length !== path.length;

    let newKey = keyHasDifference
      ? camelCaseKey(
          prefixLocal !== ""
            ? key.substring(prefixLocal.length + 1)
            : key.substring(prefixLocal.length)
        )
      : "_"; // "_" is a common identifier to contain aggregate errors
    errorsFormFields[newKey] = errors[key].join("; ");
  }

  return errorsFormFields;
};

export const getErrorMessage = (
  e: AxiosError,
  fallbackMessage?: string
): string => {
  fallbackMessage =
    fallbackMessage ??
    (i18next.t("serverErrorWhileLoading", { ns: "common" }) as string);
  const error = e.response?.data as serverError;
  const errorMessage = error?.message || fallbackMessage;

  return errorMessage;
};
