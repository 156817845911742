import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { FC } from "react";
import { AppPermissions } from "../../utils/consts";
import HasPermissions from "../../auth/HasPermissions";
import { useTranslation } from "react-i18next";

type NavigationLinkProps = {
  path: string;
  handleClick: () => void;
  title: string;
  permissions?: AppPermissions[];
};

const NavigationLink: FC<NavigationLinkProps> = ({
  handleClick,
  title,
  path,
  permissions,
}) => {
  const { t } = useTranslation("layout");

  if (permissions) {
    return (
      <HasPermissions requiredPermissions={permissions}>
        <NavLink
          to={path}
          className={({ isActive }) =>
            classNames("menu__groupLink", { active: isActive })
          }
          onClick={handleClick}
        >
          {t(title)}
        </NavLink>
      </HasPermissions>
    );
  }

  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        classNames("menu__groupLink", { active: isActive })
      }
      onClick={handleClick}
    >
      {t(title)}
    </NavLink>
  );
};

export default NavigationLink;
