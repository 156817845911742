import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeOffProfileState } from "../../types";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../../handleUpdateState";
import {
  TimeOffRequestListItemDto,
  UserTimeOffBacklogSortBy,
  UserTimeOffBalanceListItemDto,
  UserTimeOffRequestDto,
} from "../../../api/types/employeePageTypes";
import { logoutUserAction } from "../authReducer";
import { SortDirections } from "../../../api/types/commonTypes";

const initialState: TimeOffProfileState = {
  currentBalances: defaultListState,
  timeOffBacklog: {
    timeOffBacklogList: defaultStateWithInfiniteLoading,
    timeOffBacklogSorting: {
      sortBy: UserTimeOffBacklogSortBy.dateFrom,
      sortDirection: SortDirections.desc,
    },
  },
  requestData: defaultListState,
};

const timeOffProfileSlice = createSlice({
  initialState,
  name: "timeOffProfile",
  reducers: {
    // User time off current balances
    setLoadingCurrentTimeOffBalances: (state) => ({
      ...state,
      currentBalances: setStateLoading(state.currentBalances),
    }),
    setCurrentTimeOffBalances: (
      state,
      action: PayloadAction<UserTimeOffBalanceListItemDto[]>
    ) => ({
      ...state,
      currentBalances: setStateData(state.currentBalances, action.payload),
    }),
    setErrorCurrentTimeOffBalances: (state, action: PayloadAction<string>) => ({
      ...state,
      currentBalances: setStateError(state.currentBalances, action.payload),
    }),
    // User time off backlog
    setLoadingTimeOffBacklogList: (state) => ({
      ...state,
      timeOffBacklog: {
        ...state.timeOffBacklog,
        timeOffBacklogList: setInfiniteLoadLoading(
          state.timeOffBacklog.timeOffBacklogList
        ),
      },
    }),
    setTimeOffBacklogList: (
      state,
      action: PayloadAction<{
        timeOffBacklogData: TimeOffRequestListItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let backlogList = state.timeOffBacklog.timeOffBacklogList.data || [];
      backlogList = [...backlogList, ...action.payload.timeOffBacklogData];

      return {
        ...state,
        timeOffBacklog: {
          ...state.timeOffBacklog,
          timeOffBacklogList: setInfiniteLoadData(
            state.timeOffBacklog.timeOffBacklogList,
            backlogList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorTimeOffBacklogList: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffBacklog: {
        ...state.timeOffBacklog,
        timeOffBacklogList: setInfiniteLoadStateErrorMessage(
          state.timeOffBacklog.timeOffBacklogList,
          action.payload
        ),
      },
    }),
    setTimeOffBacklogSorting: (
      state,
      action: PayloadAction<[UserTimeOffBacklogSortBy, SortDirections]>
    ) => ({
      ...state,
      timeOffBacklog: {
        ...state.timeOffBacklog,
        timeOffBacklogSorting: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),

    // TimeOff Request Data
    setLoadingTimeOffRequest: (state) => ({
      ...state,
      requestData: setStateLoading(state.requestData),
    }),
    setTimeOffRequest: (
      state,
      action: PayloadAction<UserTimeOffRequestDto>
    ) => ({
      ...state,
      requestData: setStateData(state.requestData, action.payload),
    }),
    setErrorTimeOffRequest: (state, action: PayloadAction<string>) => ({
      ...state,
      requestData: setStateError(state.requestData, action.payload),
    }),
    resetTimeOffRequest: (state) => ({
      ...state,
      requestData: defaultListState,
    }),
    resetTimeOffBacklogList: (state) => ({
      ...state,
      timeOffBacklog: {
        ...state.timeOffBacklog,
        timeOffBacklogList: defaultStateWithInfiniteLoading,
      },
    }),
    resetTimeOffProfileState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default timeOffProfileSlice.reducer;

export const {
  setLoadingCurrentTimeOffBalances,
  setCurrentTimeOffBalances,
  setErrorCurrentTimeOffBalances,
  setLoadingTimeOffBacklogList,
  setTimeOffBacklogList,
  setErrorTimeOffBacklogList,
  setTimeOffBacklogSorting,
  resetTimeOffBacklogList,
  resetTimeOffProfileState,
  setTimeOffRequest,
  setErrorTimeOffRequest,
  setLoadingTimeOffRequest,
  resetTimeOffRequest,
} = timeOffProfileSlice.actions;
