import { SortDirections } from "../../api/types/commonTypes";
import { Icon } from "../components";

export const renderSortIcon = (
  columnName: string,
  sortBy: string,
  sortDirection: string,
  handleSortOrdersList: (columnName: string, direction: SortDirections) => void
) => {
  if (columnName === sortBy) {
    return sortDirection === SortDirections.asc ? (
      <div
        className="iconSortWrap active"
        onClick={() => handleSortOrdersList(columnName, SortDirections.desc)}
      >
        <Icon href="#arrowSorting" svgClass="ic-singleSortIcon" />
      </div>
    ) : (
      <div
        className="iconSortWrap active"
        onClick={() => handleSortOrdersList(columnName, SortDirections.asc)}
      >
        <Icon
          href="#arrowSorting"
          svgClass="ic-singleSortIcon ic-leftSingleSortIcon"
        />
      </div>
    );
  }

  return (
    <div
      className="iconSortWrap"
      onClick={() => handleSortOrdersList(columnName, SortDirections.asc)}
    >
      <Icon href="#arrowSorting" svgClass="ic-sortIcon ic-leftSortIcon" />
      <Icon href="#arrowSorting" svgClass="ic-sortIcon" />
    </div>
  );
};
