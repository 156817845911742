import { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { BirthdayCelebrationModal } from "./BirthdayCelebrationModal";
import { AnniversaryCelebrationModal } from "./AnniversaryCelebrationModal";
import { Features } from "utils/consts";
import { useCompanyFeatureCheck } from "utils/hooks";
import { loadBadgeMessagesSystemList } from "api/badgesApi";
import { BadgeMessageSystem } from "api/types/news";

export enum CelebrationType {
  birthday = "birthday",
  anniversary = "anniversary",
}

type CelebrationTypes = {
  isTodayBirthday: boolean;
  isTodayAnniversary: boolean;
};

export const CelebrationModal: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const lastVisit = useAppSelector((state) => state.auth.lastVisit);
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);

  const [isOpenBirthdayModal, setIsOpenBirthdayModal] =
    useState<boolean>(false);
  const [isOpenAnniversaryModal, setIsOpenAnniversaryModal] =
    useState<boolean>(false);
  const [isBirthAndAnniversaryDay, setIsBirthAndAnniversaryDay] =
    useState(false);
  const [birthDayEvent, setBirthDayEvent] = useState<BadgeMessageSystem | null>(
    null
  );
  const [hireDayEvent, setHireDayEvent] = useState<BadgeMessageSystem | null>(
    null
  );
  const getCelebrationType = useCallback(() => {
    if (!user) return { isTodayBirthday: false, isTodayAnniversary: false };

    const currentDate = new Date();
    const birthDate = user.birthDate ? new Date(user.birthDate) : null;
    const lastVisitDate = lastVisit ? new Date(lastVisit) : null;
    const hireDate = user.hireDate ? new Date(user.hireDate) : null;

    const result: CelebrationTypes = {
      isTodayBirthday: false,
      isTodayAnniversary: false,
    };

    if (!lastVisitDate) return;

    if (
      lastVisitDate?.getDate() >= currentDate.getDate() &&
      lastVisitDate?.getMonth() >= currentDate.getMonth()
    ) {
      return;
    }

    if (birthDate) {
      const isTodayBirthday =
        birthDate.getDate() === currentDate.getDate() &&
        birthDate.getMonth() === currentDate.getMonth();

      result.isTodayBirthday = isTodayBirthday;
    }

    if (hireDate) {
      const isTodayAnniversary =
        hireDate.getDate() === currentDate.getDate() &&
        hireDate.getMonth() === currentDate.getMonth() &&
        hireDate.getFullYear() !== currentDate.getFullYear();

      result.isTodayAnniversary = isTodayAnniversary;
    }

    if (result.isTodayAnniversary && result.isTodayBirthday) {
      setIsBirthAndAnniversaryDay(true);
      setIsOpenBirthdayModal(true);
      setIsOpenAnniversaryModal(false);
      return result;
    }

    if (result.isTodayAnniversary) {
      setIsOpenAnniversaryModal(true);
    }

    if (result.isTodayBirthday) {
      setIsOpenBirthdayModal(true);
    }

    return result;
  }, [lastVisit, user]);

  const getCurrentBadgeMessage = useCallback(async () => {
    if (!isGamificationAvailable || !user) return;
    const today = new Date();
    const response = await loadBadgeMessagesSystemList(
      user?.id,
      String(today.getDate()),
      String(today.getMonth() + 1)
    );
    const data = response.data.items;
    if (!data) return;
    const birthDateEvent = data.filter(
      (event) => event.autoEventType === "BirthDate"
    );
    const hireDateEvent = data.filter(
      (event) => event.autoEventType === "HireDate"
    );

    if (hireDateEvent) {
      setHireDayEvent(hireDateEvent[0]);
    }
    if (birthDateEvent) {
      setBirthDayEvent(birthDateEvent[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isGamificationAvailable]);

  const closeBirthdayModal = () => {
    setIsOpenBirthdayModal(false);

    if (isBirthAndAnniversaryDay) {
      setIsOpenAnniversaryModal(true);
    }
  };

  useEffect(() => {
    if (!user) return;

    const celebrationTypes = getCelebrationType();
    if (
      (celebrationTypes && celebrationTypes.isTodayBirthday) ||
      (celebrationTypes && celebrationTypes.isTodayAnniversary)
    ) {
      getCurrentBadgeMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpenBirthdayModal && (
        <BirthdayCelebrationModal
          event={birthDayEvent}
          onClose={closeBirthdayModal}
        />
      )}
      {isOpenAnniversaryModal && (
        <AnniversaryCelebrationModal
          event={hireDayEvent}
          onClose={() => setIsOpenBirthdayModal(false)}
        />
      )}
    </>
  );
};
