import { FC, useMemo } from "react";
import { useAppSelector } from "../store";
import IPropsWithChildren from "../utils/types/props-with-children";

type Props = {
  requiredPermissions: string[];
} & IPropsWithChildren;

const HasPermissions: FC<Props> = ({ requiredPermissions, children }) => {
  const userPermissions = useAppSelector(
    (state) => state.auth.profile?.permissions
  );

  const hasPermission = useMemo(() => {
    const checkPermission = () => {
      for (const permission of requiredPermissions) {
        if (userPermissions?.includes(permission)) {
          return true;
        }
      }

      return false;
    };

    return checkPermission();
  }, [userPermissions, requiredPermissions]);

  if (hasPermission) {
    return <>{children}</>;
  }

  return null;
};

export default HasPermissions;
