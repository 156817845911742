import HasPermissions from "auth/HasPermissions";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { Icon, LoaderSize, Loader } from "utils/components";
import { AppPermissions } from "utils/consts";
import { useParams } from "react-router-dom";
import MerchDeliveryInfoForm, { MerchDeliveryInfoValues } from "./MerchDeliveryInfoForm";
import MerchDeliveryInfoList from "./MerchDeliveryInfoList";
import { UserCardMerchDeliveryInfoDto } from "api/types/employeePageTypes";
import { usePermissionToEmployeeCard } from "pages/employeeCard/person-info/hooks/usePermissionToEmployeeCard";
import MerchDeliveryHistoryPanel from "./MerchDeliveryInfoHistoryDetails";

export type MerchDeliveryInfoBlockProps = {
  initialFormValues: MerchDeliveryInfoValues;
  handleSubmitMerchDeliveryInfo: (
    userId: number,
    body: UserCardMerchDeliveryInfoDto
  ) => void;
  editMode: boolean;
  setEditMode: (val: boolean) => void;
};

const MerchDeliveryInfoBlock: FC<MerchDeliveryInfoBlockProps> = ({
  initialFormValues,
  handleSubmitMerchDeliveryInfo,
  editMode,
  setEditMode
}) => {
  const { userId } = useParams();
  const { t } = useTranslation("employeePage");

  const isUserHavePermissions = usePermissionToEmployeeCard(userId);

  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.employeePage.employeeData
  );

  const [isHistoryMerchDeliveryModalOpen, setIsHistoryMerchDeliveryModalOpen] =
    useState(false);

  return (
    <div className="deliveryInfoBlock">
      <div className="pageTitleBlock m-btm">
        <h1 className="main-title">{t("delivery_tab")}</h1>

        <div className="d-flex">
          <HasPermissions
            requiredPermissions={[
              AppPermissions.EmployeeCard_ViewAndEditMerchDeliveryInfo,
            ]}
          >
            <button
              type="button"
              className="btn-regular actionBtn-tertiary"
              onClick={() => setIsHistoryMerchDeliveryModalOpen(true)}
            >
              <Icon href="#archive_events" svgClass="pageTitleBlock__icon" />
            </button>
          </HasPermissions>

          {data && isUserHavePermissions && (
            <button
              type="button"
              className="btn-regular actionBtn-tertiary"
              onClick={() => setEditMode(!editMode)}
            >
              <Icon
                href={editMode ? "#close-popup" : "#edite"}
                svgClass="pageTitleBlock__icon"
              />
            </button>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}

      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}
      {data && (
        <>
          {editMode ? (
            <MerchDeliveryInfoForm
              initialValues={initialFormValues}
              userId={data.id}
              setEditMode={setEditMode}
              handleSubmitMerchDeliveryInfo={handleSubmitMerchDeliveryInfo}
            />
          ) : (
            <MerchDeliveryInfoList />
          )}
        </>
      )}
      <MerchDeliveryHistoryPanel
        isModalOpen={isHistoryMerchDeliveryModalOpen}
        onCloseModal={() => setIsHistoryMerchDeliveryModalOpen(false)}
      />
    </div>
  );
};

export default MerchDeliveryInfoBlock;
