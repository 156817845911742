export const countries = [
  {
    value: "Afghanistan",
    flag: "https://flagcdn.com/af.svg",
  },
  {
    value: "Albania",
    flag: "https://flagcdn.com/al.svg",
  },
  {
    value: "Algeria",
    flag: "https://flagcdn.com/dz.svg",
  },
  {
    value: "Andorra",
    flag: "https://flagcdn.com/ad.svg",
  },
  {
    value: "Angola",
    flag: "https://flagcdn.com/ao.svg",
  },
  {
    value: "AntiguaAndBarbuda",
    flag: "https://flagcdn.com/ag.svg",
  },
  {
    value: "Argentina",
    flag: "https://flagcdn.com/ar.svg",
  },
  {
    value: "Armenia",
    flag: "https://flagcdn.com/am.svg",
  },
  {
    value: "Aruba",
    flag: "https://flagcdn.com/aw.svg",
  },
  {
    value: "Australia",
    flag: "https://flagcdn.com/au.svg",
  },
  {
    value: "Austria",
    flag: "https://flagcdn.com/at.svg",
  },
  {
    value: "Azerbaijan",
    flag: "https://flagcdn.com/az.svg",
  },
  {
    value: "Bahamas",
    flag: "https://flagcdn.com/bs.svg",
  },
  {
    value: "Bahrain",
    flag: "https://flagcdn.com/bh.svg",
  },
  {
    value: "Bangladesh",
    flag: "https://flagcdn.com/bd.svg",
  },
  {
    value: "Barbados",
    flag: "https://flagcdn.com/bb.svg",
  },
  {
    value: "Belarus",
    flag: "https://flagcdn.com/by.svg",
  },
  {
    value: "Belgium",
    flag: "https://flagcdn.com/be.svg",
  },
  {
    value: "Belize",
    flag: "https://flagcdn.com/bz.svg",
  },
  {
    value: "Benin",
    flag: "https://flagcdn.com/bj.svg",
  },
  {
    value: "Bhutan",
    flag: "https://flagcdn.com/bt.svg",
  },
  {
    value: "Bolivia",
    flag: "https://flagcdn.com/bo.svg",
  },
  {
    value: "BosniaAndHerzegovina",
    flag: "https://flagcdn.com/ba.svg",
  },
  {
    value: "Botswana",
    flag: "https://flagcdn.com/bw.svg",
  },
  {
    value: "Brazil",
    flag: "https://flagcdn.com/br.svg",
  },
  {
    value: "BritishIndianOceanTerritory",
    flag: "https://flagcdn.com/io.svg",
  },
  {
    value: "BruneiDarussalam",
    flag: "https://flagcdn.com/bn.svg",
  },
  {
    value: "Bulgaria",
    flag: "https://flagcdn.com/bg.svg",
  },
  {
    value: "BurkinaFaso",
    flag: "https://flagcdn.com/bf.svg",
  },
  {
    value: "Burundi",
    flag: "https://flagcdn.com/bi.svg",
  },
  {
    value: "Canada",
    flag: "https://flagcdn.com/ca.svg",
  },
  {
    value: "Cambodia",
    flag: "https://flagcdn.com/kh.svg",
  },
  {
    value: "Cameroon",
    flag: "https://flagcdn.com/cm.svg",
  },
  {
    value: "CaboVerde",
    flag: "https://flagcdn.com/cv.svg",
  },
  {
    value: "CentralAfricanRepublic",
    flag: "https://flagcdn.com/cf.svg",
  },
  {
    value: "Chad",
    flag: "https://flagcdn.com/td.svg",
  },
  {
    value: "Chile",
    flag: "https://flagcdn.com/cl.svg",
  },
  {
    value: "China",
    flag: "https://flagcdn.com/cn.svg",
  },
  {
    value: "Colombia",
    flag: "https://flagcdn.com/co.svg",
  },
  {
    value: "Comoros",
    flag: "https://flagcdn.com/km.svg",
  },
  {
    value: "Congo",
    flag: "https://flagcdn.com/cg.svg",
  },
  {
    value: "CongoDemocraticRepublic",
    flag: "https://flagcdn.com/cd.svg",
  },
  {
    value: "CookIslands",
    flag: "https://flagcdn.com/ck.svg",
  },
  {
    value: "CostaRica",
    flag: "https://flagcdn.com/cr.svg",
  },
  {
    value: "CoteDivoire",
    flag: "https://flagcdn.com/ci.svg",
  },
  {
    value: "Croatia",
    flag: "https://flagcdn.com/hr.svg",
  },
  {
    value: "Cuba",
    flag: "https://flagcdn.com/cu.svg",
  },
  {
    value: "Cyprus",
    flag: "https://flagcdn.com/cy.svg",
  },
  {
    value: "CzechRepublic",
    flag: "https://flagcdn.com/cz.svg",
  },
  {
    value: "Denmark",
    flag: "https://flagcdn.com/dk.svg",
  },
  {
    value: "DominicanRepublic",
    flag: "https://flagcdn.com/do.svg",
  },
  {
    value: "Djibouti",
    flag: "https://flagcdn.com/dj.svg",
  },
  {
    value: "Ecuador",
    flag: "https://flagcdn.com/ec.svg",
  },
  {
    value: "Egypt",
    flag: "https://flagcdn.com/eg.svg",
  },
  {
    value: "ElSalvador",
    flag: "https://flagcdn.com/sv.svg",
  },
  {
    value: "EquatorialGuinea",
    flag: "https://flagcdn.com/gq.svg",
  },
  {
    value: "Eritrea",
    flag: "https://flagcdn.com/er.svg",
  },
  {
    value: "Estonia",
    flag: "https://flagcdn.com/ee.svg",
  },
  {
    value: "Ethiopia",
    flag: "https://flagcdn.com/et.svg",
  },
  {
    value: "FalklandIslands",
    flag: "https://flagcdn.com/fk.svg",
  },
  {
    value: "FaroeIslands",
    flag: "https://flagcdn.com/fo.svg",
  },
  {
    value: "Fiji",
    flag: "https://flagcdn.com/fj.svg",
  },
  {
    value: "Finland",
    flag: "https://flagcdn.com/fi.svg",
  },
  {
    value: "France",
    flag: "https://flagcdn.com/fr.svg",
  },
  {
    value: "FrenchPolynesia",
    flag: "https://flagcdn.com/pf.svg",
  },
  {
    value: "Gabon",
    flag: "https://flagcdn.com/ga.svg",
  },
  {
    value: "Gambia",
    flag: "https://flagcdn.com/gm.svg",
  },
  {
    value: "Georgia",
    flag: "https://flagcdn.com/ge.svg",
  },
  {
    value: "Germany",
    flag: "https://flagcdn.com/de.svg",
  },
  {
    value: "Ghana",
    flag: "https://flagcdn.com/gh.svg",
  },
  {
    value: "Gibraltar",
    flag: "https://flagcdn.com/gi.svg",
  },
  {
    value: "Greece",
    flag: "https://flagcdn.com/gr.svg",
  },
  {
    value: "Greenland",
    flag: "https://flagcdn.com/gl.svg",
  },
  {
    value: "Grenada",
    flag: "https://flagcdn.com/gd.svg",
  },
  {
    value: "Guatemala",
    flag: "https://flagcdn.com/gt.svg",
  },
  {
    value: "Guinea",
    flag: "https://flagcdn.com/gn.svg",
  },
  {
    value: "GuineaBissau",
    flag: "https://flagcdn.com/gw.svg",
  },
  {
    value: "Guyana",
    flag: "https://flagcdn.com/gy.svg",
  },
  {
    value: "Haiti",
    flag: "https://flagcdn.com/ht.svg",
  },
  {
    value: "Honduras",
    flag: "https://flagcdn.com/hn.svg",
  },
  {
    value: "HongKong",
    flag: "https://flagcdn.com/hk.svg",
  },
  {
    value: "Hungary",
    flag: "https://flagcdn.com/hu.svg",
  },
  {
    value: "Iceland",
    flag: "https://flagcdn.com/is.svg",
  },
  {
    value: "India",
    flag: "https://flagcdn.com/in.svg",
  },
  {
    value: "Indonesia",
    flag: "https://flagcdn.com/id.svg",
  },
  {
    value: "Iran",
    flag: "https://flagcdn.com/ir.svg",
  },
  {
    value: "Iraq",
    flag: "https://flagcdn.com/iq.svg",
  },
  {
    value: "Ireland",
    flag: "https://flagcdn.com/ie.svg",
  },
  {
    value: "Israel",
    flag: "https://flagcdn.com/il.svg",
  },
  {
    value: "Italy",
    flag: "https://flagcdn.com/it.svg",
  },
  {
    value: "Japan",
    flag: "https://flagcdn.com/jp.svg",
  },
  {
    value: "Jamaica",
    flag: "https://flagcdn.com/jm.svg",
  },
  {
    value: "Jordan",
    flag: "https://flagcdn.com/jo.svg",
  },
  {
    value: "Kazakhstan",
    flag: "https://flagcdn.com/kz.svg",
  },
  {
    value: "Kenya",
    flag: "https://flagcdn.com/ke.svg",
  },
  {
    value: "Kiribati",
    flag: "https://flagcdn.com/ki.svg",
  },
  {
    value: "KoreaNorth",
    flag: "https://flagcdn.com/kp.svg",
  },
  {
    value: "KoreaSouth",
    flag: "https://flagcdn.com/kr.svg",
  },
  {
    value: "Kuwait",
    flag: "https://flagcdn.com/kw.svg",
  },
  {
    value: "Kyrgyzstan",
    flag: "https://flagcdn.com/kg.svg",
  },
  {
    value: "Laos",
    flag: "https://flagcdn.com/la.svg",
  },
  {
    value: "Latvia",
    flag: "https://flagcdn.com/lv.svg",
  },
  {
    value: "Lebanon",
    flag: "https://flagcdn.com/lb.svg",
  },
  {
    value: "Lesotho",
    flag: "https://flagcdn.com/ls.svg",
  },
  {
    value: "Liberia",
    flag: "https://flagcdn.com/lr.svg",
  },
  {
    value: "Libya",
    flag: "https://flagcdn.com/ly.svg",
  },
  {
    value: "Liechtenstein",
    flag: "https://flagcdn.com/li.svg",
  },
  {
    value: "Lithuania",
    flag: "https://flagcdn.com/lt.svg",
  },
  {
    value: "Luxembourg",
    flag: "https://flagcdn.com/lu.svg",
  },
  {
    value: "Macao",
    flag: "https://flagcdn.com/mo.svg",
  },
  {
    value: "Madagascar",
    flag: "https://flagcdn.com/mg.svg",
  },
  {
    value: "Malawi",
    flag: "https://flagcdn.com/mw.svg",
  },
  {
    value: "Malaysia",
    flag: "https://flagcdn.com/my.svg",
  },
  {
    value: "Maldives",
    flag: "https://flagcdn.com/mv.svg",
  },
  {
    value: "Mali",
    flag: "https://flagcdn.com/ml.svg",
  },
  {
    value: "Malta",
    flag: "https://flagcdn.com/mt.svg",
  },
  {
    value: "MarshallIslands",
    flag: "https://flagcdn.com/mh.svg",
  },
  {
    value: "Mauritania",
    flag: "https://flagcdn.com/mr.svg",
  },
  {
    value: "Mauritius",
    flag: "https://flagcdn.com/mu.svg",
  },
  {
    value: "Mexico",
    flag: "https://flagcdn.com/mx.svg",
  },
  {
    value: "Micronesia",
    flag: "https://flagcdn.com/fm.svg",
  },
  {
    value: "Moldova",
    flag: "https://flagcdn.com/md.svg",
  },
  {
    value: "Monaco",
    flag: "https://flagcdn.com/mc.svg",
  },
  {
    value: "Mongolia",
    flag: "https://flagcdn.com/mn.svg",
  },
  {
    value: "Montenegro",
    flag: "https://flagcdn.com/me.svg",
  },
  {
    value: "Morocco",
    flag: "https://flagcdn.com/ma.svg",
  },
  {
    value: "Mozambique",
    flag: "https://flagcdn.com/mz.svg",
  },
  {
    value: "Myanmar",
    flag: "https://flagcdn.com/mm.svg",
  },
  {
    value: "Namibia",
    flag: "https://flagcdn.com/na.svg",
  },
  {
    value: "Nepal",
    flag: "https://flagcdn.com/np.svg",
  },
  {
    value: "Netherlands",
    flag: "https://flagcdn.com/nl.svg",
  },
  {
    value: "NewCaledonia",
    flag: "https://flagcdn.com/nc.svg",
  },
  {
    value: "NewZealand",
    flag: "https://flagcdn.com/nz.svg",
  },
  {
    value: "Nicaragua",
    flag: "https://flagcdn.com/ni.svg",
  },
  {
    value: "Niger",
    flag: "https://flagcdn.com/ne.svg",
  },
  {
    value: "Nigeria",
    flag: "https://flagcdn.com/ng.svg",
  },
  {
    value: "Niue",
    flag: "https://flagcdn.com/nu.svg",
  },
  {
    value: "NorfolkIsland",
    flag: "https://flagcdn.com/nf.svg",
  },
  {
    value: "NorthMacedonia",
    flag: "https://flagcdn.com/mk.svg",
  },
  {
    value: "Norway",
    flag: "https://flagcdn.com/no.svg",
  },
  {
    value: "Oman",
    flag: "https://flagcdn.com/om.svg",
  },
  {
    value: "Pakistan",
    flag: "https://flagcdn.com/pk.svg",
  },
  {
    value: "Palau",
    flag: "https://flagcdn.com/pw.svg",
  },
  {
    value: "Panama",
    flag: "https://flagcdn.com/pa.svg",
  },
  {
    value: "PapuaNewGuinea",
    flag: "https://flagcdn.com/pg.svg",
  },
  {
    value: "Paraguay",
    flag: "https://flagcdn.com/py.svg",
  },
  {
    value: "Peru",
    flag: "https://flagcdn.com/pe.svg",
  },
  {
    value: "Philippines",
    flag: "https://flagcdn.com/ph.svg",
  },
  {
    value: "Poland",
    flag: "https://flagcdn.com/pl.svg",
  },
  {
    value: "Portugal",
    flag: "https://flagcdn.com/pt.svg",
  },
  {
    value: "Qatar",
    flag: "https://flagcdn.com/qa.svg",
  },
  {
    value: "Romania",
    flag: "https://flagcdn.com/ro.svg",
  },
  {
    value: "RussianFederation",
    flag: "https://flagcdn.com/ru.svg",
  },
  {
    value: "Rwanda",
    flag: "https://flagcdn.com/rw.svg",
  },
  {
    value: "Samoa",
    flag: "https://flagcdn.com/ws.svg",
  },
  {
    value: "SanMarino",
    flag: "https://flagcdn.com/sm.svg",
  },
  {
    value: "SaoTomeAndPrincipe",
    flag: "https://flagcdn.com/st.svg",
  },
  {
    value: "SaudiArabia",
    flag: "https://flagcdn.com/sa.svg",
  },
  {
    value: "Senegal",
    flag: "https://flagcdn.com/sn.svg",
  },
  {
    value: "Serbia",
    flag: "https://flagcdn.com/rs.svg",
  },
  {
    value: "Seychelles",
    flag: "https://flagcdn.com/sc.svg",
  },
  {
    value: "SierraLeone",
    flag: "https://flagcdn.com/sl.svg",
  },
  {
    value: "Singapore",
    flag: "https://flagcdn.com/sg.svg",
  },
  {
    value: "Slovakia",
    flag: "https://flagcdn.com/sk.svg",
  },
  {
    value: "Slovenia",
    flag: "https://flagcdn.com/si.svg",
  },
  {
    value: "SolomonIslands",
    flag: "https://flagcdn.com/sb.svg",
  },
  {
    value: "Somalia",
    flag: "https://flagcdn.com/so.svg",
  },
  {
    value: "SouthAfrica",
    flag: "https://flagcdn.com/za.svg",
  },
  {
    value: "Spain",
    flag: "https://flagcdn.com/es.svg",
  },
  {
    value: "SriLanka",
    flag: "https://flagcdn.com/lk.svg",
  },
  {
    value: "Sudan",
    flag: "https://flagcdn.com/sd.svg",
  },
  {
    value: "Suriname",
    flag: "https://flagcdn.com/sr.svg",
  },
  {
    value: "Swaziland",
    flag: "https://flagcdn.com/sz.svg",
  },
  {
    value: "Sweden",
    flag: "https://flagcdn.com/se.svg",
  },
  {
    value: "Switzerland",
    flag: "https://flagcdn.com/ch.svg",
  },
  {
    value: "SyrianArabRepublic",
    flag: "https://flagcdn.com/sy.svg",
  },
  {
    value: "Taiwan",
    flag: "https://flagcdn.com/tw.svg",
  },
  {
    value: "Tajikistan",
    flag: "https://flagcdn.com/tj.svg",
  },
  {
    value: "Tanzania",
    flag: "https://flagcdn.com/tz.svg",
  },
  {
    value: "Thailand",
    flag: "https://flagcdn.com/th.svg",
  },
  {
    value: "TimorLeste",
    flag: "https://flagcdn.com/tl.svg",
  },
  {
    value: "Togo",
    flag: "https://flagcdn.com/tg.svg",
  },
  {
    value: "Tokelau",
    flag: "https://flagcdn.com/tk.svg",
  },
  {
    value: "Tonga",
    flag: "https://flagcdn.com/to.svg",
  },
  {
    value: "Tunisia",
    flag: "https://flagcdn.com/tn.svg",
  },
  {
    value: "Turkey",
    flag: "https://flagcdn.com/tr.svg",
  },
  {
    value: "Turkmenistan",
    flag: "https://flagcdn.com/tm.svg",
  },
  {
    value: "Tuvalu",
    flag: "https://flagcdn.com/tv.svg",
  },
  {
    value: "Uganda",
    flag: "https://flagcdn.com/ug.svg",
  },
  {
    value: "Ukraine",
    flag: "https://flagcdn.com/ua.svg",
  },
  {
    value: "UnitedArabEmirates",
    flag: "https://flagcdn.com/ae.svg",
  },
  {
    value: "UnitedKingdom",
    flag: "https://flagcdn.com/gb.svg",
  },
  {
    value: "UnitedStates",
    flag: "https://flagcdn.com/us.svg",
  },
  {
    value: "Uruguay",
    flag: "https://flagcdn.com/uy.svg",
  },
  {
    value: "Vanuatu",
    flag: "https://flagcdn.com/vu.svg",
  },
  {
    value: "Venezuela",
    flag: "https://flagcdn.com/ve.svg",
  },
  {
    value: "Vietnam",
    flag: "https://flagcdn.com/vn.svg",
  },
  {
    value: "WallisAndFutuna",
    flag: "https://flagcdn.com/wf.svg",
  },
  {
    value: "Yemen",
    flag: "https://flagcdn.com/ye.svg",
  },
  {
    value: "Zambia",
    flag: "https://flagcdn.com/zm.svg",
  },
  {
    value: "Zimbabwe",
    flag: "https://flagcdn.com/zw.svg",
  },
];
