import { loadBadgesList } from "../../api/badgesApi";
import { getErrorMessage } from "./hangleServerError";
import { AxiosError } from "axios";
import { BadgesDataState } from "../../api/types/badgesTypes";

// This function is used for get list of badges, divided on groups in SendFeedbackModal, AddEditAutoEventModal, AddEditTimeOffPolicyTypeModal

export const fetchBadges = async (
  setBadgeGroupsData: (v: BadgesDataState) => void
) => {
  setBadgeGroupsData({
    isLoading: true,
    badgeGroupsList: null,
    errorMessage: "",
  });
  try {
    const response = await loadBadgesList();
    const badgeGroupsFromServer = response.data.items;
    setBadgeGroupsData({
      isLoading: false,
      badgeGroupsList: badgeGroupsFromServer,
      errorMessage: "",
    });
    return badgeGroupsFromServer;
  } catch (e) {
    const errorMessage = getErrorMessage(e as AxiosError);
    setBadgeGroupsData({
      isLoading: false,
      badgeGroupsList: null,
      errorMessage,
    });
  }
};
