import { TFunction } from "i18next";
import { OrderStatuses } from "../../../../api/types/merchDeliveryTypes";

const statusMap = {
  [OrderStatuses.Created]: "statusDelivery_created",
  [OrderStatuses.InProgress]: "statusDelivery_inProgress",
  [OrderStatuses.Delivered]: "statusDelivery_delivered",
  [OrderStatuses.Cancelled]: "statusDelivery_cancelled",
};

export function getOrderStatusNameLocalized(
  t: TFunction<"merchDelivery">,
  apiStatus: OrderStatuses | string
): string {
  return t([
    statusMap[apiStatus as OrderStatuses] ?? "statusDelivery_unknown",
    "statusDelivery_unknown",
  ]);
}
