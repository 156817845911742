import { AxiosPromise } from "axios";
import { base_url } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  GetUserSlackDialogLinkResponse,
  IntegrationDto,
  RegisterMicrosoftRequest,
  RegisterPeopleForceRequest,
  RegisterSlackRequest,
  SlackOAuthDataDto,
} from "./types/integrationTypes";

export const loadIntegrationStatus = (
  integrationType: string
): AxiosPromise<IntegrationDto> =>
  axiosInstance.get(
    `${base_url}/api/management/integrations/${integrationType}`
  );

export const loadSlackOAuthData = (): AxiosPromise<SlackOAuthDataDto> =>
  axiosInstance.get(`${base_url}/api/management/integrations/slack/oauth-data`);

export const registerSlackIntegration = (
  body: RegisterSlackRequest
): AxiosPromise<IntegrationDto> =>
  axiosInstance.post(
    `${base_url}/api/management/integrations/slack/register`,
    body
  );

export const deactivateSlackIntegration = (): AxiosPromise<IntegrationDto> =>
  axiosInstance.post(
    `${base_url}/api/management/integrations/slack/unregister`,
    {}
  );

export const gtSlackDialogLink = (
  userId: number
): AxiosPromise<GetUserSlackDialogLinkResponse> =>
  axiosInstance.get(`${base_url}/api/integrations/slack/slack-link/${userId}`);

export const registerMicrosoftIntegration = (
  body: RegisterMicrosoftRequest
): AxiosPromise<IntegrationDto> =>
  axiosInstance.post(
    `${base_url}/api/management/integrations/microsoft/register`,
    body
  );

export const deactivateMicrosoftIntegration =
  (): AxiosPromise<IntegrationDto> =>
    axiosInstance.post(
      `${base_url}/api/management/integrations/microsoft/unregister`,
      {}
    );

export const registerPeopleForceIntegration = (
  body: RegisterPeopleForceRequest
): AxiosPromise<IntegrationDto> =>
  axiosInstance.post(
    `${base_url}/api/management/integrations/peopleforce/register`,
    body
  );

export const deactivatePeopleForceIntegration =
  (): AxiosPromise<IntegrationDto> =>
    axiosInstance.post(
      `${base_url}/api/management/integrations/peopleforce/unregister`,
      {}
    );
