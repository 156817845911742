import { useParams } from "react-router-dom";
import { useAppSelector } from "store";

export const useIsCurrentUser = () => {
  const currentUserId = useAppSelector((state) => state.auth.profile?.id);
  const { userId } = useParams();

  return (
    userId &&
    !isNaN(parseInt(userId)) &&
    currentUserId &&
    !isNaN(currentUserId) &&
    Number(currentUserId) === Number(userId)
  );
};
