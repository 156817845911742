import { UserCardDatesInfoDto } from "api/types/employeePageTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "utils/components";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { useEmployeeCardViewsPermissions } from "../hooks/useEmployeeCardViewsPermissions";
import { getDateTimeString } from "utils/methods";
import useLocale from "localization/useLocale";

type Props = {
  datesInfo: UserCardDatesInfoDto;
};

const SignificantDatesInfo: FC<Props> = ({ datesInfo }) => {
  const { t } = useTranslation("accountSettings");
  const { t: tEmployeePage } = useTranslation("employeePage");
  const locale = useLocale();
  const currentUser = useIsCurrentUser();
  const permissions = useEmployeeCardViewsPermissions();

  const getTenure = (): string => {
    if (!datesInfo.tenure) {
      return "";
    }

    let message = "";
    if (datesInfo.tenure.years > 0) {
      message += tEmployeePage("tenureYears", {
        count: datesInfo.tenure.years,
      });
      message += " ";
    }
    if (datesInfo.tenure.month > 0) {
      message += tEmployeePage("tenureMonth", {
        count: datesInfo.tenure.month,
      });
      message += " ";
    }
    if (
      (datesInfo.tenure.years === 0 && datesInfo.tenure.month === 0) ||
      datesInfo.tenure.days > 0
    ) {
      message += tEmployeePage("tenureDays", {
        count: datesInfo.tenure.days,
      });
    }

    return message;
  };

  return (
    <div className="employeePage__item">
      <p className="employeePage__label">
        {tEmployeePage("significantDates_tenure")}
      </p>
      <p className="employeePage__value">
        {currentUser || permissions.isViewEmployeeTenure ? (
          getTenure()
        ) : (
          <Icon href="#lock" svgClass="ic-lock" />
        )}
      </p>
      <p className="employeePage__label">{t("userForm_hireDate")}</p>
      <p className="employeePage__value">
        {currentUser || permissions.isViewEmployeeHireDate ? (
          datesInfo.hireDate ? (
            getDateTimeString(datesInfo.hireDate, "fullDate", locale)
          ) : (
            ""
          )
        ) : (
          <Icon href="#lock" svgClass="ic-lock" />
        )}
      </p>
      <p className="employeePage__label">{t("userForm_birthDate")}</p>
      <p className="employeePage__value">
        {currentUser || permissions.isViewEmployeeDateOfBirth ? (
          datesInfo.birthDate ? (
            getDateTimeString(datesInfo.birthDate, "fullDate", locale)
          ) : (
            ""
          )
        ) : (
          <Icon href="#lock" svgClass="ic-lock" />
        )}
      </p>
    </div>
  );
};

export default SignificantDatesInfo;
