import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { loadMerchCategoryList, loadMerchItem } from "../../api/merchStoreApi";
import { getErrorMessage } from "../../utils/methods";
import {
  resetMerchCategoryList,
  resetMerchItemPageData,
  setErrorMerchCategoryList,
  setErrorMerchItemPageData,
  setLoadingMerchCategoryList,
  setLoadingMerchItemPageData,
  setMerchItemPageData,
  setStateMerchCategoryList,
} from "../reducers/merchStoreReducer";
import { refreshCoinsCount } from "./authThunks";

export const getMerchCategoryList =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingMerchCategoryList());
    try {
      const response = await loadMerchCategoryList();
      dispatch(setStateMerchCategoryList(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorMerchCategoryList(errorMessage));
    }
  };

export const getMerchItem =
  (merchId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingMerchItemPageData());

    try {
      const response = await loadMerchItem(merchId);
      dispatch(setMerchItemPageData(response.data));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorMerchItemPageData(errorMessage));
    }
  };

export const refreshMerchItemPageData =
  (merchId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetMerchItemPageData());
    dispatch(getMerchItem(merchId));
  };

export const getMerchStoreData =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    await Promise.all([
      dispatch(refreshCoinsCount()),
      dispatch(getMerchCategoryList()),
    ]);
  };

export const refreshMerchCategoryList =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetMerchCategoryList());
    dispatch(getMerchCategoryList());
  };
