import { FC, useEffect, useState } from "react";
import LayoutRightPanel from "../LayoutRightPanel";
import Notification from "./Notification";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getAllNotifications } from "../../../store/thunks/notificationsThunks";
import { resetAllNotifications } from "../../../store/reducers/notificationsReducer";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  deleteNotification,
  readAllNotifications,
} from "../../../api/notificationsApi";
import { AxiosError } from "axios";
import { serverError } from "../../../types/serverError";
import { toastError, toastSuccess } from "../../../utils/toasts";
import LayoutConfirmationPopup from "../LayoutConfirmationPopup";
import { getErrorMessage } from "utils/methods";
import { NotificationStatuses } from "api/types/notifications";

type NotificationsRightPanelProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

const NotificationsRightPanel: FC<NotificationsRightPanelProps> = ({
  isModalOpen,
  onCloseModal,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("layout");
  const { t: tCommon } = useTranslation("common");
  const { isLoading, data, errorMessage, hasMoreData } = useAppSelector(
    (state) => state.notifications.allNotifications
  );
  const [
    isDeleteAllNotificationsModalOpen,
    setIsDeleteAllNotificationsModalOpen,
  ] = useState(false);
  const [isDeleteAllNotificationsLoading, setIsDeleteAllNotificationsLoading] =
    useState(false);

  const isDisabledAllReadBtn =
    !data ||
    !data.length ||
    data.every(
      (notification) => notification.status === NotificationStatuses.read
    );

  useEffect(() => {
    if (isModalOpen) {
      dispatch(getAllNotifications());
    }
  }, [isModalOpen]);
  const closeModal = () => {
    dispatch(resetAllNotifications());
    onCloseModal();
  };

  const fetchData = async () => {
    await dispatch(getAllNotifications());
  };

  const deleteAllNotifications = async () => {
    setIsDeleteAllNotificationsLoading(true);
    try {
      await deleteNotification(null);
      dispatch(resetAllNotifications());
      dispatch(getAllNotifications());
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("serverError"));
    } finally {
      setIsDeleteAllNotificationsLoading(false);
      setIsDeleteAllNotificationsModalOpen(false);
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      await readAllNotifications();
      await dispatch(getAllNotifications(true));
      toastSuccess(t("allNotificationsSuccessfulRead"));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      toastError(errorMessage);
    }
  };

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={closeModal}
      title={t("notifications")}
      additionalClassName="popup-small notificationPopup"
    >
      <div className="wrap" id="scrollableWrap">
        {isLoading && !data && (
          <div className="loader-container">
            <Loader size={LoaderSize.Small} />
          </div>
        )}
        {errorMessage && (
          <div className="new-wrapper error">{errorMessage}</div>
        )}
        {data && !data.length && (
          <p className="no-content-message t-center">
            {t("notificationsListEmpty")}
          </p>
        )}
        {data?.length ? (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={hasMoreData}
            loader={
              <div className="loader-container">
                <Loader size={LoaderSize.Small} />
              </div>
            }
            endMessage={
              <p className="end-message">
                {tCommon("infiniteScrollEndMessage")}
              </p>
            }
            style={{ overflow: "visible" }}
            scrollableTarget="scrollableWrap"
          >
            {data.map((notification) => (
              <Notification
                notification={notification}
                key={notification.id}
                onCloseNotificationModal={closeModal}
              />
            ))}
          </InfiniteScroll>
        ) : null}
      </div>
      <div className="notificationPopup__footer">
        <button
          type="button"
          onClick={() => setIsDeleteAllNotificationsModalOpen(true)}
          disabled={!data || !data.length}
          className="btn-regular notificationPopupBtn"
        >
          {t("deleteAllNotifications")}
        </button>

        <button
          type="button"
          onClick={markAllNotificationsAsRead}
          disabled={isDisabledAllReadBtn}
          className="btn-regular notificationPopupBtn"
        >
          {t("markAllNotificationsAsRead")}
        </button>
      </div>
      <LayoutConfirmationPopup
        isModalOpen={isDeleteAllNotificationsModalOpen}
        onCloseModal={() => setIsDeleteAllNotificationsModalOpen(false)}
        confirmHandler={deleteAllNotifications}
        title={t("deleteAllNotifications")}
        question={t("deleteAllNotificationsQuestion")}
        isConfirmLoading={isDeleteAllNotificationsLoading}
      />
    </LayoutRightPanel>
  );
};

export default NotificationsRightPanel;
