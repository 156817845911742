import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { getReceiverCountry } from "utils/components";
import { formatPhoneNumber } from "utils/components/CustomPhoneInput/phoneInputHelpers";

const MerchDeliveryInfoList = () => {
  const { t } = useTranslation(["employeePage", "common", "countries"]);
  const { data } = useAppSelector((state) => state.employeePage.employeeData);
  const merchDeliveryInfo = data ? data.merchDeliveryInfo : null;

  if (!merchDeliveryInfo) return null;

  const isCorrectCoutryValue = getReceiverCountry(
    merchDeliveryInfo.receiverCountry
  );
  return (
    <div className="employeePage__item deliveryInfoBlock__item">
      <p className="employeePage__label">
        {t("receiveCorporatePresentsQuestion")}
      </p>
      <p className="employeePage__value">
        {merchDeliveryInfo.receiveCorporatePresents
          ? t("common:yes")
          : t("common:no")}
      </p>

      <p className="employeePage__label">{t("receiverName")}</p>
      <p className="employeePage__value">
        {merchDeliveryInfo.receiverFirstName}
      </p>

      <p className="employeePage__label">{t("receiverSurname")}</p>
      <p className="employeePage__value">
        {merchDeliveryInfo.receiverFamilyName}
      </p>

      <p className="employeePage__label">{t("receiverPhone")}</p>
      <p className="employeePage__value">
        {formatPhoneNumber(merchDeliveryInfo.receiverPhone)}
      </p>

      <p className="employeePage__label">{t("receiverCountry")}</p>
      <p className="employeePage__value">
        {isCorrectCoutryValue
          ? t(`countries:${merchDeliveryInfo.receiverCountry}`)
          : merchDeliveryInfo.receiverCountry}
      </p>

      <p className="employeePage__label">{t("receiverRegion")}</p>
      <p className="employeePage__value">{merchDeliveryInfo.receiverRegion}</p>
      <p className="employeePage__label">{t("receiverCity")}</p>
      <p className="employeePage__value">{merchDeliveryInfo.receiverCity}</p>
      <p className="employeePage__label">{t("deliveryPostalDepartment")}</p>
      <p className="employeePage__value">
        {merchDeliveryInfo.deliveryPostalDepartment}
      </p>

      <p className="employeePage__label">{t("zipCode")}</p>
      <p className="employeePage__value">{merchDeliveryInfo.zipCode}</p>

      <p className="employeePage__label">{t("deliveryAddress")}</p>
      <p className="employeePage__value">{merchDeliveryInfo.deliveryAddress}</p>
      <p className="merchDeliveryBlock__label">{t("topSize")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDeliveryInfo.deliveryTopSize}
      </p>

      <p className="merchDeliveryBlock__label">{t("footSize")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDeliveryInfo.deliveryFootSize}
      </p>
      <p className="merchDeliveryBlock__label">{t("bottomSize")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDeliveryInfo.deliveryBottomSize}
      </p>
    </div>
  );
};

export default MerchDeliveryInfoList;
