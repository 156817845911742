import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLoader, Icon } from "utils/components";

type ConfirmationPopupProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  confirmHandler: () => void;
  title: string;
  question: string | string[] | null;
  isConfirmLoading?: boolean;
};

const LayoutConfirmationPopup: FC<ConfirmationPopupProps> = ({
  isModalOpen,
  onCloseModal,
  confirmHandler,
  title,
  question,
  isConfirmLoading,
}) => {
  const { t: tCommon } = useTranslation("common");

  return (
    <div
      className={classNames("cd-popup", { "is-visible": isModalOpen })}
      role="alert"
    >
      <div className="cd-popup-container">
        <div className="popup-head">
          <button
            className="cd-popup-close"
            type="button"
            onClick={onCloseModal}
          >
            <Icon href="#close-popup" svgClass="ic-close-popup" />
          </button>
          <h5 className="cd-popup__title">{title}</h5>
          <div className="cd-popup__divider" />
          <p>{question}</p>
        </div>
        <div className="popup-footer">
          <div className="group-btn d-flex">
            <button
              className="btn-regular"
              type="button"
              onClick={onCloseModal}
            >
              {tCommon("no")}
            </button>
            <button
              className="btn-regular"
              type="button"
              onClick={confirmHandler}
            >
              {isConfirmLoading ? <ButtonLoader /> : tCommon("yes")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutConfirmationPopup;
