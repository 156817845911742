import { AxiosError } from "axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../store";
import {
  getProfile,
  loginUserWithGoogleCode,
} from "../store/thunks/authThunks";
import { serverError } from "../types/serverError";
import Loader from "../utils/components/Loader";
import AuthMessage from "./AuthMessage";

const GoogleCodePage: FC = () => {
  const { t } = useTranslation("auth");
  const { t: tCommon } = useTranslation("common");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [serverError, setServerError] = useState<string | null>();

  const code = searchParams.get("code");
  const redirectUrl = searchParams.get("state") ?? "/news";
  const error = searchParams.get("error");

  useEffect(() => {
    if (code) {
      (async () => {
        const body = {
          code: code,
        };

        try {
          await dispatch(loginUserWithGoogleCode(body));
          await dispatch(getProfile());
          navigate(redirectUrl, { replace: true });
        } catch (e) {
          const axiosError = e as AxiosError;
          const error = axiosError.response?.data as serverError;
          setServerError(
            error?.message || t("pleaseContactEsteemeSupportToResolveIssue")
          );
        }
      })();
    }
  }, []);

  if (!code) {
    const message =
      t("pleaseContactEsteemeSupportToResolveIssue") +
      " " +
      (error && `${t("messageFromGoogle")}: ${error}`);
    return <AuthMessage title={tCommon("errorOccurred")} message={message} />;
  }

  if (serverError) {
    return (
      <AuthMessage title={tCommon("errorOccurred")} message={serverError} />
    );
  }

  return (
    <div className="page-loader">
      <Loader />
    </div>
  );
};

export default GoogleCodePage;
