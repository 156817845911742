import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeOffPoliciesState } from "../../types";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../../handleUpdateState";
import { logoutUserAction } from "../authReducer";
import {
  TimeOffPolicyAssignPeople,
  TimeOffPolicyAssignPeopleSortBy,
  TimeOffPolicyDto,
  TimeOffPolicyPositionsRequest,
  TimeOffPolicyUserDto,
} from "../../../api/types/timeOff/timeOffPolicy";
import { SortDirections } from "../../../api/types/commonTypes";
import { Role } from "api/types/rolesTypes";
import { TimeOffLocationFilter } from "api/types/timeOff/timeOffPolicyTypes";

const initialState: TimeOffPoliciesState = {
  timeOffPolicy: defaultListState,
  timeOffLocationsData: defaultListState,
  timeOffPolicyEmployees: {
    sortingEmployeesList: {
      sortBy: TimeOffPolicyAssignPeopleSortBy.Employee,
      sortDirection: SortDirections.desc,
    },
    filters: {
      locations: [],
    },
    employeesList: defaultStateWithInfiniteLoading,
    searchQuery: "",
  },
  rolesList: defaultListState,
  positions: defaultListState,
};

const timeOffPoliciesReducer = createSlice({
  name: "timeOffPolicies",
  initialState,
  reducers: {
    setLoadingTimeOffPolicy: (state) => ({
      ...state,
      timeOffPolicy: setStateLoading(state.timeOffPolicy),
    }),
    setTimeOffPolicy: (state, action: PayloadAction<TimeOffPolicyDto>) => ({
      ...state,
      timeOffPolicy: setStateData(state.timeOffPolicy, action.payload),
    }),
    setErrorTimeOffPolicy: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffPolicy: setStateError(state.timeOffPolicy, action.payload),
    }),
    // Creating time off policy stepper
    // People criteria
    setLoadingTimeOffLocationsData: (state) => ({
      ...state,
      timeOffLocationsData: setStateLoading(state.timeOffLocationsData),
    }),
    setTimeOffLocationsData: (
      state,
      action: PayloadAction<TimeOffLocationFilter[]>
    ) => ({
      ...state,
      timeOffLocationsData: setStateData(
        state.timeOffLocationsData,
        action.payload
      ),
    }),
    setErrorTimeOffLocationsData: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffLocationsData: setStateError(
        state.timeOffLocationsData,
        action.payload
      ),
    }),
    setLoadingTimeOffPositionsData: (state) => ({
      ...state,
      positions: setStateLoading(state.positions),
    }),
    setTimeOffPolicyPositionsData: (
      state,
      action: PayloadAction<TimeOffPolicyPositionsRequest>
    ) => ({
      ...state,
      positions: setStateData(state.positions, action.payload),
    }),
    setErrorTimeOffPositionsData: (state, action: PayloadAction<string>) => ({
      ...state,
      positions: setStateError(state.positions, action.payload),
    }),
    setLoadingTimeOffPolicyEmployees: (state) => ({
      ...state,
      timeOffPolicyEmployees: {
        ...state.timeOffPolicyEmployees,
        employeesList: setInfiniteLoadLoading(
          state.timeOffPolicyEmployees.employeesList
        ),
      },
    }),
    setTimeOffPolicyEmployees: (
      state,
      action: PayloadAction<{
        timeOffPolicyEmployees: TimeOffPolicyUserDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let usersList = state.timeOffPolicyEmployees.employeesList?.data || [];
      usersList = [...usersList, ...action.payload.timeOffPolicyEmployees];
      return {
        ...state,
        timeOffPolicyEmployees: {
          ...state.timeOffPolicyEmployees,
          employeesList: setInfiniteLoadData(
            state.timeOffPolicyEmployees.employeesList,
            usersList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorTimeOffPolicyEmployees: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffPolicyEmployees: {
        ...state.timeOffPolicyEmployees,
        employeesList: setInfiniteLoadStateErrorMessage(
          state.timeOffPolicyEmployees.employeesList,
          action.payload
        ),
      },
    }),
    setTimeOffPolicyEmployeesSorting: (
      state,
      action: PayloadAction<[TimeOffPolicyAssignPeopleSortBy, SortDirections]>
    ) => ({
      ...state,
      timeOffPolicyEmployees: {
        ...state.timeOffPolicyEmployees,
        sortingEmployeesList: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    setTimeOffPolicyEmployeesFilterCriteria: (
      state,
      action: PayloadAction<TimeOffPolicyAssignPeople>
    ) => ({
      ...state,
      timeOffPolicyEmployees: {
        ...state.timeOffPolicyEmployees,
        filters: action.payload,
      },
    }),
    setTimeOffPolicyEmployeesSearchQuery: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      timeOffPolicyEmployees: {
        ...state.timeOffPolicyEmployees,
        searchQuery: action.payload,
      },
    }),
    resetTimeOffPolicyEmployees: (state) => ({
      ...state,
      timeOffPolicyEmployees: {
        ...state.timeOffPolicyEmployees,
        employeesList: defaultStateWithInfiniteLoading,
      },
    }),
    // Data for select Approvers, NotificationRecipients in Approval Step of creating policy form
    setLoadingTimeOffPolicyRoles: (state) => ({
      ...state,
      rolesList: setStateLoading(state.rolesList),
    }),
    setTimeOffPolicyRoles: (state, action: PayloadAction<Role[]>) => ({
      ...state,
      rolesList: setStateData(state.rolesList, action.payload),
    }),
    setErrorTimeOffPolicyRoles: (state, action: PayloadAction<string>) => ({
      ...state,
      rolesList: setStateError(state.rolesList, action.payload),
    }),
    resetTimeOffPolicyState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default timeOffPoliciesReducer.reducer;

export const {
  setLoadingTimeOffPolicy,
  setTimeOffPolicy,
  setErrorTimeOffPolicy,
  resetTimeOffPolicyState,
  setErrorTimeOffLocationsData,
  setLoadingTimeOffLocationsData,
  setTimeOffLocationsData,
  setErrorTimeOffPolicyEmployees,
  setLoadingTimeOffPolicyEmployees,
  setTimeOffPolicyEmployees,
  setTimeOffPolicyEmployeesSorting,
  setTimeOffPolicyEmployeesFilterCriteria,
  setTimeOffPolicyEmployeesSearchQuery,
  resetTimeOffPolicyEmployees,
  setErrorTimeOffPolicyRoles,
  setTimeOffPolicyRoles,
  setLoadingTimeOffPolicyRoles,
  setTimeOffPolicyPositionsData,
  setErrorTimeOffPositionsData,
  setLoadingTimeOffPositionsData,
} = timeOffPoliciesReducer.actions;
