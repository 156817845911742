import { exitImpersonation } from "api/authApi";
import { profile } from "api/profileApi";
import { AxiosError } from "axios";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setProfile, setTokens } from "store/reducers/authReducer";
import { useAppDispatch, useAppSelector } from "../../store";
import { serverError } from "../../types/serverError";
import { Icon } from "../../utils/components";

const ImpersonationPanel: FC = () => {
  const { t } = useTranslation("layout");
  const { t: tCommon } = useTranslation("common");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userProfile = useAppSelector((state) => state.auth.profile);

  const locationPath = useLocation().pathname;

  const handleExitImpersonation = async () => {
    try {
      var tokens = await exitImpersonation();

      dispatch(setTokens(tokens.data));
      const profileData = await profile();
      dispatch(setProfile(profileData.data));

      toast.success(t("impersonationExitSuccessfully"));

      if (locationPath.includes("news")) {
        navigate(0); // reload page
      } else {
        navigate("/news");
      }
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toast.error(error?.message || tCommon("errorOccurred"));
    }
  };

  return (
    <>
      {userProfile?.isImpersonated ? (
        <div
          className="impersonation-panel"
          onClick={() => handleExitImpersonation()}
        >
          <span className="ic-back">
            <Icon href="#back" />
          </span>

          <p>
            {userProfile.impersonatingUserFirstName +
              " " +
              userProfile.impersonatingUserFamilyName}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default ImpersonationPanel;
