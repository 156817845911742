import { FC } from "react";
import icons from "../../sprite/templateIcons.svg";

type IconProps = {
  svgClass?: string;
  href: string;
};

export const Icon: FC<IconProps> = ({ href, svgClass }) => {
  return (
    <svg className={svgClass}>
      <use xlinkHref={`${icons}${href}`}></use>
    </svg>
  );
};

export default Icon;
