import React from "react";
import { useTranslation } from "react-i18next";

const AccessDenied = () => {
  const { t } = useTranslation("auth");

  return (
    <div className="box">
      <h1 className="main-title m-btm">{t("accessDenied")}</h1>
      <p>{t("pleaseContactAdminToAccessPage")}</p>
    </div>
  );
};

export default AccessDenied;
