import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultListState } from "../../appConsts";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { GroupManagementState } from "../types";
import { logoutUserAction } from "./authReducer";
import {
  GroupFilterType,
  UserGroupDto,
  UserGroupWithParticipantsDto,
} from "api/types/groupManagementTypes";
import { DynamicCriteriaOption } from "../../pages/settings/group-management/addEditUserGroup/AddEditUserGroupPage";

const initialState: GroupManagementState = {
  searchQueryUserGroup: null,
  userGroupsData: defaultListState,
  userGroupData: defaultListState,
  groupsByUserData: defaultListState,
  groupsWithoutUserData: defaultListState,
  dynamicCriteriaData: defaultListState,
};

const groupManagementSlice = createSlice({
  name: "groupManagement",
  initialState,
  reducers: {
    setLoadingUserGroupsList: (state) => ({
      ...state,
      userGroupsData: setStateLoading(state.userGroupsData),
    }),
    setUserGroupsList: (state, action: PayloadAction<UserGroupDto[]>) => {
      return {
        ...state,
        userGroupsData: setStateData(state.userGroupsData, action.payload),
      };
    },
    setErrorUserGroupsList: (state, action: PayloadAction<string>) => ({
      ...state,
      userGroupsData: setStateError(
        state.groupsWithoutUserData,
        action.payload
      ),
    }),
    setUserGroupsSearchQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      searchQueryUserGroup: action.payload,
    }),
    setLoadingUserGroupData: (state) => ({
      ...state,
      userGroupData: setStateLoading(state.userGroupData),
    }),
    setUserGroupData: (
      state,
      action: PayloadAction<UserGroupWithParticipantsDto>
    ) => ({
      ...state,
      userGroupData: setStateData(state.userGroupData, action.payload),
    }),
    setErrorUserGroupData: (state, action: PayloadAction<string>) => ({
      ...state,
      userGroupData: setStateError(state.userGroupData, action.payload),
    }),
    setLoadingGroupsByUserData: (state) => ({
      ...state,
      groupsByUserData: setStateLoading(state.groupsByUserData),
    }),
    setGroupsByUserData: (state, action: PayloadAction<UserGroupDto[]>) => ({
      ...state,

      groupsByUserData: setStateData(state.groupsByUserData, action.payload),
    }),
    setErrorGroupsByUserData: (state, action: PayloadAction<string>) => ({
      ...state,
      groupsByUserData: setStateError(state.groupsByUserData, action.payload),
    }),
    setLoadingGroupsWithoutUserData: (state) => ({
      ...state,
      groupsWithoutUserData: setStateLoading(state.groupsWithoutUserData),
    }),
    setGroupsWithoutUserData: (
      state,
      action: PayloadAction<UserGroupDto[]>
    ) => ({
      ...state,
      groupsWithoutUserData: setStateData(
        state.groupsWithoutUserData,
        action.payload
      ),
    }),
    setErrorGroupsWithoutUserData: (state, action: PayloadAction<string>) => ({
      ...state,
      groupsWithoutUserData: setStateError(
        state.groupsWithoutUserData,
        action.payload
      ),
    }),
    resetGroupsWithoutUserData: (state) => ({
      ...state,
      groupsWithoutUserData: defaultListState,
    }),
    resetGroupsBytUserData: (state) => ({
      ...state,
      groupsByUserData: defaultListState,
    }),
    resetUserGroupData: (state) => ({
      ...state,
      userGroupData: defaultListState,
    }),
    setLoadingDynamicCriteriaData: (state) => ({
      ...state,
      dynamicCriteriaData: setStateLoading(state.dynamicCriteriaData),
    }),
    setDynamicCriteriaData: (
      state,
      action: PayloadAction<Record<GroupFilterType, DynamicCriteriaOption[]>>
    ) => ({
      ...state,
      dynamicCriteriaData: setStateData(
        state.dynamicCriteriaData,
        action.payload
      ),
    }),
    setErrorDynamicCriteria: (state, action: PayloadAction<string>) => ({
      ...state,
      dynamicCriteriaData: setStateError(
        state.dynamicCriteriaData,
        action.payload
      ),
    }),
    resetGroupManagement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default groupManagementSlice.reducer;
export const {
  setLoadingUserGroupsList,
  setUserGroupsList,
  setErrorUserGroupsList,
  setUserGroupsSearchQuery,
  resetGroupManagement,
  setErrorUserGroupData,
  setUserGroupData,
  setLoadingUserGroupData,
  setErrorGroupsWithoutUserData,
  setGroupsWithoutUserData,
  setLoadingGroupsWithoutUserData,
  setGroupsByUserData,
  setErrorGroupsByUserData,
  setLoadingGroupsByUserData,
  resetGroupsBytUserData,
  resetGroupsWithoutUserData,
  setLoadingDynamicCriteriaData,
  setDynamicCriteriaData,
  setErrorDynamicCriteria,
  resetUserGroupData,
} = groupManagementSlice.actions;
