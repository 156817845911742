import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { resetPlayerProfileState } from "store/reducers/employeePageReducers/playerProfileReducer";
import { getPlayerProfilePageData } from "store/thunks/employeePage/playerProfileThunks";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import AccessDenied from "../../../auth/AccessDenied";
import { Loader } from "../../../utils/components";
import { AppPermissions, Features } from "../../../utils/consts";
import {
  useCompanyFeatureCheck,
  usePermissionCheck,
} from "../../../utils/hooks";
import UserAchievementsEventsBacklog from "./UserAchievementsEventsBacklog";
import { usePlayerProfileViewsPermissions } from "./hooks/usePlayerProfileViewsPermissions";
import ActiveAchievementsEvents from "./statistic-blocks/ActiveAchievementsEventsBLock";
import CoinsBalanceBlock from "./statistic-blocks/CoinsBalanceBlock";
import CoinsPraisedBalanceBlock from "./statistic-blocks/CoinsPraisedBalanceBlock";
import { useTranslation } from "react-i18next";

const PlayerProfilePage = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const { t } = useTranslation("employeePage");

  const isCurrentUser = useIsCurrentUser();
  const profile = useAppSelector((state) => state.auth.profile);
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const isUserCanViewPlayerProfile = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
    AppPermissions.EmployeeCard_ViewPlayerProfile,
  ]);
  const permissions = usePlayerProfileViewsPermissions();
  const isAccessToPlayerProfile =
    isGamificationAvailable && (isUserCanViewPlayerProfile || isCurrentUser);

  useEffect(() => {
    if (userId && isAccessToPlayerProfile) {
      dispatch(getPlayerProfilePageData(+userId, permissions));
    }

    return () => {
      dispatch(resetPlayerProfileState());
    };
  }, [dispatch, isAccessToPlayerProfile, permissions, userId]);

  if (!profile) {
    return (
      <div className="box">
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (!isAccessToPlayerProfile) {
    return <AccessDenied />;
  }

  return (
    <div className="playerProfilePage">
      <h1 className="main-title m-btm">{t("playerProfile_tab")}</h1>
      <div className="countsBlocks">
        {isCurrentUser || permissions.isViewCoinsBalance ? (
          <CoinsBalanceBlock />
        ) : null}
        <ActiveAchievementsEvents />
        {isCurrentUser || permissions.isViewPraisedCoins ? (
          <CoinsPraisedBalanceBlock />
        ) : null}
      </div>

      <div className="playerProfileMain">
        {userId && (isCurrentUser || permissions.isViewQuestBacklog) && (
          <UserAchievementsEventsBacklog userId={+userId} />
        )}
        {/*At the moment there is no need to use */}
        {/* <AnalyticsCharts /> */}
      </div>
    </div>
  );
};

export default PlayerProfilePage;
