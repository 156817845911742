import { achievementCategoryDefaultColor} from "appConsts";
import { FC } from "react";
import { Icon } from "utils/components";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { getCropTitle } from "utils/methods/getCropTitle";

type CategoryLabelProps<T = any> = {
  color: string;
  name: string;
  handleNavigate?: (data: T) => void;
  handleClose?: (data: T) => void;
  isCloseIcon?: boolean;
  isFullTitle?: boolean;
  className?: string;
};

export const CategoryLabel: FC<CategoryLabelProps> = ({
  color,
  name,
  handleClose,
  handleNavigate,
  isCloseIcon = false,
  isFullTitle = false,
  className,
}) => {
  const { width } = useWindowSize();
  const mobileResponsive = 600;

  return (
    <li
      className={`tagLabel ${className} d-flex tagLabel--${
        color || achievementCategoryDefaultColor
      }`}
      onClick={handleNavigate}
    >
      <p>
        {isFullTitle
          ? name
          : getCropTitle(name, width > mobileResponsive ? 25 : 16)}
      </p>
      {isCloseIcon && (
        <button className="d-flex" onClick={handleClose}>
          <Icon
            href="#close-popup"
            svgClass="ic-close-popup tagLabelCloseIcon"
          />
        </button>
      )}
    </li>
  );
};
