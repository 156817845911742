import { AxiosPromise } from "axios";
import { base_url, countItemsPerPage } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  BadgeGroupsListResponse,
  CreateBadgeMessageRequest,
  EditBadgeMessageResponse,
} from "./types/badgesTypes";
import { BadgeMessageSystemResponse } from "./types/news";

export const loadCurrentProfileBadgeMessages = (
  pageNumber: number
): AxiosPromise<BadgeMessageSystemResponse> =>
  axiosInstance.get(
    `${base_url}/api/badge-messages?pageNumber=${pageNumber}&pageSize=${countItemsPerPage}`
  );

export const loadProfileBadgeMessages = (
  userId: number,
  pageNumber: number
): AxiosPromise<BadgeMessageSystemResponse> =>
  axiosInstance.get(
    `${base_url}/api/badge-messages/user/${userId}?pageNumber=${pageNumber}&pageSize=${countItemsPerPage}`
  );

export const loadBadgesList = (): AxiosPromise<BadgeGroupsListResponse> =>
  axiosInstance.get(`${base_url}/api/badges`);

export const sendBadgeMessage = (
  body: CreateBadgeMessageRequest
): AxiosPromise => axiosInstance.post(`${base_url}/api/badge-messages`, body);

export const editBadgeMessage = (
  messageId: number,
  message: string
): AxiosPromise<EditBadgeMessageResponse> =>
  axiosInstance.put(`${base_url}/api/badge-messages/${messageId}`, { message });

export const deleteBadgeMessage = (messageId: number) =>
  axiosInstance.delete(`${base_url}/api/badge-messages/${messageId}`, {
    data: { confirmDelete: true },
  });

export const loadBadgeMessagesSystemList = (
  userID: number,
  day: string,
  month: string
): AxiosPromise<BadgeMessageSystemResponse> =>
  axiosInstance.get(
    `${base_url}/api/badge-messages/user/${userID}/odata?$filter=IsSystem eq true and month(CreatedDate) eq ${month} and day(CreatedDate) eq ${day} and (CompanyAutoEvent/EventType eq 'BirthDate' or CompanyAutoEvent/EventType eq 'HireDate')`
  );
