import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { config } from "utils/config";
import { defaultLanguage, supportedLanguages } from "./supportedLanguages";
import enLocalization from "./translations/en/enLocalization";
import ruLocalization from "./translations/ru/ruLocalization";
import ukLocalization from "./translations/uk/ruLocalization";
import appLocalization from "./types/appLocalization";

type appResources = {
  en: appLocalization;
  ru: appLocalization;
  uk: appLocalization;
};

const resources: appResources = {
  en: {
    ...enLocalization,
  },
  ru: {
    ...ruLocalization,
  },
  uk: {
    ...ukLocalization,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage,
    supportedLngs: supportedLanguages,
    debug: config.localizationLogDebugEnabled,
    resources,
    interpolation: {
      escapeValue: true,
    },
    fallbackNS: "common",
    detection: {
      order: ["localStorage"],
    },
  });
