import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company } from "../../api/types/companyTypes";
import { defaultStateWithInfiniteLoading } from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
} from "../handleUpdateState";
import { CompaniesState } from "../types";
import { logoutUserAction } from "./authReducer";

const initialState: CompaniesState = {
  searchQuery: null,
  companiesData: defaultStateWithInfiniteLoading,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setLoadingCompaniesList: (state) => ({
      ...state,
      companiesData: setInfiniteLoadLoading(state.companiesData),
    }),
    setCompaniesList: (
      state,
      action: PayloadAction<{
        companies: Company[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let companies = state.companiesData.data || [];
      companies = [...companies, ...action.payload.companies];

      return {
        ...state,
        companiesData: setInfiniteLoadData(
          state.companiesData,
          companies,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorCompaniesList: (state, action: PayloadAction<string>) => ({
      ...state,
      companiesData: setInfiniteLoadStateErrorMessage(
        state.companiesData,
        action.payload
      ),
    }),
    resetCompaniesList: (state) => ({
      ...state,
      companiesData: initialState.companiesData,
    }),
    setCompanySearchQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      searchQuery: action.payload,
    }),
    resetCompaniesManagement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default companiesSlice.reducer;
export const {
  setLoadingCompaniesList,
  setCompaniesList,
  setErrorCompaniesList,
  resetCompaniesList,
  setCompanySearchQuery,
  resetCompaniesManagement,
} = companiesSlice.actions;
