import React, { ForwardedRef } from "react";
import Icon from "./Icon";

export const DatePickersCustomIcon = React.forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div {...props} ref={ref}>
        <Icon href="#calendar" svgClass="datePickers__calendarIcon" />
      </div>
    );
  }
);
