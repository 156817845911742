import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t: tCommon } = useTranslation("common");

  return (
    <main className="notFoundPage">
      <div className="notFoundPage__content">
        <div className="notFoundPage__textWrap">
          <p className="notFoundPage__code">404</p>
          <p className="notFoundPage__error">{tCommon("404_error")}</p>
        </div>

        <img src="/img/404-img.png" alt="404" className="notFoundPage__img" />
      </div>
    </main>
  )
};

export default NotFoundPage;