import classNames from "classnames";
import { FC } from "react";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { CelebrationType } from "./CelebrationModal";

type Step1Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  nextStep: () => void;
  type: CelebrationType | null;
};

export const Step1: FC<Step1Props> = ({
  isModalOpen,
  onCloseModal,
  nextStep,
  type,
}) => {
  const { t } = useTranslation("celebrationModal");
  const birthdayType = type === CelebrationType.birthday;

  return (
    <div
      className={classNames("celebrationModal cd-popup", {
        "is-visible": isModalOpen,
      })}
      role="alert"
    >
      <div className="cd-popup-container">
        <button className="cd-popup-close" type="button" onClick={onCloseModal}>
          <Icon href="#close-popup" svgClass="ic-close-popup" />
        </button>
        <div className="celebrationModal__content">
          <h1
            className={classNames("main-title", {
              "main-title--m-width": birthdayType,
            })}
          >
            {t(`${birthdayType ? "title_birthday" : "title_anniversary"}`)}
          </h1>
          <div className="celebrationModal__text">
            {t(
              `${birthdayType ? "subtitle_birthday" : "subtitle_anniversary"}`
            )}
          </div>
          <img
            className={classNames("celebrationModal__img", {
              "celebrationModal__img--margin": !birthdayType,
            })}
            src={`/img/${birthdayType ? "gift-box" : "star"}.png`}
            alt=""
          />
          <button className="btn-primary" onClick={nextStep}>
            {t(`${birthdayType ? "openBox" : "makeWish"}`)}
          </button>
        </div>
      </div>
    </div>
  );
};
