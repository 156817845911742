import { components, OptionProps } from "react-select";
import Icon from "./Icon";
import { Option } from "../types";

// used for render options of single selects which used in app directly (for example, LoginPage.tsx)
export const CustomSingleSelectOption = (props: OptionProps<Option, false>) => {
  const { isSelected, children } = props;

  if (isSelected) {
    return (
      <components.Option {...props}>
        {children}
        <Icon href="#check" svgClass="select__check" />
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};

//used for render options of single and multi selects handled by using CustomSelect component
export const CustomOption = (props: OptionProps<Option>) => {
  const { isSelected, children } = props;

  if (isSelected) {
    return (
      <components.Option {...props}>
        {children}
        <Icon href="#check" svgClass="select__check" />
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};
