import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { Icon, Loader, LoaderSize } from "utils/components";
import { AppPermissions } from "utils/consts";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { usePermissionToEmployeeCard } from "../hooks/usePermissionToEmployeeCard";
import ContactsForm, { ContactsValues } from "./ContactsForm";
import ContactsInfo from "./ContactsInfo";

const ContactsBlock = () => {
  const { t } = useTranslation("employeePage");
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.employeePage.employeeData
  );
  const isUserHavePermissions = usePermissionToEmployeeCard(
    AppPermissions.ViewAndEditUserEmployeeCards
  );
  const currentUser = useIsCurrentUser();

  const [editMode, setEditMode] = useState(false);
  const initialContactsFormValues: ContactsValues = {
    email: data?.contacts.email || "",
    phoneNumber: data?.contacts.phoneNumber || "",
    facebook: data?.contacts.facebook || "",
    instagram: data?.contacts.instagram || "",
    linkedIn: data?.contacts.linkedIn || "",
    twitch: data?.contacts.twitch || "",
    slack: data?.contacts.slack || "",
  };

  const isHaveAccessToEdit = data && (currentUser || isUserHavePermissions);

  return (
    <div className="contactsBlock">
      <div className="pageTitleBlock">
        <h1 className="main-title">{t("contacts_title")}</h1>

        {isHaveAccessToEdit && (
          <button
            type="button"
            className="btn-tertiary actionBtn-tertiary"
            onClick={() => setEditMode((prevState) => !prevState)}
          >
            <Icon
              href={editMode ? "#close-popup" : "#edite"}
              svgClass="buttonIcon"
            />
          </button>
        )}
      </div>

      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}

      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}

      {data && (
        <>
          {editMode ? (
            <ContactsForm
              initialValues={initialContactsFormValues}
              userId={data.id}
              setEditMode={setEditMode}
            />
          ) : (
            <ContactsInfo contacts={data.contacts} />
          )}
        </>
      )}
    </div>
  );
};

export default ContactsBlock;
