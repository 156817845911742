import { MerchOrderDto } from "api/types/merchDeliveryTypes";
import { FC } from "react";
import { getAvatarSign, getFullName } from "utils/methods";
import MerchDeliveryDetails from "utils/components/MerchDeliveryDetails";
import UserAvatar from "../../../../utils/components/UserAvatar";

type OrderViewMerchDeliveryInfoProps = {
  order: MerchOrderDto;
};

export const OrderViewMechDeliveryInfo: FC<OrderViewMerchDeliveryInfoProps> = ({
  order,
}) => {
  const { avatarImageUrl, familyName, firstName } = order.user;
  return (
    <>
      <div className="userBlock d-flex">
        <UserAvatar
          avatarImageUrl={avatarImageUrl}
          avatarSign={getAvatarSign(firstName, familyName)}
          className="user-avatar userBlock__avatar"
        />
        <span className="user-name">{getFullName(firstName, familyName)}</span>
      </div>
      {order.merchDeliveryInfo && (
        <div className="merchDeliveryBlock">
          <MerchDeliveryDetails merchDelivery={order.merchDeliveryInfo} />
        </div>
      )}
    </>
  );
};
