import React, { FC, useEffect, useState } from "react";
import Icon from "../Icon";
import { Slider } from "rsuite";

type RangeZoomInputProps = {
  picture: File | Blob;
  handleChangeZoom: (v: number) => void;
  minZoomValue: number;
};

const RangeZoomInput: FC<RangeZoomInputProps> = ({
  picture,
  handleChangeZoom,
  minZoomValue,
}) => {
  const [zoomValue, setZoomValue] = useState(0);

  useEffect(() => {
    setZoomValue(0);
  }, [picture]);

  return (
    <div className="zoomSlider">
      <Icon href="#minus" svgClass="zoomSlider__icon" />
      <Slider
        progress
        min={minZoomValue}
        max={minZoomValue + 4}
        step={0.01}
        className="zoomSlider__slider"
        value={zoomValue}
        onChange={(value) => {
          setZoomValue(value);
          handleChangeZoom(value);
        }}
        tooltip={false}
      />
      <Icon href="#plus" svgClass="zoomSlider__icon" />
    </div>
  );
};

export default RangeZoomInput;
