import isNumber from "lodash/isNumber";
import { FC } from "react";
import { Tooltip, Whisper } from "rsuite";
import { Icon } from "utils/components";
import Loader, { LoaderSize } from "utils/components/Loader";

type PlayerStatisticBlockProps = {
  title: string;
  isLoading?: boolean;
  errorMessage?: string;
  data: number | null | undefined;
  isCoinsImg?: boolean;
  tooltipText: string;
};

const PlayerStatisticBlock: FC<PlayerStatisticBlockProps> = ({
  title,
  isLoading,
  errorMessage,
  data,
  isCoinsImg,
  tooltipText,
}) => {
  return (
    <div className="box countsBlocks__item">
      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}
      {isNumber(data) && (
        <>
          <div className="countsBlocks__title-block">
            <h2 className="statisticBlock__title countsBlocks__title">
              {title}
            </h2>
            <Whisper
              speaker={
                <Tooltip className="custom-tooltip">
                  {tooltipText}
                </Tooltip>
              }
              trigger="click"
              placement="topStart"
            >
              <span>
                <Icon href="#info" svgClass="ic-info" />
              </span>
            </Whisper>
          </div>

          <div className="countsBlocks__block-value">
            {isCoinsImg && (
              <img src="/img/coins.png" className="coinsImg" alt="coins" />
            )}
            <h1 className="statisticCount statisticBlock__value countsBlocks__value">
              {data}
            </h1>
          </div>
        </>
      )}
    </div>
  );
};

export default PlayerStatisticBlock;
