import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BadgeMessageSystem } from "../../api/types/news";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  resetInfiniteLoadState,
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { NewsState, Tabs } from "../types";
import { logoutUserAction } from "./authReducer";
import { AchievementDto } from "../../api/types/achievementTypes";
import { User } from "api/types/usersTypes";
import { MerchItemDto } from "api/types/merchStoreTypes";
import { EmojiReactionUsersItemDto } from "../../api/types/emojiTypes";
import { config } from "utils/config";

const initialState: NewsState = {
  messageData: {
    selectedTab: config.defaultNewsTab as Tabs || Tabs.myTeam,
    ...defaultStateWithInfiniteLoading,
  },
  essentialsAchievements: defaultStateWithInfiniteLoading,
  essentialsMerch: defaultStateWithInfiniteLoading,
  myTeamEventsByMonth: defaultListState,
  eventsEndingByMonth: defaultListState,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    /* BADGE MESSAGES */
    setErrorMessages: (state, action: PayloadAction<string>) => ({
      ...state,
      messageData: setInfiniteLoadStateErrorMessage(
        state.messageData,
        action.payload
      ),
    }),
    setLoadingMessages: (state) => ({
      ...state,
      messageData: setInfiniteLoadLoading(state.messageData),
    }),
    setChangeTab: (state, action: PayloadAction<Tabs>) => ({
      ...state,
      messageData: {
        ...resetInfiniteLoadState(state.messageData),
        selectedTab: action.payload,
      },
    }),
    setMessages: (
      state,
      action: PayloadAction<{
        messages: BadgeMessageSystem[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let messages = state.messageData.data || [];
      messages = [...messages, ...action.payload.messages];

      return {
        ...state,
        messageData: setInfiniteLoadData(
          state.messageData,
          messages,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    resetNewsBadgeMessages: (state) => ({
      ...state,
      messageData: {
        ...resetInfiniteLoadState(state.messageData),
        selectedTab: state.messageData.selectedTab,
      },
    }),
    updateNewsBadgeMessageReactions: (
      state,
      action: PayloadAction<{
        badgeMessageId: number;
        updatedEmojiReactions: EmojiReactionUsersItemDto[];
      }>
    ) => {
      const { badgeMessageId, updatedEmojiReactions } = action.payload;
      const updatedBadgeMessagesArr = (state.messageData.data || []).map(
        (badgeMessage) =>
          badgeMessage.id === badgeMessageId
            ? { ...badgeMessage, emojiReactions: updatedEmojiReactions }
            : badgeMessage
      );
      return {
        ...state,
        messageData: {
          ...state.messageData,
          data: updatedBadgeMessagesArr,
        },
      };
    },
    editNewsBadgeMessage: (
      state,
      action: PayloadAction<BadgeMessageSystem>
    ) => {
      const updatedBadgeMessagesArr = (state.messageData.data || []).map(
        (badgeMessage) =>
          badgeMessage.id === action.payload.id ? action.payload : badgeMessage
      );

      return {
        ...state,
        messageData: {
          ...state.messageData,
          data: updatedBadgeMessagesArr,
        },
      };
    },
    // EssentialsAchievements - Achievements
    setLoadingEssentialsAchievements: (state) => ({
      ...state,
      essentialsAchievements: setInfiniteLoadLoading(
        state.essentialsAchievements
      ),
    }),
    setEssentialsAchievements: (
      state,
      action: PayloadAction<{
        achievements: AchievementDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let achievements = state.essentialsAchievements.data || [];
      achievements = [...achievements, ...action.payload.achievements];

      return {
        ...state,
        essentialsAchievements: setInfiniteLoadData(
          state.essentialsAchievements,
          achievements,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorEssentialsAchievements: (state, action: PayloadAction<string>) => ({
      ...state,
      essentialsAchievements: setInfiniteLoadStateErrorMessage(
        state.essentialsAchievements,
        action.payload
      ),
    }),
    // EssentialsAchievements - Merch
    setLoadingEssentialsMerch: (state) => ({
      ...state,
      essentialsMerch: setInfiniteLoadLoading(state.essentialsMerch),
    }),
    setEssentialsMerch: (
      state,
      action: PayloadAction<{
        merch: MerchItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let merch = state.essentialsMerch.data || [];
      merch = [...merch, ...action.payload.merch];

      return {
        ...state,
        essentialsMerch: setInfiniteLoadData(
          state.essentialsMerch,
          merch,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorEssentialsMerch: (state, action: PayloadAction<string>) => ({
      ...state,
      essentialsMerch: setInfiniteLoadStateErrorMessage(
        state.essentialsMerch,
        action.payload
      ),
    }),
    setLoadingMyTeamEventsByMonth: (state) => ({
      ...state,
      myTeamEventsByMonth: setStateLoading(state.myTeamEventsByMonth),
    }),
    setStateMyTeamEventsByMonth: (state, action: PayloadAction<User[]>) => ({
      ...state,
      myTeamEventsByMonth: setStateData(
        state.myTeamEventsByMonth,
        action.payload
      ),
    }),
    setErrorMyTeamEventsByMonth: (state, action: PayloadAction<string>) => ({
      ...state,
      myTeamEventsByMonth: setStateError(
        state.myTeamEventsByMonth,
        action.payload
      ),
    }),
    setLoadingEventsEndingByMonth: (state) => ({
      ...state,
      eventsEndingByMonth: setStateLoading(state.eventsEndingByMonth),
    }),
    setStateEventsEndingByMonth: (
      state,
      action: PayloadAction<AchievementDto[]>
    ) => ({
      ...state,
      eventsEndingByMonth: setStateData(
        state.eventsEndingByMonth,
        action.payload
      ),
    }),
    setErrorEventsEndingByMonth: (state, action: PayloadAction<string>) => ({
      ...state,
      eventsEndingByMonth: setStateError(
        state.eventsEndingByMonth,
        action.payload
      ),
    }),
    resetNewsState: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default newsSlice.reducer;
export const {
  setChangeTab,
  setLoadingMessages,
  setMessages,
  setErrorMessages,
  resetNewsBadgeMessages,
  editNewsBadgeMessage,
  updateNewsBadgeMessageReactions,
  setLoadingEssentialsAchievements,
  setEssentialsAchievements,
  setErrorEssentialsAchievements,
  setLoadingEssentialsMerch,
  setEssentialsMerch,
  setErrorEssentialsMerch,
  setErrorEventsEndingByMonth,
  setStateEventsEndingByMonth,
  setLoadingEventsEndingByMonth,
  setErrorMyTeamEventsByMonth,
  setStateMyTeamEventsByMonth,
  setLoadingMyTeamEventsByMonth,
  resetNewsState,
} = newsSlice.actions;
