import { usePermissionCheck } from "../../../../utils/hooks";
import { AppPermissions } from "../../../../utils/consts";

export const useTimeOffProfileViewsPermissions = () => {
  return {
    isViewTimeOffBalances: usePermissionCheck([
      AppPermissions.TimeOff_ViewOtherUserBalance,
    ]),
    isViewTimeOffBacklog: usePermissionCheck([
      AppPermissions.TimeOff_ViewOtherUserBacklog,
    ]),
  };
};
