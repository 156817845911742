import { BadgeMessageSystem } from "./news";

export type BadgeItem = {
  id: number;
  name: string;
  imageUrl: string;
  groupName: string;
};

export type BadgeGroupItem = {
  groupName: string;
  badges: BadgeItem[];
};

export type BadgeGroupsListResponse = {
  items: BadgeGroupItem[];
};

export enum Visibility {
  all = "all",
  myTeam = "my_team",
  me = "me",
}

export type CreateBadgeMessageRequest = {
  receiverUserIds: number[];
  badgeId: number | null;
  message: string;
  visibility: Visibility;
  coins: number | null;
};

export type EditBadgeMessageResponse = {
  message: BadgeMessageSystem;
};

export type BadgesDataState = {
  isLoading: boolean;
  badgeGroupsList: null | BadgeGroupItem[];
  errorMessage: string;
};