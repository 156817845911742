import { AxiosError } from "axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadOrderDetails } from "../../../../api/merchDeliveryApi";
import { MerchOrderDto } from "../../../../api/types/merchDeliveryTypes";
import Loader, { LoaderSize } from "../../../../utils/components/Loader";
import { getErrorMessage } from "../../../../utils/methods";
import LayoutRightPanel from "../../../layout/LayoutRightPanel";
import { OrderViewInfo } from "./OrderViewInfo";
import { OrderViewMechDeliveryInfo } from "./OrderViewMerchDeliveryInfo";
import { OrderViewMerchInfo } from "./OrderViewMerchInfo";

type OrderViewPanelProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  selectedOrderId: null | number;
};

const OrderViewPanel: FC<OrderViewPanelProps> = ({
  isModalOpen,
  selectedOrderId,
  onCloseModal,
}) => {
  const { t } = useTranslation("merchDelivery");
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [order, setOrder] = useState<MerchOrderDto | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabsOrderTitle = [
    t("merchItemInfo"),
    t("merchDeliveryInfo"),
    t("orderInfo"),
  ];

  const orderViewsBlocks = (order: MerchOrderDto): JSX.Element[] => {
    return [
      <OrderViewMerchInfo order={order} />,
      <OrderViewMechDeliveryInfo order={order} />,
      <OrderViewInfo order={order} />,
    ];
  };

  useEffect(() => {
    if (selectedOrderId && isModalOpen) {
      (async () => {
        setIsLoading(true);
        try {
          const response = await loadOrderDetails(selectedOrderId);
          setOrder(response.data);
        } catch (e) {
          const errorMessage = getErrorMessage(e as AxiosError);
          setErrorMessage(errorMessage);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isModalOpen, selectedOrderId]);

  const closeModal = () => {
    setOrder(null);
    setErrorMessage("");
    setActiveTab(0);
    onCloseModal();
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={closeModal}
      title={t("orderDetails_title")}
      additionalClassName="popup-order-view"
    >
      <div className="order-view-wrap">
        <div className="wrap">
          {isLoading && (
            <div className="loader-container">
              <Loader size={LoaderSize.Small} />
            </div>
          )}
          {errorMessage && (
            <div className="new-wrapper error">{errorMessage}</div>
          )}

          {order && (
            <>
              <ul className="pageTabs">
                {tabsOrderTitle.map((tab, index) => (
                  <li
                    key={index}
                    className={
                      activeTab === index
                        ? "pageTabs__link selected"
                        : "pageTabs__link"
                    }
                    onClick={() => handleTabClick(index)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
              {orderViewsBlocks(order)[activeTab]}
            </>
          )}
        </div>
        <div className="popup-footer">
          <div className="group-btn">
            <button
              className="btn-tertiary main-close"
              type="button"
              onClick={closeModal}
            >
              {tCommon("form_cancel")}
            </button>
          </div>
        </div>
      </div>
    </LayoutRightPanel>
  );
};

export default OrderViewPanel;
