import { ReactNode } from "react";

export enum SearchType {
  Event = "Achievement",
  User = "AppUser",
  Category = "AchievementCategory",
}

export type SearchDto = {
  id: number;
  type: SearchType;
  text: string;
};

export type SearchResponse = {
  items: SearchDto[];
};

export type SearchRequest = {
  searchQuery: string;
  filterCurrentUser: boolean;
};

export type OptionsSearch = {
  label: ReactNode;
  value: number;
  type: SearchType;
};

export enum SearchNavigateLinks {
  Employee = "/employee",
  Category = "/achievement-categories",
  Event = "/achievements",
}
