import { NotificationLinkObjectTypes } from "../../../api/types/notifications";

type NotificationNavigationData = {
  path: string;
  state: null | { fromNotification: true };
};

export const getNavigationPath = (
  linkObjectType: NotificationLinkObjectTypes | string,
  linkObjectId: number,
  currentUserId?: number
): NotificationNavigationData => {
  if (!currentUserId)
    return {
      path: "/news",
      state: null,
    };

  switch (linkObjectType) {
    case NotificationLinkObjectTypes.merch:
      return {
        path: `/merch-store/merches/${linkObjectId}`,
        state: null,
      };

    case NotificationLinkObjectTypes.merchOrder:
      return {
        path: `/employee/${currentUserId}/delivery-info`,
        state: { fromNotification: true },
      };

    case NotificationLinkObjectTypes.profile:
      return {
        path: `/employee/${linkObjectId}`,
        state: null,
      };

    case NotificationLinkObjectTypes.achievementEvent:
      return {
        path: `/employee/${currentUserId}/player-info`,
        state: { fromNotification: true },
      };

    case NotificationLinkObjectTypes.achievement:
      return {
        path: `/achievements/${linkObjectId}`,
        state: null,
      };

    default:
      return {
        path: "/news",
        state: null,
      };
  }
};
