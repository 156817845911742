import { useTranslation } from "react-i18next";
import { FC } from "react";
import { MerchDeliveryInfoDto } from "api/types/merchDeliveryTypes";
import { formatPhoneNumber } from "./CustomPhoneInput/phoneInputHelpers";

type MerchDeliveryDetailsProps = {
  merchDelivery: MerchDeliveryInfoDto;
};

const MerchDeliveryDetails: FC<MerchDeliveryDetailsProps> = ({
  merchDelivery,
}) => {
  const { t } = useTranslation("employeePage");

  return (
    <div className="merchDeliveryBlock__item">
      <p className="merchDeliveryBlock__label">{t("receiverName")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.receiverFirstName}
      </p>

      <p className="merchDeliveryBlock__label">{t("receiverSurname")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.receiverFamilyName}
      </p>

      <p className="merchDeliveryBlock__label">{t("receiverPhone")}</p>
      <p className="merchDeliveryBlock__value">
        {formatPhoneNumber(merchDelivery.receiverPhone)}
      </p>

      <p className="merchDeliveryBlock__label">{t("receiverCountry")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.receiverCountry}
      </p>

      <p className="merchDeliveryBlock__label">{t("receiverRegion")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.receiverRegion}
      </p>
      <p className="merchDeliveryBlock__label">{t("receiverCity")}</p>
      <p className="merchDeliveryBlock__value">{merchDelivery.receiverCity}</p>
      <p className="merchDeliveryBlock__label">{t("zipCode")}</p>
      <p className="merchDeliveryBlock__value">{merchDelivery.zipCode}</p>
      <p className="merchDeliveryBlock__label">
        {t("deliveryPostalDepartment")}
      </p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.deliveryPostalDepartment}
      </p>

      <p className="merchDeliveryBlock__label">{t("zipCode")}</p>
      <p className="merchDeliveryBlock__value">{merchDelivery.zipCode}</p>

      <p className="merchDeliveryBlock__label">{t("deliveryAddress")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.deliveryAddress}
      </p>

      <p className="merchDeliveryBlock__label">{t("topSize")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.deliveryTopSize}
      </p>

      <p className="merchDeliveryBlock__label">{t("footSize")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.deliveryFootSize}
      </p>

      <p className="merchDeliveryBlock__label">{t("bottomSize")}</p>
      <p className="merchDeliveryBlock__value">
        {merchDelivery.deliveryBottomSize}
      </p>
    </div>
  );
};

export default MerchDeliveryDetails;
