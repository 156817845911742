import LayoutRightPanel from "pages/layout/LayoutRightPanel";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { resetTimeOffRequest } from "store/reducers/employeePageReducers/timeOffProfileReducer";
import { getTimeOffRequestById } from "store/thunks/employeePage/timeOffProfileThunks";
import {
  ButtonLoader,
  DatePickersCustomIcon,
  Loader,
  LoaderSize,
} from "utils/components";
import StructureCard from "../person-info/StructureCard";
import { DateRangePicker } from "rsuite";
import { parseDateFromString } from "utils/methods/parseDateFromString";
import { TimeOffRequestDaysList } from "./addEditTimeOffRequest/TimeOffRequestDaysList";
import { getDateTimeString } from "utils/methods";
import useLocale from "localization/useLocale";
import ReactQuill from "react-quill";
import { ReactQuillThemes } from "utils/consts";
import { safeJsonParse } from "utils/methods/safeJsonParse";
import { approveRequest, rejectRequest } from "api/timeOff/timeOffRequestsApi";
import { toastError, toastSuccess } from "utils/toasts";
import { AxiosError } from "axios";
import { serverError } from "types/serverError";
import { refreshTimeOffRequests } from "store/thunks/timeOff/timeOffRequestsThunks";

type TimeOffRequestViewProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  requestId: number | null;
  userId?: string | number;
  isManagementPage?: boolean;
};

export const TimeOffRequestView: FC<TimeOffRequestViewProps> = ({
  isModalOpen,
  onCloseModal,
  requestId,
  userId,
  isManagementPage = false,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([
    "timeOffProfile",
    "timeOffPolicies",
    "common",
    "events",
  ]);
  const locale = useLocale();
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.timeOffProfile.requestData
  );
  const { user, policyType, fromDate, toDate, note, createdAt, balance } =
    data || {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dateRange: [Date, Date] | null =
    fromDate && toDate
      ? [parseDateFromString(fromDate || ""), parseDateFromString(toDate || "")]
      : null;

  useEffect(() => {
    if (!requestId || !userId) {
      return;
    }

    if (isModalOpen) {
      dispatch(getTimeOffRequestById(+userId, requestId));
    }

    return () => {
      dispatch(resetTimeOffRequest());
    };
  }, [dispatch, requestId, isModalOpen]);

  const onApproveRequest = async () => {
    if (!requestId) return;

    setIsSubmitting(true);
    try {
      await approveRequest(requestId);
      onCloseModal();
      toastSuccess(t("timeOffPolicies:approveRequest"));
      await dispatch(refreshTimeOffRequests());
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || t("timeOffPolicies:errorRequest"));
    } finally {
      setIsSubmitting(false);
    }
  };

  const onRejectRequest = async () => {
    if (!requestId) return;

    setIsSubmitting(true);
    try {
      await rejectRequest(requestId);
      onCloseModal();
      toastSuccess(t("timeOffPolicies:rejectRequest"));
      await dispatch(refreshTimeOffRequests());
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || t("timeOffPolicies:errorRequest"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
      title={t("timeOffRequestTitle")}
      additionalClassName={"popup-badge timeOffRequestPopup"}
    >
      {isModalOpen ? (
        <>
          {isLoading ? (
            <div className="loader-container">
              <Loader size={LoaderSize.Page} />
            </div>
          ) : errorMessage ? (
            <div className="new-wrapper error">{errorMessage}</div>
          ) : (
            <div className="form-style  popup-panel-form">
              <div className="wrap">
                {user && (
                  <StructureCard
                    user={user}
                    isMenuRender={isManagementPage}
                    placementPopover="bottomEnd"
                  />
                )}
                <div className="d-flex timeOffRequestPopup__date-block">
                  <span>{t("timeOffRequestCreate")}</span>
                  <span>
                    {createdAt
                      ? getDateTimeString(createdAt, "fullDate", locale)
                      : "-"}
                  </span>
                </div>

                <label className="label">
                  <span className="label-title">{t("timeOffType")}</span>
                  <input
                    value={policyType?.name}
                    disabled
                    className="form-input"
                  />
                </label>

                <label className="label">
                  <span className="label-title">{t("timeOffDateRange")}</span>
                  <DateRangePicker
                    name="dateRange"
                    value={dateRange}
                    className="date-range-picker"
                    size="sm"
                    character=" — "
                    format="MM/dd/yyyy"
                    caretAs={DatePickersCustomIcon}
                    cleanable={false}
                    disabled
                  />
                </label>

                <TimeOffRequestDaysList
                  timeOffBalanceId={balance?.id || null}
                  dateRange={dateRange}
                />

                <label className="label">
                  <span className="label-title">
                    {t("timeOffPolicies:note")}
                  </span>

                  <div className="customTextEditor">
                    <ReactQuill
                      readOnly={true}
                      theme={ReactQuillThemes.snow}
                      value={safeJsonParse(note || "{}")}
                    />
                  </div>
                </label>
              </div>
              <div className="popup-footer">
                {isManagementPage ? (
                  <div>
                    <div className="group-btn d-flex">
                      <button
                        className="btn-tertiary main-close"
                        type="button"
                        disabled={isSubmitting}
                        onClick={onRejectRequest}
                      >
                        {isSubmitting ? (
                          <ButtonLoader />
                        ) : (
                          t("events:reject_btn")
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn-primary"
                        disabled={isSubmitting}
                        onClick={onApproveRequest}
                      >
                        {isSubmitting ? (
                          <ButtonLoader />
                        ) : (
                          t("events:approve_btn")
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="group-btn d-flex">
                    <button
                      className="btn-tertiary main-close"
                      type="button"
                      onClick={onCloseModal}
                    >
                      {t("common:form_cancel")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : null}
    </LayoutRightPanel>
  );
};
