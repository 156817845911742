import { AxiosPromise } from "axios";
import { base_url } from "../appConsts";
import { axiosInstance, axiosInstanceSkip } from "./instance";
import {
  loginBodyRequest,
  loginResponse,
  loginWithAzureTokenRequest,
  loginWithGoogleCodeRequest,
  resetPasswordRequest,
  resetPasswordWithCodeRequest,
} from "./types/auth";

export const login = (body: loginBodyRequest): AxiosPromise<loginResponse> =>
  axiosInstanceSkip.post(`${base_url}/api/auth/login`, body);

export const resetPassword = (body: resetPasswordRequest): AxiosPromise =>
  axiosInstanceSkip.post(`${base_url}/api/auth/forget-password`, body);

export const resetPasswordWithCode = (
  body: resetPasswordWithCodeRequest
): AxiosPromise =>
  axiosInstanceSkip.post(`${base_url}/api/auth/forget-password-code`, body);

export const loginWithGoogleCode = (
  body: loginWithGoogleCodeRequest
): AxiosPromise<loginResponse> =>
  axiosInstanceSkip.post(`${base_url}/api/auth/login-google-code`, body);

export const loginWithAzureToken = (
  params: loginWithAzureTokenRequest
): AxiosPromise<loginResponse> =>
  axiosInstanceSkip.get(`${base_url}/api/auth/user-azure`, { params });

export const logout = (): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/auth/logout`);

export const refresh = (): AxiosPromise<loginResponse> =>
  axiosInstanceSkip.post(`${base_url}/api/auth/refresh`, {});

export const impersonateAsHost = (
  companyId: number
): AxiosPromise<loginResponse> =>
  axiosInstance.post(`${base_url}/api/auth/impersonation/view-as-company`, {
    companyId,
  });

export const impersonateAsUser = (
  userId: number
): AxiosPromise<loginResponse> =>
  axiosInstance.post(`${base_url}/api/auth/impersonation/view-as-user`, {
    userId,
  });

export const exitImpersonation = (): AxiosPromise<loginResponse> =>
  axiosInstance.post(`${base_url}/api/auth/impersonation/exit`, {});
