import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import useLocale from "localization/useLocale";
import { getDaysList, getTotalUsedDays } from "../methods/calculateTimeOffDays";

type TimeOffPolicyRequestDaysListProps = {
  timeOffBalanceId: number | null;
  dateRange: [Date, Date] | null;
};

export const TimeOffRequestDaysList: FC<TimeOffPolicyRequestDaysListProps> = ({
  dateRange,
  timeOffBalanceId,
}) => {
  const { t, i18n } = useTranslation("timeOffProfile");
  const locale = useLocale();

  const { data } = useAppSelector(
    (state) => state.timeOffProfile.currentBalances
  );
  const { data: requestData } = useAppSelector(
    (state) => state.timeOffProfile.requestData
  );

  const currentPolicyBalance = 
    data && data?.find((balance) => balance.id === timeOffBalanceId);

  // if we edit request we take info from UserTimeOffRequestDto, if we create request we take info from current user balances
  const policyType = requestData ? requestData.policyType : currentPolicyBalance?.policyType;
  const currentBalance = requestData ? requestData.balance.daysAvailable : currentPolicyBalance?.daysAvailable;
  const unlimitedBalance = currentBalance === null;

  const daysList = useMemo(
    () => getDaysList(dateRange, locale, policyType),
    [dateRange, i18n.language, locale, policyType?.deductDays]
  );

  const totalUsedDays = useMemo(() => getTotalUsedDays(daysList), [daysList]);

  const defaultValue = 0;
  const remainingDays = (currentBalance || defaultValue) - totalUsedDays;
  const isNegativeDaysValue = remainingDays < defaultValue;

  if (!timeOffBalanceId) {
    return <div className="m-btm error">{t("timeOffYouNeedPolicy")}</div>;
  }

  return (
    <div className="requestDaysBlock">
      <div
        className={`requestDaysBlock__text ${
          isNegativeDaysValue ? "requestDaysBlock__text-negative" : ""
        }`}
      >
        <span>{t("timeOffYouHaveUsed")}</span>
        <span className="requestDaysBlock__text requestDaysBlock__text--bold">{` ${totalUsedDays} ${t(
          "balanceDays",
          {
            count: totalUsedDays,
          }
        )}.`}</span>
        {!unlimitedBalance && (
          <span>
            {` ${t("timeOffRemainingBalance")}`}
            <span
              className={`${
                isNegativeDaysValue ? "requestDaysBlock__remainingDays" : ""
              }`}
            >
              {`${remainingDays} ${t("balanceDays", {
                count: remainingDays,
              })}.`}
            </span>
          </span>
        )}
      </div>
      <div className="timeOffRequestPopup__divider" />
      <ul className="requestDaysBlock__list">
        {daysList.map((day, index) => {
          const { date, dayWeek, isWorkDay, remainingDays } = day;
          const labelWorkDayStyle = isWorkDay
            ? "tagLabel--gray"
            : "tagLabel--yellow";

          return (
            <li key={index} className="requestDaysBlock__item">
              <span>{date}</span>
              <span className="requestDaysBlock__dayweek">{dayWeek}</span>
              <span
                className={`tagLabel ${labelWorkDayStyle} d-flex tagLabel-small `}
              >
                {isWorkDay
                  ? `${t("timeOffWorkday")}`
                  : `${t("timeOffWeekend")}`}
              </span>

              {!unlimitedBalance && (
                <span className="requestDaysBlock__remainingDays">
                  {`${remainingDays} ${t("balanceDays", {
                    count: remainingDays,
                  })}`}
                </span>
              )}
            </li>
          );
        })}
      </ul>
      <div className="timeOffRequestPopup__divider timeOffRequestPopup__divider--margin" />
      <div className="d-flex">
        <p>{t("timeOffTotalDays")}</p>
        <div
          className={`requestDaysBlock__remainingDays  ${
            isNegativeDaysValue ? "requestDaysBlock__text-negative" : ""
          }`}
        >
          {`${totalUsedDays} ${t("balanceDays", {
            count: totalUsedDays,
          })}`}
        </div>
      </div>
    </div>
  );
};
