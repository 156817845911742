import { FC, useEffect } from "react";
import { createSearchParams, Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { getProfile } from "../store/thunks/authThunks";
import Loader from "../utils/components/Loader";
import { storeCurrentPath } from "./azureLogin/LocalRedirectUrlStorage";

/* links with implementation notes:
- https://github.com/remix-run/react-router/blob/main/examples/auth/README.md
- https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
- https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6
*/
const AuthGuard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.auth.profile);

  useEffect(() => {
    if (!profile) {
      (async () => {
        try {
          storeCurrentPath();
          await dispatch(getProfile());
        } catch {
          navigate({
            pathname: "/login",
            search: createSearchParams({
              returnUrl: `${window.location.pathname}${window.location.search}`,
            }).toString(),
          });
        }
      })();
    }
  }, []);

  if (profile) {
    return <Outlet />;
  }

  return (
    <div className="page-loader">
      <Loader />
    </div>
  );
};

export default AuthGuard;
