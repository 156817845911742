import { useAppSelector } from "store";
import { AppPermissions } from "utils/consts";

export const usePermissionToEmployeeCard = (
  userId: string | undefined | number,
  checkIsCurrentUserNotAdmin?: boolean
): boolean => {
  const profilePermissions =
    useAppSelector((state) => state.auth.profile?.permissions) || [];
  const profile = useAppSelector((state) => state.auth.profile);
  const isCurrentUser = profile && userId ? profile.id === +userId : false;
  const isAdmin = profilePermissions.includes(
    AppPermissions.ViewAndEditUserEmployeeCards
  );
  const isUserWithPermissionToViewCards = profilePermissions.includes(
    AppPermissions.EmployeeCard_ViewUsersEmployeeCard
  );

  if (checkIsCurrentUserNotAdmin) {
    return isCurrentUser && !isAdmin;
  }

  return isCurrentUser || isAdmin || isUserWithPermissionToViewCards;
};
