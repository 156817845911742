import { enUS, ru, uk } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const locales: any = { en: enUS, uk, ru };

const useLocale = (): Locale => {
  const { i18n } = useTranslation();
  if (i18n.language in locales) {
    return locales[i18n.language];
  } else {
    return locales.en;
  }
};

export default useLocale;
