import { FC, useMemo } from "react";
import { useAppSelector } from "../store";
import IPropsWithChildren from "../utils/types/props-with-children";

type Props = {
  requiredIntegration: string;
} & IPropsWithChildren;

const CompanyHasIntegration: FC<Props> = ({
  requiredIntegration,
  children,
}) => {
  const companyIntegrations = useAppSelector(
    (state) => state.auth.profile?.companyActiveIntegrations
  );

  const hasIntegration = useMemo(() => {
    const checkIntegration = () => {
      if (companyIntegrations?.includes(requiredIntegration)) {
        return true;
      }

      return false;
    };

    return checkIntegration();
  }, [companyIntegrations, requiredIntegration]);

  if (hasIntegration) {
    return <>{children}</>;
  }

  return null;
};

export default CompanyHasIntegration;
