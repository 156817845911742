import { Dispatch } from "redux";
import { getErrorMessage } from "../../../utils/methods";
import { AxiosError } from "axios";
import {
  resetTimeOffBacklogList,
  setCurrentTimeOffBalances,
  setErrorCurrentTimeOffBalances,
  setErrorTimeOffBacklogList,
  setErrorTimeOffRequest,
  setLoadingCurrentTimeOffBalances,
  setLoadingTimeOffBacklogList,
  setLoadingTimeOffRequest,
  setTimeOffBacklogList,
  setTimeOffRequest,
} from "../../reducers/employeePageReducers/timeOffProfileReducer";
import {
  loadCurrentUserTimeOffBacklog,
  loadOtherUserTimeOffBacklog,
  loadCurrentUserTimeOffBalances,
  loadOtherUserTimeOffBalances,
  loadTimeOffRequest,
} from "../../../api/employeePageApi";
import { TUserTimeOffProfilePermissions } from "../../types";
import { store } from "../../index";
import { SortDirections } from "../../../api/types/commonTypes";
import { countItemsPerPage } from "../../../appConsts";
import { UserTimeOffBacklogParams } from "../../../api/types/employeePageTypes";

export const getUserTimeOffBalances =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    const currentUserId = store.getState().auth.profile?.id;
    const isCurrentUser = userId === currentUserId;

    dispatch(setLoadingCurrentTimeOffBalances());

    try {
      const response = isCurrentUser
        ? await loadCurrentUserTimeOffBalances()
        : await loadOtherUserTimeOffBalances(userId);
      dispatch(setCurrentTimeOffBalances(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorCurrentTimeOffBalances(errorMessage));
    }
  };

export const getUserTimeOffBacklog =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    const currentUserId = store.getState().auth.profile?.id;
    const isCurrentUser = userId === currentUserId;

    dispatch(setLoadingTimeOffBacklogList());

    try {
      const { timeOffBacklogList, timeOffBacklogSorting } =
        store.getState().timeOffProfile.timeOffBacklog;
      const { sortBy, sortDirection } = timeOffBacklogSorting;
      const nextPage = timeOffBacklogList.page + 1;

      const requestBody: UserTimeOffBacklogParams = {
        pageNumber: nextPage,
        pageSize: countItemsPerPage,
        orderBy: sortBy,
        isDescending: sortDirection === SortDirections.desc,
      };

      const response = isCurrentUser
        ? await loadCurrentUserTimeOffBacklog(requestBody)
        : await loadOtherUserTimeOffBacklog(userId, requestBody);
      const backlogFromApi = response.data.items;
      const noMoreData =
        backlogFromApi?.length === 0 ||
        backlogFromApi?.length < countItemsPerPage;
      dispatch(
        setTimeOffBacklogList({
          timeOffBacklogData: backlogFromApi,
          hasMoreData: !noMoreData,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorTimeOffBacklogList(errorMessage));
    }
  };

export const refreshUserTimeOffBacklog =
  (userId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetTimeOffBacklogList());
    dispatch(getUserTimeOffBacklog(userId));
  };

export const getTimeOffProfilePageData =
  (userId: number, permissions?: TUserTimeOffProfilePermissions) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const currentUserId = store.getState().auth.profile?.id;
    const isCurrentUser = userId === currentUserId;

    await Promise.all([
      (isCurrentUser || permissions?.isViewTimeOffBalances) &&
        dispatch(getUserTimeOffBalances(userId)),
      (isCurrentUser || permissions?.isViewTimeOffBacklog) &&
        dispatch(getUserTimeOffBacklog(userId)),
    ]);
  };

export const getTimeOffRequestById =
  (userId: number, requestId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingTimeOffRequest());

    try {
      const response = await loadTimeOffRequest(userId, requestId);
      
      dispatch(setTimeOffRequest(response.data));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorTimeOffRequest(errorMessage));
    }
  };
