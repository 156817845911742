import React, { FC, useState } from "react";
import { FieldProps } from "formik";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import _debounce from "lodash/debounce";
import { SearchUsersRequest } from "../../api/types/usersTypes";
import { loadUsersOfSearch } from "../../api/usersApi";
import { getErrorMessage } from "../methods";
import { AxiosError } from "axios";

interface SearchUsersSelectProps extends FieldProps {
  isMulti?: boolean;
  filterCurrentUser: boolean;
}

const SearchUsersSelect: FC<SearchUsersSelectProps> = ({
  field,
  form,
  isMulti = true,
  filterCurrentUser,
}) => {
  const { t: tCommon } = useTranslation("common");
  const [errorSearch, setErrorSearch] = useState("");

  const getFromApi = _debounce(
    (
      inputValue: string,
      callback: (options: { value: number; label: string }[]) => void
    ) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }
      const body: SearchUsersRequest = {
        query: inputValue,
        filterCurrentUser,
      };
      loadUsersOfSearch(body)
        .then((response) => {
          const options = response.data.items.map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.familyName}`,
          }));
          callback(options);
        })
        .catch((e) => {
          const errorMessage = getErrorMessage(e as AxiosError);
          setErrorSearch(errorMessage);
          callback([]);
        });
    },
    750
  );

  return (
    <div>
      <AsyncSelect
        name={field.name}
        value={field.value}
        loadOptions={getFromApi}
        isMulti={isMulti}
        isClearable={true}
        onChange={(option) => {
          setErrorSearch("");
          form.setFieldValue(field.name, option);
        }}
        className="asyncSelect__container"
        classNamePrefix="asyncSelect"
        placeholder={tCommon("startTypingToSearch")}
        noOptionsMessage={() => <>{tCommon("select_noItems") as string}</>}
      />

      {errorSearch && <div className="error">{errorSearch}</div>}
    </div>
  );
};

export default SearchUsersSelect;
