import classNames from "classnames";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Loader, { LoaderSize } from "utils/components/Loader";
import { BadgesDataState } from "api/types/badgesTypes";

type Props = {
  onChangeTab: (i: number) => void;
  onCloseModal: () => void;
  selectedBadgeId: number | null;
  changeSelectedBadgeId: (id: number | null) => void;
  badgeGroupsData: BadgesDataState;
};

const ModalBadgeTab: FC<Props> = ({
  onCloseModal,
  onChangeTab,
  selectedBadgeId,
  changeSelectedBadgeId,
  badgeGroupsData,
}) => {
  const { t } = useTranslation("employeeAchievements");
  const { t: tCommon } = useTranslation("common");

  const { isLoading, badgeGroupsList, errorMessage } = badgeGroupsData;
  const isDisabledNextStep =
    Boolean(errorMessage) || !badgeGroupsList?.length || !selectedBadgeId;
  return (
    <div className="badge-wrapper">
      <div className="wrap">
        {isLoading && !badgeGroupsList && (
          <div className="loader-container">
            <Loader size={LoaderSize.Page} />
          </div>
        )}
        {errorMessage && (
          <div className="new-wrapper error">{errorMessage}</div>
        )}
        {badgeGroupsList && !badgeGroupsList.length && (
          <p className="no-content-message">{t("noBadgesToDisplay")}</p>
        )}
        {badgeGroupsList?.map((badgeGroup) => {
          return (
            <Fragment key={badgeGroup.groupName}>
              <h2 className="title-block m-btm">{badgeGroup.groupName}</h2>
              <div className="feedbackBadgesWrap">
                {badgeGroup.badges.map((badge) => (
                  <div
                    className={classNames("box feedbackBadge boxItemImg", {
                      active: badge.id === selectedBadgeId,
                    })}
                    key={badge.id}
                    onClick={() =>
                      changeSelectedBadgeId(
                        badge.id === selectedBadgeId ? null : badge.id
                      )
                    }
                  >
                    <div className="boxItemImg__imgWrap d-flex">
                      <img src={badge.imageUrl} alt="badge" />

                      <div className="feedbackBadge__icon" />
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          );
        }) ?? null}
      </div>
      <div className="popup-footer">
        <div className="group-btn d-flex">
          <button
            className="btn-tertiary main-close"
            type="button"
            onClick={onCloseModal}
          >
            {tCommon("form_cancel")}
          </button>
          <button
            className="btn-primary btn-popup"
            type="button"
            onClick={() => onChangeTab(1)}
            disabled={isDisabledNextStep}
          >
            {tCommon("form_next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalBadgeTab;
