import { AxiosError, AxiosPromise } from "axios";
import { Dispatch } from "redux";
import {
  loadAllBadgeMessages,
  loadAllEventsEndingByMonth,
  loadEssentialsAchievements,
  loadEssentialsMerch,
  loadMyTeamBadgeMessages,
  loadMyTeamEventsByMonth,
} from "../../api/newsApi";
import { BadgeMessageSystemResponse } from "../../api/types/news";
import { countItemsPerPage } from "../../appConsts";
import { getErrorMessage } from "../../utils/methods";
import { store } from "../index";
import {
  resetNewsBadgeMessages,
  setChangeTab,
  setErrorEssentialsAchievements,
  setErrorEssentialsMerch,
  setErrorEventsEndingByMonth,
  setErrorMessages,
  setErrorMyTeamEventsByMonth,
  setEssentialsAchievements,
  setEssentialsMerch,
  setLoadingEssentialsAchievements,
  setLoadingEssentialsMerch,
  setLoadingEventsEndingByMonth,
  setLoadingMessages,
  setLoadingMyTeamEventsByMonth,
  setMessages,
  setStateEventsEndingByMonth,
  setStateMyTeamEventsByMonth,
} from "../reducers/newsReducer";
import { Tabs } from "../types";

type MapApi = Record<
  Tabs,
  (pageNumber: number) => AxiosPromise<BadgeMessageSystemResponse>
>;
const mapApi: MapApi = {
  all: loadAllBadgeMessages,
  myTeam: loadMyTeamBadgeMessages,
};

export const loadMoreMessages =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const nextPage = store.getState().news.messageData.page + 1;
    const currentTab = store.getState().news.messageData.selectedTab;

    dispatch(setLoadingMessages());
    try {
      const response = await mapApi[currentTab](nextPage);
      const messagesFromApi = response.data.items;

      const noMoreMessages =
        messagesFromApi.length === 0 ||
        messagesFromApi.length < countItemsPerPage;
      const pageNumber = nextPage;

      dispatch(
        setMessages({
          messages: messagesFromApi,
          hasMoreData: !noMoreMessages,
          page: pageNumber,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorMessages(errorMessage));
    }
  };

export const changeNewsTab =
  (tabId: Tabs) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(setChangeTab(tabId));
    dispatch(loadMoreMessages());
  };

export const refreshNewsBadgeMessages =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetNewsBadgeMessages());
    dispatch(loadMoreMessages());
  };

export const getEssentialsAchievements =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const nextPage = store.getState().news.essentialsAchievements.page + 1;

    dispatch(setLoadingEssentialsAchievements());

    try {
      const response = await loadEssentialsAchievements(nextPage);
      const achievementsFromApi = response.data.items;
      const noMoreAchievements =
        achievementsFromApi.length === 0 ||
        achievementsFromApi.length < countItemsPerPage;

      dispatch(
        setEssentialsAchievements({
          achievements: achievementsFromApi,
          hasMoreData: !noMoreAchievements,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorEssentialsAchievements(errorMessage));
    }
  };

export const getEssentialsMerch =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const nextPage = store.getState().news.essentialsMerch.page + 1;

    dispatch(setLoadingEssentialsMerch());

    try {
      const response = await loadEssentialsMerch(nextPage);
      const merchFromApi = response.data.items;
      const noMoreMerch =
        merchFromApi.length === 0 || merchFromApi.length < countItemsPerPage;

      dispatch(
        setEssentialsMerch({
          merch: merchFromApi,
          hasMoreData: !noMoreMerch,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorEssentialsMerch(errorMessage));
    }
  };

export const getMyTeamEventsByMonth =
  (
    managerID: number | null,
    currentMonth: string,
    currentUserId: number | null
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!currentUserId) return;

    dispatch(setLoadingMyTeamEventsByMonth());
    try {
      const response = await loadMyTeamEventsByMonth(
        managerID,
        currentMonth,
        currentUserId
      );
      const eventsFromApi = response.data.items;

      dispatch(setStateMyTeamEventsByMonth(eventsFromApi));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorMyTeamEventsByMonth(errorMessage));
    }
  };

export const getAllEventsEndingByMonth =
  (currentMonth: string, isGamification: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!isGamification) return;
    dispatch(setLoadingEventsEndingByMonth());

    try {
      const response = await loadAllEventsEndingByMonth(currentMonth);
      const eventsFromApi = response.data.items;

      dispatch(setStateEventsEndingByMonth(eventsFromApi));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorEventsEndingByMonth(errorMessage));
    }
  };

export const getHomePageData =
  (isGamification: boolean, currentMonth: string) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    await Promise.all([
      dispatch(loadMoreMessages()),
      isGamification
        ? dispatch(getEssentialsAchievements())
        : Promise.resolve(),
      isGamification ? dispatch(getEssentialsMerch()) : Promise.resolve(),
      dispatch(getAllEventsEndingByMonth(currentMonth, isGamification)),
    ]);
  };
