import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BadgeMessageSystem } from "../../../api/types/news";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../../handleUpdateState";
import { EmployeeAchievementsState } from "../../types";
import { logoutUserAction } from "../authReducer";
import {
  UserBadgeDto,
  Feedback,
  UnresponsedFeedbackRequest,
} from "../../../api/types/employeePageTypes";
import { EmojiReactionUsersItemDto } from "../../../api/types/emojiTypes";

const initialState: EmployeeAchievementsState = {
  profileMessages: defaultStateWithInfiniteLoading,
  feedbackRequestsData: defaultListState,
  unresponsedFeedbackRequestsData: defaultListState,
  userBadgesData: defaultStateWithInfiniteLoading,
  totalBadgesCount: defaultListState,
};

const employeeAchievementsSlice = createSlice({
  name: "employeeAchievements",
  initialState,
  reducers: {
    setLoadingProfileMessages: (state) => ({
      ...state,
      profileMessages: setInfiniteLoadLoading(state.profileMessages),
    }),
    setProfileMessages: (
      state,
      action: PayloadAction<{
        messages: BadgeMessageSystem[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let messages = state.profileMessages.data || [];
      messages = [...messages, ...action.payload.messages];

      return {
        ...state,
        profileMessages: setInfiniteLoadData(
          state.profileMessages,
          messages,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorProfileMessages: (state, action: PayloadAction<string>) => ({
      ...state,
      profileMessages: setInfiniteLoadStateErrorMessage(
        state.profileMessages,
        action.payload
      ),
    }),
    resetProfileMessages: (state) => ({
      ...state,
      profileMessages: initialState.profileMessages,
    }),
    updateProfileBadgeMessageReactions: (
      state,
      action: PayloadAction<{
        badgeMessageId: number;
        updatedEmojiReactions: EmojiReactionUsersItemDto[];
      }>
    ) => {
      const { badgeMessageId, updatedEmojiReactions } = action.payload;
      const updatedBadgeMessagesArr = (state.profileMessages.data || []).map(
        (badgeMessage) =>
          badgeMessage.id === badgeMessageId
            ? { ...badgeMessage, emojiReactions: updatedEmojiReactions }
            : badgeMessage
      );
      return {
        ...state,
        profileMessages: {
          ...state.profileMessages,
          data: updatedBadgeMessagesArr,
        },
      };
    },
    editProfileBadgeMessage: (
      state,
      action: PayloadAction<BadgeMessageSystem>
    ) => {
      const updatedBadgeMessagesArr = (state.profileMessages.data || []).map(
        (badgeMessage) =>
          badgeMessage.id === action.payload.id ? action.payload : badgeMessage
      );

      return {
        ...state,
        profileMessages: {
          ...state.profileMessages,
          data: updatedBadgeMessagesArr,
        },
      };
    },
    /* FEEDBACK REQUESTS */
    setLoadingFR: (state) => ({
      ...state,
      feedbackRequestsData: setStateLoading(state.feedbackRequestsData),
    }),
    setFeedbackRequests: (state, action: PayloadAction<Feedback[]>) => ({
      ...state,
      feedbackRequestsData: setStateData(
        state.feedbackRequestsData,
        action.payload
      ),
    }),
    setErrorFR: (state, action: PayloadAction<string>) => ({
      ...state,
      feedbackRequestsData: setStateError(
        state.feedbackRequestsData,
        action.payload
      ),
    }),
    resetFR: (state) => ({
      ...state,
      feedbackRequestsData: defaultListState,
    }),
    // Sender active Feedback Requests
    setLoadingUnresponsedFeedbackRequests: (state) => ({
      ...state,
      unresponsedFeedbackRequestsData: setStateLoading(
        state.unresponsedFeedbackRequestsData
      ),
    }),
    setUnresponsedFeedbackRequestss: (
      state,
      action: PayloadAction<UnresponsedFeedbackRequest[]>
    ) => ({
      ...state,
      unresponsedFeedbackRequestsData: setStateData(
        state.unresponsedFeedbackRequestsData,
        action.payload
      ),
    }),
    setErrorUnresponsedFeedbackRequests: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      unresponsedFeedbackRequestsData: setStateError(
        state.unresponsedFeedbackRequestsData,
        action.payload
      ),
    }),
    // User Badges block
    setLoadingUserBadges: (state) => ({
      ...state,
      userBadgesData: setInfiniteLoadLoading(state.userBadgesData),
    }),
    setUserBadges: (
      state,
      action: PayloadAction<{
        badges: UserBadgeDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let badges = state.userBadgesData.data || [];
      badges = [...badges, ...action.payload.badges];

      return {
        ...state,
        userBadgesData: setInfiniteLoadData(
          state.userBadgesData,
          badges,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setUserBadgesError: (state, action: PayloadAction<string>) => ({
      ...state,
      messageData: setInfiniteLoadStateErrorMessage(
        state.userBadgesData,
        action.payload
      ),
    }),
    setLoadingTotalBadgesCount: (state) => ({
      ...state,
      totalBadgesCount: setStateLoading(state.totalBadgesCount),
    }),
    setTotalBadgesCount: (
      state,
      action: PayloadAction<number>
    ) => ({
      ...state,
      totalBadgesCount: setStateData(
        state.totalBadgesCount,
        action.payload
      ),
    }),
    setErrorTotalBadgesCount: (state, action: PayloadAction<string>) => ({
      ...state,
      totalBadgesCount: setStateError(state.totalBadgesCount, action.payload),
    }),
    resetProfileState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default employeeAchievementsSlice.reducer;

export const {
  setLoadingProfileMessages,
  setErrorProfileMessages,
  setProfileMessages,
  resetProfileState,
  updateProfileBadgeMessageReactions,
  resetProfileMessages,
  editProfileBadgeMessage,
  setLoadingFR,
  setFeedbackRequests,
  setErrorFR,
  resetFR,
  setLoadingUserBadges,
  setUserBadges,
  setUserBadgesError,
  setTotalBadgesCount,
  setErrorUnresponsedFeedbackRequests,
  setLoadingUnresponsedFeedbackRequests,
  setUnresponsedFeedbackRequestss,
  setErrorTotalBadgesCount,
  setLoadingTotalBadgesCount
} = employeeAchievementsSlice.actions;
