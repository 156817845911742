import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../../api/types/rolesTypes";
import { UserWithRole } from "../../api/types/usersTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { SystemSettingsState } from "../types";
import { logoutUserAction } from "./authReducer";
import { CompanyTreeItem } from "../../api/types/companyTypes";

const initialState: SystemSettingsState = {
  assignRoleTabState: {
    usersSearchQuery: null,
    usersWithRoles: defaultStateWithInfiniteLoading,
    // TODO remove not used
    rolesList: defaultListState,
  },
  settingRoleTabState: {
    rolesList: defaultListState,
  },
  accessManagementTabState: {
    searchQuery: null,
    roleIds: null,
    usersList: defaultStateWithInfiniteLoading,
    accessManagementRoles: defaultListState,
  },
  addEditRolePageState: {
    companiesList: defaultListState,
    permissionsList: defaultListState,
    selectedToEditRole: defaultListState,
  },
};

const systemSettingsSlice = createSlice({
  name: "systemSettings",
  initialState,
  reducers: {
    /* ASSIGN_TAB ROLES LIST */
    setLoadingRoles: (state) => ({
      ...state,
      assignRoleTabState: {
        ...state.assignRoleTabState,
        rolesList: setStateLoading(state.assignRoleTabState.rolesList),
      },
    }),
    setRoles: (state, action: PayloadAction<Role[]>) => ({
      ...state,
      assignRoleTabState: {
        ...state.assignRoleTabState,
        rolesList: setStateData(
          state.assignRoleTabState.rolesList,
          action.payload
        ),
      },
    }),
    setErrorRoles: (state, action: PayloadAction<string>) => ({
      ...state,
      assignRoleTabState: {
        ...state.assignRoleTabState,
        rolesList: setStateError(
          state.assignRoleTabState.rolesList,
          action.payload
        ),
      },
    }),

    /* ASSIGN_TAB USERS WITH ROLES LIST */
    setUsersWithRolesSearchQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      assignRoleTabState: {
        ...state.assignRoleTabState,
        usersSearchQuery: action.payload,
      }
    }),
    setLoadingUsersRolesList: (state) => ({
      ...state,
      assignRoleTabState: {
        ...state.assignRoleTabState,
        usersWithRoles: setInfiniteLoadLoading(
          state.assignRoleTabState.usersWithRoles
        ),
      },
    }),
    setUsersRolesList: (
      state,
      action: PayloadAction<{
        usersRoles: UserWithRole[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let usersRoles = state.assignRoleTabState.usersWithRoles.data || [];
      usersRoles = [...usersRoles, ...action.payload.usersRoles];

      return {
        ...state,
        assignRoleTabState: {
          ...state.assignRoleTabState,
          usersWithRoles: setInfiniteLoadData(
            state.assignRoleTabState.usersWithRoles,
            usersRoles,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorUsersRolesList: (state, action: PayloadAction<string>) => ({
      ...state,
      assignRoleTabState: {
        ...state.assignRoleTabState,
        usersWithRoles: setInfiniteLoadStateErrorMessage(
          state.assignRoleTabState.usersWithRoles,
          action.payload
        ),
      },
    }),
    resetUsersRolesList: (state) => ({
      ...state,
      assignRoleTabState: {
        ...state.assignRoleTabState,
        usersWithRoles: defaultStateWithInfiniteLoading,
      },
    }),

    /* SETTING_ROLE_TAB ROLES LIST */
    setLoadingRolesList: (state) => ({
      ...state,
      settingRoleTabState: {
        ...state.settingRoleTabState,
        rolesList: setStateLoading(state.settingRoleTabState.rolesList),
      },
    }),
    setRolesList: (state, action: PayloadAction<Role[]>) => ({
      ...state,
      settingRoleTabState: {
        ...state.settingRoleTabState,
        rolesList: setStateData(
          state.settingRoleTabState.rolesList,
          action.payload
        ),
      },
    }),
    setErrorRolesList: (state, action: PayloadAction<string>) => ({
      ...state,
      settingRoleTabState: {
        ...state.settingRoleTabState,
        rolesList: setStateError(
          state.settingRoleTabState.rolesList,
          action.payload
        ),
      },
    }),

    /*  ACCESS MANAGEMENT TAB STATE */
    setLoadingAccessManagementRoles: (state) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        accessManagementRoles: setStateLoading(
          state.accessManagementTabState.accessManagementRoles
        ),
      },
    }),
    setAccessManagementRoles: (state, action: PayloadAction<Role[]>) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        accessManagementRoles: setStateData(
          state.accessManagementTabState.accessManagementRoles,
          action.payload
        ),
      },
    }),
    setErrorAccessManagementRoles: (state, action: PayloadAction<string>) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        accessManagementRoles: setStateError(
          state.accessManagementTabState.accessManagementRoles,
          action.payload
        ),
      },
    }),
    setLoadingAccessManagementUsersList: (state) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        usersList: setInfiniteLoadLoading(
          state.accessManagementTabState.usersList
        ),
      },
    }),
    setAccessManagementUsersList: (
      state,
      action: PayloadAction<{
        usersList: UserWithRole[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let usersList = state.accessManagementTabState.usersList.data || [];
      usersList = [...usersList, ...action.payload.usersList];

      return {
        ...state,
        accessManagementTabState: {
          ...state.accessManagementTabState,
          usersList: setInfiniteLoadData(
            state.accessManagementTabState.usersList,
            usersList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorAccessManagementUsersList: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        usersList: setInfiniteLoadStateErrorMessage(
          state.accessManagementTabState.usersList,
          action.payload
        ),
      },
    }),
    resetAccessManagementUsersList: (state) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        usersList: defaultStateWithInfiniteLoading,
      },
    }),
    setSelectedRoleIds: (state, action: PayloadAction<number[] | null>) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        roleIds: action.payload,
      },
    }),
    setUsersSearchQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        searchQuery: action.payload,
      },
    }),
    resetFiltersAndUsersList: (state) => ({
      ...state,
      accessManagementTabState: {
        ...state.accessManagementTabState,
        roleIds: null,
        searchQuery: null,
        usersList: defaultStateWithInfiniteLoading,
      },
    }),

    /*  ADD EDIT ROLE PAGE STATE */
    setLoadingCompanies: (state) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        companiesList: setStateLoading(
          state.addEditRolePageState.companiesList
        ),
      },
    }),
    setCompanies: (state, action: PayloadAction<CompanyTreeItem[]>) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        companiesList: setStateData(
          state.addEditRolePageState.companiesList,
          action.payload
        ),
      },
    }),
    setErrorCompanies: (state, action: PayloadAction<string>) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        companiesList: setStateError(
          state.addEditRolePageState.companiesList,
          action.payload
        ),
      },
    }),
    setLoadingPermissionsList: (state) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        permissionsList: setStateLoading(
          state.addEditRolePageState.permissionsList
        ),
      },
    }),
    setPermissionsList: (state, action: PayloadAction<string[]>) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        permissionsList: setStateData(
          state.addEditRolePageState.permissionsList,
          action.payload
        ),
      },
    }),
    setErrorPermissionsList: (state, action: PayloadAction<string>) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        permissionsList: setStateError(
          state.addEditRolePageState.permissionsList,
          action.payload
        ),
      },
    }),
    setLoadingSelectedToEditRole: (state) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        selectedToEditRole: setStateLoading(
          state.addEditRolePageState.selectedToEditRole
        ),
      },
    }),
    setSelectedToEditRole: (state, action: PayloadAction<Role>) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        selectedToEditRole: setStateData(
          state.addEditRolePageState.selectedToEditRole,
          action.payload
        ),
      },
    }),
    setErrorSelectedToEditRole: (state, action: PayloadAction<string>) => ({
      ...state,
      addEditRolePageState: {
        ...state.addEditRolePageState,
        selectedToEditRole: setStateError(
          state.addEditRolePageState.selectedToEditRole,
          action.payload
        ),
      },
    }),
    resetSystemSettings: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default systemSettingsSlice.reducer;
export const {
  setUsersWithRolesSearchQuery,
  setLoadingUsersRolesList,
  setUsersRolesList,
  setErrorUsersRolesList,
  resetSystemSettings,
  setLoadingRoles,
  setRoles,
  setErrorRoles,
  setLoadingRolesList,
  setErrorRolesList,
  setRolesList,
  resetUsersRolesList,
  setLoadingAccessManagementUsersList,
  setAccessManagementUsersList,
  setErrorAccessManagementUsersList,
  setLoadingAccessManagementRoles,
  setAccessManagementRoles,
  setErrorAccessManagementRoles,
  setSelectedRoleIds,
  setUsersSearchQuery,
  resetAccessManagementUsersList,
  resetFiltersAndUsersList,
  setLoadingCompanies,
  setCompanies,
  setErrorCompanies,
  setLoadingPermissionsList,
  setPermissionsList,
  setErrorPermissionsList,
  setLoadingSelectedToEditRole,
  setSelectedToEditRole,
  setErrorSelectedToEditRole,
} = systemSettingsSlice.actions;
