import React, { FC, useEffect, useState } from "react";

export enum LoaderSize {
  Small = "Small",
  Page = "Page",
}

type ILoaderProps = {
  size?: LoaderSize;
} & React.SVGProps<SVGSVGElement>;

const loaderSizeMap: Record<string, { width: string; height: string }> = {
  [LoaderSize.Page]: { width: "92", height: "92" },
  [LoaderSize.Small]: { width: "40", height: "40" },
};

const rects = [
  <rect
    x="65.5359"
    y="4.16211"
    width="8"
    height="16"
    rx="4"
    transform="rotate(30 65.5359 4.16211)"
    fill="#e3e3e3"
  />,
  <rect
    x="83.8372"
    y="19.5352"
    width="8"
    height="16"
    rx="4"
    transform="rotate(60 83.8372 19.5352)"
    fill="#E0E0E0"
  />,
  <rect
    x="92"
    y="42"
    width="8"
    height="16"
    rx="4"
    transform="rotate(90 92 42)"
    fill="#E0E0E0"
  />,
  <rect
    x="87.8374"
    y="65.5371"
    width="8"
    height="16"
    rx="4"
    transform="rotate(120 87.8374 65.5371)"
    fill="#CDCDCD"
  />,
  <rect
    x="72.4639"
    y="83.8379"
    width="8"
    height="16"
    rx="4"
    transform="rotate(150 72.4639 83.8379)"
    fill="#B9B9B9"
  />,
  <rect x="42" y="76" width="8" height="16" rx="4" fill="#999999" />,

  <rect
    x="27.5359"
    y="69.9805"
    width="8"
    height="16"
    rx="4"
    transform="rotate(30 27.5359 69.9805)"
    fill="#858585"
  />,
  <rect
    x="18.0193"
    y="57.5352"
    width="8"
    height="16"
    rx="4"
    transform="rotate(60 18.0193 57.5352)"
    fill="#707070"
  />,
  <rect
    x="16"
    y="42"
    width="8"
    height="16"
    rx="4"
    transform="rotate(90 16 42)"
    fill="#5C5C5C"
  />,
  <rect
    x="22.0195"
    y="27.5371"
    width="8"
    height="16"
    rx="4"
    transform="rotate(120 22.0195 27.5371)"
    fill="#474747"
  />,
  <rect
    x="34.4639"
    y="18.0195"
    width="8"
    height="16"
    rx="4"
    transform="rotate(150 34.4639 18.0195)"
    fill="#333333"
  />,

  <rect x="42" width="8" height="16" rx="4" fill="#272727" />,
];

export const Loader: FC<ILoaderProps> = ({ size, ...rest }) => {
  size = size ?? LoaderSize.Page;

  const iconSize = loaderSizeMap[size];

  const [visibleRectCount, setVisibleRectCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setVisibleRectCount((count) => (count + 1) % rects.length);
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <svg
      width={iconSize.width}
      height={iconSize.height}
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {rects.map((rect, index) => (
        <g
          key={`group-${index}`}
          style={{
            opacity: index === visibleRectCount ? 1 : 0.4,
            transition: "opacity 0.1s ease-in-out",
          }}
        >
          {rect}
        </g>
      ))}
    </svg>
  );
};

export default Loader;
