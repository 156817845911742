import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BadgeGroupManagementDto } from "../../api/types/badgesManagementTypes";
import { defaultListState } from "../../appConsts";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { ManageBadgesState } from "../types";
import { logoutUserAction } from "./authReducer";

const initialState: ManageBadgesState = {
  badgeGroupsList: defaultListState,
};

const manageBadgesSlice = createSlice({
  name: "manageBadges",
  initialState,
  reducers: {
    setLoadingBadgeGroups: (state) => ({
      ...state,
      badgeGroupsList: setStateLoading(state.badgeGroupsList),
    }),
    setBadgeGroups: (
      state,
      action: PayloadAction<BadgeGroupManagementDto[]>
    ) => ({
      ...state,
      badgeGroupsList: setStateData(state.badgeGroupsList, action.payload),
    }),
    setErrorBadgeGroups: (state, action: PayloadAction<string>) => ({
      ...state,
      badgeGroupsList: setStateError(state.badgeGroupsList, action.payload),
    }),
    resetBadgeGroupsList: (state) => ({
      ...state,
      badgeGroupsList: defaultListState,
    }),
    resetManageBadges: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default manageBadgesSlice.reducer;
export const {
  setBadgeGroups,
  setLoadingBadgeGroups,
  setErrorBadgeGroups,
  resetBadgeGroupsList,
  resetManageBadges,
} = manageBadgesSlice.actions;
