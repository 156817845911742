import { UserOfSearch } from "../usersTypes";
import { PageDto } from "../commonTypes";

export enum TimeOffPolicyTypesPaymentStatuses {
  paid = "Paid",
  unpaid = "Unpaid",
}

export enum TimeOffPolicyTypesDeductDays {
  any = "Any",
  workingDays = "Working",
}

export type CreatePolicyTypeRequestBody = {
  iconKey: string;
  name: string;
  color: string;
  description: string;
  paymentStatus: TimeOffPolicyTypesPaymentStatuses;
  deductDays: TimeOffPolicyTypesDeductDays;
  badgeId: number;
  cheerfulMessage: string;
};

export type UpdateTimeOffPolicyTypeRequest = CreatePolicyTypeRequestBody;

export type TimeOffPolicyTypeBaseDto = {
  id: number;
  name: string;
  paymentStatus: TimeOffPolicyTypesPaymentStatuses;
  deductDays: TimeOffPolicyTypesDeductDays;
};

export type TimeOffPolicyTypeDto = TimeOffPolicyTypeBaseDto & {
  iconKey: string;
  color: string | null;
  description: string;
  badgeId: number;
  cheerfulMessage: string;
};

export type TimeOffPolicyTypeListItemDto = {
  id: number;
  iconKey: string;
  name: string;
  description: string;
  policiesCount: number;
};

export type TimeOffPolicyAssignPeopleCriteriaDto = {
  locations: string[] | null;
};

export type TimeOffPolicyAssignedUsersPageDto = PageDto<UserOfSearch>;

export type TimeOffPolicyListDto = {
  items: TimeOffPolicyListItemDto[];
};

export type TimeOffPolicyListItemDto = {
  id: number;
  name: string | null;
  lastUpdatedAt: string | null;
  isEnabled: boolean;
  assignedUsers: TimeOffPolicyAssignedUsersPageDto;
  assignCriteria: TimeOffPolicyAssignPeopleCriteriaDto;
};

export type TimeOffPoliciesListQueryParams = {
  isEnabled?: boolean;
};

export type TimeOffLocationFilter = {
  value: string;
  isAvailable: boolean;
};

export type TimeOffLocationsFilterResponse = {
  locations: TimeOffLocationFilter[];
};
