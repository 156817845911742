import { FC, useState } from "react";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { CelebrationType } from "./CelebrationModal";
import { toastSuccess } from "utils/toasts";
import { useTranslation } from "react-i18next";
import { useConfetti } from "./hooks/useConfetti";
import { CelebrationConfetti } from "./CelebrationConfetti";
import { BadgeMessageSystem } from "api/types/news";

type AnniversaryCelebrationModalProps = {
  event: BadgeMessageSystem | null;
  onClose: () => void;
};

export const AnniversaryCelebrationModal: FC<
  AnniversaryCelebrationModalProps
> = ({ event, onClose }) => {
  const { t } = useTranslation("celebrationModal");
  const { t: tMerch } = useTranslation("merchStore");
  const [isOpenStep1, setIsOpenStep1] = useState<boolean>(true);
  const [isOpenStep2, setIsOpenStep2] = useState<boolean>(false);
  const [isShowConfetti, setIsShowConfetti] = useState(false);
  const isConfettiVisible = useConfetti(isShowConfetti, 5000);

  const closeStep1 = () => {
    setIsOpenStep1(false);
    if (event) {
      toastSuccess(
        `${t("toast")} ${tMerch("coins", {
          count: event.coins ? event?.coins : 0,
        })} ${t("toast_happy_anniversary")}`
      );
    }

    setIsShowConfetti(true);
    setTimeout(() => {
      onClose();
      setIsShowConfetti(false);
    }, 5000);
  };

  const closeStep2 = () => {
    setIsOpenStep2(false);
  };

  const nextStep = () => {
    setIsOpenStep1(false);
    if (!event) {
      closeStep1();
      return;
    }
    setIsOpenStep2(true);
  };

  return (
    <>
      <Step1
        isModalOpen={isOpenStep1}
        onCloseModal={closeStep1}
        type={CelebrationType.anniversary}
        nextStep={nextStep}
      />
      {event ? (
        <Step2
          isModalOpen={isOpenStep2}
          onCloseModal={closeStep2}
          type={CelebrationType.anniversary}
          event={event}
        />
      ) : null}
      {isConfettiVisible && <CelebrationConfetti />}
    </>
  );
};
