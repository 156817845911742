import { FC } from "react";
import { Tooltip, Whisper } from "rsuite";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../hooks/useWindowSize";

type FieldLabelProps = {
  labelTitle: string;
};

const mobileWidth = 500;
const RequiredFieldLabel: FC<FieldLabelProps> = ({ labelTitle }) => {
  const { t } = useTranslation("common");
  const { width } = useWindowSize();

  return (
    <p className="field-label-required">
      {labelTitle}

      <Whisper
        placement={width > mobileWidth ? "rightStart" : "top"}
        trigger="hover"
        speaker={
          <Tooltip className="custom-tooltip field-label-tooltip">
            {t("requiredLabel")}
          </Tooltip>
        }
      >
        <span className="field-star">{"\u00a0"}*</span>
      </Whisper>
    </p>
  );
};

export default RequiredFieldLabel;
