import { AxiosPromise } from "axios";
import { base_url } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  ExportOrdersDataRequest,
  MerchOrderDto,
  MerchOrdersStatsResponse,
  OrderDeliveryDateRequest,
  OrderDetailsRequestBody,
  SearchOrdersRequest,
  SearchOrdersResponse,
} from "./types/merchDeliveryTypes";

export const loadOrderStatusesStats =
  (): AxiosPromise<MerchOrdersStatsResponse> =>
    axiosInstance.get(`${base_url}/api/merch-orders/management/stats`);

export const loadOrdersList = (
  body: SearchOrdersRequest
): AxiosPromise<SearchOrdersResponse> =>
  axiosInstance.post(`${base_url}/api/merch-orders/management/search`, body);

export const exportOrdersData = (body: ExportOrdersDataRequest) =>
  axiosInstance.post(`${base_url}/api/merch-orders/management/export`, body, {
    responseType: "json",
  });

export const loadOrderDetails = (
  orderId: number
): AxiosPromise<MerchOrderDto> =>
  axiosInstance.get(`${base_url}/api/merch-orders/management/${orderId}`);

export const processOrder = (orderId: number, body: OrderDetailsRequestBody) =>
  axiosInstance.post(
    `${base_url}/api/merch-orders/management/${orderId}/start-processing`,
    body
  );

export const editOrder = (orderId: number, body: OrderDetailsRequestBody) =>
  axiosInstance.patch(
    `${base_url}/api/merch-orders/management/${orderId}`,
    body
  );

export const confirmOrderDelivery = (
  orderId: number,
  body: OrderDeliveryDateRequest
) =>
  axiosInstance.post(
    `${base_url}/api/merch-orders/management/${orderId}/received`,
    body
  );

export const cancelOrder = (orderId: number) =>
  axiosInstance.post(
    `${base_url}/api/merch-orders/management/${orderId}/reject`
  );
