import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AnalyticsDataResponse,
  AnalyticsPopularBadgeDto,
  AnalyticsRequest,
  DateFilterType,
  FiltersDataResponse,
} from "../../api/types/analyticTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { AnalyticsState } from "../types";
import { logoutUserAction } from "./authReducer";

const initialState: AnalyticsState = {
  dropdownsData: defaultListState,
  analyticsData: defaultListState,
  analyticsFilter: {
    dateFilter: {
      filterType: DateFilterType.Month,
      customRange: null,
    },
    positions: [],
    departaments: [],
    employees: [],
    managers: [],
  },
  popularBadges: defaultStateWithInfiniteLoading,
};

const analyticsReducer = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    /* filter dropdowns */
    setLoadingDropdownsData: (state) => ({
      ...state,
      dropdownsData: setStateLoading(state.dropdownsData),
    }),
    setDropdownsData: (state, action: PayloadAction<FiltersDataResponse>) => ({
      ...state,
      dropdownsData: setStateData(state.dropdownsData, action.payload),
    }),
    setDropdownsError: (state, action: PayloadAction<string>) => ({
      ...state,
      dropdownsData: setStateError(state.dropdownsData, action.payload),
    }),
    /* analytics data */
    setLoadingAnalyticsData: (state) => ({
      ...state,
      analyticsData: setStateLoading(state.analyticsData),
    }),
    setAnalyticsData: (
      state,
      action: PayloadAction<AnalyticsDataResponse>
    ) => ({
      ...state,
      analyticsData: setStateData(state.analyticsData, action.payload),
    }),
    setAnalyticsError: (state, action: PayloadAction<string>) => ({
      ...state,
      analyticsData: setStateError(state.analyticsData, action.payload),
    }),
    /* analytics popular badges*/
    setLoadingAnalyticsPopularBadges: (state) => ({
      ...state,
      popularBadges: setInfiniteLoadLoading(state.popularBadges),
    }),
    setAnalyticsPopularBadges: (
      state,
      action: PayloadAction<{
        popularBadges: AnalyticsPopularBadgeDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let popularBadges = state.popularBadges.data || [];
      popularBadges = [...popularBadges, ...action.payload.popularBadges];

      return {
        ...state,
        popularBadges: setInfiniteLoadData(
          state.popularBadges,
          popularBadges,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorAnalyticsPopularBadges: (state, action: PayloadAction<string>) => ({
      ...state,
      popularBadges: setInfiniteLoadStateErrorMessage(
        state.popularBadges,
        action.payload
      ),
    }),
    /* analytics filter data */
    setAnalyticsFilter: (state, action: PayloadAction<AnalyticsRequest>) => ({
      ...state,
      analyticsFilter: action.payload,
    }),
    resetAnalyticsPopularBadges: (state) => ({
      ...state,
      popularBadges: defaultStateWithInfiniteLoading,
    }),
    resetAnalyticsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default analyticsReducer.reducer;

export const {
  setLoadingDropdownsData,
  setDropdownsData,
  setDropdownsError,
  setLoadingAnalyticsData,
  setAnalyticsError,
  setAnalyticsData,
  setAnalyticsFilter,
  setAnalyticsPopularBadges,
  setErrorAnalyticsPopularBadges,
  setLoadingAnalyticsPopularBadges,
  resetAnalyticsPopularBadges,
  resetAnalyticsData,
} = analyticsReducer.actions;
