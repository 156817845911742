import { AxiosError } from "axios";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ButtonLoader, Icon } from "utils/components";
import * as Yup from "yup";
import { useAppDispatch } from "../store";
import { logoutUserAction } from "../store/reducers/authReducer";
import { getProfile, loginUser } from "../store/thunks/authThunks";
import { serverError } from "../types/serverError";
import AggregateErrorMessage from "../utils/components/AggregateErrorMessage";
import { handleServerError } from "../utils/methods";
import LoginWithGoogle from "./LoginWithGoogle";
import { EsteemePolicies } from "../appConsts";
import LoginWithAzure from "./azureLogin/LoginWithAzure";
import { config } from "utils/config";
interface Values {
  email: string;
  password: string;
  remember: boolean;
}

const LoginPage: FC = () => {
  const { t } = useTranslation("auth");
  const { t: tCommon } = useTranslation("common");

  const [searchParams] = useSearchParams();
  const [serverError, setServerError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const returnUrl = searchParams.get("returnUrl") || "/news";
  const { isShowAzureLogin, isShowGoogleLogin, isShowLoginForm } =
    config.authentication;

  useEffect(() => {
    dispatch(logoutUserAction());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initialValues: Values = {
    email: "",
    password: "",
    remember: false,
  };

  const handleSubmitForm = async (
    values: Values,
    { setErrors }: FormikHelpers<Values>
  ) => {
    setServerError(null);

    const body = {
      email: values.email,
      password: values.password,
    };

    try {
      await dispatch(loginUser(body));
      await dispatch(getProfile());
      navigate(returnUrl, { replace: true });
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      setServerError(error?.message || t("incorrectLoginOrPassword"));
      setErrors(handleServerError(error || {}));
    }
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required(
      tCommon("validation_fieldIsRequired") as string
    ),
    password: Yup.string().required(
      tCommon("validation_fieldIsRequired") as string
    ),
    remember: Yup.boolean(),
  });

  return (
    <div className="login-page">
      <Link to="/" className="logo">
        <img src={"/img/logo.png"} alt="logo" />
      </Link>

      {serverError && (
        <p className="error error-auth" data-cy="incorrect-login">
          {serverError}
        </p>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={handleSubmitForm}
      >
        {(props: FormikProps<Values>) => {
          const { errors, values, handleBlur, handleChange, isSubmitting } =
            props;
          const disabled = !!errors.email || !!errors.password || isSubmitting;
          return (
            <Form className="form-login form-style box" noValidate={true}>
              <h1 className="title login-title">{t("welcome_to_esteeme")}</h1>
              {isShowLoginForm && (
                <>
                  <p className="login-subtitle">{t("fill_info")}</p>
                  <div className="login-divider login-divider--vertical-margin" />
                  <AggregateErrorMessage
                    component="p"
                    className="error error-auth"
                  />

                  <label className="name label">
                    <span className="label-title">{t("login")}</span>
                    <input
                      type="text"
                      name="email"
                      className="form-input"
                      placeholder={t("login") || ""}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      component="p"
                      name="email"
                      className="error error-auth"
                    />
                  </label>

                  <label className="name label">
                    <span className="label-title">{t("password")}</span>
                    <div className="login-icon">
                      <div
                        onClick={(evt) => {
                          setShowPassword(!showPassword);
                          evt.preventDefault();
                        }}
                      >
                        <Icon
                          svgClass="ic-eye"
                          href={`#${showPassword ? "wiews-filled" : "wiews"}`}
                        />
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-input"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("password") || ""}
                      />
                    </div>
                    <ErrorMessage
                      component="p"
                      name="password"
                      className="error error-auth"
                    />
                  </label>

                  <Field
                    id="remember"
                    type="checkbox"
                    name="remember"
                    className="checkboxInput"
                  />
                  <label htmlFor="remember" className="checkboxLabel m-top">
                    {t("rememberMe")}
                  </label>

                  <p className="t-center">
                    <span>{t("policiesConsent")}</span>
                    {EsteemePolicies.map((policy, index) => (
                      <Fragment key={policy.linkId}>
                        <a
                          href={policy.href}
                          target="_blank"
                          className="policyLink"
                          rel="noreferrer"
                        >
                          {t(`${policy.linkId}_policy`)}
                        </a>

                        {index <= 1 && <span>, </span>}
                        {index === 2 && <span>{t("and_join")}</span>}
                      </Fragment>
                    ))}
                  </p>

                  <div className="login-group-btn">
                    <Link to="/forget-password" className="btn-regular">
                      {t("forgotPassword")}
                    </Link>
                    <button
                      type="submit"
                      className="btn-primary"
                      disabled={disabled}
                    >
                      {isSubmitting ? <ButtonLoader /> : t("signIn")}
                    </button>
                  </div>
                </>
              )}

              <div className="sso-auth">
                {(isShowAzureLogin || isShowGoogleLogin) && isShowLoginForm ? (
                  <>
                    <div className="login-divider" />
                    <span>{t("or")}</span>
                    <p className="google-auth-text">
                      {t("signInWithSocialAccount")}
                    </p>
                  </>
                ) : null}

                {isShowGoogleLogin && <LoginWithGoogle returnUrl={returnUrl} />}
                {isShowAzureLogin && <LoginWithAzure />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginPage;
