import { AxiosError } from "axios";
import { Dispatch } from "redux";
import {
  login,
  loginWithAzureToken,
  loginWithGoogleCode,
  logout,
} from "../../api/authApi";
import { loadUserCoins, profile } from "../../api/profileApi";
import {
  loginBodyRequest,
  loginWithAzureTokenRequest,
  loginWithGoogleCodeRequest,
} from "../../api/types/auth";
import { loadUserInfo } from "../../api/usersApi";
import { getErrorMessage } from "../../utils/methods";
import {
  logoutUserAction,
  resetCoinsCount,
  setErrorCoinsCount,
  setLastVisit,
  setLoadingUserCoins,
  setProfile,
  setTokens,
  setUserCoins,
  setUserInfo,
} from "../reducers/authReducer";

export const loginUser =
  (data: loginBodyRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      const response = await login(data);
      await dispatch(setTokens(response.data));
      await dispatch(setLastVisit(response.data.lastVisit));
    } catch (e) {
      throw e;
    }
  };

export const loginUserWithGoogleCode =
  (data: loginWithGoogleCodeRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      const response = await loginWithGoogleCode(data);
      await dispatch(setTokens(response.data));
      await dispatch(setLastVisit(response.data.lastVisit));
    } catch (e) {
      throw e;
    }
  };

export const loginUserWithAzureToken =
  (data: loginWithAzureTokenRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      const response = await loginWithAzureToken(data);
      dispatch(setTokens(response.data));
      dispatch(setLastVisit(response.data.lastVisit));
    } catch (e) {
      throw e;
    }
  };

export const logoutUser =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await logout();
      dispatch(logoutUserAction());
    } catch (e) {
      throw e;
    }
  };

export const getProfile =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await profile();
      dispatch(setProfile(response.data));
    } catch (e) {
      throw e;
    }
  };

export const getUserInfo =
  (id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await loadUserInfo(id);
      await dispatch(setUserInfo(response.data));
    } catch (e) {
      throw e;
    }
  };

export const getUserCoinsCount =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingUserCoins());
    try {
      const response = await loadUserCoins();
      dispatch(setUserCoins(response.data.coins));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorCoinsCount(errorMessage));
    }
  };

export const refreshCoinsCount =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    await dispatch(resetCoinsCount());
    await dispatch(getUserCoinsCount());
  };
