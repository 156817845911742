import { AxiosPromise } from "axios";
import { base_url } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  AchievementCoinTransactionListDto,
  ActiveAchievementsResponse,
  CategoryEventsCountListDto,
  SearchUserAchievementEventsResponse,
  UserAchievementEventsRequestBody,
} from "./types/playerProfileTypes";
import { UserCoinsResponse } from "./types/profile";

export const loadUserCoinsBalance = (
  userId: number
): AxiosPromise<UserCoinsResponse> =>
  axiosInstance.get(`${base_url}/api/user-card/${userId}/coins`);

export const loadUserCoinsPraisedBalance = (
  userId: number
): AxiosPromise<UserCoinsResponse> =>
  axiosInstance.get(`${base_url}/api/user-card/${userId}/praised-coins`);

export const loadActiveAchievementsEventsCount = (
  userId: number
): AxiosPromise<ActiveAchievementsResponse> =>
  axiosInstance.get(
    `${base_url}/api/user-card/${userId}/achievements/events/active-count`
  );

export const loadAchievementCoinTransactions = (
  userId: number
): AxiosPromise<AchievementCoinTransactionListDto> =>
  axiosInstance.get(
    `${base_url}/api/user-card/${userId}/achievement-transactions/history`
  );

export const loadAchievementCategoriesCount = (
  userId: number
): AxiosPromise<CategoryEventsCountListDto> =>
  axiosInstance.get(
    `${base_url}/api/user-card/${userId}/achievements/events/count-by-categories`
  );

export const getUserAchievementsBacklog = (
  userId: number,
  body: UserAchievementEventsRequestBody
): AxiosPromise<SearchUserAchievementEventsResponse> =>
  axiosInstance.post(
    `${base_url}/api/user-card/${userId}/achievements/events/search`,
    body
  );
