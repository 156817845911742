import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { getErrorMessage } from "../../utils/methods";
import {
  resetGroupManagement,
  setDynamicCriteriaData,
  setErrorDynamicCriteria,
  setErrorGroupsByUserData,
  setErrorGroupsWithoutUserData,
  setErrorUserGroupData,
  setErrorUserGroupsList,
  setGroupsByUserData,
  setGroupsWithoutUserData,
  setLoadingDynamicCriteriaData,
  setLoadingGroupsByUserData,
  setLoadingGroupsWithoutUserData,
  setLoadingUserGroupData,
  setLoadingUserGroupsList,
  setUserGroupData,
  setUserGroupsList,
} from "store/reducers/groupManagementReducer";
import {
  loadGroupsByUser,
  loadGroupsWithoutUser,
  loadUserGroup,
  loadUserGroups,
} from "api/groupManagementApi";
import {
  searchDepartments,
  searchLocations,
  searchPositions,
} from "../../api/usersApi";
import { GroupFilterType } from "../../api/types/groupManagementTypes";

export const getUserGroups = () => async (dispatch: Dispatch) => {
  dispatch(setLoadingUserGroupsList());
  try {
    const userGroupsFromApi = await loadUserGroups();

    dispatch(setUserGroupsList(userGroupsFromApi.data));
  } catch (e) {
    const errorMessage = getErrorMessage(e as AxiosError);
    dispatch(setErrorUserGroupsList(errorMessage));
  }
};

export const refreshGroupManagement =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    await dispatch(resetGroupManagement());
    await dispatch(getUserGroups());
  };

export const getUserGroupData =
  (userGroupId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingUserGroupData());

    try {
      const userGroupFromApi = await loadUserGroup(userGroupId);

      dispatch(setUserGroupData(userGroupFromApi.data));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorUserGroupData(errorMessage));
    }
  };

export const getGroupsByUser =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingGroupsByUserData());
    try {
      const userGroupsFromApi = await loadGroupsByUser(userId);

      dispatch(setGroupsByUserData(userGroupsFromApi.data));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorGroupsByUserData(errorMessage));
    }
  };

export const getGroupsWithoutUser =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingGroupsWithoutUserData());
    try {
      const groupsWithoutUserFromApi = await loadGroupsWithoutUser(userId);

      dispatch(setGroupsWithoutUserData(groupsWithoutUserFromApi.data));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorGroupsWithoutUserData(errorMessage));
    }
  };

export const getDynamicCriteriaData =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingDynamicCriteriaData());

    try {
      const response = await Promise.all([
        searchPositions(),
        searchDepartments(),
        searchLocations(),
      ]);
      const modifiedFiltersData = response.map((r) =>
        r.data.items.map((value) => ({ value, label: value }))
      );
      dispatch(
        setDynamicCriteriaData({
          [GroupFilterType.position]: modifiedFiltersData[0],
          [GroupFilterType.department]: modifiedFiltersData[1],
          [GroupFilterType.location]: modifiedFiltersData[2],
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorDynamicCriteria(errorMessage));
    }
  };
