import { FC } from "react";
import { Tooltip, Whisper } from "rsuite";
import Icon from "./Icon";

type ButtonClass = "tertiary" | "regular";

export type ManagementButtonProps = {
  tooltipContent: string;
  iconHref: string;
  onButtonClick: () => void;
  btnClass?: ButtonClass;
};
export const ManagementButton: FC<ManagementButtonProps> = ({
  tooltipContent,
  iconHref,
  onButtonClick,
  btnClass = "regular",
}) => (
  <Whisper
    speaker={
      <Tooltip
        className="custom-tooltip custom-tooltip-black managementTooltip"
        arrow={false}
      >
        {tooltipContent}
      </Tooltip>
    }
    trigger="hover"
    followCursor
    placement="bottomEnd"
  >
    <button
      type="button"
      className={`btn-regular actionBtn-${btnClass}`}
      onClick={onButtonClick}
    >
      <Icon href={iconHref} svgClass="ic-dots" />
    </button>
  </Whisper>
);
