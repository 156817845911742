import { useAppSelector } from "../../store";
import { Features } from "../consts";

export const useCompanyFeatureCheck = (feature: Features): boolean => {
  const companyFeatures =
    useAppSelector((state) => state.auth.profile?.companyAvailableFeatures) ||
    [];

  return companyFeatures.includes(feature);
};
