import { useTranslation } from "react-i18next";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import { getOrderStatusNameLocalized } from "../../merch/merchStore/utils/getOrderStatusNameLocalized";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useIsCurrentUser } from "../../../utils/hooks/useIsCurrentUser";
import useLocale from "../../../localization/useLocale";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import { getCropTitle } from "../../../utils/methods/getCropTitle";
import OrderViewPanel from "../../merch/merchDelivery/orderViewPanel/OrderViewPanel";
import { SortDirections } from "../../../api/types/commonTypes";
import {
  getEmployeeOrders,
  refreshEmployeeMerchOrdersList,
} from "../../../store/thunks/employeePage/employeeDeliveryInfoThunks";
import { setEmployeeMerchOrdersSorting } from "../../../store/reducers/employeePageReducers/employeeDeliveryInfoReducer";
import { getDateTimeString } from "utils/methods";
import { renderSortIcon } from "../../../utils/methods/renderSortIcon";
import { EmployeeOrdersSortBy } from "../../../api/types/employeePageTypes";

const EmployeeMerchOrdersTable = () => {
  const dispatch = useAppDispatch();
  const locale = useLocale();
  const location = useLocation();
  const targetScrollRef = useRef<HTMLDivElement | null>(null);
  const { userId } = useParams();
  const { t } = useTranslation("employeePage");
  const { t: tMerchDelivery } = useTranslation("merchDelivery");
  const { t: tCommon } = useTranslation("common");
  const { t: tMerchStore } = useTranslation("merchStore");
  const { isLoading, data, errorMessage, hasMoreData } = useAppSelector(
    (state) => state.employeeDeliveryInfo.employeeMerchOrders.ordersList
  );
  const { sortBy, sortDirection } = useAppSelector(
    (state) => state.employeeDeliveryInfo.employeeMerchOrders.sorting
  );
  const isCurrentUser = useIsCurrentUser();

  const [isOrderViewModalOpen, setIsOrderViewModalOpen] = useState(false);
  const [selectedToViewOrderId, setSelectedToViewOrderId] = useState<
    number | null
  >(null);

  // for navigation from merchOrder status notification to Orders table
  // implementation from https://javascript.plainenglish.io/creating-a-hash-anchor-link-effect-with-react-router-a63dcb1a9b0e
  const scrollToTarget = () => {
    setTimeout(() => {
      targetScrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  useEffect(() => {
    if (location.state) {
      scrollToTarget();
    }
  }, []);

  const fetchEmployeeOrdersData = async () => {
    if (!userId) return;
    await dispatch(getEmployeeOrders(+userId));
  };

  const handleSortOrdersList = async (
    columnName: string,
    direction: SortDirections
  ) => {
    if (!userId) return;

    dispatch(
      setEmployeeMerchOrdersSorting([
        columnName as EmployeeOrdersSortBy,
        direction,
      ])
    );

    await dispatch(refreshEmployeeMerchOrdersList(+userId));
  };

  if (!userId) {
    return null;
  }

  return (
    <div className="employeeOrdersTable" ref={targetScrollRef}>
      <h1 className="main-title m-btm">
        {isCurrentUser ? t("userOrdersTitle") : t("employeeOrdersTitle")}
      </h1>
      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}
      {data && !data.length && (
        <p className="no-content-message m-top">
          {isCurrentUser
            ? t("emptyUserOrdersData_message")
            : t("emptyEmployeeOrdersData_message")}
        </p>
      )}
      {data && data.length > 0 && (
        <div className="table-wrap">
          <div className="table-inner table-header">
            <div className="item-col item-th">
              <span>{t("ordersTable_itemName")}</span>
            </div>

            <div className="item-col item-th">
              <span>{t("ordersTable_dateCreation")}</span>
              {/*Column names correspond to field name in database*/}
              {renderSortIcon(
                EmployeeOrdersSortBy.CreatedDate,
                sortBy,
                sortDirection,
                handleSortOrdersList
              )}
            </div>

            <div className="item-col item-th">
              <span>{t("ordersTable_dateDelivery")}</span>
              {renderSortIcon(
                EmployeeOrdersSortBy.DeliveredDate,
                sortBy,
                sortDirection,
                handleSortOrdersList
              )}
            </div>

            <div className="item-col item-th">
              <span>{t("ordersTable_cost")}</span>
              {renderSortIcon(
                EmployeeOrdersSortBy.Coins,
                sortBy,
                sortDirection,
                handleSortOrdersList
              )}
            </div>

            <div className="item-col item-th">
              <span>{t("ordersTable_status")}</span>
              {renderSortIcon(
                EmployeeOrdersSortBy.Status,
                sortBy,
                sortDirection,
                handleSortOrdersList
              )}
            </div>
          </div>

          <InfiniteScroll
            dataLength={data.length}
            next={fetchEmployeeOrdersData}
            hasMore={hasMoreData}
            loader={
              <div className="loader-container">
                <Loader size={LoaderSize.Small} />
              </div>
            }
            endMessage={
              <p className="end-message">
                {tCommon("infiniteScrollEndMessage")}
              </p>
            }
            style={{ overflow: "visible" }}
          >
            {data.map((order) => {
              return (
                <div
                  className="table-inner new-table-inner"
                  key={order.id}
                  onClick={() => {
                    setSelectedToViewOrderId(order.id);
                    setIsOrderViewModalOpen(true);
                  }}
                >
                  <div className="item-col">
                    <span className="item-col-first-el">
                      {getCropTitle(order.merchItem.name, 38)}
                    </span>
                  </div>

                  <div className="item-col">
                    <span>
                      {getDateTimeString(order.orderDate, "fullDate", locale)}
                    </span>
                  </div>

                  <div className="item-col">
                    {order.deliveredDate
                      ? getDateTimeString(
                          order.deliveredDate,
                          "fullDate",
                          locale
                        )
                      : null}
                  </div>

                  <div className="item-col">
                    <span>{tMerchStore("coins", { count: order.coins })}</span>
                  </div>

                  <div className="item-col">
                    <span>
                      {getOrderStatusNameLocalized(
                        tMerchDelivery,
                        order.orderStatus
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      )}
      <OrderViewPanel
        isModalOpen={isOrderViewModalOpen}
        onCloseModal={() => {
          setIsOrderViewModalOpen(false);
          setSelectedToViewOrderId(null);
        }}
        selectedOrderId={selectedToViewOrderId}
      />
    </div>
  );
};

export default EmployeeMerchOrdersTable;
