import { UserCardMerchDeliveryInfoDto } from "api/types/employeePageTypes";
import { AxiosError } from "axios";
import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Radio, RadioGroup } from "rsuite";
import { useAppDispatch } from "store";
import { refreshEmployeePageData } from "store/thunks/employeePage/employeePageThunks";
import { serverError } from "types/serverError";
import {
  AggregateErrorMessage,
  ButtonLoader,
  ShippingCountriesSelect,
} from "utils/components";
import * as Yup from "yup";
import { toastError, toastSuccess } from "utils/toasts";
import { CustomPhoneInput } from "utils/components";
import { validatePhoneNumber } from "utils/components/CustomPhoneInput/phoneInputHelpers";
import RequiredFieldLabel from "../../../../utils/components/RequiredFieldLabel";

export enum ReceivePresents {
  yes = "yes",
  no = "no",
}

export type MerchDeliveryInfoValues = {
  receiveCorporatePresents: ReceivePresents;
  receiverFirstName: string;
  receiverFamilyName: string;
  receiverPhone: string;
  receiverCountry: string;
  receiverRegion: string | null;
  receiverCity: string;
  zipCode: string;
  deliveryPostalDepartment: string | null;
  deliveryAddress: string | null;
  deliveryTopSize: string;
  deliveryFootSize: string;
  deliveryBottomSize: string;
};

const resetMerchDeliveryInfoValues: MerchDeliveryInfoValues = {
  receiveCorporatePresents: ReceivePresents.no,
  receiverFirstName: "",
  receiverFamilyName: "",
  receiverPhone: "",
  receiverCountry: "",
  receiverRegion: "",
  receiverCity: "",
  zipCode: "",
  deliveryPostalDepartment: "",
  deliveryAddress: "",
  deliveryTopSize: "",
  deliveryFootSize: "",
  deliveryBottomSize: "",
};

type MerchDeliveryInfoFormProps = {
  initialValues: MerchDeliveryInfoValues;
  userId: number;
  setEditMode: (v: false) => void;
  handleSubmitMerchDeliveryInfo: (
    userId: number,
    body: UserCardMerchDeliveryInfoDto
  ) => void;
};

const MerchDeliveryInfoForm: FC<MerchDeliveryInfoFormProps> = ({
  initialValues,
  userId,
  setEditMode,
  handleSubmitMerchDeliveryInfo,
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation([
    "employeePage",
    "common",
    "accountSettings",
    "countries",
  ]);

  const createConditionalValidation = (
    message: string,
    isRequired: boolean = true
  ) =>
    Yup.string().when("receiveCorporatePresents", {
      is: ReceivePresents.yes,
      then: isRequired ? Yup.string().required(message) : Yup.string(),
      otherwise: Yup.string().notRequired(),
    });

  const validationSchema = Yup.object().shape({
    receiverFirstName: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
    receiverFamilyName: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
    receiverCountry: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
    receiverPhone: Yup.string().when("receiveCorporatePresents", {
      is: (value: ReceivePresents) => value === ReceivePresents.yes,
      then: Yup.string()
        .required(t("common:validation_fieldIsRequired") as string)
        .test({
          name: "isValidPhoneNumber",
          message: t("common:invalid_phone_number") as string,
          test: (value) => !value || validatePhoneNumber(value),
        }),
      otherwise: Yup.string(),
    }),
    receiverCity: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
    zipCode: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
    deliveryAddress: Yup.string().test({
      name: "deliveryAddressRequired",
      test: function (value) {
        const { deliveryPostalDepartment, receiveCorporatePresents } =
          this.parent;
        if (
          receiveCorporatePresents === ReceivePresents.yes &&
          !deliveryPostalDepartment &&
          !value
        ) {
          return this.createError({
            message: t("deliveryPostalOrDeliveryAddress") as string,
          });
        }
        return true;
      },
    }),
    deliveryTopSize: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
    deliveryFootSize: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
    deliveryBottomSize: createConditionalValidation(
      t("common:validation_fieldIsRequired") as string
    ),
  });

  const handleSubmit = async (values: MerchDeliveryInfoValues) => {
    const requestBody: UserCardMerchDeliveryInfoDto = {
      receiveCorporatePresents:
        values.receiveCorporatePresents === ReceivePresents.yes,
      receiverFirstName: values.receiverFirstName,
      receiverFamilyName: values.receiverFamilyName,
      receiverPhone: values.receiverPhone,
      receiverCountry: values.receiverCountry,
      receiverRegion: values.receiverRegion || null,
      receiverCity: values.receiverCity,
      zipCode: values.zipCode,
      deliveryPostalDepartment: values.deliveryPostalDepartment || null,
      deliveryAddress: values.deliveryAddress || null,
      deliveryTopSize: values.deliveryTopSize,
      deliveryFootSize: values.deliveryFootSize,
      deliveryBottomSize: values.deliveryBottomSize,
    };
    try {
      await handleSubmitMerchDeliveryInfo(userId, requestBody);
      dispatch(refreshEmployeePageData(userId));
      toastSuccess(t("accountSettings:userUpdatedToast"));
      setEditMode(false);
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || t("common:incorrectData"));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<MerchDeliveryInfoValues>) => {
        const { values, isSubmitting, setFieldValue, setValues } = props;
        const isInputDisabled =
          values.receiveCorporatePresents === ReceivePresents.no;

        const getLabelContent = (labelText: string) => {
          return isInputDisabled ? (
            labelText
          ) : (
            <RequiredFieldLabel labelTitle={labelText} />
          );
        };

        return (
          <Form className="form-style" autoComplete="off">
            <AggregateErrorMessage component="div" className="error" />
            <div className="employeePage__item deliveryInfoBlock__item">
              <p className="employeePage__label">
                {t("receiveCorporatePresentsQuestion")}
              </p>
              <div className="deliveryInfoBlock__radio">
                <RadioGroup
                  value={values.receiveCorporatePresents}
                  onChange={(value) => {
                    if (value === ReceivePresents.no) {
                      setValues({
                        ...resetMerchDeliveryInfoValues,
                      });
                    } else {
                      setFieldValue("receiveCorporatePresents", value);
                    }
                  }}
                  inline
                >
                  <Radio value={ReceivePresents.yes}>{t("common:yes")}</Radio>
                  <Radio value={ReceivePresents.no}>{t("common:no")}</Radio>
                </RadioGroup>
                <ErrorMessage
                  component="p"
                  name="receiveCorporatePresents"
                  className="error"
                />
              </div>
              <label
                htmlFor="receiverFirstName"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("receiverName"))}
              </label>
              <div>
                <Field
                  id="receiverFirstName"
                  type="text"
                  name="receiverFirstName"
                  placeholder={t("receiverName")}
                  className="form-input"
                  disabled={isInputDisabled}
                />
                <ErrorMessage
                  component="p"
                  name="receiverFirstName"
                  className="error"
                />
              </div>

              <label
                htmlFor="receiverFamilyName"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("receiverSurname"))}
              </label>
              <div>
                <Field
                  id="receiverFamilyName"
                  type="text"
                  name="receiverFamilyName"
                  placeholder={t("receiverSurname")}
                  className="form-input"
                  disabled={isInputDisabled}
                />
                <ErrorMessage
                  component="p"
                  name="receiverFamilyName"
                  className="error"
                />
              </div>
              <label
                htmlFor="receiverPhone"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("receiverPhone"))}
              </label>
              <div>
                <Field
                  id="receiverPhone"
                  type="text"
                  component={CustomPhoneInput}
                  name="receiverPhone"
                  placeholder={t("receiverPhone")}
                  className="form-input"
                  disabled={isInputDisabled}
                  lang={i18n.language}
                />
                <ErrorMessage
                  component="p"
                  name="receiverPhone"
                  className="error"
                />
              </div>
              <ShippingCountriesSelect
                name={"receiverCountry"}
                isInputDisabled={isInputDisabled}
              />

              <label
                htmlFor="receiverRegion"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {t("receiverRegion")}
              </label>
              <div>
                <Field
                  id="receiverRegion"
                  type="text"
                  name="receiverRegion"
                  placeholder={t("receiverRegion")}
                  className="form-input"
                  disabled={isInputDisabled}
                />
                <ErrorMessage
                  component="p"
                  name="receiverRegion"
                  className="error"
                />
              </div>
              <label
                htmlFor="receiverCity"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("receiverCity"))}
              </label>
              <div>
                <Field
                  id="receiverCity"
                  type="text"
                  name="receiverCity"
                  placeholder={t("receiverCity")}
                  className="form-input"
                  disabled={isInputDisabled}
                />
                <ErrorMessage
                  component="p"
                  name="receiverCity"
                  className="error"
                />
              </div>
              <label
                htmlFor="zipCode"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("zipCode"))}
              </label>
              <div>
                <Field
                  id="zipCode"
                  type="text"
                  name="zipCode"
                  placeholder={t("zipCode")}
                  disabled={isInputDisabled}
                  className="form-input"
                />
                <ErrorMessage component="p" name="zipCode" className="error" />
              </div>

              <label
                htmlFor="deliveryPostalDepartment"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {t("deliveryPostalDepartment")}
              </label>
              <div>
                <Field
                  id="deliveryPostalDepartment"
                  type="text"
                  name="deliveryPostalDepartment"
                  placeholder={t("deliveryPostalDepartment_placeholder")}
                  disabled={isInputDisabled}
                  className="form-input"
                />
                <ErrorMessage
                  component="p"
                  name="deliveryPostalDepartment"
                  className="error"
                />
              </div>

              <label
                htmlFor="deliveryAddress"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("deliveryAddress"))}
              </label>
              <div>
                <Field
                  id="deliveryAddress"
                  type="text"
                  name="deliveryAddress"
                  placeholder={t("deliveryAddress")}
                  disabled={isInputDisabled}
                  className="form-input"
                />
                <ErrorMessage
                  component="p"
                  name="deliveryAddress"
                  className="error"
                />
              </div>
              <label
                htmlFor="deliveryTopSize"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("topSize"))}
              </label>
              <div>
                <Field
                  id="deliveryTopSize"
                  type="text"
                  name="deliveryTopSize"
                  placeholder={t("topSize")}
                  disabled={isInputDisabled}
                  className="form-input"
                />
                <ErrorMessage
                  component="p"
                  name="deliveryTopSize"
                  className="error"
                />
              </div>

              <label
                htmlFor="deliveryFootSize"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("footSize"))}
              </label>
              <div>
                <Field
                  id="deliveryFootSize"
                  type="text"
                  name="deliveryFootSize"
                  placeholder={t("footSize")}
                  disabled={isInputDisabled}
                  className="form-input"
                />
                <ErrorMessage
                  component="p"
                  name="deliveryFootSize"
                  className="error"
                />
              </div>
              <label
                htmlFor="deliveryBottomSize"
                className={classNames("employeePage__label", {
                  "label-disabled": isInputDisabled,
                })}
              >
                {getLabelContent(t("bottomSize"))}
              </label>
              <div>
                <Field
                  id="deliveryBottomSize"
                  type="text"
                  name="deliveryBottomSize"
                  placeholder={t("bottomSize")}
                  disabled={isInputDisabled}
                  className="form-input"
                />
                <ErrorMessage
                  component="p"
                  name="deliveryBottomSize"
                  className="error"
                />
              </div>
            </div>

            <div className="formButtons">
              <button
                type="button"
                className="btn-tertiary formButtons__formBtn"
                disabled={isSubmitting}
                onClick={() => setEditMode(false)}
              >
                {t("common:form_cancel")}
              </button>
              <button
                type="submit"
                className="btn-primary formButtons__formBtn"
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoader /> : t("common:form_save")}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MerchDeliveryInfoForm;
