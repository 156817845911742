import { useAppDispatch, useAppSelector } from "../../../store";
import { SortDirections } from "../../../api/types/commonTypes";
import {
  getUserTimeOffBacklog,
  refreshUserTimeOffBacklog,
} from "../../../store/thunks/employeePage/timeOffProfileThunks";
import { useParams } from "react-router-dom";
import { setTimeOffBacklogSorting } from "../../../store/reducers/employeePageReducers/timeOffProfileReducer";
import {
  TimeOffRequestStatuses,
  UserTimeOffBacklogSortBy,
} from "../../../api/types/employeePageTypes";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import { renderSortIcon } from "../../../utils/methods/renderSortIcon";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useIsCurrentUser } from "../../../utils/hooks/useIsCurrentUser";
import Icon from "../../../utils/components/Icon";
import { getRequestStatusNameLocalized } from "../../../utils/methods/getRequestStatusNameLocalized";
import useLocale from "../../../localization/useLocale";
import { displayTimeOffPeriod } from "../../../utils/methods/displayTimeOffPeriod";
import { toastError, toastSuccess } from "../../../utils/toasts";
import { AxiosError } from "axios";
import { serverError } from "../../../types/serverError";
import { useState } from "react";
import { cancelTimeOffRequest } from "../../../api/employeePageApi";
import LayoutConfirmationPopup from "../../layout/LayoutConfirmationPopup";
import AddEditTimeOffRequest from "./addEditTimeOffRequest/AddEditTimeOffRequest";
import { TimeOffRequestView } from "./TimeOffRequestView";

const TimeOffsBacklog = () => {
  const dispatch = useAppDispatch();
  const locale = useLocale();
  const { t } = useTranslation("timeOffProfile");
  const { t: tCommon } = useTranslation("common");
  const { userId } = useParams();
  const isCurrentUser = useIsCurrentUser();
  const { timeOffBacklogList, timeOffBacklogSorting } = useAppSelector(
    (state) => state.timeOffProfile.timeOffBacklog
  );
  const { isLoading, data, errorMessage, hasMoreData } = timeOffBacklogList;
  const { sortBy, sortDirection } = timeOffBacklogSorting;

  const [selectedBacklogRequestId, setSelectedBacklogRequestId] = useState<
    null | number
  >(null);
  const [isCancelRequestModalOpen, setIsCancelRequestModalOpen] =
    useState(false);
  const [isShowRequestModal, setIsShowRequestModal] = useState(false);
  const [isShowRequestViewModal, setIsShowRequestViewModal] = useState(false);

  const handleShowRequestModal = () => {
    setIsShowRequestModal((prev) => !prev);
  };

  const handleShowRequestViewModal = () => {
    setIsShowRequestViewModal((prev) => !prev);
    setSelectedBacklogRequestId(null);
  };

  const handleRequestToView = (id: number) => {
    setSelectedBacklogRequestId(id);
    setIsShowRequestViewModal(true);
  };

  const handleRequestToEdit = (id: number) => {
    setSelectedBacklogRequestId(id);
    setIsShowRequestModal(true);
  };

  const fetchData = async () => {
    if (!userId) return;

    await dispatch(getUserTimeOffBacklog(+userId));
  };

  const handleSortTimeOffBacklog = async (
    columnName: string,
    direction: SortDirections
  ) => {
    if (!userId) return;

    dispatch(
      setTimeOffBacklogSorting([
        columnName as UserTimeOffBacklogSortBy,
        direction,
      ])
    );
    await dispatch(refreshUserTimeOffBacklog(+userId));
  };

  const cancelRequest = async () => {
    if (!selectedBacklogRequestId || !userId) return;

    try {
      await cancelTimeOffRequest(selectedBacklogRequestId);
      setSelectedBacklogRequestId(null);
      dispatch(refreshUserTimeOffBacklog(+userId));
      toastSuccess(t("timeOffRequestCancel_successMsg"));
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("serverError"));
    } finally {
      setIsCancelRequestModalOpen(false);
    }
  };

  return (
    <section className="timeOffsBacklog">
      <h1 className="main-title">{t("timeOffsBacklog")}</h1>

      <div className="table-wrap">
        {isLoading && !data && (
          <div className="loader-container">
            <Loader size={LoaderSize.Small} />
          </div>
        )}
        {errorMessage && (
          <div className="new-wrapper error">{errorMessage}</div>
        )}
        {data && !data.length && (
          <p className="no-content-message">
            {isCurrentUser
              ? t("timeOffsBacklog_emptyData_user")
              : t("timeOffsBacklog_emptyData_admin")}
          </p>
        )}
        {data?.length ? (
          <>
            <div className="table-inner table-header">
              <div className="item-col item-th">
                <span>{t("backlog_type")}</span>
                {renderSortIcon(
                  UserTimeOffBacklogSortBy.policyType,
                  sortBy,
                  sortDirection,
                  handleSortTimeOffBacklog
                )}
              </div>
              <div className="item-col item-th">
                <span>{t("backlog_period")}</span>
                {renderSortIcon(
                  UserTimeOffBacklogSortBy.dateFrom,
                  sortBy,
                  sortDirection,
                  handleSortTimeOffBacklog
                )}
              </div>
              <div className="item-col item-th">
                <span>{t("backlog_duration")}</span>
                {renderSortIcon(
                  UserTimeOffBacklogSortBy.duration,
                  sortBy,
                  sortDirection,
                  handleSortTimeOffBacklog
                )}
              </div>
              <div className="item-col item-th">
                <span>{t("backlog_status")}</span>
                {renderSortIcon(
                  UserTimeOffBacklogSortBy.status,
                  sortBy,
                  sortDirection,
                  handleSortTimeOffBacklog
                )}
              </div>
              <div className="item-col item-th" />
            </div>
            <div className="peopleOverBlock__table-content">
              <InfiniteScroll
                dataLength={data.length}
                next={fetchData}
                hasMore={hasMoreData}
                loader={
                  <div className="loader-container">
                    <Loader size={LoaderSize.Small} />
                  </div>
                }
                style={{ overflow: "visible" }}
              >
                {data.map((backlogRequest) => {
                  const {
                    id,
                    policyType,
                    fromDate,
                    toDate,
                    daysTotal,
                    status,
                  } = backlogRequest;

                  return (
                    <div className="table-inner new-table-inner" key={id}>
                      <div className="item-col d-flex item-col-first-el">
                        {policyType.name}
                      </div>

                      <div className="item-col">
                        <span>
                          {displayTimeOffPeriod(fromDate, toDate, locale)}
                        </span>
                      </div>

                      <div className="item-col">
                        <span>{daysTotal}</span>
                      </div>

                      <div className="item-col">
                        {status ? (
                          <span>{getRequestStatusNameLocalized(status)}</span>
                        ) : null}
                      </div>

                      <div className="item-col">
                        {status === TimeOffRequestStatuses.onReview &&
                          isCurrentUser && (
                            <>
                              <button
                                type="button"
                                className="btn-tertiary actionBtn-tertiary"
                                onClick={() => {
                                  setSelectedBacklogRequestId(id);
                                  setIsCancelRequestModalOpen(true);
                                }}
                              >
                                <Icon
                                  svgClass="buttonIcon"
                                  href="#deleteRequest"
                                />
                              </button>

                              <button
                                type="button"
                                className="btn-tertiary actionBtn-tertiary"
                                onClick={() => handleRequestToEdit(id)}
                              >
                                <Icon svgClass="buttonIcon" href="#edite" />
                              </button>
                            </>
                          )}

                        <button
                          type="button"
                          className="btn-tertiary actionBtn-tertiary"
                          onClick={() => handleRequestToView(id)}
                        >
                          <Icon svgClass="buttonIcon" href="#wiews" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </>
        ) : null}
      </div>

      <LayoutConfirmationPopup
        isModalOpen={isCancelRequestModalOpen}
        onCloseModal={() => {
          setSelectedBacklogRequestId(null);
          setIsCancelRequestModalOpen(false);
        }}
        confirmHandler={cancelRequest}
        title={t("timeOffRequest_cancel")}
        question={t("timeOffRequest_confirmCancel")}
      />
      <AddEditTimeOffRequest
        isModalOpen={isShowRequestModal}
        onCloseModal={handleShowRequestModal}
        requestId={selectedBacklogRequestId}
      />
      <TimeOffRequestView
        isModalOpen={isShowRequestViewModal}
        onCloseModal={handleShowRequestViewModal}
        userId={userId}
        requestId={selectedBacklogRequestId}
      />
    </section>
  );
};

export default TimeOffsBacklog;
