import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export type ToggleMode = {
  id: string;
  title: string;
  value: number;
};

type CustomToggleProps = {
  modes: ToggleMode[];
  selectedMode: number;
  handleModeChange: (mode: number) => void;
};
export const CustomToggle: FC<CustomToggleProps> = ({
  modes,
  selectedMode,
  handleModeChange,
}) => {
  const { t: tCommon } = useTranslation("common");

  return (
    <div className="customToggle">
      {modes.map((mode) => (
        <button
          type="button"
          className={classNames("customToggle__btn", {
            selected: selectedMode === mode.value,
          })}
          key={mode.id}
          onClick={() => handleModeChange(mode.value)}
        >
          <span className="customToggle__btn-label">{tCommon(mode.title)}</span>
        </button>
      ))}
    </div>
  );
};

export default CustomToggle;
