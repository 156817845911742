import { FC, ReactNode, Suspense } from "react";
import Loader from "./Loader";

interface SuspenseWrapperProps {
  children: ReactNode;
}

export const SuspenseWrapper: FC<SuspenseWrapperProps> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="page-loader">
          <Loader />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
