import classNames from "classnames";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { resetEmployeePageState } from "store/reducers/employeePageReducers/employeePersonalInfoReducer";
import { getUserData } from "store/thunks/employeePage/employeePageThunks";
import { Loader, LoaderSize } from "utils/components";
import { AppPermissions, Features } from "utils/consts";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { useCompanyFeatureCheck, usePermissionCheck } from "utils/hooks";
import MainUserInfoBlock from "./MainUserInfoBlock";
import { Whisper } from "rsuite";
import { OverlayTriggerHandle } from "rsuite/esm/internals/Overlay/OverlayTrigger";

const EmployeePage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("employeePage");
  const { t: tCommon } = useTranslation("common");
  const { t: tLayout } = useTranslation("layout");
  const triggerRef = useRef<OverlayTriggerHandle | null>(null);
  const { userId } = useParams();
  const profile = useAppSelector((state) => state.auth.profile);
  const isCurrentUser = useIsCurrentUser();
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.employeePage.userData
  );
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const isUserCanViewPlayerProfile = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
    AppPermissions.EmployeeCard_ViewPlayerProfile,
  ]);
  const isUserCanViewPersonalInfo = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
    AppPermissions.EmployeeCard_ViewUsersEmployeeCard,
  ]);
  const isUserViewAndEditMerchDeliveryInfo = usePermissionCheck([
    AppPermissions.EmployeeCard_ViewAndEditMerchDeliveryInfo,
  ]);
  const isUserViewMerchOrders = usePermissionCheck([
    AppPermissions.MerchOrders_ViewAndManageOrders,
  ]);
  const isAccessToPersonalInfo = isCurrentUser || isUserCanViewPersonalInfo;
  const isAccessToPlayerProfile =
    isGamificationAvailable && (isUserCanViewPlayerProfile || isCurrentUser);
  const isAccessToDeliverylInfo =
    isGamificationAvailable &&
    (isCurrentUser ||
      isUserViewAndEditMerchDeliveryInfo ||
      isUserViewMerchOrders);

  const isTimeOffAvailable = useCompanyFeatureCheck(Features.TimeOff);
  const isUserCanViewTimeOffProfile = usePermissionCheck([
    AppPermissions.TimeOff_ViewOtherUserBalance,
    AppPermissions.TimeOff_ViewOtherUserBacklog,
  ]);
  const isAccessToTimeOffProfile =
    isTimeOffAvailable && (isUserCanViewTimeOffProfile || isCurrentUser);

  useEffect(() => {
    if (userId) {
      dispatch(getUserData(+userId));
    }

    return () => {
      dispatch(resetEmployeePageState());
    };
  }, [userId]);

  const tabs = [
    {
      title: t("achievements_tab"),
      path: `/employee/${userId}`,
      className: "pageTabs__link",
      permission: null,
    },
    {
      title: t("mainInfo_tab"),
      path: `/employee/${userId}/personal-info`,
      className: "pageTabs__link pageTabs__popoverLink",
      permission: isAccessToPersonalInfo,
    },
    {
      title: t("playerProfile_tab"),
      path: `/employee/${userId}/player-info`,
      className: "pageTabs__link pageTabs__popoverLink",
      permission: isAccessToPlayerProfile,
    },
    {
      title: t("delivery_tab"),
      path: `/employee/${userId}/delivery-info`,
      className: "pageTabs__link pageTabs__popoverLink",
      permission: isAccessToDeliverylInfo,
    },
    {
      title: tLayout("navigation_timeOff"),
      path: `/employee/${userId}/time-off`,
      className: "pageTabs__link pageTabs__popoverLink",
      permission: isAccessToTimeOffProfile,
    },
  ];

  const speaker = (
    <div className="customPopover employeePage__popover">
      {tabs.slice(1).map((tab) => {
        if (tab.permission || tab.permission === null) {
          return (
            <NavLink
              key={tab.path}
              to={tab.path}
              className={({ isActive }) =>
                classNames(`${tab.className}`, { selected: isActive })
              }
              onClick={() => {
                triggerRef.current?.close();
              }}
            >
              {tab.title}
            </NavLink>
          );
        }

        return null;
      })}
    </div>
  );

  if (!profile) {
    return (
      <div className="box">
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="employeePage">
      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}
      {data && <MainUserInfoBlock user={data} />}

      {(isAccessToPersonalInfo ||
        isAccessToPlayerProfile ||
        isAccessToDeliverylInfo ||
        isAccessToTimeOffProfile) && (
        <div className="pageTabs mainTabs">
          {tabs.map((tab, index) => {
            if (tab.permission || tab.permission === null) {
              return (
                <NavLink
                  key={tab.path}
                  to={tab.path}
                  className={({ isActive }) =>
                    classNames(`${tab.className}`, { selected: isActive })
                  }
                  end={!index}
                >
                  {tab.title}
                </NavLink>
              );
            }

            return null;
          })}

          <Whisper
            speaker={speaker}
            ref={triggerRef}
            trigger="click"
            placement="bottomEnd"
          >
            <button
              type="button"
              className="pageTabs__link pageTabs__hiddenLink"
            >
              {tCommon("tabs_more")}
            </button>
          </Whisper>
        </div>
      )}

      <Outlet />
    </div>
  );
};

export default EmployeePage;
