import classNames from "classnames";
import { FC } from "react";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { CelebrationType } from "./CelebrationModal";
import { CelebrationConfetti } from "./CelebrationConfetti";
import { useConfetti } from "./hooks/useConfetti";
import { BadgeMessageSystem } from "api/types/news";

type Step2Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  type: CelebrationType | null;
  event: BadgeMessageSystem | null;
};

export const Step2: FC<Step2Props> = ({
  isModalOpen,
  onCloseModal,
  type,
  event,
}) => {
  const { t } = useTranslation("celebrationModal");
  const { t: tMerch } = useTranslation("merchStore");
  const birthdayType = type === CelebrationType.birthday;
  const isConfettiVisible = useConfetti(isModalOpen);

  if (!event) return null;

  return (
    <div
      className={classNames("celebrationModal cd-popup", {
        "is-visible": isModalOpen,
      })}
      role="alert"
    >
      <div className="cd-popup-container">
        <button className="cd-popup-close" type="button" onClick={onCloseModal}>
          <Icon href="#close-popup" svgClass="ic-close-popup" />
        </button>
        <div className="celebrationModal__content">
          <h1 className="main-title">
            {t(`${birthdayType ? "title_birthday" : "title_anniversary"}`)}
          </h1>
          <div className="celebrationModal__text">{event?.message}</div>
          <img
            className="celebrationModal__img-coins"
            src="/img/star-coins.png"
            alt=""
          />
          <div className="celebrationModal__coins">
            + {tMerch("coins", { count: event.coins ? +event?.coins : 0 })}
          </div>
          <button className="btn-primary" type="button" onClick={onCloseModal}>
            {t("getCoins")}
          </button>
        </div>
      </div>
      {isConfettiVisible && <CelebrationConfetti />}
    </div>
  );
};
