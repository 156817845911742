import { MerchStoreState } from "../types";
import { defaultListState } from "../../appConsts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import {
  MerchCategoryDto,
  MerchItemDto,
} from "../../api/types/merchStoreTypes";
import { logoutUserAction } from "./authReducer";

const initialState: MerchStoreState = {
  merchCategoryList: defaultListState,
  merchItemPageData: defaultListState,
};

const merchStoreSlice = createSlice({
  initialState,
  name: "merchStore",
  reducers: {
    setLoadingMerchCategoryList: (state) => ({
      ...state,
      merchCategoryList: setStateLoading(state.merchCategoryList),
    }),
    setStateMerchCategoryList: (
      state,
      action: PayloadAction<MerchCategoryDto[]>
    ) => ({
      ...state,
      merchCategoryList: setStateData(state.merchCategoryList, action.payload),
    }),
    setErrorMerchCategoryList: (state, action: PayloadAction<string>) => ({
      ...state,
      merchCategoryList: setStateError(state.merchCategoryList, action.payload),
    }),
    resetMerchCategoryList: (state) => ({
      ...state,
      merchCategoryList: defaultListState,
    }),
    setLoadingMerchItemPageData: (state) => ({
      ...state,
      merchItemPageData: setStateLoading(state.merchItemPageData),
    }),
    setMerchItemPageData: (state, action: PayloadAction<MerchItemDto>) => ({
      ...state,
      merchItemPageData: setStateData(state.merchItemPageData, action.payload),
    }),
    setErrorMerchItemPageData: (state, action: PayloadAction<string>) => ({
      ...state,
      merchItemPageData: setStateError(state.merchItemPageData, action.payload),
    }),
    resetMerchItemPageData: (state) => ({
      ...state,
      merchItemPageData: defaultListState,
    }),
    resetMerchStoreState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default merchStoreSlice.reducer;
export const {
  setLoadingMerchCategoryList,
  setStateMerchCategoryList,
  setErrorMerchCategoryList,
  setLoadingMerchItemPageData,
  setMerchItemPageData,
  setErrorMerchItemPageData,
  resetMerchItemPageData,
  resetMerchCategoryList,
  resetMerchStoreState,
} = merchStoreSlice.actions;
