import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
function ErrorRoute() {
  let error = useRouteError();
  console.log(error);

  const { t: tCommon } = useTranslation("common");
  const currentDate = new Date();
  const formattedDate = format(currentDate, "dd-MM-yyyy HH:mm:ss");

  return (
    <div className="errorRoute">
      <div>
        <img src="/img/error-cat.png" alt="catIcon" />
      </div>
      <div>
        <h1 className="main-title">{tCommon("error_title")}</h1>
        <p className="errorRoute__text">{tCommon("error_subtitle")}</p>
        <p className="errorRoute__time">{formattedDate}</p>
      </div>
    </div>
  );
}

export default ErrorRoute;
