import { DeltaStatic } from "quill";
import { AxiosError } from "axios";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import isNumber from "lodash/isNumber";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { ButtonLoader } from "utils/components";
import * as Yup from "yup";
import { editBadgeMessage, sendBadgeMessage } from "../../../api/badgesApi";
import { Visibility } from "../../../api/types/badgesTypes";
import { UserOfSearch } from "../../../api/types/usersTypes";
import { NoImageUrl } from "../../../appConsts";
import { useAppDispatch, useAppSelector } from "../../../store";
import { refreshCoinsCount } from "../../../store/thunks/authThunks";
import { refreshEmployeeAchievementsData } from "../../../store/thunks/employeePage/employeeAchievementsThunks";
import { serverError } from "../../../types/serverError";
import AggregateErrorMessage from "../../../utils/components/AggregateErrorMessage";
import CustomSelect from "../../../utils/components/CustomSelect";
import { Features } from "../../../utils/consts";
import {
  getFullName,
  getFullNameFromUser,
  handleServerError,
} from "../../../utils/methods";
import { toastError, toastSuccess } from "utils/toasts";
import { respondToRequestFeedback } from "../../../api/employeePageApi";
import { Feedback } from "../../../api/types/employeePageTypes";
import { BadgeMessageSystem } from "../../../api/types/news";
import { editNewsBadgeMessage } from "../../../store/reducers/newsReducer";
import { editProfileBadgeMessage } from "../../../store/reducers/employeePageReducers/employeeAchievementsReducer";
import CustomRichTextComponent from "utils/components/CustomRichTextComponent";
import SearchUsersSelect from "../../../utils/components/SearchUsersSelect";
import { useCompanyFeatureCheck } from "../../../utils/hooks";
import CustomInputNumber from "../../../utils/components/CustomInputNumber";
import RequiredFieldLabel from "../../../utils/components/RequiredFieldLabel";

type FeedbackFormProps = {
  onComeBack: (i: number) => void;
  onCloseModal: () => void;
  selectedBadgeImage: string | undefined;
  selectedBadgeId: number | null;
  receiver?: UserOfSearch | null;
  feedback?: Feedback | null;
  badgeMessage?: BadgeMessageSystem | null;
};

type ReceiverOption = {
  value: number;
  label: string;
};

interface Values {
  receiverUserIds: ReceiverOption[];
  message: string;
  badgeId: number | null;
  visibility: Visibility;
  coins: number | "";
}

const MAX_LENGTH_MESSAGE = 340;

const FeedbackModalFormTab: FC<FeedbackFormProps> = ({
  onComeBack,
  onCloseModal,
  selectedBadgeImage,
  selectedBadgeId,
  receiver,
  feedback,
  badgeMessage,
}) => {
  const location = useLocation();
  const isHomePage = location.pathname.includes("news");
  const { t } = useTranslation("employeeAchievements");
  const { t: tCommon } = useTranslation("common");
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const { data: coinsBalance } = useAppSelector(
    (state) => state.auth.coinsCount
  );
  const submitBtnLabel = badgeMessage
    ? tCommon("form_save")
    : t("action_sendMessage");

  const dispatch = useAppDispatch();

  // we store as receiverUserIds array of object {value: userId, label: userFullName } for reusing existing SearchUserSelect.tsx
  // async select in this component is used in editing mode also, so its value must be array of objects {label: string, value: number | string} not just number
  // but in request to api we send only userIds
  const getReceiverUserIds = () => {
    if (receiver) {
      return [
        {
          value: receiver.id,
          label: getFullName(receiver.firstName, receiver.familyName),
        },
      ];
    }

    if (feedback?.fromUser) {
      return [
        {
          value: feedback.fromUser.id,
          label: getFullName(
            feedback?.fromUser.firstName,
            feedback?.fromUser.familyName
          ),
        },
      ];
    }

    if (badgeMessage?.toUser.userId) {
      return [
        {
          value: badgeMessage.toUser.userId,
          label: getFullName(
            badgeMessage?.toUser.firstName,
            badgeMessage?.toUser.familyName
          ),
        },
      ];
    }

    return [];
  };

  const initialValues: Values = {
    receiverUserIds: getReceiverUserIds(),
    message: badgeMessage ? badgeMessage.message : "",
    badgeId: selectedBadgeId,
    visibility: Visibility.all,
    coins: "",
  };
  const badgeImg = selectedBadgeImage ? selectedBadgeImage : NoImageUrl;
  const { userId } = useParams();

  const gerReceiverFullName = () => {
    if (receiver) {
      return getFullNameFromUser(receiver);
    }

    if (feedback) {
      return getFullName(
        feedback.fromUser?.firstName,
        feedback.fromUser?.familyName
      );
    }

    if (badgeMessage) {
      return getFullName(
        badgeMessage.toUser.firstName,
        badgeMessage.toUser.familyName
      );
    }

    return null;
  };

  const handleSubmitForm = async (
    values: Values,
    { setErrors, resetForm }: FormikHelpers<Values>
  ) => {
    let baseData = {
      badgeId: values.badgeId,
      message: JSON.stringify(values.message),
      visibility: values.visibility,
      coins: values.coins || null,
    };

    const data = {
      ...baseData,
      receiverUserIds: values.receiverUserIds.map((user) => user.value),
    };

    try {
      if (feedback) {
        // respond to feedback request
        await respondToRequestFeedback(baseData, feedback.id);
      } else if (badgeMessage) {
        // editing badge message
        const response = await editBadgeMessage(
          badgeMessage.id,
          JSON.stringify(values.message)
        );

        if (isHomePage) {
          dispatch(editNewsBadgeMessage(response.data.message));
        } else {
          dispatch(editProfileBadgeMessage(response.data.message));
        }
      } else {
        // sending badge message
        await sendBadgeMessage(data);
      }
      toastSuccess(
        badgeMessage
          ? t("messageSuccessfullyUpdated")
          : t("messageSuccessfullySent")
      );

      if (isGamificationAvailable && values.coins) {
        dispatch(refreshCoinsCount());
      }

      // update employee achievements data if send cheers or respond to feedback request
      if (userId && !badgeMessage) {
        dispatch(refreshEmployeeAchievementsData(+userId));
      }

      resetForm();
      onCloseModal();
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("incorrectData"));
      setErrors(handleServerError(error || {}));
    }
  };

  const visibilityOptions = [
    { value: Visibility.all, label: t("feedbackVisibility_all") },
    { value: Visibility.myTeam, label: t("feedbackVisibility_myTeam") },
    { value: Visibility.me, label: t("feedbackVisibility_me") },
  ];

  const ValidationSchema = Yup.object().shape({
    receiverUserIds: Yup.array().min(
      1,
      t("message_receivers_required") as string
    ),
    message: Yup.mixed()
      .required(t("message_message_required") as string)
      .test(
        "lengthCheck",
        tCommon("lengthDesc_error") as string,
        (value: DeltaStatic) => {
          if (value) {
            const length = value.length() - 1;
            if (length > MAX_LENGTH_MESSAGE) return false;
          }
          return true;
        }
      ),
    badgeId: Yup.number().required(t("message_badge_required") as string),
    visibility: Yup.string(),
    coins: Yup.number()
      .nullable()
      .positive(tCommon("numberValue_positive") as string)
      .integer(tCommon("numberValue_integer") as string)
      .test(
        "totalCoinsCheck",
        t("coinsBalance_err") as string,
        (value, ctx) => {
          if (value && coinsBalance) {
            const totalSpentCount = value * ctx.parent.receiverUserIds.length;
            if (totalSpentCount > coinsBalance || value > coinsBalance)
              return false;
          }

          return true;
        }
      ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmitForm}
    >
      {(props: FormikProps<Values>) => {
        const { values, isSubmitting } = props;

        const totalSentCoins =
          Number(values.coins) * values.receiverUserIds.length;

        return (
          <Form className="popup-feedback-next form-style form-feedback">
            <div className="new-wrap">
              <div className="wrap-item">
                <div className="box boxItemImg">
                  <div className="boxItemImg__imgWrap d-flex">
                    <img src={badgeImg} alt="badge" />
                  </div>
                </div>

                {!badgeMessage && (
                  <button
                    type="button"
                    className="btn-regular"
                    onClick={() => onComeBack(0)}
                  >
                    {t("chooseAnotherBadge")}
                  </button>
                )}
              </div>
              <ErrorMessage
                component="div"
                name="badgeId"
                className="new-wrapper error"
              />
              <AggregateErrorMessage
                component="div"
                className="new-wrapper error"
              />

              <label className="label">
                <RequiredFieldLabel labelTitle={t("sendMessage_to")} />

                {receiver || feedback || badgeMessage ? (
                  <>
                    <div className="receiverName">{gerReceiverFullName()}</div>
                    <Field type="hidden" name="receiverUserIds" />
                  </>
                ) : (
                  <Field
                    name="receiverUserIds"
                    component={SearchUsersSelect}
                    filterCurrentUser={true}
                  />
                )}
                <ErrorMessage
                  component="div"
                  name="receiverUserIds"
                  className="error m-btm"
                />
              </label>

              {!badgeMessage && (
                <>
                  <label className="label">
                    <span className="label-title">{tCommon("visibility")}</span>
                    <Field
                      name="visibility"
                      component={CustomSelect}
                      options={visibilityOptions}
                      placeholder={tCommon("select_placeholder")}
                    />
                    <ErrorMessage
                      component="div"
                      name="visibility"
                      className="error"
                    />
                  </label>

                  {isGamificationAvailable && isNumber(coinsBalance) && (
                    <>
                      <label className="label">
                        <span className="label-title">
                          {t("sendCoins_label")}
                        </span>
                        <Field
                          name="coins"
                          component={CustomInputNumber}
                          placeholder={t("sendCoins_placeholder")}
                        />
                        <ErrorMessage
                          component="div"
                          name="coins"
                          className="error"
                        />
                      </label>

                      {values.coins ? (
                        <div className="totalSpentCoins">
                          <span>{t("sendCoins_total")}</span>
                          <span className="totalCount">{`${Number(
                            values.coins
                          )} X ${
                            values.receiverUserIds.length
                          } = ${totalSentCoins}`}</span>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}

              <div className="label">
                <RequiredFieldLabel labelTitle={t("sendMessage_message")} />

                <Field
                  name="message"
                  component={CustomRichTextComponent}
                  rows={6}
                  placeholder={
                    tCommon("message_message_placeholder", {
                      count: MAX_LENGTH_MESSAGE,
                    }) as string
                  }
                  maxLength={MAX_LENGTH_MESSAGE}
                />
              </div>
            </div>

            <div className="popup-footer">
              <div className="group-btn d-flex">
                <button
                  className="btn-tertiary main-close"
                  type="button"
                  onClick={onCloseModal}
                >
                  {tCommon("form_cancel")}
                </button>

                <button
                  className="btn-primary btn-popup"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <ButtonLoader /> : submitBtnLabel}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FeedbackModalFormTab;
