import EmojiBlock from "../../utils/components/emoji/EmojiBlock";
import { FC } from "react";
import { EmojiReactionUsersItemDto } from "../../api/types/emojiTypes";
import { getErrorMessage } from "../../utils/methods";
import { AxiosError } from "axios";
import { toastError } from "../../utils/toasts";
import { useTranslation } from "react-i18next";
import { changeEmojiReaction } from "../../api/emojiReactionsApi";
import { useAppDispatch } from "../../store";
import { useLocation } from "react-router-dom";
import { updateNewsBadgeMessageReactions } from "../../store/reducers/newsReducer";
import { updateProfileBadgeMessageReactions } from "../../store/reducers/employeePageReducers/employeeAchievementsReducer";

type ReactionsBadgeMessageProps = {
  badgeMessageId: number;
  emojiReactions: EmojiReactionUsersItemDto[];
};
const ReactionsBadgeMessage: FC<ReactionsBadgeMessageProps> = ({
  badgeMessageId,
  emojiReactions,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isHomePage = location.pathname.includes("news");
  const { t } = useTranslation("common");
  const changeReaction = async (emojiId: string | null) => {
    try {
      const response = await changeEmojiReaction({
        achievementId: null,
        badgeMessageId,
        emojiId,
      });
      const updateBadgeMessagePayload = {
        badgeMessageId,
        updatedEmojiReactions: response.data.items,
      };

      if (isHomePage) {
        dispatch(updateNewsBadgeMessageReactions(updateBadgeMessagePayload));
      } else {
        dispatch(updateProfileBadgeMessageReactions(updateBadgeMessagePayload));
      }
    } catch (e) {
      const errorMessage = getErrorMessage(
        e as AxiosError,
        t("errorAddingReaction") as string
      );
      toastError(errorMessage);
    }
  };

  return (
    <div className="badgeMessageReactions">
      <EmojiBlock
        reactions={emojiReactions}
        changeReaction={changeReaction}
        placementEmojiTooltip={"bottomStart"}
      />
    </div>
  );
};

export default ReactionsBadgeMessage;
