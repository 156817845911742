import { AppPermissions } from "utils/consts";
import { usePermissionCheck } from "utils/hooks";

export const viewCoinsBalancePermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_PlayerProfile_ViewCoinsBalance,
];
export const viewPraisedCoinsPermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_PlayerProfile_ViewPraisedCoins,
];
export const viewActivityChartPermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_PlayerProfile_ViewActivityChart,
];
export const viewQuestBacklogPermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_PlayerProfile_ViewQuestBacklog,
];

export const usePlayerProfileViewsPermissions = () => {
  return {
    isViewCoinsBalance: usePermissionCheck(viewCoinsBalancePermissions),
    isViewPraisedCoins: usePermissionCheck(viewPraisedCoinsPermissions),
    isViewActivityChart: usePermissionCheck(viewActivityChartPermissions),
    isViewQuestBacklog: usePermissionCheck(viewQuestBacklogPermissions),
  };
};
