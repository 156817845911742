import { isValid, parse } from "date-fns";

export const parseDateFromString = (dateString: string): Date => {
  const isoShortFormat = "yyyy-MM-dd";
  let dateTimeParsed = new Date(dateString);

  if (isValid(parse(dateString, isoShortFormat, new Date()))) {
    dateTimeParsed = parse(dateString, isoShortFormat, new Date());
  }

  return dateTimeParsed;
};
