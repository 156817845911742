import { FC } from "react";

interface CustomSwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const CustomSwitch: FC<CustomSwitchProps> = ({
  checked,
  disabled,
  onChange,
  label,
}) => {
  return (
    <div className="toggle">
      <label className="toggle__container">
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        <span className="toggle__slider"></span>
      </label>
      <span>{label}</span>
    </div>
  );
};

export default CustomSwitch;
