import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeOffRequestsState } from "../../types";
import {
  defaultLastMonthRange,
  defaultStateWithInfiniteLoading,
} from "../../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
} from "../../handleUpdateState";
import { logoutUserAction } from "../authReducer";
import { AllStatusesFilter } from "api/types/commonTypes";
import {
  TimeOffRequestsManagementListItemDto,
  TimeOffRequestsStatusFilter,
} from "api/types/timeOff/timeOffRequestsTypes";
import { CustomFilters } from "utils/components/CustomFiltersForm";

const initialState: TimeOffRequestsState = {
  requestsData: defaultStateWithInfiniteLoading,
  requestsFilters: {
    status: AllStatusesFilter.all,
    filters: {
      dateRange: defaultLastMonthRange,
      positions: [],
      managers: [],
      departments: [],
      locations: [],
    },
  },
};

const timeOffRequestsReducer = createSlice({
  name: "timeOffRequests",
  initialState,
  reducers: {
    setLoadingTimeOffRequests: (state) => ({
      ...state,
      requestsData: setInfiniteLoadLoading(state.requestsData),
    }),
    setTimeOffRequests: (
      state,
      action: PayloadAction<{
        requests: TimeOffRequestsManagementListItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let requestsList = state.requestsData?.data || [];
      requestsList = [...requestsList, ...action.payload.requests];
      return {
        ...state,
        requestsData: setInfiniteLoadData(
          state.requestsData,
          requestsList,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorTimeOffRequests: (state, action: PayloadAction<string>) => ({
      ...state,
      requestsData: setInfiniteLoadStateErrorMessage(
        state.requestsData,
        action.payload
      ),
    }),
    resetTimeOffRequests: (state) => ({
      ...state,
      requestsData: defaultStateWithInfiniteLoading,
    }),
    setTimeOffRequestsFilters: (
      state,
      action: PayloadAction<{
        status: TimeOffRequestsStatusFilter;
        filters: CustomFilters;
      }>
    ) => ({
      ...state,
      requestsFilters: action.payload,
    }),
    resetRequestsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default timeOffRequestsReducer.reducer;

export const {
  setErrorTimeOffRequests,
  setLoadingTimeOffRequests,
  setTimeOffRequests,
  setTimeOffRequestsFilters,
  resetTimeOffRequests,
  resetRequestsState,
} = timeOffRequestsReducer.actions;
