import { dateOnly } from "../../utils/types";
import { OdataDTO } from "./commonTypes";

export enum NotificationStatuses {
  read = "Read",
  unread = "Unread",
}

export enum NotificationIconTypes {
  merch = "Merch",
  news = "News",
  approved = "Approved",
  rejected = "Rejected",
  feedbackRequest = "FeedbackRequest",
  giftBox = "GiftBox",
  anniversary = "Anniversary",
  badgeMessage = "BadgeMessage",
}

export enum NotificationLinkObjectTypes {
  merch = "MerchItem",
  merchOrder = "MerchOrder",
  profile = "Profile",
  achievementEvent = "AchievementEvent",
  achievement = "Achievement",
}

export enum NotificationSourceType {
  Unknown = 0,
  BadgeMessageReceived = 1,
  FeedbackRequestReceived = 2,
  MerchItemCreated = 3,
  MerchDeliveryInProgress = 4,
  MerchDeliveryDelivered = 5,
  MerchDeliveryCancelled = 6,
  AchievementEventCreated = 7,
  AchievementEventApproved = 8,
  AchievementEventRejected = 9,
  BirthdayTeamMember = 10,
  AnniversaryTeamMember = 11,
  ApplyOnAchievement= 12,
}

export type NotificationDto = {
  id: number;
  createdDate: dateOnly;
  message: string;
  status: NotificationStatuses | string;
  iconType: NotificationIconTypes | string;
  linkObjectType: NotificationLinkObjectTypes | string;
  linkObjectId: number;
  sourceType: NotificationSourceType;
};

export type NotificationsODataDto = OdataDTO<NotificationDto>;
export enum NotificationUpdateAction {
  read = "Read",
  delete = "Delete",
}

export type ReadNotificationsResponse = {
  count: number;
};
