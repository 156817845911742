import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AchievementArchiveItemDto,
  AchievementArchiveListDto,
  AchievementArchiveListFilters,
} from "../../api/types/achievementsArchiveTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { AchievementsArchiveState } from "../types";
import { logoutUserAction } from "./authReducer";

export const achievementsArchiveInitialState: AchievementsArchiveState = {
  achievementsArchiveData: {
    achievementsArchiveList: defaultStateWithInfiniteLoading,
    filters: null,
    searchQuery: null,
  },
  achievementArchiveData: defaultListState,
};

const achievementsArchiveSlice = createSlice({
  initialState: achievementsArchiveInitialState,
  name: "achievementsArchive",
  reducers: {
    // Orders table
    setLoadingAchievementsArchiveList: (state) => ({
      ...state,
      achievementsArchiveData: {
        ...state.achievementsArchiveData,
        achievementsArchiveList: setInfiniteLoadLoading(
          state.achievementsArchiveData.achievementsArchiveList
        ),
      },
    }),
    setAchievementsArchiveList: (
      state,
      action: PayloadAction<{
        achievementsArchiveList: AchievementArchiveListDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let achievementsArchiveList =
        state.achievementsArchiveData.achievementsArchiveList.data || [];
      achievementsArchiveList = [
        ...achievementsArchiveList,
        ...action.payload.achievementsArchiveList,
      ];

      return {
        ...state,
        achievementsArchiveData: {
          ...state.achievementsArchiveData,
          achievementsArchiveList: setInfiniteLoadData(
            state.achievementsArchiveData.achievementsArchiveList,
            achievementsArchiveList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorAchievementsArchiveList: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementsArchiveData: {
        ...state.achievementsArchiveData,
        achievementsArchiveList: setInfiniteLoadStateErrorMessage(
          state.achievementsArchiveData.achievementsArchiveList,
          action.payload
        ),
      },
    }),
    setAchievementsArchiveSearchQuery: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementsArchiveData: {
        ...state.achievementsArchiveData,
        searchQuery: action.payload,
      },
    }),
    setAchievementsArchiveFilters: (
      state,
      action: PayloadAction<AchievementArchiveListFilters | null>
    ) => ({
      ...state,
      achievementsArchiveData: {
        ...state.achievementsArchiveData,
        filters: action.payload,
      },
    }),
    resetAchievementsArchiveState: () => achievementsArchiveInitialState,
    resetAchievementsArchiveList: (state) => ({
      ...state,
      achievementsArchiveData: {
        ...state.achievementsArchiveData,
        achievementsArchiveList: defaultStateWithInfiniteLoading,
      },
    }),
    // Achievement Archive Page
    setLoadingAchievementArchiveData: (state) => ({
      ...state,
      achievementArchiveData: setStateLoading(state.achievementArchiveData),
    }),
    setAchievementArchiveData: (
      state,
      action: PayloadAction<AchievementArchiveItemDto>
    ) => ({
      ...state,
      achievementArchiveData: setStateData(
        state.achievementArchiveData,
        action.payload
      ),
    }),
    setErrorAchievementArchiveData: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementArchiveData: setStateError(
        state.achievementArchiveData,
        action.payload
      ),
    }),
    resetAchievementArchiveData: (state) => ({
      ...state,
      achievementArchiveData: defaultListState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => achievementsArchiveInitialState);
  },
});

export default achievementsArchiveSlice.reducer;

export const {
  setAchievementsArchiveList,
  setLoadingAchievementsArchiveList,
  setErrorAchievementsArchiveList,
  resetAchievementsArchiveState,
  setLoadingAchievementArchiveData,
  setAchievementArchiveData,
  setErrorAchievementArchiveData,
  setAchievementsArchiveSearchQuery,
  setAchievementsArchiveFilters,
  resetAchievementArchiveData,
  resetAchievementsArchiveList,
} = achievementsArchiveSlice.actions;
