import { useTranslation } from "react-i18next";
import { Option } from "../utils/types/option";

export const culturesToCompanyCultureMapping: { [language: string]: string } = {
  en: "en-US",
  ru: "ru-RU",
  uk: "uk-UA",
};

export const companyCultureToCulturesMapping: { [language: string]: string } = {
  "en-US": "en",
  "ru-RU": "ru",
  "uk-UA": "uk",
};

const userChosenByLanguageLocalStorageKey = "languageChosenByUser";

export function isLanguageChosenByUser(): boolean {
  return !!window.localStorage.getItem(userChosenByLanguageLocalStorageKey);
}

function saveUserLanguageChoice(): void {
  window.localStorage.setItem(userChosenByLanguageLocalStorageKey, "true");
}

export default function useChangeLocalization(): [
  Option[],
  Option | undefined,
  string,
  (value: any, isUserChoice: boolean) => void
] {
  const { t: tCommon, i18n } = useTranslation("common");

  const languageOptions = [
    { label: tCommon("language_en"), value: "en" },
    // { label: tCommon("language_ru"), value: "ru" },
    { label: tCommon("language_uk"), value: "uk" },
  ];

  const currentLanguage = i18n.language;
  const currentLanguageOption = languageOptions.find(
    (o) => o.value === currentLanguage
  );

  const handleChangeLanguage = (value: any, isUserChoice: boolean) => {
    const language = value as string;
    if (!language) {
      return;
    }
    (async () => await i18n.changeLanguage(language))();
    if (isUserChoice) {
      saveUserLanguageChoice();
    }
  };

  return [
    languageOptions,
    currentLanguageOption,
    currentLanguage,
    handleChangeLanguage,
  ];
}
