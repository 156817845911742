import Confetti from "react-confetti";
import { useWindowSize } from "utils/hooks/useWindowSize";

export const CelebrationConfetti = () => {
  const { width, height } = useWindowSize();
  return (
    <Confetti
      width={width}
      height={height}
      numberOfPieces={250}
      gravity={0.15}
    />
  );
};
