import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface IAuthMessageProps {
  title: string;
  message: string;
}

const AuthMessage: FC<IAuthMessageProps> = ({ title, message }) => {
  const { t } = useTranslation("auth");

  return (
    <div className="login-page" data-cy="auth-message-block">
      <Link to="/" className="logo">
        <img src="/img/logo.png" alt="logo" />
      </Link>
      <div className="form-login form-style box">
        <h1 className="title login-title">{title}</h1>
        <div className="login-divider login-divider--vertical-margin" />
        <p className="login-box-center">
          <span className="login-auth-message">{message}</span>
        </p>
        <div className="login-box-center">
          <Link to="/login" className="btn-tertiary">
            {t("backToLogin")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthMessage;
