import { AchievementCategoryBaseDto, AchievementDto } from "./achievementTypes";
import { ListDto, PageDto, QueryParams } from "./commonTypes";
import { UserOfSearchWithActiveStatus } from "./usersTypes";

export type AchievementEventDocumentDto = {
  id: number;
  isImage: boolean;
  fileUrl: string;
};

export type AchievementEventDto = {
  id: number;
  coins: number;
  status: string;
  createdDate: string;
  achievement: AchievementDto;
  documents: AchievementEventDocumentDto[];
};

export type AchievementCategorySearchDto = {
  id: number;
  name: string;
};

export type AchievementSearchDto = {
  id: number;
  name: string;
  categories: AchievementCategoryBaseDto[];
};

export type AchievementCategoriesSearchListDto =
  ListDto<AchievementCategorySearchDto>;

export type AchievementsSearchListDto = ListDto<AchievementSearchDto>;

export type CreateAchievementEventRequestBody = {
  achievementId: number;
  coins: number;
  description: string;
};

export type CreateAchievementEventResponse = {
  event: AchievementEventDto;
};

export enum AchievementsEventsStatuses {
  OnReview = "OnReview",
  Approved = "Approved",
  Rejected = "Rejected",
}

export type AchievementEventDetailedDto = {
  id: number;
  coins: number;
  status: AchievementsEventsStatuses | string;
  createdDate: Date | string;
  achievement: AchievementDto;
  documents: AchievementEventDocumentDto[];
  description: string | null;
  creatorUser: UserOfSearchWithActiveStatus;
};

export type AchievementsEventsFilters = {
  achievementsCategoriesIds: number[];
  achievementsIds: number[];
  achievementsEventsStatus: AchievementsEventsStatuses | null;
};

export enum DateSortDirectionAchievementsEvents {
  asc = "asc",
  dsc = "desc",
}

export type SearchAchievementsEventsRequest = {
  filters: AchievementsEventsFilters | null;
  dateSortDirection: DateSortDirectionAchievementsEvents;
  pageNumber: number;
  pageSize: number;
};

export type GetEventsForApprovalResponse = {
  items: AchievementEventDetailedDto[];
  pageNumber: number;
  totalItems: number;
};

export enum AchievementsStatuses {
  all = "All",
  open = "OpenToApply",
  planned = "Planned",
  alreadyIn = "Applied",
}

export type AchievementsResponse = PageDto<AchievementDto>;

export type AchievementsQuery = Omit<QueryParams, "searchQuery">;

export type AchievementsFilters = {
  status: AchievementsStatuses;
};

export type AchievementsRequestParams = AchievementsQuery & {
  filters: {
    status?: AchievementsStatuses | string;
    categoryIds: number[];
  };
};

export type AchievementsFilterByCategory = {
  id: number;
  name: string;
  color: string;
};

export enum AchievementsSortBy {
  coins = "coins",
}
