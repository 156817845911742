import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import PlayerStatisticBlock from "./PlayerStatisticBlock";

const ActiveAchievementsEvents: FC = () => {
  const { t } = useTranslation("playerProfile");
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.playerProfile.activeAchievementsEvents
  );
  
  return (
    <PlayerStatisticBlock
      title={t("activeAchievementsEventsBlockTitle")}
      isLoading={isLoading}
      errorMessage={errorMessage}
      data={data}
      tooltipText={t("eventsTooltip")}
    />
  );
};

export default ActiveAchievementsEvents;
