import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserListItemDto } from "../../api/types/usersTypes";
import { defaultStateWithInfiniteLoading } from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
} from "../handleUpdateState";
import { AccountState } from "../types";
import { logoutUserAction } from "./authReducer";
import { SortDirections } from "api/types/commonTypes";
import { CompanyColorsDto } from "api/types/profile";

const initialState: AccountState = {
  usersData: defaultStateWithInfiniteLoading,
  sorting: {
    sortBy: "HiredOn",
    sortDirection: SortDirections.desc,
  },
  searchQuery: "",
  colorTheme: null,
};

const accountReducer = createSlice({
  name: "accountSettings",
  initialState,
  reducers: {
    setColorTheme: (state, action: PayloadAction<CompanyColorsDto>) => ({
      ...state,
      colorTheme: action.payload,
    }),
    setLoadingUsersList: (state) => ({
      ...state,
      usersData: setInfiniteLoadLoading(state.usersData),
    }),
    setUsersList: (
      state,
      action: PayloadAction<{
        users: UserListItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let users = state.usersData.data || [];
      users = [...users, ...action.payload.users];

      return {
        ...state,
        usersData: setInfiniteLoadData(
          state.usersData,
          users,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorUsersList: (state, action: PayloadAction<string>) => ({
      ...state,
      usersData: setInfiniteLoadStateErrorMessage(
        state.usersData,
        action.payload
      ),
    }),
    setUsersListSorting: (
      state,
      action: PayloadAction<[string, SortDirections]>
    ) => ({
      ...state,
      sorting: {
        sortBy: action.payload[0],
        sortDirection: action.payload[1],
      },
    }),
    setAccountUsersSearchQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      searchQuery: action.payload,
    }),
    resetUsersList: (state) => ({
      ...state,
      usersData: defaultStateWithInfiniteLoading,
    }),
    resetAccountState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default accountReducer.reducer;
export const {
  setColorTheme,
  setLoadingUsersList,
  setUsersList,
  setErrorUsersList,
  resetAccountState,
  resetUsersList,
  setUsersListSorting,
  setAccountUsersSearchQuery,
} = accountReducer.actions;
