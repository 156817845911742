import { AxiosPromise } from "axios";
import { base_url } from "../appConsts";
import { axiosInstance } from "./instance";
import { Profile, UserCoinsResponse } from "./types/profile";

export const profile = (): AxiosPromise<Profile> =>
  axiosInstance.get(`${base_url}/api/profile`);

export const updateCompanyCoverImg = (body: FormData): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/companies/company-cover-image`, body);

export const loadUserCoins = (): AxiosPromise<UserCoinsResponse> =>
  axiosInstance.get(`${base_url}/api/profile/coins`);
