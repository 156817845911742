import { TopUsersResponse } from "./news";
import { ExportFormat, PageDto, QueryParams } from "./commonTypes";

export enum DatePeriods {
  MAU = "MAU",
  DAU = "DAU",
}

export enum DateFilterType {
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
  Custom = "Custom",
}

export enum MainFilters {
  position = "positions",
  department = "departaments",
  employee = "employees",
  manager = "managers",
}

export type DateInfo = {
  date: string;
  sendBadges: number;
  receivedBadges: number;
};

export type FiltersDataResponse = {
  positions: string[];
  departaments: string[];
};

export type AnalyticsPopularBadgeDto = {
  badgeId: number;
  badgeName: string;
  badgeGroupName: string;
  badgeImageUrl: string;
  count: number;
};

export type AnalyticsPopularBadgesResponse = PageDto<AnalyticsPopularBadgeDto>;

export type AnalyticsRequest = {
  dateFilter: {
    filterType: DateFilterType;
    customRange: {
      dateFrom: Date | string;
      dateTo: Date | string;
    } | null;
  };
  positions: string[];
  departaments: string[];
  employees: number[];
  managers: number[];
};

export type AnalyticsPopularBadgesParams = AnalyticsRequest & QueryParams;

export type AnalyticsDataResponse = {
  diagramResponse: {
    days: DateInfo[];
    months: DateInfo[];
  };
  topUsers: TopUsersResponse;
};

export type ExportAnalyticsRequest = {
  exportFormat: ExportFormat;
} & AnalyticsRequest;
