import ReactMarkdown from "react-markdown";
import { Icon } from "utils/components";
import { NotificationDto } from "api/types/notifications";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { readNotification } from "api/notificationsApi";
import { toastError } from "utils/toasts";
import { getErrorMessage } from "utils/methods";
import { AxiosError } from "axios";
import { getNavigationPath } from "./notificationsUtils";
import i18next from "i18next";
import { AnyAction } from "redux";
import { getTotalCountAndIdUnreadNotifications } from "store/thunks/notificationsThunks";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "store";

const CloseButton = (
  id: number,
  shouldReqSent: boolean,
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
) => {
  const handleClick = async () => {
    if (!shouldReqSent) return;
    try {
      await readNotification(id);
      dispatch(getTotalCountAndIdUnreadNotifications());
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      toastError(errorMessage);
    }
  };
  return (
    <button
      className="btn-tertiary actionBtn-tertiary"
      type="button"
      onClick={handleClick}
      //to avoid errors in the console
      data-closetoast="true"
    >
      <Icon href="#close-popup" svgClass="ic-close-popup" />
    </button>
  );
};

export const toastNotifications = (
  notification: NotificationDto,
  count: number,
  setIsNotificationsModalOpen: (val: boolean) => void,
  navigation: NavigateFunction,
  dispatch: ThunkDispatch<RootState, {}, AnyAction>,
  currentUserId?: number
) => {
  const manyNotification = count > 1;
  const shouldOpenModal = manyNotification
    ? () => {
        setIsNotificationsModalOpen(true);
        toast.dismiss();
      }
    : () => handleNotificationClick(notification.id);

  const notificationNavigationPath = getNavigationPath(
    notification.linkObjectType,
    notification.linkObjectId,
    currentUserId
  );

  const handleNotificationClick = async (id: number) => {
    toast.dismiss();
    // state for scrolling to some part of page
    navigation(notificationNavigationPath.path, {
      state: notificationNavigationPath.state,
    });
    try {
      await readNotification(id);
      dispatch(getTotalCountAndIdUnreadNotifications());
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      toastError(errorMessage);
    }
  };

  toast(
    <div className="notification-toast__text" onClick={shouldOpenModal}>
      <ReactMarkdown>
        {manyNotification
          ? i18next.t("notificationMessages", { ns: "layout", count })
          : notification.message}
      </ReactMarkdown>
    </div>,
    {
      icon: () => (
        <div onClick={shouldOpenModal}>
          <Icon
            href={`#${
              manyNotification ? "megaphone" : notification.iconType
            }Icon`}
            svgClass="ic-notification"
          />
          {manyNotification ? (
            <div className="btn-primary badge-label-primary notification-toast__label">
              {count}
            </div>
          ) : null}
        </div>
      ),
      closeButton: CloseButton(notification.id, !manyNotification, dispatch),
      className: "notification-toast",
      bodyClassName: "notification-toast__body",
      pauseOnFocusLoss: false,
    }
  );
};
