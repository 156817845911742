import { AxiosPromise } from "axios";
import {
  CreateDynamicUserGroupRequest,
  CreateStaticUserGroupRequest,
  UpdateDynamicUserGroupRequest,
  UpdateStaticUserGroupRequest,
  UserGroupDto,
  UserGroupWithParticipantsDto,
} from "./types/groupManagementTypes";
import { base_url } from "appConsts";
import { axiosInstance } from "./instance";

export const loadUserGroups = (): AxiosPromise<UserGroupDto[]> =>
  axiosInstance.get(`${base_url}/api/user-groups`);

export const loadUserGroup = (
  userGroupId: number
): AxiosPromise<UserGroupWithParticipantsDto> =>
  axiosInstance.get(`${base_url}/api/user-groups/${userGroupId}`);

export const loadGroupsByUser = (
  userId: number
): AxiosPromise<UserGroupDto[]> => {
  return axiosInstance.get(`${base_url}/api/users/${userId}/user-groups`);
};

export const loadGroupsWithoutUser = (
  userId: number
): AxiosPromise<UserGroupDto[]> => {
  return axiosInstance.get(
    `${base_url}/api/users/${userId}/user-groups?isMember=false`
  );
};

export const deleteUserGroup = (userGroupId: number) =>
  axiosInstance.delete(`${base_url}/api/user-groups/${userGroupId}`);

export const createStaticUserGroup = (body: CreateStaticUserGroupRequest) =>
  axiosInstance.post(`${base_url}/api/user-groups/static`, body);

export const createDynamicUserGroup = (body: CreateDynamicUserGroupRequest) =>
  axiosInstance.post(`${base_url}/api/user-groups/dynamic`, body);

export const editStaticUserGroup = (
  body: UpdateStaticUserGroupRequest,
  groupId: number
): AxiosPromise<UserGroupWithParticipantsDto> =>
  axiosInstance.put(`${base_url}/api/user-groups/static/${groupId}`, body);

export const editDynamicUserGroup = (
  body: UpdateDynamicUserGroupRequest,
  groupId: number
): AxiosPromise<UserGroupWithParticipantsDto> =>
  axiosInstance.put(`${base_url}/api/user-groups/dynamic/${groupId}`, body);

export const addUserToGroups = (userId: number, groupIds: number[]) =>
  axiosInstance.post(`${base_url}/api/user-groups/static/users`, {
    userId,
    groupIds,
  });
