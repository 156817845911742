import { FieldProps } from "formik";
import { FC, useMemo } from "react";
import {
  CountryData,
  PhoneInput,
  defaultCountries,
} from "react-international-phone";

import "react-international-phone/style.css";
import { uaTranslations } from "./uaTranslations";
interface CustomPhoneInputProps extends FieldProps {
  placeholder: string;
  disabled?: boolean;
  lang: string;
}

export const CustomPhoneInput: FC<CustomPhoneInputProps> = ({
  field,
  form,
  placeholder,
  disabled = false,
  lang,
}) => {
  const uaLang = lang === "uk";
  const countries = useMemo(
    () =>
      uaLang
        ? defaultCountries.map((country) => {
            const translatedName = uaTranslations[country[1]];
            return [
              translatedName || country[0],
              country[1],
              country[2],
              country[3],
            ];
          })
        : defaultCountries,
    [uaLang]
  );

  const handlePhoneInputChange = (value: string) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <div className="customPhoneInput">
      <PhoneInput
        placeholder={placeholder}
        onChange={handlePhoneInputChange}
        disabled={disabled}
        defaultCountry="ua"
        countries={countries as CountryData[]}
        inputClassName="customPhoneInput__input"
        value={field.value}
      />
    </div>
  );
};
