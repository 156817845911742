import { AxiosError } from "axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { registerSlackIntegration } from "../../../../api/integrationsApi";
import Loader from "../../../../utils/components/Loader";
import { getErrorMessage } from "../../../../utils/methods";
import { slackIntegrationInfo } from "../integrationInfos";

const SlackConnectWithCodePage: FC = () => {
  const { t } = useTranslation("integrations");

  const integrationInfo = slackIntegrationInfo;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [serverError, setServerError] = useState<string | null>(null);

  const code = searchParams.get("code");
  const error = searchParams.get("error");
  const errorDescription =
    searchParams.get("error_description") ??
    t("noSlackErrorDescriptionAvailable");

  const activateSlackIntegration = async (code: string) => {
    try {
      await registerSlackIntegration({ code });

      navigate(integrationInfo.routeLink);
    } catch (e) {
      const errorMessage = getErrorMessage(
        e as AxiosError,
        t("errorActivatingSlackIntegration") as string
      );
      setServerError(errorMessage);
    }
  };

  useEffect(() => {
    if (code) {
      activateSlackIntegration(code);
    }
  }, [code]);

  if (error || serverError) {
    return (
      <div className="integration-details slack-integration-details">
        <h1 className="main-title m-btm">{t("errorConnectingToSlack")}</h1>
        <div className="wrap">
          <div>
            {error ? (
              <>
                <p>
                  <b>{t("slackErrorCode")}:</b> {error}
                </p>
                <p>
                  <b>{t("slackErrorDescription")}:</b> {errorDescription}
                </p>
              </>
            ) : (
              <p>
                <b>{t("slackErrorReason")}:</b> {serverError}
              </p>
            )}
            <p>
              {t("contactUsIfYouNeedAssistance")}{" "}
              <a href="mailto:support@esteeme.net">support@esteeme.net</a>
            </p>
          </div>
          <div className="line" />
          <div className="d-flex">
            <Link to={integrationInfo.routeLink} className="btn btn-details">
              {t("navigateBack")}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="integration-details slack-integration-details box">
      <div className="page-loader">
        <Loader />
      </div>
    </div>
  );
};

export default SlackConnectWithCodePage;
