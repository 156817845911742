import { NotificationsState } from "../types";
import {
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,

} from "../handleUpdateState";
import {
  NotificationDto,
} from "../../api/types/notifications";
import { logoutUserAction } from "./authReducer";

const initialState: NotificationsState = {
  allNotifications: defaultStateWithInfiniteLoading,
  unreadNotificationsTotalCount: 0,
  uniqueIdNotifications: [],
};

const notificationsSlice = createSlice({
  initialState,
  name: "notifications",
  reducers: {
    setLoadingAllNotifications: (state) => ({
      ...state,
      allNotifications: setInfiniteLoadLoading(state.allNotifications),
    }),
    setAllNotifications: (
      state,
      action: PayloadAction<{
        notifications: NotificationDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      return {
        ...state,
        allNotifications: setInfiniteLoadData(
          state.allNotifications,
          action.payload.notifications,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorAllNotifications: (state, action: PayloadAction<string>) => ({
      ...state,
      allNotifications: setInfiniteLoadStateErrorMessage(
        state.allNotifications,
        action.payload
      ),
    }),
    resetAllNotifications: (state) => ({
      ...state,
      allNotifications: defaultStateWithInfiniteLoading,
    }),
    setUnreadNotificationsTotalCountAndId: (
      state,
      action: PayloadAction<{ totalCount: number; idSet: number[] }>
    ) => ({
      ...state,
      unreadNotificationsTotalCount: action.payload.totalCount,
      uniqueIdNotifications: action.payload.idSet,
    }),
    resetNotificationsStoreState: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default notificationsSlice.reducer;

export const {
  setLoadingAllNotifications,
  setAllNotifications,
  setErrorAllNotifications,
  resetAllNotifications,
  setUnreadNotificationsTotalCountAndId,
  resetNotificationsStoreState,
} = notificationsSlice.actions;
