import { DirectoryState, UsersDirectoryPayload } from "../types";
import { defaultStateWithInfiniteLoading } from "../../appConsts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
} from "../handleUpdateState";
import { logoutUserAction } from "./authReducer";
import { UserDirectoryDto } from "api/types/usersDirectoryTypes";

const initialState: DirectoryState = {
  searchQuery: "",
  country: "",
  location: "",
  directoryUsersList: defaultStateWithInfiniteLoading,
};

const directorySlice = createSlice({
  name: "directory",
  initialState,
  reducers: {
    setLoadingDirectoryUsersList: (state) => ({
      ...state,
      directoryUsersList: setInfiniteLoadLoading(state.directoryUsersList),
    }),
    setDirectoryUsersList: (
      state,
      action: PayloadAction<{
        directoryUsersList: UserDirectoryDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let usersList = state.directoryUsersList.data || [];
      usersList = [...usersList, ...action.payload.directoryUsersList];

      return {
        ...state,
        directoryUsersList: setInfiniteLoadData(
          state.directoryUsersList,
          usersList,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorDirectoryUsersList: (state, action: PayloadAction<string>) => ({
      ...state,
      directoryUsersList: setInfiniteLoadStateErrorMessage(
        state.directoryUsersList,
        action.payload
      ),
    }),
    resetDirectoryUsersList: (state) => ({
      ...state,
      directoryUsersList: defaultStateWithInfiniteLoading,
    }),
    setDirectoryUsersQuery: (
      state,
      action: PayloadAction<UsersDirectoryPayload>
    ) => ({
      ...state,
      searchQuery: action.payload?.search || "",
      country: action.payload?.country || "",
      location: action.payload?.location || "",
    }),
    resetDirectoryState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default directorySlice.reducer;
export const {
  setLoadingDirectoryUsersList,
  setDirectoryUsersList,
  setErrorDirectoryUsersList,
  setDirectoryUsersQuery,
  resetDirectoryUsersList,
  resetDirectoryState,
} = directorySlice.actions;
