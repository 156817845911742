import { toast } from "react-toastify";
import toastSuccessIcon from "sprite/toastSuccess.svg";
import toastErrorIcon from "sprite/toastError.svg";
import toastWarningIcon from "sprite/toastWarning.svg";
import toastInfoIcon from "sprite/toastInfo.svg";

export const toastSuccess = (text: string) => {
  toast.success(`${text}`, {
    icon: () => <img src={toastSuccessIcon} alt="" />,
  });
};

export const toastError = (text: string) => {
  toast.error(`${text}`, {
    icon: () => <img src={toastErrorIcon} alt="" />,
  });
};

export const toastWarning = (text: string) => {
  toast.warn(`${text}`, {
    icon: () => <img src={toastWarningIcon} alt="" />,
  });
};

export const toastInfo = (text: string) => {
  toast.warn(`${text}`, {
    icon: () => <img src={toastInfoIcon} alt="" />,
  });
};