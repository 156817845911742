import React, { FC } from "react";
import {
  NotificationDto,
  NotificationStatuses,
} from "../../../api/types/notifications";
import { Icon } from "../../../utils/components";
import { getDateTimeString, getErrorMessage } from "../../../utils/methods";
import { useTranslation } from "react-i18next";
import useLocale from "../../../localization/useLocale";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { getNavigationPath } from "./notificationsUtils";
import {
  deleteNotification,
  readNotification,
} from "../../../api/notificationsApi";
import { AxiosError } from "axios";
import { toastError } from "../../../utils/toasts";
import {
  getAllNotifications,
  getTotalCountAndIdUnreadNotifications,
} from "../../../store/thunks/notificationsThunks";
import { serverError } from "../../../types/serverError";

type NotificationProps = {
  notification: NotificationDto;
  onCloseNotificationModal: () => void;
};

const Notification: FC<NotificationProps> = ({
  notification,
  onCloseNotificationModal,
}) => {
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector((state) => state.auth.profile?.id);
  const { t: tCommon } = useTranslation("common");
  const locale = useLocale();
  const navigation = useNavigate();
  const {
    id,
    iconType,
    createdDate,
    message,
    linkObjectType,
    linkObjectId,
    status,
  } = notification;
  const dateTimeString = getDateTimeString(createdDate, "fullDateTime", locale);
  const notificationNavigationPath = getNavigationPath(
    linkObjectType,
    linkObjectId,
    currentUserId
  );

  const handleNotificationClick = async () => {
    // state for scrolling to some part of page
    navigation(notificationNavigationPath.path, {
      state: notificationNavigationPath.state,
    });
    onCloseNotificationModal();
    if (status === NotificationStatuses.unread) {
      try {
        await readNotification(id);
        dispatch(getTotalCountAndIdUnreadNotifications());
      } catch (e) {
        const errorMessage = getErrorMessage(e as AxiosError);
        toastError(errorMessage || tCommon("serverError"));
      }
    }
  };

  const handleDeleteNotification = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    try {
      await deleteNotification(id);
      dispatch(getAllNotifications(true));
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("serverError"));
    }
  };

  return (
    <div
      className={classNames("box notification", {
        unread: status === NotificationStatuses.unread,
      })}
      onClick={handleNotificationClick}
    >
      <div className="notification__main">
        <div className="notification__iconWrap">
          <Icon href={`#${iconType}Icon`} svgClass="ic-notification" />
        </div>

        <div className="notification__content">
          <p className="notification__date">{dateTimeString}</p>
          <ReactMarkdown className="notification__message">
            {message}
          </ReactMarkdown>
        </div>
      </div>

      <button
        type="button"
        className="notification__delete"
        onClick={handleDeleteNotification}
      >
        <Icon href="#close-popup" svgClass="ic-notification" />
      </button>
    </div>
  );
};

export default Notification;
