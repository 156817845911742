import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  loadMoreMessages,
  refreshNewsBadgeMessages,
} from "../../store/thunks/newsThunks";
import Loader, { LoaderSize } from "../../utils/components/Loader";
import BadgeMessage from "./BadgeMessage";
import FeedbackRightPanel from "../employeeCard/employee-achievements/FeedbackRightPanel";
import { BadgeMessageSystem } from "../../api/types/news";
import LayoutConfirmationPopup from "../layout/LayoutConfirmationPopup";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils/methods";
import { AxiosError } from "axios";
import { toastError } from "../../utils/toasts";
import { deleteBadgeMessage } from "../../api/badgesApi";

const NewsTabContent: FC = () => {
  const { t } = useTranslation("news");
  const { t: tCommon } = useTranslation("common");

  const dispatch = useAppDispatch();
  const messageData = useAppSelector((state) => state.news.messageData);
  const { isLoading, data, errorMessage } = messageData;
  const hasMoreMessages = useAppSelector(
    (state) => state.news.messageData.hasMoreData
  );
  const [isEditBadgeMessageModalOpen, setIsEditBadgeMessageModalOpen] =
    useState(false);
  const [selectedBadgeMessage, setSelectedBadgeMessage] =
    useState<BadgeMessageSystem | null>(null);
  const [messageIdToDelete, setMessageIdToDelete] = useState<null | number>(
    null
  );
  const [isDeleteMessageModalOpen, setIsDeleteMessageModalOpen] =
    useState(false);

  const fetchData = async () => {
    await dispatch(loadMoreMessages());
  };

  const selectToEditBadgeMessage = (message: BadgeMessageSystem) => {
    setSelectedBadgeMessage(message);
    setIsEditBadgeMessageModalOpen(true);
  };

  const selectToDeleteBadgeMessage = (messageId: number) => {
    setMessageIdToDelete(messageId);
    setIsDeleteMessageModalOpen(true);
  };

  const handleDeleteBadgeMessage = async () => {
    if (messageIdToDelete) {
      try {
        await deleteBadgeMessage(messageIdToDelete);
        toast.success(t("badgeMessage_deleted"));
        dispatch(refreshNewsBadgeMessages());
      } catch (e) {
        const errorMessage = getErrorMessage(
          e as AxiosError,
          t("badgeMessage_deleteError") as string
        );
        toastError(errorMessage);
      } finally {
        setMessageIdToDelete(null);
        setIsDeleteMessageModalOpen(false);
      }
    } else return;
  };

  return (
    <div className="wrap">
      {isLoading && !data && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="error">{errorMessage}</div>}
      {data && !data.length && (
        <p className="no-content-message">{t("noMessagesToDisplay")}</p>
      )}
      {data?.length ? (
        <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMoreMessages}
          loader={
            <div className="loader-container">
              <Loader size={LoaderSize.Small} />
            </div>
          }
          endMessage={
            <p className="end-message">{tCommon("infiniteScrollEndMessage")}</p>
          }
          style={{ overflow: "visible" }}
        >
          {data.map((message) => {
            return (
              <BadgeMessage
                message={message}
                key={message.id}
                setSelectedToEditMessage={selectToEditBadgeMessage}
                setSelectedToDeleteMessage={selectToDeleteBadgeMessage}
              />
            );
          })}
        </InfiniteScroll>
      ) : null}
      <FeedbackRightPanel
        isModalOpen={isEditBadgeMessageModalOpen}
        onCloseModal={() => {
          setSelectedBadgeMessage(null);
          setIsEditBadgeMessageModalOpen(false);
        }}
        badgeMessage={selectedBadgeMessage}
      />
      <LayoutConfirmationPopup
        isModalOpen={isDeleteMessageModalOpen}
        onCloseModal={() => {
          setMessageIdToDelete(null);
          setIsDeleteMessageModalOpen(false);
        }}
        confirmHandler={handleDeleteBadgeMessage}
        title={t("deleteBadgeMessage")}
        question={t("deleteBadgeMsgQuestion")}
      />
    </div>
  );
};

export default NewsTabContent;
