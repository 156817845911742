import { FC } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router";
import { ToastContainer } from "react-toastify";
import { CustomProvider } from "rsuite";
import useRsuiteLocale from "./localization/useRsuiteLocale";
import router from "./routes";
import { store } from "./store";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

type AppProps = {
  instance: PublicClientApplication;
};

const App: FC<AppProps> = ({ instance }) => {
  const rsuiteLocale = useRsuiteLocale();

  return (
    <div data-cy="app-component">
      <MsalProvider instance={instance}>
        <CustomProvider locale={rsuiteLocale}>
          <Provider store={store}>
            <ToastContainer hideProgressBar={true} pauseOnFocusLoss={false} />
            <RouterProvider router={router} />
          </Provider>
        </CustomProvider>
      </MsalProvider>
    </div>
  );
};

export default App;
