import { dateOnly } from "utils/types";
import { ListDto, PrivacySettingsDto, PrivacySettingsRequest } from "./commonTypes";

export enum AutoEventTypes {
  HireDate = "HireDate",
  BirthDate = "BirthDate",
  Custom = "CustomDate",
}

export type AutoCoinEventDto = {
  id: number;
  eventName: string;
  eventType: AutoEventTypes;
  messageTemplate: string;
  eventDate: dateOnly | null;
  enabled: boolean;
  coins: number;
  badge: {
    id: number;
    name: string;
    imageUrl: string;
  };
  isPrivate: boolean;
  privacySettings: PrivacySettingsDto | null;
};

export type AutoCoinEventListItemDto = AutoCoinEventDto;

export type AutoCoinEventListDto = ListDto<AutoCoinEventListItemDto>;

export type SetAutoCoinEventEnableDisableResponse = {
  enabled: boolean;
};

export type CreateAutoCoinEventRequest = PrivacySettingsRequest & {
  eventName: string;
  eventType: AutoEventTypes;
  eventDate: dateOnly | null;
  badgeId: number;
  coins: number | null;
  messageTemplate: string;
};

export type CreateAutoEventResponse = {
  event: AutoCoinEventDto;
};

export type UpdateAutoCoinEventRequest = CreateAutoCoinEventRequest;
