import {
  itemsWithLoadingState,
  itemsWithPagingState,
} from "../types/stateTypes";

/* Helpers for itemsWithLoadingState<T> like types */
export const setStateLoading = <
  TStateData,
  TState extends itemsWithLoadingState<TStateData>
>(
  stateObj: TState
): TState => {
  return {
    ...stateObj,
    isLoading: true,
    errorMessage: "",
  };
};

export const setStateData = <
  TStateData,
  TState extends itemsWithLoadingState<TStateData>
>(
  stateObj: TState,
  data: TStateData
): TState => {
  return {
    ...stateObj,
    data,
    isLoading: false,
  };
};

export const setStateError = <
  TStateData,
  TState extends itemsWithLoadingState<TStateData>
>(
  stateObj: TState,
  errorMessage: string
): TState => {
  return {
    ...stateObj,
    isLoading: false,
    data: null,
    errorMessage,
  };
};

/* Helpers for itemsWithPagingState<T> like types */
export const setInfiniteLoadStateErrorMessage = <
  TStateData,
  TState extends itemsWithPagingState<TStateData>
>(
  stateObj: TState,
  errorMessage: string
): TState => {
  return {
    ...stateObj,
    isLoading: false,
    data: null,
    errorMessage,
  };
};

export const setInfiniteLoadLoading = <
  TStateData,
  TState extends itemsWithPagingState<TStateData>
>(
  stateObj: TState
): TState => {
  return {
    ...stateObj,
    isLoading: true,
    errorMessage: "",
  };
};

export const resetInfiniteLoadState = <
  TStateData,
  TState extends itemsWithPagingState<TStateData>
>(
  stateObj: TState
): TState => {
  return {
    ...stateObj,
    isLoading: false,
    hasMoreData: true,
    data: null,
    errorMessage: "",
    page: 0,
  };
};

export const setInfiniteLoadData = <
  TStateData,
  TState extends itemsWithPagingState<TStateData>
>(
  stateObj: TState,
  data: TStateData,
  hasMoreData: boolean,
  page: number
): TState => {
  return {
    ...stateObj,
    isLoading: false,
    data,
    hasMoreData: hasMoreData,
    page: page,
    errorMessage: "",
  };
};
