export enum AppPermissions {
  AddEditRemoveCompanyMember = "AddEditRemoveCompanyMember",
  ChangeCompanysLogoAndColors = "ChangeCompanysLogoAndColors",
  PreviewPagesAsEmployee = "PreviewPagesAsEmployee" /* TODO not working right now */,
  ViewCompanysAnalytics = "ViewCompanysAnalytics",
  ManageBadges = "ManageBadges",
  PreviewPagesAsСompany = "PreviewPagesAsСompany",
  ActivateDeactivateCompanys = "ActivateDeactivateCompanys",
  ViewAndEditCompanyData = "ViewAndEditCompanyData",
  AddRemoveBadges = "AddRemoveBadges",
  RolesManagement = "RolesManagement",
  ManageIntegrations = "ManageIntegrations",
  ViewCompanyOrgStructure = "ViewCompanyOrgStructure",
  EditDeleteBadgeMessages = "EditDeleteBadgeMessages",
  Merch_AddEditDeleteMerch = "Merch_AddEditDeleteMerch",
  MerchOrders_ViewAndManageOrders = "MerchOrders_ViewAndManageOrders",
  Merch_ViewMerchDashboard = "Merch_ViewMerchDashboard",
  Achievements_CreateEditCompanyAchievements = "Achievements_CreateEditCompanyAchievements",
  Achievements_ViewAnalytics = "Achievements_ViewAnalytics",
  Activity_ViewAnalytics = "Activity_ViewAnalytics",
  AchievementEvents_ReviewAchievementEvents = "AchievementEvents_ReviewAchievementEvents",
  ViewAndEditUserEmployeeCards = "ViewAndEditUserEmployeeCards",
  EmployeeCard_ViewAndEditMerchDeliveryInfo = "EmployeeCard_ViewAndEditMerchDeliveryInfo",
  EmployeeCard_ViewUsersEmployeeCard = "EmployeeCard_ViewUsersEmployeeCard",
  EmployeeCard_ViewUsersEmployeeCard_ViewEmployeeId = "EmployeeCard_ViewUsersEmployeeCard_ViewEmployeeId",
  EmployeeCard_ViewUsersEmployeeCard_ViewLocation = "EmployeeCard_ViewUsersEmployeeCard_ViewLocation",
  EmployeeCard_ViewUsersEmployeeCard_ViewEmploymentType = "EmployeeCard_ViewUsersEmployeeCard_ViewEmploymentType",
  EmployeeCard_ViewUsersEmployeeCard_ViewUserPhone = "EmployeeCard_ViewUsersEmployeeCard_ViewUserPhone",
  EmployeeCard_ViewUsersEmployeeCard_ViewUserSocialNetworks = "EmployeeCard_ViewUsersEmployeeCard_ViewUserSocialNetworks",
  EmployeeCard_ViewUsersEmployeeCard_ViewDateOfBirth = "EmployeeCard_ViewUsersEmployeeCard_ViewDateOfBirth",
  EmployeeCard_ViewUsersEmployeeCard_ViewHireDate = "EmployeeCard_ViewUsersEmployeeCard_ViewHireDate",
  EmployeeCard_ViewUsersEmployeeCard_ViewTenure = "EmployeeCard_ViewUsersEmployeeCard_ViewTenure",
  EmployeeCard_ViewPlayerProfile = "EmployeeCard_ViewPlayerProfile",
  EmployeeCard_PlayerProfile_ViewCoinsBalance = "EmployeeCard_PlayerProfile_ViewCoinsBalance",
  EmployeeCard_PlayerProfile_ViewPraisedCoins = "EmployeeCard_PlayerProfile_ViewPraisedCoins",
  EmployeeCard_PlayerProfile_ViewActivityChart = "EmployeeCard_PlayerProfile_ViewActivityChart",
  EmployeeCard_PlayerProfile_ViewQuestBacklog = "EmployeeCard_PlayerProfile_ViewQuestBacklog",
  Gamification_ManageAutoEvents = "Gamification_ManageAutoEvents",
  Gamification_Achievements_ViewAchievementsArchive = "Gamification_Achievements_ViewAchievementsArchive",
  UserGroupsManagement_ViewAddEditDeleteUserGroups = "UserGroupsManagement_ViewAddEditDeleteUserGroups",
  TimeOff_ViewAddEditDeletePolicies = "TimeOff_ViewAddEditDeletePolicies",
  TimeOff_ViewOtherUserBalance = "TimeOff_ViewOtherUserBalance",
  TimeOff_ViewOtherUserBacklog = "TimeOff_ViewOtherUserBacklog",
  TimeOff_ViewAndManageOtherUsersRequests = "TimeOff_ViewAndManageOtherUsersRequests",
  TimeOff_ViewCalendar = "TimeOff_ViewCalendar",
}

export enum Features {
  Gamification = "Gamification",
  TimeOff = "Time Off",
}

export enum ErrorCodes {
  CompanyFeatureAccessDenied = "CompanyFeatureAccessDenied",
}

export enum CompanyStatuses {
  Demo = "Demo",
  Active = "Active",
  InDebt = "In debt",
  Inactive = "Inactive",
}

export enum BillingTypes {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
  Undefined = "N/A",
}

export enum PriceTypes {
  Fixed = "Fixed",
  PerUser = "Per User",
  FreeOfCharge = "Free of Charge",
}

export enum IntegrationTypes {
  Slack = "Slack",
  MicrosoftEntraId = "MicrosoftEntraId",
  PeopleForce = "PeopeForce",
}

// React rich text component has 2 themes (default is snow with editor tools, and "bubble" without tools, for view reach text)
export enum ReactQuillThemes {
  snow = "snow",
  bubble = "bubble",
}

export enum UserSources {
  Esteeme = "Esteeme",
  MicrosoftEntraId = "MicrosoftEntraId",
  PeopleForce = "PeopleForce",
}

// values of these user fields we will receive from AzureAD (if integration is active), so we disable their editing
export const microsoftEntraIdUserFields = {
  firstName: true,
  familyName: true,
  email: true,
  birthDate: true,
  hireDate: true,
  departament: true,
  position: true,
  managerId: true,
  country: true,
  location: true,
  employmentType: true,
};

export enum ScrollDirection {
  left = "left",
  right = "right",
}
