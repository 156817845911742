import { FC } from "react";
import { config } from "utils/config";
import { formatCountriesList, getLocalizedCountries } from "./utils";
import { useTranslation } from "react-i18next";
import { Tooltip, Whisper } from "rsuite";
import Icon from "../Icon";
import { ErrorMessage, Field } from "formik";
import classNames from "classnames";
import CustomSelect from "../CustomSelect";
import { CountrySingleValue, CustomCountryOption } from "./CustomCountrySelect";
import RequiredFieldLabel from "../RequiredFieldLabel";

type ShippingCountriesSelectProps = {
  isInputDisabled?: boolean;
  name: string;
};

export const ShippingCountriesSelect: FC<ShippingCountriesSelectProps> = ({
  isInputDisabled = false,
  name,
}) => {
  const { t } = useTranslation(["employeePage", "countries", "auth"]);
  const { availableDeliveryCountries } = config;
  const translateCountriesList = formatCountriesList(t);
  const countriesList = getLocalizedCountries(t);

  return (
    <>
      <label
        htmlFor="receiverCountry"
        className={classNames("employeePage__label employeePage__label--flex", {
          "label-disabled": isInputDisabled,
        })}
      >
        {isInputDisabled ? (
          t("receiverCountry")
        ) : (
          <RequiredFieldLabel labelTitle={t("receiverCountry")} />
        )}
        {availableDeliveryCountries && translateCountriesList?.length ? (
          <Whisper
            speaker={
              <Tooltip className="custom-tooltip">
                {t("deliveryInfoTooltip", {
                  countriesList: translateCountriesList,
                })}
              </Tooltip>
            }
            trigger="hover"
            placement="bottomStart"
          >
            <span>
              <Icon href="#info" svgClass="ic-info" />
            </span>
          </Whisper>
        ) : null}
      </label>
      <div>
        <Field
          id="receiverCountry"
          component={CustomSelect}
          name={name}
          customComponents={{
            Option: CustomCountryOption,
            SingleValue: CountrySingleValue,
          }}
          options={countriesList}
          placeholder={t("receiverCountry")}
          className="form-input"
          isDisabled={isInputDisabled}
          availableDeliveryCountries={availableDeliveryCountries}
          isSearchable
          isClearable
        />
        <ErrorMessage component="p" name={name} className="error" />
      </div>
    </>
  );
};
