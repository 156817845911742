import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";

const currentDate = new Date();

// in month select we must display 11 months prev from currentDate and 11 months next from currentDate
const countDisplayedMonth = 11;

export const startIntervalDate = startOfMonth(
  subMonths(currentDate, countDisplayedMonth)
);

export const endIntervalDate = startOfMonth(
  addMonths(currentDate, countDisplayedMonth)
);

export const defaultCurrentMonthRange: [string, string] = [
  startOfMonth(new Date()).toString(),
  endOfMonth(new Date()).toString(),
];

// according to design must display 10 employee per page
export const countEmployeesPerPage = 10;
