import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import PlayerStatisticBlock from "./PlayerStatisticBlock";

const CoinsBalanceBlock: FC = () => {
  const { t } = useTranslation("playerProfile");
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.playerProfile.coinsCount
  );

  return (
    <PlayerStatisticBlock
      title={t("coinsBalance")}
      isLoading={isLoading}
      errorMessage={errorMessage}
      data={data}
      isCoinsImg
      tooltipText={t("balanceTooltip")}
    />
  );
};

export default CoinsBalanceBlock;
