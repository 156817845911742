import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../store";
import Loader from "../utils/components/Loader";
import AccessDenied from "./AccessDenied";
import { usePermissionCheck } from "../utils/hooks";

export interface IRequirePermissionsGuardProps {
  /* ANY of listed permissions will work */
  permissions: string[];
}

const RequirePermissionsGuard: FC<IRequirePermissionsGuardProps> = ({
  permissions,
}) => {
  const profile = useAppSelector((state) => state.auth.profile);
  const isAccess = usePermissionCheck(permissions);

  if (profile) {
    if (isAccess) {
      return <Outlet />;
    } else {
      return <AccessDenied />;
    }
  }

  return (
    <div className="box">
      <div className="loader-container">
        <Loader />
      </div>
    </div>
  );
};

export default RequirePermissionsGuard;
