import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginResponse } from "../../api/types/auth";
import { Profile } from "../../api/types/profile";
import { UserProfile } from "../../api/types/usersTypes";
import { AuthState } from "../types";
import { defaultListState } from "../../appConsts";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";

const initialState: AuthState = {
  tokens: null,
  profile: null,
  user: null,
  coinsCount: defaultListState,
  lastVisit: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<loginResponse>) => ({
      ...state,
      tokens: {
        accessToken: action.payload.accessToken,
      },
    }),
    setProfile: (state, action: PayloadAction<Profile>) => ({
      ...state,
      profile: action.payload,
    }),
    setUserInfo: (state, action: PayloadAction<UserProfile>) => ({
      ...state,
      user: action.payload,
    }),
    setLoadingUserCoins: (state) => ({
      ...state,
      coinsCount: setStateLoading(state.coinsCount),
    }),
    setUserCoins: (state, action: PayloadAction<number>) => ({
      ...state,
      coinsCount: setStateData(state.coinsCount, action.payload),
    }),
    setErrorCoinsCount: (state, action: PayloadAction<string>) => ({
      ...state,
      coinsCount: setStateError(state.coinsCount, action.payload),
    }),
    setLastVisit: (state, action: PayloadAction<Date | null>) => ({
      ...state,
      lastVisit: action.payload,
    }),
    resetCoinsCount: (state) => ({
      ...state,
      coinsCount: defaultListState,
    }),
    logoutUserAction: (): AuthState => initialState,
  },
});

export default authSlice.reducer;
export const {
  setTokens,
  setProfile,
  setUserInfo,
  logoutUserAction,
  setLoadingUserCoins,
  setUserCoins,
  setErrorCoinsCount,
  setLastVisit,
  resetCoinsCount,
} = authSlice.actions;
