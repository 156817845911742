import { EmployeeDeliveryInfoState } from "../../types";
import { defaultStateWithInfiniteLoading } from "../../../appConsts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
} from "../../handleUpdateState";
import {
  EmployeeOrdersSortBy,
  ExchangeHistoryItemDto,
} from "../../../api/types/employeePageTypes";
import { MerchItemDto } from "../../../api/types/merchStoreTypes";
import { logoutUserAction } from "../authReducer";
import { SortDirections } from "../../../api/types/commonTypes";

const initialState: EmployeeDeliveryInfoState = {
  employeeMerchOrders: {
    ordersList: defaultStateWithInfiniteLoading,
    sorting: {
      sortBy: EmployeeOrdersSortBy.CreatedDate,
      sortDirection: SortDirections.desc,
    },
  },
  employeeReceivedItems: defaultStateWithInfiniteLoading,
};

const employeeDeliveryInfoSlice = createSlice({
  initialState,
  name: "employeeDeliveryInfo",
  reducers: {
    // Employee merch orders on Merch delivery info tab
    setLoadingEmployeeMerchOrders: (state) => ({
      ...state,
      employeeMerchOrders: {
        ...state.employeeMerchOrders,
        ordersList: setInfiniteLoadLoading(
          state.employeeMerchOrders.ordersList
        ),
      },
    }),
    setEmployeeMerchOrders: (
      state,
      action: PayloadAction<{
        ordersList: ExchangeHistoryItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let ordersList = state.employeeMerchOrders.ordersList.data || [];
      ordersList = [...ordersList, ...action.payload.ordersList];

      return {
        ...state,
        employeeMerchOrders: {
          ...state.employeeMerchOrders,
          ordersList: setInfiniteLoadData(
            state.employeeMerchOrders.ordersList,
            ordersList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorEmployeeMerchOrders: (state, action: PayloadAction<string>) => ({
      ...state,
      employeeMerchOrders: {
        ...state.employeeMerchOrders,
        ordersList: setInfiniteLoadStateErrorMessage(
          state.employeeMerchOrders.ordersList,
          action.payload
        ),
      },
    }),
    setEmployeeMerchOrdersSorting: (
      state,
      action: PayloadAction<[EmployeeOrdersSortBy, SortDirections]>
    ) => ({
      ...state,
      employeeMerchOrders: {
        ...state.employeeMerchOrders,
        sorting: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    resetEmployeeMerchOrders: (state) => ({
      ...state,
      employeeMerchOrders: {
        ...state.employeeMerchOrders,
        ordersList: defaultStateWithInfiniteLoading,
      },
    }),
    // Employee Received items on Merch Delivery info tab
    setLoadingEmployeeReceivedItems: (state) => ({
      ...state,
      employeeReceivedItems: setInfiniteLoadLoading(
        state.employeeReceivedItems
      ),
    }),
    setEmployeeReceivedItems: (
      state,
      action: PayloadAction<{
        receivedItemsFromApi: MerchItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let receivedItemsArr = state.employeeReceivedItems.data || [];
      receivedItemsArr = [
        ...receivedItemsArr,
        ...action.payload.receivedItemsFromApi,
      ];

      return {
        ...state,
        employeeReceivedItems: setInfiniteLoadData(
          state.employeeReceivedItems,
          receivedItemsArr,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorEmployeeReceivedItems: (state, action: PayloadAction<string>) => ({
      ...state,
      employeeReceivedItems: setInfiniteLoadStateErrorMessage(
        state.employeeReceivedItems,
        action.payload
      ),
    }),
    resetEmployeeDeliveryInfoState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default employeeDeliveryInfoSlice.reducer;

export const {
  setLoadingEmployeeMerchOrders,
  setEmployeeMerchOrders,
  setErrorEmployeeMerchOrders,
  setEmployeeMerchOrdersSorting,
  resetEmployeeMerchOrders,
  setLoadingEmployeeReceivedItems,
  setEmployeeReceivedItems,
  setErrorEmployeeReceivedItems,
  resetEmployeeDeliveryInfoState,
} = employeeDeliveryInfoSlice.actions;
