import { FieldProps } from "formik";
import { InputNumber } from "rsuite";

interface CustomInputNumberProps extends FieldProps {
  placeholder: string;
  disabled: boolean;
}
const CustomInputNumber = ({
  placeholder,
  form,
  field,
  disabled,
}: CustomInputNumberProps) => {
  const onChange = (value: number | string) => {
    const newValue = value ? +value : value;
    form.setFieldValue(field.name, newValue);
  };

  return (
    <div className="customInputNumber">
      <InputNumber
        name={field.name}
        value={field.value}
        size="lg"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={form.handleBlur}
        scrollable={false}
        min={0}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomInputNumber;
