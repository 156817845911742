import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AchievementEventDto } from "../../../api/types/achievementEventsTypes";
import {
  AchievementCoinTransactionDto,
  CategoryEventsCountDto,
} from "../../../api/types/playerProfileTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../../handleUpdateState";
import { PlayerProfileState } from "../../types";
import { logoutUserAction } from "../authReducer";

const initialState: PlayerProfileState = {
  coinsCount: defaultListState,
  coinsPraisedCount: defaultListState,
  activeAchievementsEvents: defaultListState,
  achievementCoinsTransactions: defaultListState,
  achievementCategoriesCount: defaultListState,
  userAchievementEvents: defaultStateWithInfiniteLoading,
};

const playerProfileSlice = createSlice({
  initialState,
  name: "playerProfile",
  reducers: {
    // Balance of coins
    setLoadingCoinsBalance: (state) => ({
      ...state,
      coinsCount: setStateLoading(state.coinsCount),
    }),
    setCoinsBalance: (state, action: PayloadAction<number>) => ({
      ...state,
      coinsCount: setStateData(state.coinsCount, action.payload),
    }),
    setErrorCoinsBalance: (state, action: PayloadAction<string>) => ({
      ...state,
      coinsCount: setStateError(state.coinsCount, action.payload),
    }),
    // Balance of praised coins
    setLoadingCoinsPraisedBalance: (state) => ({
      ...state,
      coinsPraisedCount: setStateLoading(state.coinsPraisedCount),
    }),
    setCoinsPraisedBalance: (state, action: PayloadAction<number>) => ({
      ...state,
      coinsPraisedCount: setStateData(state.coinsPraisedCount, action.payload),
    }),
    setErrorCoinsPraisedBalance: (state, action: PayloadAction<string>) => ({
      ...state,
      coinsPraisedCount: setStateError(state.coinsPraisedCount, action.payload),
    }),
    // Active events block
    setLoadingAchievementsEventsCount: (state) => ({
      ...state,
      activeAchievementsEvents: setStateLoading(state.activeAchievementsEvents),
    }),
    setActiveAchievementsEventsCount: (
      state,
      action: PayloadAction<number>
    ) => ({
      ...state,
      activeAchievementsEvents: setStateData(
        state.activeAchievementsEvents,
        action.payload
      ),
    }),
    setErrorActiveAchievementsEventsCount: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      activeAchievementsEvents: setStateError(
        state.activeAchievementsEvents,
        action.payload
      ),
    }),
    // Activity chart
    setLoadingAchievementsCoinsTransactions: (state) => ({
      ...state,
      achievementCoinsTransactions: setStateLoading(
        state.achievementCoinsTransactions
      ),
    }),
    setAchievementsCoinsTransactions: (
      state,
      action: PayloadAction<AchievementCoinTransactionDto[]>
    ) => ({
      ...state,
      achievementCoinsTransactions: setStateData(
        state.achievementCoinsTransactions,
        action.payload
      ),
    }),
    setErrorAchievementsCoinsTransactions: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementCoinsTransactions: setStateError(
        state.achievementCoinsTransactions,
        action.payload
      ),
    }),
    // Categories chart
    setLoadingAchievementCategoriesCount: (state) => ({
      ...state,
      achievementCategoriesCount: setStateLoading(
        state.achievementCategoriesCount
      ),
    }),
    setAchievementCategoriesCount: (
      state,
      action: PayloadAction<CategoryEventsCountDto[]>
    ) => ({
      ...state,
      achievementCategoriesCount: setStateData(
        state.achievementCategoriesCount,
        action.payload
      ),
    }),
    setErrorAchievementCategoriesCount: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementCategoriesCount: setStateError(
        state.achievementCategoriesCount,
        action.payload
      ),
    }),
    // Quest backlog
    setLoadingUserAchievementEvents: (state) => ({
      ...state,
      userAchievementEvents: setInfiniteLoadLoading(
        state.userAchievementEvents
      ),
    }),
    setUserAchievementEvents: (
      state,
      action: PayloadAction<{
        userAchievementEventsList: AchievementEventDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let userAchievementsEventsList = state.userAchievementEvents.data || [];
      userAchievementsEventsList = [
        ...userAchievementsEventsList,
        ...action.payload.userAchievementEventsList,
      ];

      return {
        ...state,
        userAchievementEvents: setInfiniteLoadData(
          state.userAchievementEvents,
          userAchievementsEventsList,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorUserAchievementEvents: (state, action: PayloadAction<string>) => ({
      ...state,
      userAchievementEvents: setInfiniteLoadStateErrorMessage(
        state.userAchievementEvents,
        action.payload
      ),
    }),
    resetPlayerProfileState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default playerProfileSlice.reducer;

export const {
  setLoadingCoinsBalance,
  setCoinsBalance,
  setErrorCoinsBalance,
  setLoadingCoinsPraisedBalance,
  setCoinsPraisedBalance,
  setErrorCoinsPraisedBalance,
  setLoadingAchievementsEventsCount,
  setActiveAchievementsEventsCount,
  setErrorActiveAchievementsEventsCount,
  setLoadingAchievementsCoinsTransactions,
  setAchievementsCoinsTransactions,
  setErrorAchievementsCoinsTransactions,
  resetPlayerProfileState,
  setLoadingAchievementCategoriesCount,
  setAchievementCategoriesCount,
  setErrorAchievementCategoriesCount,
  setLoadingUserAchievementEvents,
  setUserAchievementEvents,
  setErrorUserAchievementEvents,
} = playerProfileSlice.actions;
