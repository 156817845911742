import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { TPlayerProfilePermissions } from "store/types";
import {
  getUserAchievementsBacklog,
  loadAchievementCategoriesCount,
  loadAchievementCoinTransactions,
  loadActiveAchievementsEventsCount,
  loadUserCoinsBalance,
  loadUserCoinsPraisedBalance,
} from "../../../api/playersProfileApi";
import { UserAchievementEventsRequestBody } from "../../../api/types/playerProfileTypes";
import { countItemsPerPage } from "../../../appConsts";
import { getErrorMessage } from "../../../utils/methods";
import { store } from "../../index";
import {
  resetPlayerProfileState,
  setAchievementCategoriesCount,
  setAchievementsCoinsTransactions,
  setActiveAchievementsEventsCount,
  setCoinsBalance,
  setCoinsPraisedBalance,
  setErrorAchievementCategoriesCount,
  setErrorAchievementsCoinsTransactions,
  setErrorActiveAchievementsEventsCount,
  setErrorCoinsBalance,
  setErrorCoinsPraisedBalance,
  setErrorUserAchievementEvents,
  setLoadingAchievementCategoriesCount,
  setLoadingAchievementsCoinsTransactions,
  setLoadingAchievementsEventsCount,
  setLoadingCoinsBalance,
  setLoadingCoinsPraisedBalance,
  setLoadingUserAchievementEvents,
  setUserAchievementEvents,
} from "../../reducers/employeePageReducers/playerProfileReducer";
import { refreshCoinsCount } from "../authThunks";

export const getActiveAchievementsEventsCount =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingAchievementsEventsCount());

    try {
      const response = await loadActiveAchievementsEventsCount(userId);
      dispatch(setActiveAchievementsEventsCount(response.data.count));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorActiveAchievementsEventsCount(errorMessage));
    }
  };

export const getAchievementsCoinsTransactions =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingAchievementsCoinsTransactions());

    try {
      const response = await loadAchievementCoinTransactions(userId);
      dispatch(setAchievementsCoinsTransactions(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorAchievementsCoinsTransactions(errorMessage));
    }
  };

export const getAchievementCategoriesCount =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingAchievementCategoriesCount());

    try {
      const response = await loadAchievementCategoriesCount(userId);
      dispatch(setAchievementCategoriesCount(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorAchievementCategoriesCount(errorMessage));
    }
  };

export const getUserAchievementEventsList =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingUserAchievementEvents());

    try {
      const nextPage =
        store.getState().playerProfile.userAchievementEvents.page + 1;
      const requestBody: UserAchievementEventsRequestBody = {
        pageNumber: nextPage,
        pageSize: countItemsPerPage,
        status: null,
      };

      const response = await getUserAchievementsBacklog(userId, requestBody);
      const userAchievementsEventsListFromApi = response.data.items;
      const noMoreData =
        userAchievementsEventsListFromApi.length === 0 ||
        userAchievementsEventsListFromApi.length < countItemsPerPage;

      dispatch(
        setUserAchievementEvents({
          userAchievementEventsList: userAchievementsEventsListFromApi,
          hasMoreData: !noMoreData,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorUserAchievementEvents(errorMessage));
    }
  };

export const getPlayerCoinsBalance =
  (userId: number) => async (dispatch: Dispatch) => {
    dispatch(setLoadingCoinsBalance());

    try {
      const response = await loadUserCoinsBalance(userId);
      dispatch(setCoinsBalance(response.data.coins));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorCoinsBalance(errorMessage));
    }
  };

export const getPlayerCoinsPraisedBalance =
  (userId: number) => async (dispatch: Dispatch) => {
    dispatch(setLoadingCoinsPraisedBalance());

    try {
      const response = await loadUserCoinsPraisedBalance(userId);
      dispatch(setCoinsPraisedBalance(response.data.coins));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorCoinsPraisedBalance(errorMessage));
    }
  };

export const getPlayerProfilePageData =
  (userId: number, permissions: TPlayerProfilePermissions) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const currentUserId = store.getState().auth.profile?.id;
    const isCurrentUser = userId === currentUserId;

    await Promise.all([
      isCurrentUser ? dispatch(refreshCoinsCount()) : Promise.resolve(),
      (isCurrentUser || permissions.isViewCoinsBalance) &&
        dispatch(getPlayerCoinsBalance(userId)),
      (isCurrentUser || permissions.isViewPraisedCoins) &&
        dispatch(getPlayerCoinsPraisedBalance(userId)),
      dispatch(getActiveAchievementsEventsCount(userId)),
      dispatch(getAchievementCategoriesCount(userId)),
      dispatch(getAchievementsCoinsTransactions(userId)),
      (isCurrentUser || permissions.isViewQuestBacklog) &&
        dispatch(getUserAchievementEventsList(userId)),
    ]);
  };

export const refreshPlayerProfilePageData =
  (userId: number, permissions: TPlayerProfilePermissions) =>
  async (dispatch: Dispatch<any>) => {
    await dispatch(resetPlayerProfileState());
    await dispatch(getPlayerProfilePageData(userId, permissions));
  };
