import { base_url, countItemsPerPage } from "../appConsts";
import { axiosInstance } from "./instance";
import { AxiosPromise } from "axios";
import {
  NotificationsODataDto,
  NotificationDto,
  NotificationUpdateAction,
  ReadNotificationsResponse,
} from "./types/notifications";

export const loadAllNotifications = (
  pageNumber: number
): AxiosPromise<NotificationsODataDto> => {
  const totalItemsCount = pageNumber * countItemsPerPage;
  return axiosInstance.get(
    `${base_url}/api/notifications/odata?$orderby=Status asc, CreatedDate desc&$top=${totalItemsCount}&$count=true`
  );
};

export const loadAllUnreadNotifications =
  (): AxiosPromise<NotificationsODataDto> =>
    axiosInstance.get(
      `${base_url}/api/notifications/odata?$filter=Status eq Enums.NotificationStatus'Unread'&$count=true`
    );

export const readNotification = (
  notificationId: number
): AxiosPromise<ReadNotificationsResponse> =>
  axiosInstance.put(
    `${base_url}/api/notifications/action/odata?$filter=Id eq ${notificationId}`,
    {
      action: NotificationUpdateAction.read,
    }
  );

export const deleteNotification = (
  notificationId: number | null
): AxiosPromise<NotificationDto> => {
  const fetchUrl = notificationId
    ? `${base_url}/api/notifications/action/odata?$filter=Id eq ${notificationId}`
    : `${base_url}/api/notifications/action/odata`;

  return axiosInstance.put(fetchUrl, {
    action: NotificationUpdateAction.delete,
  });
};

export const readAllNotifications =
  (): AxiosPromise<ReadNotificationsResponse> =>
    axiosInstance.put(
      `${base_url}/api/notifications/action/odata?$filter=Status eq Enums.NotificationStatus'Unread'`,
      {
        action: NotificationUpdateAction.read,
      }
    );
