import axios, { AxiosHeaders } from "axios";
import i18next from "i18next";
import { defaultLanguage } from "localization/supportedLanguages";
import { culturesToCompanyCultureMapping } from "localization/useChangeLocalization";
import { createSearchParams } from "react-router-dom";
import { store } from "../store";
import { setProfile, setTokens } from "../store/reducers/authReducer";
import { ErrorCodes } from "../utils/consts";
import { refresh } from "./authApi";
import { profile } from "./profileApi";

const axiosInstance = axios.create({
  withCredentials: true,
});
const axiosInstanceSkip = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.request.use(async (config) => {
  const i18Language = i18next.language ?? defaultLanguage;

  const apiLanguage = culturesToCompanyCultureMapping[i18Language];

  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }
  config.headers.set("Accept-Language", apiLanguage);

  return config;
});

axiosInstanceSkip.interceptors.request.use(async (config) => {
  const i18Language = i18next.language ?? defaultLanguage;

  const apiLanguage = culturesToCompanyCultureMapping[i18Language];

  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }
  config.headers.set("Accept-Language", apiLanguage);

  return config;
});

axiosInstance.interceptors.request.use(async (config) => {
  const accessToken = store.getState().auth.tokens?.accessToken;

  if (accessToken) {
    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

const redirectToLogin = () => {
  const searchString = createSearchParams({
    returnUrl: `${window.location.pathname}${window.location.search}`,
  }).toString();
  window.location.href = `/login?${searchString}`;
};

type WaitingCheck = {
  401: false | Promise<void>;
  403: boolean;
};

// for prevent multiple requests for refresh (401) and profile (403)
const waiting: WaitingCheck = {
  401: false,
  403: false,
};

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      if (error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;

        try {
          const doRefresh = async () => {
            const responseNewTokens = await refresh();
            store.dispatch(setTokens(responseNewTokens.data));
            const profileData = await profile();
            store.dispatch(setProfile(profileData.data));
          };

          if (!waiting["401"]) {
            waiting["401"] = doRefresh();
          }
          await waiting["401"];

          return axiosInstance.request(originalRequest);
        } catch (e) {
          redirectToLogin();
        } finally {
          waiting["401"] = false;
        }
      } else {
        redirectToLogin();
      }
    }

    if (
      error.response.status === 403 &&
      error.response.data.errorCode === ErrorCodes.CompanyFeatureAccessDenied &&
      !waiting["403"]
    ) {
      waiting["403"] = true;
      try {
        const updatedProfileData = await profile();
        store.dispatch(setProfile(updatedProfileData.data));
      } catch (e) {
        redirectToLogin();
      } finally {
        waiting["403"] = false;
      }
    }

    throw error;
  }
);

export { axiosInstance, axiosInstanceSkip };
