const SessionStorageKey = 'local-url';

export const storeCurrentPath = () => {
  window.sessionStorage.setItem(SessionStorageKey, window.location.pathname);
};

export const getStoredPath = () => {
  return window.sessionStorage.getItem(SessionStorageKey);
};

export const clearStoredPath = () => {
  window.sessionStorage.removeItem(SessionStorageKey);
};

const whitelist = [
  /\/news/gm,
  /\/login/gm,
  /\/forget-password/gm,
  /\/forget-password-code/gm,
  /\/employee\/[0-9]+/gm,
  /\/employee\/[0-9]+\/personal-info/gm,
  /\/employee\/[0-9]+\/player-info/gm,
  /\/employee\/[0-9]+\/delivery-info/gm,
  /\/employee\/[0-9]+\/time-off/gm,
  /\/achievements/gm,
  /\/achievements\/[0-9]+/gm,
  /\/achievement-categories/gm,
  /\/achievement-categories\/[0-9]+/gm,
  /\/leaderboard/gm,
  /\/achievements\/add-achievement/gm,
  /\/achievement-categories\/[0-9]+\/add-achievement/gm,
  /\/achievements\/[0-9]+\/edit/gm,
  /\/achievements-applications/gm,
  /\/analytics\/activity/gm,
  /\/analytics\/badges/gm,
  /\/analytics\/achievements/gm,
  /\/manage-auto-events/gm,
  /\/events\/archive/gm,
  /\/events\/archive\/[0-9]+/gm,
  /\/merch-store/gm,
  /\/merch-store\/merches\/[0-9]+/gm,
  /\/merch-delivery/gm,
  /\/merch-items-management/gm,
  /\/manage-badges/gm,
  /\/time-off-policy-types/gm,
  /\/time-off-policy-types\/[0-9]+/gm,
  /\/time-off-policy-types\/[0-9]+\/add-policy/gm,
  /\/time-off-policy-types\/[0-9]+\/edit-policy\/[0-9]+/gm,
  /\/time-off-policy-types\/[0-9]+\/policies\/[0-9]+/gm,
  /\/time-off-requests/gm,
  /\/account-settings/gm,
  /\/company-management/gm,
  /\/group-management/gm,
  /\/group-management\/create-group/gm,
  /\/group-management\/edit-group\/[0-9]+/gm,
  /\/group-management\/groups\/[0-9]+/gm,
  /\/org-structure/gm,
  /\/org-structure\/[0-9]+/gm,
  /\/directory/gm,
  /\/integrations/gm,
  /\/integrations\/slack/gm,
  /\/integrations\/slack\/connect-code/gm,
  /\/integrations\/microsoft/gm,
  /\/integrations\/microsoft\/connect/gm,
  /\/system-settings/gm,
  /\/system-settings\/setting-role/gm,
  /\/system-settings\/access-management/gm,
  /\/system-settings\/access-management\/new-role/gm,
  /\/system-settings\/access-management\/role\/[0-9]+/gm
];

export const isValidUrl = (url: string | null) => {
  if (!url) return false;

  return whitelist.some((r) => r.test(url));
};