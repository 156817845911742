import { FC } from "react";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from "recharts";
import { ChartsColors } from "../../appConsts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

type ChartDataItem = {
  category: string;
  count: number;
};

type CustomPieChartProps = {
  chartData: ChartDataItem[];
};

export const CustomPieChart: FC<CustomPieChartProps> = ({ chartData }) => {
  const CustomTooltip = (prop: TooltipProps<ValueType, NameType>) => {
    const { active, payload } = prop;

    if (active && payload && payload.length) {
      return (
        <div className="chartTooltip">
          <div className="chartTooltip__label">
            <p className="chartTooltip__labelTitle">{payload[0].name}</p>
            <p className="chartTooltip__labelValue">{payload[0].value}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = () => {
    return (
      <ul className="customLegend">
        {chartData.map((entry, index) => (
          <li key={`item-${index}`} className="customLegend__item">
            <div
              style={{
                backgroundColor: index
                  ? ChartsColors[index % ChartsColors.length]
                  : "#5494BD",
              }}
              className="customLegend__icon"
            />
            <span>{entry.category}</span>
            <span className="customLegend__count">{entry.count}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="customPieChart">
      <CustomLegend />
      <ResponsiveContainer width="100%" height={350}>
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius={90}
            outerRadius={160}
            dataKey="count"
            nameKey="category"
            stroke="none"
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  index ? ChartsColors[index % ChartsColors.length] : "#5494BD"
                }
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomPieChart;
