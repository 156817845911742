import { OptionNumberValue } from "utils/types/option";
import { UserOfSearchWithActiveStatus } from "./usersTypes";

export enum UserGroupType {
  Static = "static",
  Dynamic = "dynamic",
}

export enum GroupFilterType {
  position = "positions",
  department = "departments",
  location = "locations",
}

export type DynamicUserGroupFilterDto = Record<GroupFilterType, string[]>;

export type UserGroupDto = {
  id: number;
  name: string;
  description: string;
  isDynamic: boolean;
  userCount: number;
};

export type UserGroupWithParticipantsDto = UserGroupDto & {
  users: UserOfSearchWithActiveStatus[];
  filter: DynamicUserGroupFilterDto;
  companyId: number;
};

export type CreateStaticUserGroupRequest = {
  name: string;
  description: string;
  userIds: number[];
};

export type CreateDynamicUserGroupRequest = {
  name: string;
  description: string;
  filter: DynamicUserGroupFilterDto;
};

export type UpdateStaticUserGroupRequest = CreateStaticUserGroupRequest;

export type UpdateDynamicUserGroupRequest = CreateDynamicUserGroupRequest;

export type UserGroupsSelectState = {
  isLoadingUserGroups: boolean;
  userGroups: OptionNumberValue[] | null;
  userGroupsError: string;
}
