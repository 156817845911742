import { TimeOffPolicyTypeRequestDto } from "api/types/employeePageTypes";
import { TimeOffPolicyTypesDeductDays } from "api/types/timeOff/timeOffPolicyTypes";
import { dateTimeTemplates } from "appConsts";
import { eachDayOfInterval, format, isWeekend } from "date-fns";
import i18next from "i18next";

export type DayLisItem = {
  date: string;
  dayWeek: string;
  isWorkDay: boolean;
  remainingDays: number;
};

export const getDaysList = (
  dateRange: [Date, Date] | null,
  locale: Locale,
  policyType?: TimeOffPolicyTypeRequestDto
): DayLisItem[] => {
  if (dateRange && dateRange.length === 2) {
    const [startDate, endDate] = dateRange;
    return eachDayOfInterval({
      start: startDate,
      end: endDate,
    }).map((date) => {
      const oneRemainingDay = 1;
      const notRemainingDay = 0;

      const language = i18next.language;
      const template = dateTimeTemplates[language];

      const formattedDate = format(date, template?.monthDayDate, { locale });
      const dayWeek = format(date, template.daysWeek, {
        locale,
      });
      const isWorkDay = !isWeekend(date);
      const remainingDays =
        (isWeekend(date) &&
          policyType?.deductDays === TimeOffPolicyTypesDeductDays.any) ||
        isWorkDay
          ? oneRemainingDay
          : notRemainingDay;
      return {
        date: formattedDate,
        dayWeek,
        isWorkDay,
        remainingDays,
      };
    });
  }
  return [];
};

export const getTotalUsedDays = (daysList: DayLisItem[]): number => {
  return daysList.reduce(
    (acc: number, day: DayLisItem) => acc + day.remainingDays,
    0
  );
};
