type UaTranslations = Record<string, string>;

export const uaTranslations: UaTranslations = {
  au: "Австралія",
  at: "Австрія",
  az: "Азербайджан",
  ax: "Аландські острови",
  al: "Албанія",
  dz: "Алжир",
  as: "Американське Самоа",
  ai: "Ангілья",
  ao: "Ангола",
  ad: "Андорра",
  aq: "Антарктида",
  ag: "Антигуа і Барбуда",
  ar: "Аргентина",
  am: "Вірменія",
  aw: "Аруба",
  af: "Афганістан",
  bs: "Багами",
  bd: "Бангладеш",
  bb: "Барбадос",
  bh: "Бахрейн",
  by: "Білорусь",
  bz: "Беліз",
  be: "Бельгія",
  bj: "Бенін",
  bm: "Бермуди",
  bg: "Болгарія",
  bo: "Болівія",
  bq: "Бонейр, Сінт-Естатіус і Саба",
  ba: "Боснія і Герцеговина",
  bw: "Ботсвана",
  br: "Бразилія",
  io: "Британська територія в Індійському океані",
  bn: "Бруней-Даруссалам",
  bf: "Буркіна-Фасо",
  bi: "Бурунді",
  bt: "Бутан",
  vu: "Вануату",
  va: "Ватикан",
  gb: "Велика Британія",
  hu: "Угорщина",
  ve: "Венесуела",
  vg: "Британські Віргінські острови",
  vi: "Віргінські острови США",
  um: "Зовнішні малі острови США",
  tl: "Східний Тимор",
  vn: "В'єтнам",
  ga: "Габон",
  ht: "Гаїті",
  gy: "Гаяна",
  gm: "Гамбія",
  gh: "Гана",
  gp: "Гваделупа",
  gt: "Гватемала",
  gn: "Гвінея",
  gw: "Гвінея-Бісау",
  de: "Німеччина",
  gg: "Гернсі",
  gi: "Гібралтар",
  hn: "Гондурас",
  hk: "Гонконг",
  gd: "Гренада",
  gl: "Гренландія",
  gr: "Греція",
  ge: "Грузія",
  gu: "Гуам",
  dk: "Данія",
  je: "Джерсі",
  dj: "Джибуті",
  dg: "Дієго-Гарсія",
  dm: "Домініка",
  do: "Домініканська Республіка",
  eg: "Єгипет",
  zm: "Замбія",
  eh: "Західна Сахара",
  zw: "Зімбабве",
  il: "Ізраїль",
  in: "Індія",
  id: "Індонезія",
  jo: "Йорданія",
  iq: "Ірак",
  ir: "Іран",
  ie: "Ірландія",
  is: "Ісландія",
  es: "Іспанія",
  it: "Італія",
  ye: "Ємен",
  cv: "Кабо-Верде",
  kz: "Казахстан",
  kh: "Камбоджа",
  cm: "Камерун",
  ca: "Канада",
  ic: "Канарські острови",
  qa: "Катар",
  ke: "Кенія",
  cy: "Кіпр",
  kg: "Киргизія",
  ki: "Кірібаті",
  cn: "Китай",
  kp: "Північна Корея",
  cc: "Кокосові острови",
  co: "Колумбія",
  km: "Комори",
  cg: "Конго - Браззавіль",
  cd: "Конго - Кіншаса",
  xk: "Косово",
  cr: "Коста-Рика",
  ci: "Кот-д'Івуар",
  cu: "Куба",
  kw: "Кувейт",
  cw: "Кюрасао",
  la: "Лаос",
  lv: "Латвія",
  ls: "Лесото",
  lr: "Ліберія",
  lb: "Ліван",
  ly: "Лівія",
  lt: "Литва",
  li: "Ліхтенштейн",
  lu: "Люксембург",
  mu: "Маврикій",
  mr: "Мавританія",
  mg: "Мадагаскар",
  yt: "Майотта",
  mo: "Макао",
  mw: "Малаві",
  my: "Малайзія",
  ml: "Малі",
  mv: "Мальдіви",
  mt: "Мальта",
  ma: "Марокко",
  mq: "Мартініка",
  mh: "Маршаллові Острови",
  mx: "Мексика",
  mz: "Мозамбік",
  md: "Молдова",
  mc: "Монако",
  mn: "Монголія",
  ms: "Монтсеррат",
  mm: "М'янма",
  na: "Намібія",
  nr: "Науру",
  np: "Непал",
  ne: "Нігер",
  ng: "Нігерія",
  nl: "Нідерланди",
  ni: "Нікарагуа",
  nu: "Ніуе",
  nz: "Нова Зеландія",
  nc: "Нова Каледонія",
  no: "Норвегія",
  ac: "Острів Вознесіння",
  im: "Острів Мен",
  nf: "Острів Норфолк",
  cx: "Острів Різдва",
  sh: "Острів Святої Єлени",
  pn: "Острови Піткерн",
  tc: "Острови Теркс і Кайкос",
  ae: "Об'єднані Арабські Емірати",
  om: "Оман",
  ky: "Острови Кайман",
  ck: "Острови Кука",
  pk: "Пакистан",
  pw: "Палау",
  ps: "Палестинські території",
  pa: "Панама",
  pg: "Папуа-Нова Гвінея",
  py: "Парагвай",
  pe: "Перу",
  pl: "Польща",
  pt: "Португалія",
  pr: "Пуерто-Рико",
  kr: "Південна Корея",
  re: "Реюньйон",
  ru: "Росія",
  rw: "Руанда",
  ro: "Румунія",
  sv: "Сальвадор",
  ws: "Самоа",
  sm: "Сан-Марино",
  st: "Сан-Томе і Принсіпі",
  sa: "Саудівська Аравія",
  mk: "Північна Македонія",
  mp: "Північні Маріанські острови",
  sc: "Сейшельські острови",
  bl: "Сен-Бартелемі",
  mf: "Сен-Мартен",
  pm: "Сен-П'єр і Мікелон",
  sn: "Сенегал",
  vc: "Сент-Вінсент і Гренадини",
  kn: "Сент-Кітс і Невіс",
  lc: "Сент-Люсія",
  rs: "Сербія",
  ea: "Сеута і Мелілья",
  sg: "Сінгапур",
  sx: "Сінт-Мартен",
  sy: "Сирія",
  sk: "Словаччина",
  si: "Словенія",
  us: "Сполучені Штати Америки",
  sb: "Соломонові острови",
  so: "Сомалі",
  sd: "Судан",
  sr: "Суринам",
  sl: "Сьєрра-Леоне",
  tj: "Таджикистан",
  th: "Таїланд",
  tw: "Тайвань",
  tz: "Танзанія",
  tg: "Того",
  tk: "Токелау",
  to: "Тонга",
  tt: "Тринідад і Тобаго",
  ta: "Острів Трістан-да-Кунья",
  tv: "Тувалу",
  tn: "Туніс",
  tm: "Туркменістан",
  tr: "Туреччина",
  ug: "Уганда",
  uz: "Узбекистан",
  ua: "Україна",
  wf: "Уолліс і Футуна",
  uy: "Уругвай",
  fo: "Фарерські острови",
  fm: "Федеративні Штати Мікронезії",
  fj: "Фіджі",
  ph: "Філіппіни",
  fi: "Фінляндія",
  fk: "Фолклендські острови",
  fr: "Франція",
  gf: "Французька Гвіана",
  pf: "Французька Полінезія",
  tf: "Французькі Південні території",
  hr: "Хорватія",
  cf: "Центральноафриканська Республіка",
  td: "Чад",
  me: "Чорногорія",
  cz: "Чехія",
  cl: "Чилі",
  ch: "Швейцарія",
  se: "Швеція",
  sj: "Шпіцберген і Ян-Маєн",
  lk: "Шрі-Ланка",
  ec: "Еквадор",
  gq: "Екваторіальна Гвінея",
  er: "Еритрея",
  sz: "Есватіні",
  ee: "Естонія",
  et: "Ефіопія",
  gs: "Південна Джорджія і Південні Сендвічеві острови",
  za: "Південна Африка",
  ss: "Південний Судан",
  jm: "Ямайка",
  jp: "Японія",
};
