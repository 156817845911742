import { AxiosError } from "axios";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../api/authApi";
import { resetPasswordRequest } from "../api/types/auth";
import { serverError } from "../types/serverError";
import AggregateErrorMessage from "../utils/components/AggregateErrorMessage";
import { handleServerError } from "../utils/methods/hangleServerError";
import AuthMessage from "./AuthMessage";
import { ButtonLoader } from "utils/components";

interface PasswordResetValues {
  email: string;
}

const ForgotPasswordPage = () => {
  const { t } = useTranslation("auth");
  const { t: tCommon } = useTranslation("common");

  const [serverError, setServerError] = useState<string | null>(null);
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);

  const handleSubmitForm = async (
    values: PasswordResetValues,
    { setErrors }: FormikHelpers<PasswordResetValues>
  ) => {
    setServerError(null);

    const body: resetPasswordRequest = {
      email: values.email,
    };

    try {
      await resetPassword(body);

      setPasswordResetEmailSent(true);
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      setServerError(error?.message || t("errorResettingPassword"));
      setErrors(handleServerError(error || {}));
    }
  };

  if (passwordResetEmailSent) {
    return (
      <AuthMessage
        title={t("resetPasswordEmailSuccessfullySent")}
        message={t("pleaseCheckEmailForInstructions")}
      />
    );
  }

  const PasswordResetSchema = Yup.object().shape({
    email: Yup.string()
      .email(tCommon("validation_email") as string)
      .required(tCommon("validation_fieldIsRequired") as string),
  });

  return (
    <div className="login-page">
      <Link to="/" className="logo">
        <img src="/img/logo.png" alt="" />
      </Link>

      {serverError && <p className="error error-auth">{serverError}</p>}

      <Formik
        initialValues={{ email: "" }}
        validationSchema={PasswordResetSchema}
        onSubmit={handleSubmitForm}
      >
        {(props: FormikProps<PasswordResetValues>) => {
          const { errors, values, handleBlur, handleChange, isSubmitting } =
            props;
          const disabled = !!errors.email || isSubmitting;

          return (
            <Form
              className="form-login form-style box"
              autoComplete="off"
              noValidate={true}
            >
              <h1 className="title login-title">{t("forgotYourPassword")}</h1>
              <div className="login-divider login-divider--vertical-margin" />
              <AggregateErrorMessage
                component="p"
                className="error error-auth"
              />

              <label className="name label">
                <span className="label-title">{t("yourEmailAddress")}</span>
                <input
                  type="email"
                  name="email"
                  className="form-input"
                  placeholder="example@mail.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="p"
                  name="email"
                  className="error error-auth"
                />
              </label>

              <div className="login-group-btn login-group-btn--margin-top">
                <Link to="/login" className="btn-regular">
                  {t("backToLogin")}
                </Link>
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={disabled}
                >
                  {isSubmitting ? <ButtonLoader /> : tCommon("form_next")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForgotPasswordPage;
