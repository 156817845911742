import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { base_url } from "../../appConsts";

import {
  TimeOffRequestResponse,
  GetTimeOffRequestsManagementRequest,
} from "api/types/timeOff/timeOffRequestsTypes";

export const loadTimeOffRequests = (
  body: GetTimeOffRequestsManagementRequest
): AxiosPromise<TimeOffRequestResponse> =>
  axiosInstance.post(`${base_url}/api/time-off/requests/management/list`, body);

export const approveRequest = (
  requestId: number
): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/time-off/requests/${requestId}/approve`);

export const rejectRequest = (
  requestId: number
): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/time-off/requests/${requestId}/reject`);
