import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  loadAllNotifications,
  loadAllUnreadNotifications,
} from "api/notificationsApi";
import {
  NotificationDto,
  NotificationSourceType,
} from "api/types/notifications";
import { AxiosError } from "axios";
import { toastNotifications } from "pages/layout/notifications/toastNotifications";
import { NavigateFunction } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import { RootState, store } from "store";
import {
  setAllNotifications,
  setErrorAllNotifications,
  setLoadingAllNotifications,
  setUnreadNotificationsTotalCountAndId,
} from "store/reducers/notificationsReducer";
import { getErrorMessage } from "utils/methods";
import { toastError } from "utils/toasts";
import { refreshMerchCategoryList } from "./merchStoreThunks";
import { refreshEmployeeMerchOrdersList } from "./employeePage/employeeDeliveryInfoThunks";
import { refreshCoinsCount } from "./authThunks";
import {
  refreshEmployeeProfileMessages,
  refreshFeedbackRequests,
} from "./employeePage/employeeAchievementsThunks";
import {
  viewActivityChartPermissions,
  viewCoinsBalancePermissions,
  viewPraisedCoinsPermissions,
  viewQuestBacklogPermissions,
} from "../../pages/employeeCard/player-profile/hooks/usePlayerProfileViewsPermissions";
import { refreshPlayerProfilePageData } from "./employeePage/playerProfileThunks";
import { refreshNewsBadgeMessages } from "./newsThunks";
import { Features } from "../../utils/consts";

// this thunk used for:
// 1. initial get first page of notifications if isNotificationsModal is open (in api call we use increased currentPage in store (0) by 1),
// 2. for fetch next pages by InfiniteScroll (in api call we use increased currentPage in store by 1)
// 3. for update notifications in NC every 10sec if isNotificationsModal is open
// (for example, user have scrolled to 2nd page - 40 items, so we refresh this 40 items)
export const getAllNotifications =
  (isUpdated = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    const currentNotificationsPage =
      store.getState().notifications.allNotifications.page;
    const nextPage = isUpdated
      ? currentNotificationsPage
      : currentNotificationsPage + 1;

    if (!isUpdated) {
      dispatch(setLoadingAllNotifications());
    }

    try {
      const response = await loadAllNotifications(nextPage);
      const notificationsFromApi = response.data.items;
      const totalNotificationsCount = response.data.totalItemCount;

      const hasMoreNotifications =
        notificationsFromApi.length !== 0 &&
        notificationsFromApi.length < totalNotificationsCount;

      dispatch(
        setAllNotifications({
          notifications: notificationsFromApi,
          hasMoreData: hasMoreNotifications,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorAllNotifications(errorMessage));
    }
  };

export const getTotalCountAndIdUnreadNotifications =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await loadAllUnreadNotifications();

      const idSet = new Set<number>();
      response.data.items.forEach((notification: NotificationDto) => {
        idSet.add(notification.id);
      });

      dispatch(
        setUnreadNotificationsTotalCountAndId({
          totalCount: response.data.totalItemCount,
          idSet: Array.from(idSet),
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      toastError(errorMessage);
    }
  };

const permissionsCheck = (
  requiredPermissions: string[],
  profilePermissions: string[]
) => {
  return profilePermissions.some((profilePermission) =>
    requiredPermissions.includes(profilePermission)
  );
};

const updatePagesContentAfterNewNotification =
  (notification: NotificationDto) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    const currentUserId = store.getState().auth.profile?.id;
    const profilePermissions = store.getState().auth.profile?.permissions || [];
    const isGamification = store
      .getState()
      .auth.profile?.companyAvailableFeatures.includes(Features.Gamification);

    if (!currentUserId) return;

    const playerProfileViewsPermissions = {
      isViewCoinsBalance: permissionsCheck(
        viewCoinsBalancePermissions,
        profilePermissions
      ),
      isViewPraisedCoins: permissionsCheck(
        viewPraisedCoinsPermissions,
        profilePermissions
      ),
      isViewActivityChart: permissionsCheck(
        viewActivityChartPermissions,
        profilePermissions
      ),
      isViewQuestBacklog: permissionsCheck(
        viewQuestBacklogPermissions,
        profilePermissions
      ),
    };

    switch (notification.sourceType) {
      case NotificationSourceType.MerchItemCreated:
        await dispatch(refreshMerchCategoryList());
        return;

      case NotificationSourceType.MerchDeliveryInProgress:
      case NotificationSourceType.MerchDeliveryDelivered:
        await dispatch(refreshEmployeeMerchOrdersList(currentUserId));
        return;

      case NotificationSourceType.MerchDeliveryCancelled: {
        await dispatch(refreshEmployeeMerchOrdersList(currentUserId));
        // after cancel merchOrder coins return, so we must update coins in Layout and on Player Profile page
        await dispatch(refreshCoinsCount());
        await dispatch(
          refreshPlayerProfilePageData(
            currentUserId,
            playerProfileViewsPermissions
          )
        );
        return;
      }

      case NotificationSourceType.FeedbackRequestReceived:
        await dispatch(refreshFeedbackRequests());
        return;

      case NotificationSourceType.AchievementEventRejected:
        await dispatch(
          refreshPlayerProfilePageData(
            currentUserId,
            playerProfileViewsPermissions
          )
        );
        return;

      case NotificationSourceType.AchievementEventApproved:
      case NotificationSourceType.BadgeMessageReceived: {
        if (isGamification) {
          await dispatch(refreshCoinsCount());
          await dispatch(
            refreshPlayerProfilePageData(
              currentUserId,
              playerProfileViewsPermissions
            )
          );
        }
        await dispatch(refreshEmployeeProfileMessages(currentUserId));
        await dispatch(refreshNewsBadgeMessages());
        return;
      }

      case NotificationSourceType.BirthdayTeamMember:
      case NotificationSourceType.AnniversaryTeamMember:
        await dispatch(refreshNewsBadgeMessages());
        return;

      default:
        return;
    }
  };

export const getLatestUnreadNotifications =
  (
    setIsNotificationsModalOpen: (val: boolean) => void,
    navigate: NavigateFunction,
    appDispatch: ThunkDispatch<RootState, {}, AnyAction>
  ) =>
  async (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
    try {
      const response = await loadAllUnreadNotifications();

      const currentState = getState();
      const newNotifications = response.data.items;
      const currentUniqueIds = currentState.notifications.uniqueIdNotifications;
      const uniqueIdNotifications = new Set<number>(currentUniqueIds);
      const newTotalCount = response.data.totalItemCount;
      const newNotificationsToDisplay = newNotifications.filter(
        (notification) => !uniqueIdNotifications.has(notification.id)
      );

      if (
        currentState.notifications.unreadNotificationsTotalCount > newTotalCount
      ) {
        dispatch(
          setUnreadNotificationsTotalCountAndId({
            totalCount: newTotalCount,
            idSet: currentUniqueIds,
          })
        );
      }
      if (newNotificationsToDisplay.length > 0) {
        const topNotification = newNotificationsToDisplay[0];
        const currentUserId = currentState.auth.user?.id;
        toastNotifications(
          topNotification,
          newNotificationsToDisplay.length,
          setIsNotificationsModalOpen,
          navigate,
          appDispatch,
          currentUserId
        );

        newNotificationsToDisplay.forEach((notification) => {
          uniqueIdNotifications.add(notification.id);
        });

        dispatch(
          setUnreadNotificationsTotalCountAndId({
            totalCount: newTotalCount,
            idSet: Array.from(uniqueIdNotifications),
          })
        );

        // if for example we got 2 badge message notifications during 10sec we update all related pages only once
        const uniqueNotificationsTypes = new Set<NotificationSourceType>();
        const uniqueNotificationsByTypes = newNotificationsToDisplay.filter(
          (notification) => {
            if (!uniqueNotificationsTypes.has(notification.sourceType)) {
              uniqueNotificationsTypes.add(notification.sourceType);
              return true;
            }

            return false;
          }
        );

        for await (const notification of uniqueNotificationsByTypes) {
          await dispatch(updatePagesContentAfterNewNotification(notification));
        }
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      toastError(errorMessage);
    }
  };
