import uk from "date-fns/locale/uk";

const Calendar = {
  sunday: "Нд",
  monday: "Пн",
  tuesday: "Вт",
  wednesday: "Ср",
  thursday: "Чт",
  friday: "Пт",
  saturday: "Сб",
  ok: "ОК",
  today: "Сьогодні",
  yesterday: "Вчора",
  hours: "Годин",
  minutes: "Хвилин",
  seconds: "Секунд",
  formattedMonthPattern: "MMM, yyyy",
  formattedDayPattern: "MMM dd, yyyy",
  dateLocale: uk as any,
};

const config = {
  common: {
    loading: "Завантаження...",
    emptyMessage: "Дані не знайдені",
    remove: "Видалити",
    clear: "Очистити",
  },
  Plaintext: {
    unfilled: "незаповнено",
    notSelected: "Не обрано",
    notUploaded: "Не завантажено",
  },
  Pagination: {
    more: "Більше",
    prev: "Попередня",
    next: "Наступна",
    first: "Перша",
    last: "Остання",
    limit: "{0} / сторінок",
    total: "всього: {0}",
    skip: "До{0}",
  },
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "Останні 7 днів",
  },
  Picker: {
    noResultsText: "Результати не знайдено",
    placeholder: "Обрати",
    searchPlaceholder: "Пошук",
    checkAll: "Все",
  },
  InputPicker: {
    newItem: "Новий",
    createOption: 'Створити опцію "{0}"',
  },
  Uploader: {
    inited: "Початок",
    progress: "Відвантаження",
    error: "Помилка",
    complete: "Завершено",
    emptyFile: "Порожній",
    upload: "Завантажити",
    removeFile: "Видалити файл",
  },
  CloseButton: {
    closeLabel: "несправність",
  },
  Breadcrumb: {
    expandText: "Показати шлях",
  },
  Toggle: {
    on: "Вкл",
    off: "Викл",
  },
};

export default config;
