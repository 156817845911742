import { AxiosError } from "axios";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonLoader, Icon } from "utils/components";
import * as Yup from "yup";
import { resetPasswordWithCode } from "../api/authApi";
import { resetPasswordWithCodeRequest } from "../api/types/auth";
import { serverError } from "../types/serverError";
import AggregateErrorMessage from "../utils/components/AggregateErrorMessage";
import { handleServerError } from "../utils/methods/hangleServerError";
import AuthMessage from "./AuthMessage";
import { toastSuccess } from "utils/toasts";

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  passwordResetCode: string;
}

const ResetPasswordWithCodePage: FC = () => {
  const { t } = useTranslation("auth");
  const { t: tCommon } = useTranslation("common");

  const navigate = useNavigate();
  const location = useLocation();
  const [serverError, setServerError] = useState<string | null>(null);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const queryParams = new URLSearchParams(location.search);
  const passwordResetCode = queryParams.get("code");

  if (!passwordResetCode) {
    return (
      <AuthMessage
        title={t("invalidCode")}
        message={t("pleaseContactEsteemeSupportToResolveIssue")}
      />
    );
  }

  const initialValues: FormValues = {
    email: "",
    password: "",
    confirmPassword: "",
    passwordResetCode: passwordResetCode,
  };

  const handleSubmitForm = async (
    values: FormValues,
    { setErrors }: FormikHelpers<FormValues>
  ) => {
    setServerError(null);

    const body: resetPasswordWithCodeRequest = {
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      code: values.passwordResetCode,
    };

    try {
      await resetPasswordWithCode(body);

      toastSuccess(t("passwordResetCompletedSuccessfully"));

      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 1500);
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      setServerError(error?.message || t("errorResettingPassword"));
      setErrors(handleServerError(error || {}));
    }
  };

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().required(t("validation_login_required") as string),
    password: Yup.string()
      .required(t("validation_password_required") as string)
      .min(6, t("validation_password_minlength", { length: 6 }) as string)
      .matches(/^(?=.*[a-z])/, t("validation_password_lowercase") as string)
      .matches(/^(?=.*[A-Z])/, t("validation_password_uppercase") as string)
      .matches(/^(?=.*[0-9])/, t("validation_password_digits") as string)
      .matches(
        /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
        t("validation_password_specialCharacter") as string
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("validation_confirmPassword") as string
    ),
    passwordResetCode: Yup.string().required(
      tCommon("validation_fieldIsRequired") as string
    ),
  });

  return (
    <>
      <div className="login-page">
        <Link to="/" className="logo">
          <img src={"/img/logo.png"} alt="logo" />
        </Link>

        {serverError && <p className="error error-auth">{serverError}</p>}

        <Formik
          initialValues={initialValues}
          validationSchema={ResetPasswordSchema}
          onSubmit={handleSubmitForm}
        >
          {(props: FormikProps<FormValues>) => {
            const { errors, values, handleBlur, handleChange, isSubmitting } =
              props;
            const disabled =
              !!errors.email ||
              !!errors.password ||
              !!errors.confirmPassword ||
              !!errors.passwordResetCode ||
              isSubmitting;
            return (
              <Form
                className="form-login form-style box"
                autoComplete="off"
                noValidate={true}
              >
                <h1 className="title login-title">{t("resetYourPassword")}</h1>
                <div className="login-divider login-divider--vertical-margin" />
                <AggregateErrorMessage
                  component="p"
                  className="error error-auth"
                />

                <label className="name label">
                  <span className="label-title">{t("yourEmail")}</span>
                  <input
                    type="text"
                    name="email"
                    className="form-input"
                    placeholder="example@mail.com"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="p"
                    name="email"
                    className="error error-auth"
                  />
                </label>

                <label className="name label">
                  <span className="label-title">{t("password")}</span>
                  <div className="login-icon">
                    <div
                      onClick={(evt) => {
                        setShowPassword(!showPassword);
                        evt.preventDefault();
                      }}
                    >
                      <Icon
                        svgClass="ic-eye"
                        href={`#${showPassword ? "wiews-filled" : "wiews"}`}
                      />
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-input"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("password") || ""}
                    />
                  </div>
                  <ErrorMessage
                    component="p"
                    name="password"
                    className="error error-auth"
                  />
                </label>

                <label className="name label">
                  <span className="label-title">{t("confirmPassword")}</span>
                  <div className="login-icon">
                    <div
                      onClick={(evt) => {
                        setShowConfirmPassword(!showConfirmPassword);
                        evt.preventDefault();
                      }}
                    >
                      <Icon
                        svgClass="ic-eye"
                        href={`#${
                          showConfirmPassword ? "wiews-filled" : "wiews"
                        }`}
                      />
                    </div>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-input"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("confirmPassword") || ""}
                    />
                  </div>
                  <ErrorMessage
                    component="p"
                    name="confirmPassword"
                    className="error error-auth"
                  />
                </label>

                <input
                  type="hidden"
                  name="passwordResetCode"
                  value={values.passwordResetCode}
                  onChange={handleChange}
                />

                <div className="login-group-btn login-group-btn--margin-top">
                  <Link to="/login" className="btn-tertiary">
                    {t("backToLogin")}
                  </Link>
                  <button
                    type="submit"
                    className="btn-primary"
                    disabled={disabled}
                  >
                    {isSubmitting ? <ButtonLoader /> : t("resetPassword")}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ResetPasswordWithCodePage;
