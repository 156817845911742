import classNames from "classnames";
import { FC, useEffect } from "react";
import IPropsWithChildren from "../../utils/types/props-with-children";
import { Icon } from "utils/components";

type Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  title: string;
  additionalClassName: string;
} & IPropsWithChildren;

const LayoutRightPanel: FC<Props> = ({
  title,
  children,
  isModalOpen,
  onCloseModal,
  additionalClassName,
}) => {
  useEffect(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;

    if (isModalOpen) {
      document.body.classList.add("preventScroll");
      //add right margin to keep page from jumping right
      document.body.style.paddingRight = `${scrollBarCompensation}px`;
    } else {
      document.body.classList.remove("preventScroll");
      document.body.style.paddingRight = "0px";
    }
  }, [isModalOpen]);

  return (
    <>
      <div className={classNames("popup-overlay", { opened: isModalOpen })} />
      <div
        className={classNames(`popup ${additionalClassName}`, {
          opened: isModalOpen,
        })}
      >
        <div className="d-flex popup-head">
          <h5 className="main-title">{title}</h5>
          <button
            className="btn-tertiary actionBtn-tertiary closeBtn"
            onClick={onCloseModal}
          >
            <Icon href="#close-popup" svgClass="ic-close-popup" />
          </button>
        </div>

        {children}
      </div>
    </>
  );
};

export default LayoutRightPanel;
