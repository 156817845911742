import { Dispatch } from "redux";
import {
  resetEmployeeMerchOrders,
  setEmployeeMerchOrders,
  setEmployeeReceivedItems,
  setErrorEmployeeMerchOrders,
  setErrorEmployeeReceivedItems,
  setLoadingEmployeeMerchOrders,
  setLoadingEmployeeReceivedItems,
} from "../../reducers/employeePageReducers/employeeDeliveryInfoReducer";
import { store } from "../../index";
import {
  loadEmployeeMerchOrders,
  loadEmployeeReceivedItems,
} from "../../../api/employeePageApi";
import {
  countItemsPerPage,
  countReceivedItemsPerPage,
} from "../../../appConsts";
import { getErrorMessage } from "../../../utils/methods";
import { AxiosError } from "axios";
import { getEmployeePageData } from "./employeePageThunks";

// info for Merch delivery tab
export const getEmployeeOrders =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingEmployeeMerchOrders());

    try {
      const { ordersList, sorting } =
        store.getState().employeeDeliveryInfo.employeeMerchOrders;
      const nextPage = ordersList.page + 1;

      const response = await loadEmployeeMerchOrders(
        userId,
        nextPage,
        sorting.sortBy,
        sorting.sortDirection
      );
      const ordersListFromApi = response.data.items;
      const noMoreData =
        ordersListFromApi.length === 0 ||
        ordersListFromApi.length < countItemsPerPage;

      dispatch(
        setEmployeeMerchOrders({
          ordersList: ordersListFromApi,
          hasMoreData: !noMoreData,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorEmployeeMerchOrders(errorMessage));
    }
  };

export const refreshEmployeeMerchOrdersList =
  (userId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetEmployeeMerchOrders());
    dispatch(getEmployeeOrders(userId));
  };

export const getEmployeeReceivedItems =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingEmployeeReceivedItems());

    try {
      const { employeeReceivedItems } = store.getState().employeeDeliveryInfo;
      const nextPage = employeeReceivedItems.page + 1;

      const response = await loadEmployeeReceivedItems(userId, nextPage);
      const receivedItemsListFromApi = response.data.items.map(
        (order) => order.merchItem
      );
      const noMoreData =
        receivedItemsListFromApi.length === 0 ||
        receivedItemsListFromApi.length < countReceivedItemsPerPage;

      dispatch(
        setEmployeeReceivedItems({
          receivedItemsFromApi: receivedItemsListFromApi,
          hasMoreData: !noMoreData,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorEmployeeReceivedItems(errorMessage));
    }
  };

export const getMerchDeliveryInfoPageData =
  (userId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    await Promise.all([
      dispatch(getEmployeePageData(userId)),
      dispatch(getEmployeeOrders(userId)),
      dispatch(getEmployeeReceivedItems(userId)),
    ]);
  };
