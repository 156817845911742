export const config = {
  authentication: {
    isShowLoginForm: process.env.REACT_APP_IS_SHOW_LOGIN_FORM === "true",
    isShowGoogleLogin: process.env.REACT_APP_IS_SHOW_GOOGLE_LOGIN === "true",
    isShowAzureLogin: process.env.REACT_APP_IS_SHOW_AZURE_LOGIN === "true",
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    azureClientId: process.env.REACT_APP_AZURE_CLIENT_ID,
  },
  localizationLogDebugEnabled: process.env.REACT_APP_LOCALIZATION_LOG_DEBUG === "true",
  hotjarTrackingEnabled: process.env.REACT_APP_HOTJAR_TRACKING === "true",
  hotjarSiteId: process.env.REACT_APP_HOTJAR_SITE_ID,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  defaultNewsTab: process.env.REACT_APP_DEFAULT_NEWS_TAB,
  availableDeliveryCountries: process.env.REACT_APP_AVAILABLE_DELIVERY_COUNTRIES || "",
};
