import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { resetPersonInfoPageState } from "store/reducers/employeePageReducers/employeePersonalInfoReducer";
import { getEmployeePageData } from "store/thunks/employeePage/employeePageThunks";
import {
  MerchDeliveryInfoValues,
  ReceivePresents,
} from "./merchDeliveryInfoBlock/MerchDeliveryInfoForm";
import MerchDeliveryInfoBlock from "./merchDeliveryInfoBlock/MerchDeliveryInfoBlock";

import { editMerchDeliveryInfo } from "api/employeePageApi";
import { UserCardMerchDeliveryInfoDto } from "api/types/employeePageTypes";
import EmployeeMerchOrdersTable from "./EmployeeMerchOrdersTable";
import { useIsCurrentUser } from "../../../utils/hooks/useIsCurrentUser";
import {
  useCompanyFeatureCheck,
  usePermissionCheck,
} from "../../../utils/hooks";
import { AppPermissions, Features } from "../../../utils/consts";
import EmployeeReceivedItems from "./EmployeeReceivedItems";
import AccessDenied from "../../../auth/AccessDenied";
import { getMerchDeliveryInfoPageData } from "../../../store/thunks/employeePage/employeeDeliveryInfoThunks";
import { resetEmployeeDeliveryInfoState } from "../../../store/reducers/employeePageReducers/employeeDeliveryInfoReducer";
import { getReceiverCountry } from "utils/components";

const MerchDeliveryInfoPage: FC = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { data } = useAppSelector((state) => state.employeePage.employeeData);
  const [editModeInfo, setEditModeInfo] = useState<boolean>(false);

  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const isCurrentUser = useIsCurrentUser();
  const isUserViewMerchOrders =
    usePermissionCheck([AppPermissions.MerchOrders_ViewAndManageOrders]) ||
    isCurrentUser;
  const isUserViewAndEditMerchDeliveryInfo = usePermissionCheck([
    AppPermissions.EmployeeCard_ViewAndEditMerchDeliveryInfo,
  ]);
  const isAccessToDeliverylInfo =
    isGamificationAvailable &&
    (isCurrentUser ||
      isUserViewAndEditMerchDeliveryInfo ||
      isUserViewMerchOrders);

  const initialFormDeliveryInfoValues: MerchDeliveryInfoValues = {
    receiveCorporatePresents: data?.merchDeliveryInfo?.receiveCorporatePresents
      ? ReceivePresents.yes
      : ReceivePresents.no,
    receiverFirstName: data?.merchDeliveryInfo?.receiverFirstName || "",
    receiverFamilyName: data?.merchDeliveryInfo?.receiverFamilyName || "",
    receiverPhone: data?.merchDeliveryInfo?.receiverPhone || "",
    receiverCountry: getReceiverCountry(
      data?.merchDeliveryInfo?.receiverCountry
    ),
    receiverRegion: data?.merchDeliveryInfo?.receiverRegion || "",
    receiverCity: data?.merchDeliveryInfo?.receiverCity || "",
    zipCode: data?.merchDeliveryInfo?.zipCode || "",
    deliveryPostalDepartment:
      data?.merchDeliveryInfo?.deliveryPostalDepartment || "",
    deliveryAddress: data?.merchDeliveryInfo?.deliveryAddress || "",
    deliveryTopSize: data?.merchDeliveryInfo?.deliveryTopSize || "",
    deliveryFootSize: data?.merchDeliveryInfo?.deliveryFootSize || "",
    deliveryBottomSize: data?.merchDeliveryInfo?.deliveryBottomSize || "",
  };

  const handleSubmitMerchDeliveryInfo = async (
    userId: number,
    body: UserCardMerchDeliveryInfoDto
  ) => {
    const response = await editMerchDeliveryInfo(userId, body);

    return response;
  };

  useEffect(() => {
    if (userId) {
      if (isUserViewMerchOrders) {
        dispatch(getMerchDeliveryInfoPageData(+userId));
      } else {
        dispatch(getEmployeePageData(+userId));
      }
    } else {
      console.error("Unknown user id");
      navigate("/news");
    }

    return () => {
      dispatch(resetPersonInfoPageState());
      dispatch(resetEmployeeDeliveryInfoState());
    };
  }, [userId, isUserViewMerchOrders, dispatch, navigate]);

  if (!isAccessToDeliverylInfo) {
    return <AccessDenied />;
  }

  return (
    <div className="merchDeliveryInfoPage">
      {/*This Layout is rendered on Desktop from 1500px*/}
      <div className="merchDeliveryInfoDesktop">
        <div>
          <MerchDeliveryInfoBlock
            handleSubmitMerchDeliveryInfo={handleSubmitMerchDeliveryInfo}
            initialFormValues={initialFormDeliveryInfoValues}
            editMode={editModeInfo}
            setEditMode={setEditModeInfo}
          />
          {isUserViewMerchOrders && <EmployeeMerchOrdersTable />}
        </div>

        <div>{isUserViewMerchOrders && <EmployeeReceivedItems />}</div>
      </div>

      {/*This Layout is rendered before 1500px*/}
      <div className="merchDeliveryInfoTablet">
        <MerchDeliveryInfoBlock
          handleSubmitMerchDeliveryInfo={handleSubmitMerchDeliveryInfo}
          initialFormValues={initialFormDeliveryInfoValues}
          editMode={editModeInfo}
          setEditMode={setEditModeInfo}
        />

        {isUserViewMerchOrders && (
          <>
            <EmployeeReceivedItems />
            <EmployeeMerchOrdersTable />
          </>
        )}
      </div>
    </div>
  );
};

export default MerchDeliveryInfoPage;
