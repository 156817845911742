import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultListState } from "../../appConsts";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { OrgStructureData, OrgStructureState } from "../types";
import { logoutUserAction } from "./authReducer";

const initialState: OrgStructureState = {
  orgStructureData: defaultListState,
};

const orgStructureReducer = createSlice({
  name: "orgStructure",
  initialState,
  reducers: {
    setLoadingOrgStructureData: (state) => ({
      ...state,
      orgStructureData: setStateLoading(state.orgStructureData),
    }),
    setOrgStructureData: (state, action: PayloadAction<OrgStructureData>) => ({
      ...state,
      orgStructureData: setStateData(state.orgStructureData, action.payload),
    }),
    setOrgStructureError: (state, action: PayloadAction<string>) => ({
      ...state,
      orgStructureData: setStateError(state.orgStructureData, action.payload),
    }),
    resetOrgStructureData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default orgStructureReducer.reducer;

export const {
  setLoadingOrgStructureData,
  setOrgStructureData,
  setOrgStructureError,
  resetOrgStructureData,
} = orgStructureReducer.actions;
