import { useAppSelector } from "../../store";

export const useCompanyIntegrationsCheck = (
  integrations: string[]
): boolean => {
  const companyIntegrations =
    useAppSelector((state) => state.auth.profile?.companyActiveIntegrations) ||
    [];

  return companyIntegrations.some((integration) =>
    integrations.includes(integration)
  );
};
