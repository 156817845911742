import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import LayoutRightPanel from "../../../layout/LayoutRightPanel";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  getGroupsByUser,
  getGroupsWithoutUser,
} from "store/thunks/groupManagementThunks";
import { ButtonLoader, Loader, LoaderSize } from "utils/components";
import { getCropTitle } from "utils/methods/getCropTitle";
import { resetGroupsWithoutUserData } from "store/reducers/groupManagementReducer";
import { AxiosError } from "axios";
import { toastError, toastSuccess } from "utils/toasts";
import { serverError } from "types/serverError";
import { addUserToGroups } from "api/groupManagementApi";

type Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

const AddPersonToGroupModal: FC<Props> = ({ isModalOpen, onCloseModal }) => {
  const { userId } = useParams();

  const { t } = useTranslation("employeePage");
  const { t: tGroup } = useTranslation("groupManagement");
  const { t: tCommon } = useTranslation("common");

  const dispatch = useAppDispatch();
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.groupManagement.groupsWithoutUserData
  );
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const fetchGroupsWithoutUser = useCallback(() => {
    if (!userId) return;
    dispatch(getGroupsWithoutUser(+userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (isModalOpen) {
      fetchGroupsWithoutUser();
    }

    return () => {
      dispatch(resetGroupsWithoutUserData());
      setSelectedGroupIds([]);
    };
  }, [isModalOpen, dispatch, fetchGroupsWithoutUser]);

  const closeModal = () => {
    onCloseModal();
  };

  const handleSubmit = async () => {
    try {
      if (!userId) return;

      setSubmitting(true);
      await addUserToGroups(+userId, selectedGroupIds);
      dispatch(getGroupsByUser(+userId));
      onCloseModal();
      toastSuccess(tGroup("userAddToGroupSuccessfully"));
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("incorrectData"));
    } finally {
      setSubmitting(false);
    }
  };

  const handleAddUserToGroup = (groupId: number) => {
    setSelectedGroupIds([...selectedGroupIds, groupId]);
  };

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={closeModal}
      title={t("addPersonToGroup")}
      additionalClassName={"popup-feedback"}
    >
      <div className="wrap addPersonGroupModal">
        <div className="addPersonGroupModal__btn-container">
          <Link to="/group-management/create-group" className="btn-primary">
            {tGroup("addGroup")}
          </Link>
        </div>

        {isLoading && !data && (
          <div className="loader-container">
            <Loader size={LoaderSize.Page} />
          </div>
        )}
        {errorMessage && (
          <div className="new-wrapper error">{errorMessage}</div>
        )}
        {data && !data.length && (
          <p className="no-content-message">{tGroup("noUserGroupToDisplay")}</p>
        )}

        <div className="groupsList">
          {data?.map((group) => {
            const { name, description, id, isDynamic } = group;
            const isDisabledBtn = selectedGroupIds.includes(id);

            if (isDynamic) return null;

            return (
              <div key={id} className="d-flex">
                <div className="groupsList__name">
                  <span>{getCropTitle(name, 11)}</span>
                </div>

                <div className="groupsList__desc">
                  <span>{getCropTitle(description, 40)}</span>
                </div>

                <div className="groupsList__btn">
                  <button
                    type="button"
                    className="btn-regular"
                    onClick={() => {
                      handleAddUserToGroup(id);
                    }}
                    disabled={isDisabledBtn}
                  >
                    {t("form_add")}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="popup-footer">
        <div className="group-btn d-flex">
          <button
            className="btn-tertiary main-close"
            type="button"
            onClick={onCloseModal}
          >
            {tCommon("form_cancel")}
          </button>
          <button
            type="submit"
            className="btn-primary"
            disabled={!selectedGroupIds.length}
            onClick={handleSubmit}
          >
            {isSubmitting ? <ButtonLoader /> : tCommon("form_save")}
          </button>
        </div>
      </div>
    </LayoutRightPanel>
  );
};

export default AddPersonToGroupModal;
