import appLocalization from "../../types/appLocalization";
import accountSettings from "./accountSettings.json";
import badgesAnalytics from "./analyticsPage/badgesAnalytics.json";
import activityAnalytics from "./analyticsPage/activityAnalytics.json";
import auth from "./auth.json";
import common from "./common.json";
import companyManagement from "./companyManagement.json";
import integrations from "./integrations.json";
import layout from "./layout.json";
import manageBadges from "./manageBadges.json";
import news from "./news.json";
import systemSettings from "./systemSettings.json";
import employeeAchievements from "./employeePage/employeeAchievements.json";
import orgChart from "./orgChart.json";
import directory from "./directory.json";
import merchStore from "./merchStore.json";
import merchDelivery from "./merchDelivery.json";
import events from "./events.json";
import playerProfile from "./employeePage/playerProfile.json";
import leaderboard from "./leaderboard.json";
import achievementsAnalytics from "./analyticsPage/achievementsAnalytics.json";
import employeePage from "./employeePage/employeePage.json";
import manageAutoEvents from "./manageAutoEvents.json";
import achievementsArchive from "./achievementsArchive.json";
import celebrationModal from "./celebrationModal.json";
import groupManagement from "./groupManagement.json";
import merchItemsManagement from "./merchItemsManagement.json";
import timeOffPolicies from "./timeOff/timeOffPolicies.json";
import timeOffProfile from "./employeePage/timeOffProfile.json";
import timeOffCalendar from "./timeOff/timeOffCalendar.json";
import countries from "./countries.json";

const enLocalization: appLocalization = {
  common,
  accountSettings,
  badgesAnalytics,
  activityAnalytics,
  companyManagement,
  integrations,
  layout,
  manageBadges,
  news,
  systemSettings,
  employeeAchievements,
  auth,
  orgChart,
  directory,
  merchStore,
  merchDelivery,
  merchItemsManagement,
  events,
  playerProfile,
  leaderboard,
  achievementsAnalytics,
  employeePage,
  manageAutoEvents,
  achievementsArchive,
  celebrationModal,
  groupManagement,
  timeOffPolicies,
  timeOffProfile,
  timeOffCalendar,
  countries,
};

export default enLocalization;
