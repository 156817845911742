import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logoutUserAction } from "./authReducer";
import { LeaderboardState } from "../types";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import {
  SearchLeadersMode,
  LeaderDto,
  SearchLeadersFilters,
} from "../../api/types/leaderboardTypes";
import { AchievementSearchDto } from "../../api/types/achievementEventsTypes";

const initialState: LeaderboardState = {
  filterDropdownData: defaultListState,
  leaderboardData: {
    leaderboardList: defaultStateWithInfiniteLoading,
    mode: SearchLeadersMode.allCompany,
    achievementsIds: [],
  },
};

const leaderboardSlice = createSlice({
  initialState,
  name: "leaderboard",
  reducers: {
    // Set filters dropdown data
    setLoadingActiveAchievements: (state) => ({
      ...state,
      filterDropdownData: setStateLoading(state.filterDropdownData),
    }),
    setActiveAchievements: (
      state,
      action: PayloadAction<AchievementSearchDto[]>
    ) => ({
      ...state,
      filterDropdownData: setStateData(
        state.filterDropdownData,
        action.payload
      ),
    }),
    setErrorActiveAchievements: (state, action: PayloadAction<string>) => ({
      ...state,
      filterDropdownData: setStateError(
        state.filterDropdownData,
        action.payload
      ),
    }),
    // Set chosen filters
    setLeadersFilters: (
      state,
      action: PayloadAction<SearchLeadersFilters>
    ) => ({
      ...state,
      leaderboardData: {
        ...state.leaderboardData,
        mode: action.payload.mode,
        achievementsIds: action.payload.achievementsIds,
      },
    }),
    // Leaderboard list
    setLoadingLeaderboardList: (state) => ({
      ...state,
      leaderboardData: {
        ...state.leaderboardData,
        leaderboardList: setInfiniteLoadLoading(
          state.leaderboardData.leaderboardList
        ),
      },
    }),
    setLeaderboardList: (
      state,
      action: PayloadAction<{
        leaderboardList: LeaderDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let leaderboard = state.leaderboardData.leaderboardList.data || [];
      leaderboard = [...leaderboard, ...action.payload.leaderboardList];

      return {
        ...state,
        leaderboardData: {
          ...state.leaderboardData,
          leaderboardList: setInfiniteLoadData(
            state.leaderboardData.leaderboardList,
            leaderboard,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorLeaderboardList: (state, action: PayloadAction<string>) => ({
      ...state,
      leaderboardData: {
        ...state.leaderboardData,
        leaderboardList: setInfiniteLoadStateErrorMessage(
          state.leaderboardData.leaderboardList,
          action.payload
        ),
      },
    }),
    resetLeaderboardList: (state) => ({
      ...state,
      leaderboardData: {
        ...state.leaderboardData,
        leaderboardList: defaultStateWithInfiniteLoading,
      },
    }),
    resetLeaderboardState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default leaderboardSlice.reducer;

export const {
  setLoadingActiveAchievements,
  setActiveAchievements,
  setErrorActiveAchievements,
  setLeadersFilters,
  setLoadingLeaderboardList,
  setLeaderboardList,
  setErrorLeaderboardList,
  resetLeaderboardList,
  resetLeaderboardState,
} = leaderboardSlice.actions;
