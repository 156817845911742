import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import { useIsCurrentUser } from "../../../utils/hooks/useIsCurrentUser";
import { useParams } from "react-router-dom";
import { getEmployeeReceivedItems } from "../../../store/thunks/employeePage/employeeDeliveryInfoThunks";
import { getCoverImageUrl } from "utils/methods/getCoverImageUrl";

const EmployeeReceivedItems = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("employeePage");
  const { t: tCommon } = useTranslation("common");
  const { userId } = useParams();
  const isCurrentUser = useIsCurrentUser();
  const { isLoading, data, hasMoreData, errorMessage } = useAppSelector(
    (state) => state.employeeDeliveryInfo.employeeReceivedItems
  );

  if (!userId) return null;

  return (
    <div className="employeeReceivedItems">
      <h1 className="main-title m-btm">{t("receivedItems")}</h1>
      {/*Initial loader for get first data from api*/}
      {isLoading && data === null && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && data === null && (
        <div className="new-wrapper error">{errorMessage}</div>
      )}
      {data && !data.length && (
        <p className="no-content-message m-top">
          {isCurrentUser
            ? t("emptyUserReceivedItemsData_message")
            : t("emptyEmployeeReceivedItemsData_message")}
        </p>
      )}
      {data && (
        <>
          <div className="receivedItemsWrap">
            {data.map(({ name, documents, coverImageId }, index) => {
              const coverImageUrl = getCoverImageUrl(documents, coverImageId);
              return (
                <div className="box boxItemImg" key={index}>
                  <div className="boxItemImg__imgWrap d-flex">
                    <img src={coverImageUrl} alt={name} />
                  </div>
                </div>
              );
            })}
          </div>
          {/*Loading which works after click See more btn*/}
          {isLoading && (
            <div className="loader-container">
              <Loader size={LoaderSize.Small} />
            </div>
          )}
          {/*Render possible error after click See more btn*/}
          {errorMessage && (
            <div className="new-wrapper error">{errorMessage}</div>
          )}
          {hasMoreData && (
            <button
              type="button"
              onClick={() => dispatch(getEmployeeReceivedItems(+userId))}
              className="btn-regular seeMoreReceivedItemsBtn"
            >
              {tCommon("seeMore_btn")}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeeReceivedItems;
