import { getDateTimeString } from "./getDateTimeString";
import { parseDateFromString } from "./parseDateFromString";
import { isSameDay, isSameMonth } from "date-fns";
import format from "date-fns/format";
import i18next from "i18next";

export const displayTimeOffPeriod = (
  dateFrom: string,
  dateTo: string,
  locale: Locale
) => {
  if (isSameDay(new Date(dateFrom), new Date(dateTo))) {
    return getDateTimeString(dateFrom, "fullDate", locale);
  }

  const parsedDateFrom = parseDateFromString(dateFrom);
  const parsedDateTo = parseDateFromString(dateTo);

  const datesOfSameMonthYear = isSameMonth(parsedDateFrom, parsedDateTo);

  if (!datesOfSameMonthYear) {
    const startDate = getDateTimeString(dateFrom, "fullDate", locale);
    const endDate = getDateTimeString(dateTo, "fullDate", locale);

    return `${startDate} - ${endDate}`;
  }

  const getFormattedDate = (date: Date, formatCode: string) =>
    format(date, formatCode, {
      locale,
    });

  const month = getFormattedDate(parsedDateTo, "MMMM");
  const startDay = getFormattedDate(parsedDateFrom, "dd");
  const endDay = getFormattedDate(parsedDateTo, "dd");
  const year = getFormattedDate(parsedDateFrom, "yyyy");
  const language = i18next.language;

  return language === "en"
    ? `${month} ${startDay} - ${endDay}, ${year}`
    : `${startDay} - ${endDay} ${month}, ${year}`;
};
