import { dateOnly } from "utils/types";
import { ListDto, PageDto, QueryParams } from "./commonTypes";
import { MerchDeliveryInfoDto, OrderStatuses } from "./merchDeliveryTypes";
import { UserOfSearch } from "./usersTypes";
import { Visibility } from "./badgesTypes";
import { MerchItemDto } from "./merchStoreTypes";
import { TimeOffPolicyTypesDeductDays } from "./timeOff/timeOffPolicyTypes";

// Achievements tab

export type Feedback = {
  id: number;
  fromUser: {
    id: number;
    firstName: string | null;
    familyName: string | null;
    avatarImageUrl: string | null;
    position: string | null;
    isOnline: boolean;
  };
};

export type FeedbackResponse = {
  items: Feedback[];
};

export type RequestFeedbackRequest = {
  userId: number;
};

export type RespondToFeedbackRequestRequest = {
  badgeId: number | null;
  message: string;
  visibility: Visibility;
  coins: number | null;
};

export type UserBadgeDto = {
  id: number;
  imageUrl: string;
  count: number;
};

export type ResponseUserBadges = PageDto<UserBadgeDto>;

// Personal info tab
export enum EmploymentTypes {
  fullTimeInOffice = "FullTimeInOffice",
  fullTimeRemote = "FullTimeRemote",
  partTimeInOffice = "PartTimeInOffice",
  partTimeRemote = "PartTimeRemote",
}

export type UserCardBasicInfoDto = {
  firstName: string | null;
  familyName: string | null;
  pronouns: string | null;
  position: string | null;
  departament: string | null;
  manager: UserOfSearch | null;
  companyName: string | null;
  employmentType: EmploymentTypes | null;
  country: string | null;
  location: string | null;
  source: string | null;
};

type UserCardBaseContactsInfoDto = {
  phoneNumber: string | null;
  linkedIn: string | null;
  instagram: string | null;
  facebook: string | null;
  twitch: string | null;
  slack: string | null;
};

export type UserCardContactsInfoDto = UserCardBaseContactsInfoDto & {
  email: string | null;
};

export type UserCardDatesInfoDto = {
  birthDate: dateOnly | null;
  hireDate: dateOnly | null;
  tenure: {
    years: number;
    month: number;
    days: number;
  } | null;
};
export type UpdateContactsRequest = UserCardBaseContactsInfoDto & {
  email: string;
};

export type UserCardDto = {
  id: number;
  employeeId: number | null;
  basicInfo: UserCardBasicInfoDto;
  contacts: UserCardContactsInfoDto;
  dates: UserCardDatesInfoDto;
  merchDeliveryInfo: UserCardMerchDeliveryInfoDto | null;
};

export type EditBasicInfoRequest = {
  firstName: string | null;
  familyName: string | null;
  pronouns: string | null;
  position: string | null;
  departament: string | null;
  managerId: number | null;
  employmentType: EmploymentTypes | null;
  country: string | null;
  location: string | null;
};

export type UpdateSignificantDatesRequest = {
  birthDate: dateOnly;
  hireDate: dateOnly;
};

// Merch delivery tab

export type UserCardMerchDeliveryInfoDto = MerchDeliveryInfoDto & {
  receiveCorporatePresents: boolean;
};

export type HistoryRecordMerchDeliveryInfo = {
  id: number;
  createdDate: string;
  isCurrent: boolean;
  createdBy: UserOfSearch | null;
  merchDeliveryInfo: MerchDeliveryInfoDto;
};

export type HistoryMerchDeliveryInfoResponse =
  ListDto<HistoryRecordMerchDeliveryInfo>;

export type ExchangeHistoryItemDto = {
  id: number;
  orderDate: string;
  deliveredDate: string | null;
  orderStatus: OrderStatuses | string;
  merchItem: MerchItemDto;
  coins: number;
};

export type ExchangeHistoryResponse = ListDto<ExchangeHistoryItemDto>;

export enum EmployeeOrdersSortBy {
  CreatedDate = "CreatedDate",
  DeliveredDate = "DeliveredDate",
  Coins = "Coins",
  Status = "Status",
}

export type UnresponsedFeedbackRequest = {
  id: number;
  senderId: number;
  receiverId: number;
  responseId: number | null;
};

export type UnresponsedFeedbackRequestsResponse = {
  items: UnresponsedFeedbackRequest[];
};

export type UnresponsedFeedbackRequestsQuery = {
  receiverId?: number;
  hasResponse?: boolean;
};

// TimeOff tab

export type TimeOffPolicyTypeBalanceDto = {
  id: number;
  iconKey: string;
  name: string;
  deductDays: TimeOffPolicyTypesDeductDays;
};

export type UserTimeOffBalanceListItemDto = {
  id: number;
  policyType: TimeOffPolicyTypeBalanceDto;
  isNoteMandatory: boolean;
  daysAvailable: number | null;
  daysUsed: number;
};

export type UserTimeOffBalanceListDto = {
  items: UserTimeOffBalanceListItemDto[];
};

export enum TimeOffRequestStatuses {
  onReview = "OnReview",
  approved = "Approved",
  rejected = "Rejected",
}

// user time-off backlog
export type TimeOffRequestListItemDto = {
  id: number;
  policyType: {
    id: number;
    name: string;
  };
  fromDate: string;
  toDate: string;
  daysTotal: number;
  status: TimeOffRequestStatuses | null;
};

export enum UserTimeOffBacklogSortBy {
  policyType = "PolicyType",
  dateFrom = "DateFrom",
  duration = "Duration",
  status = "Status",
}

export type UserTimeOffBacklogParams = QueryParams;

export type UserTimeOffBacklogResponse = PageDto<TimeOffRequestListItemDto>;

export type TimeOffRequestDateRange = {
  fromDate: string;
  toDate: string;
};

export type CreateTimeOffRequestRequest = {
  timeOffBalanceId: number;
  dateRange: TimeOffRequestDateRange;
  daysTotal: number;
  note: string | null;
};

export type UpdateTimeOffRequestRequest = CreateTimeOffRequestRequest;

export type TimeOffPolicyTypeRequestDto = {
  id: number;
  name: string;
  deductDays: TimeOffPolicyTypesDeductDays;
};

export type TimeOffBalanceRequestDto = {
  id: number;
  daysAvailable: number | null;
};

// for get by id request
export type UserTimeOffRequestDto = {
  id: number;
  isNoteMandatory: boolean;
  note: string | null;
  fromDate: string;
  toDate: string;
  createdAt: string;
  balance: TimeOffBalanceRequestDto;
  policyType: TimeOffPolicyTypeRequestDto;
  user: UserOfSearch;
};
