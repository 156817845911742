import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logoutUserAction } from "./authReducer";
import { MerchItemsManagementState } from "../types";
import {
  defaultLastMonthRange,
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  resetInfiniteLoadState,
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import {
  ItemOverTimeDto,
  ItemsOverTimeSortBy,
  MerchCoinStatisticsDto,
  PopularMerchItemDto,
  PopularMerchItemsTabs,
} from "../../api/types/merchItemsManagementTypes";
import { ClientDateRange, SortDirections } from "../../api/types/commonTypes";

const initialState: MerchItemsManagementState = {
  coinsStatisticData: defaultListState,
  popularMerchItems: {
    selectedTab: PopularMerchItemsTabs.all,
    ...defaultStateWithInfiniteLoading,
  },
  itemsOverTime: {
    itemsOverTimeFilters: {
      dateRange: defaultLastMonthRange,
    },
    itemsOverTimeList: defaultStateWithInfiniteLoading,
    itemsOverTimeSorting: {
      sortBy: ItemsOverTimeSortBy.price,
      sortDirection: SortDirections.desc,
    },
  },
};

const merchItemsManagementSlice = createSlice({
  initialState,
  name: "merchItemsManagement",
  reducers: {
    // Coins statistic blocks
    setLoadingCoinsStatisticData: (state) => ({
      ...state,
      coinsStatisticData: setStateLoading(state.coinsStatisticData),
    }),
    setCoinsStatisticData: (
      state,
      action: PayloadAction<MerchCoinStatisticsDto>
    ) => ({
      ...state,
      coinsStatisticData: setStateData(
        state.coinsStatisticData,
        action.payload
      ),
    }),
    setErrorCoinsStatisticData: (state, action: PayloadAction<string>) => ({
      ...state,
      coinsStatisticData: setStateError(
        state.coinsStatisticData,
        action.payload
      ),
    }),
    /* Popular items */
    setChangePopularItemsTab: (
      state,
      action: PayloadAction<PopularMerchItemsTabs>
    ) => ({
      ...state,
      popularMerchItems: {
        ...resetInfiniteLoadState(state.popularMerchItems),
        selectedTab: action.payload,
      },
    }),
    setLoadingPopularMerchItems: (state) => ({
      ...state,
      popularMerchItems: setInfiniteLoadLoading(state.popularMerchItems),
    }),
    setPopularMerchItems: (
      state,
      action: PayloadAction<{
        popularItems: PopularMerchItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let popularMerchItems = state.popularMerchItems.data || [];
      popularMerchItems = [
        ...popularMerchItems,
        ...action.payload.popularItems,
      ];

      return {
        ...state,
        popularMerchItems: setInfiniteLoadData(
          state.popularMerchItems,
          popularMerchItems,
          action.payload.hasMoreData,
          action.payload.page
        ),
      };
    },
    setErrorPopularMerchItems: (state, action: PayloadAction<string>) => ({
      ...state,
      popularMerchItems: setInfiniteLoadStateErrorMessage(
        state.popularMerchItems,
        action.payload
      ),
    }),
    /* Items over time */
    setLoadingItemsOverTime: (state) => ({
      ...state,
      itemsOverTime: {
        ...state.itemsOverTime,
        itemsOverTimeList: setInfiniteLoadLoading(
          state.itemsOverTime.itemsOverTimeList
        ),
      },
    }),
    setItemsOverTime: (
      state,
      action: PayloadAction<{
        itemsOverTime: ItemOverTimeDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let itemsOverTime = state.itemsOverTime.itemsOverTimeList.data || [];
      itemsOverTime = [...itemsOverTime, ...action.payload.itemsOverTime];

      return {
        ...state,
        itemsOverTime: {
          ...state.itemsOverTime,
          itemsOverTimeList: setInfiniteLoadData(
            state.itemsOverTime.itemsOverTimeList,
            itemsOverTime,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorItemsOverTime: (state, action: PayloadAction<string>) => ({
      ...state,
      itemsOverTime: {
        ...state.itemsOverTime,
        itemsOverTimeList: setInfiniteLoadStateErrorMessage(
          state.itemsOverTime.itemsOverTimeList,
          action.payload
        ),
      },
    }),
    setItemsOverTimeListSorting: (
      state,
      action: PayloadAction<[ItemsOverTimeSortBy, SortDirections]>
    ) => ({
      ...state,
      itemsOverTime: {
        ...state.itemsOverTime,
        itemsOverTimeSorting: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    setItemsOverTimeListFilters: (
      state,
      action: PayloadAction<{ dateRange: ClientDateRange }>
    ) => ({
      ...state,
      itemsOverTime: {
        ...state.itemsOverTime,
        itemsOverTimeFilters: {
          ...state.itemsOverTime.itemsOverTimeFilters,
          dateRange: action.payload.dateRange,
        },
      },
    }),
    resetItemsOverTimeList: (state) => ({
      ...state,
      itemsOverTime: {
        ...state.itemsOverTime,
        itemsOverTimeList: defaultStateWithInfiniteLoading,
      },
    }),
    resetMerchItemsManagementState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default merchItemsManagementSlice.reducer;

export const {
  setLoadingCoinsStatisticData,
  setCoinsStatisticData,
  setErrorCoinsStatisticData,
  setChangePopularItemsTab,
  setLoadingPopularMerchItems,
  setPopularMerchItems,
  setErrorPopularMerchItems,
  setLoadingItemsOverTime,
  setItemsOverTime,
  setErrorItemsOverTime,
  setItemsOverTimeListSorting,
  setItemsOverTimeListFilters,
  resetItemsOverTimeList,
  resetMerchItemsManagementState,
} = merchItemsManagementSlice.actions;
