import { IntegrationTypes, UserSources } from "utils/consts";
import { useCompanyIntegrationsCheck } from "./useCompanyIntegrationsCheck";

export const useDisableEditDeleteUser = (userSource?: string | null) => {
  const isMicrosoftEntraIdIntegrationActive = useCompanyIntegrationsCheck([
    IntegrationTypes.MicrosoftEntraId,
  ]);
  const isPeopleForceIntegrationActive = useCompanyIntegrationsCheck([
    IntegrationTypes.PeopleForce,
  ]);

  if (
    userSource === UserSources.MicrosoftEntraId &&
    isMicrosoftEntraIdIntegrationActive
  ) {
    return true;
  }

  if (
    userSource === UserSources.PeopleForce &&
    isPeopleForceIntegrationActive
  ) {
    return true;
  }

  return false;
};
