import { Dispatch } from "redux";
import { getErrorMessage, toDateOnly } from "../../../utils/methods";
import { AxiosError } from "axios";
import { store } from "../../index";
import { countItemsPerPage, defaultLastMonthRange } from "appConsts";
import {
  resetTimeOffRequests,
  setErrorTimeOffRequests,
  setLoadingTimeOffRequests,
  setTimeOffRequests,
} from "store/reducers/timeOff/timeOffRequestsReducer";
import { loadTimeOffRequests } from "api/timeOff/timeOffRequestsApi";
import { AllStatusesFilter } from "api/types/commonTypes";
import { GetTimeOffRequestsManagementRequest } from "api/types/timeOff/timeOffRequestsTypes";

export const getTimeOffRequests =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingTimeOffRequests());
    try {
      const { requestsData, requestsFilters } =
        store.getState().timeOffRequests;
      const { filters, status } = requestsFilters;
      const { positions, departments, locations, managers } = filters;

      const nextPage = requestsData.page + 1;
      const dates = {
        fromDate: toDateOnly(
          new Date(
            filters.dateRange ? filters.dateRange[0] : defaultLastMonthRange[0]
          )
        ) as string,
        toDate: new Date(
          filters.dateRange ? filters.dateRange[1] : defaultLastMonthRange[1]
        ),
      };
      const requestBody: GetTimeOffRequestsManagementRequest = {
        pageNumber: nextPage,
        pageSize: countItemsPerPage,
        filters: {
          dateRange: dates,
          status: status === AllStatusesFilter.all ? null : status,
          positions: positions.length ? (positions as string[]) : null,
          locations: locations.length ? (locations as string[]) : null,
          departments: departments.length ? (departments as string[]) : null,
          managerIds: managers.length ? (managers as number[]) : null,
        },
      };

      const response = await loadTimeOffRequests(requestBody);
      const requestsFromApi = response.data.items;

      const noMoreData =
        requestsFromApi?.length === 0 ||
        requestsFromApi?.length < countItemsPerPage;

      dispatch(
        setTimeOffRequests({
          requests: requestsFromApi,
          hasMoreData: !noMoreData,
          page: nextPage,
        })
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorTimeOffRequests(errorMessage));
    }
  };

export const refreshTimeOffRequests =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetTimeOffRequests());
    dispatch(getTimeOffRequests());
  };
