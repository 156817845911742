import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MerchOrderDto } from "api/types/merchDeliveryTypes";
import { getOrderStatusNameLocalized } from "pages/merch/merchStore/utils/getOrderStatusNameLocalized";
import { getDateTimeString } from "utils/methods";
import useLocale from "localization/useLocale";

type OrderViewInfoProps = {
  order: MerchOrderDto;
};

export const OrderViewInfo: FC<OrderViewInfoProps> = ({ order }) => {
  const { t } = useTranslation("merchDelivery");
  const locale = useLocale();

  return (
    <div className="orderInfo">
      <div className="categoryText__info">
        <p className="categoryText__infoTitle">{t("orderStatus")}</p>
        <p className="categoryText__infoDesc">
          {getOrderStatusNameLocalized(t, order.status)}
        </p>
      </div>
      <div className="categoryText__info">
        <p className="categoryText__infoTitle">{t("orderCreatedDate")}</p>
        <p className="categoryText__infoDesc">
          {getDateTimeString(order.createdDate, "fullDateTime", locale)}
        </p>
      </div>
      {order.deliveryDate && (
        <div className="categoryText__info">
          <p className="categoryText__infoTitle">{t("orderDeliveryDate")}</p>
          <p className="categoryText__infoDesc">
            {getDateTimeString(order.deliveryDate, "fullDate", locale)}
          </p>
        </div>
      )}

      {order.orderDetails && (
        <>
          <h4 className="categoryText__aboutTitle">
            {t("orderDetails_title")}
          </h4>
          <p className="categoryText__desc">{order.orderDetails}</p>
        </>
      )}
    </div>
  );
};
