import { FC, useRef } from "react";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { useTranslation } from "react-i18next";
import { ButtonLoader, DatePickersCustomIcon } from "utils/components";
import CustomRichTextComponent from "utils/components/CustomRichTextComponent";
import { OptionNumberValue } from "utils/types";
import CustomSelect from "utils/components/CustomSelect";
import * as Yup from "yup";
import { DeltaStatic } from "quill";

import { toastError, toastSuccess } from "utils/toasts";
import { AxiosError } from "axios";
import { serverError } from "types/serverError";
import { handleServerError, toDateOnly } from "utils/methods";
import AggregateErrorMessage from "utils/components/AggregateErrorMessage";
import { useAppDispatch, useAppSelector } from "store";
import { useParams } from "react-router-dom";
import RequiredFieldLabel from "utils/components/RequiredFieldLabel";
import { TimeOffRequestValues } from "./AddEditTimeOffRequest";
import { DateRangePicker } from "rsuite";
import { TimeOffRequestDaysList } from "./TimeOffRequestDaysList";
import {
  TimeOffRequestDateRange,
  CreateTimeOffRequestRequest,
} from "api/types/employeePageTypes";
import { getTimeOffProfilePageData } from "store/thunks/employeePage/timeOffProfileThunks";
import { createTimeOffRequest, editTimeOffRequest } from "api/employeePageApi";
import useLocale from "localization/useLocale";
import { getDaysList, getTotalUsedDays } from "../methods/calculateTimeOffDays";
import { resetTimeOffBacklogList } from "store/reducers/employeePageReducers/timeOffProfileReducer";
import { refreshTimeOffRequests } from "store/thunks/timeOff/timeOffRequestsThunks";

const MAX_LENGTH_NOTE_MSG = 300;

type AddEditTimeOffRequestFormProps = {
  initialValues: TimeOffRequestValues;
  onCloseModal: () => void;
  policyTypeOptions: OptionNumberValue[];
  requestId?: number | null;
};

const AddEditTimeOffRequestForm: FC<AddEditTimeOffRequestFormProps> = ({
  initialValues,
  onCloseModal,
  policyTypeOptions,
  requestId,
}) => {
  const dispatch = useAppDispatch();
  const locale = useLocale();
  const { userId } = useParams();

  const { t } = useTranslation(["timeOffProfile", "common", "timeOffPolicies"]);
  const { data } = useAppSelector(
    (state) => state.timeOffProfile.currentBalances
  );
  const { data: requestData } = useAppSelector(
    (state) => state.timeOffProfile.requestData
  );
  const formikRef = useRef<FormikProps<TimeOffRequestValues>>(null);

  const handleClose = () => {
    formikRef?.current?.resetForm();
    formikRef?.current?.setErrors({});
    onCloseModal();
  };

  const findCurrentBalanceInfo = (timeOffBalanceId: number | null) => {
    const currentBalance = data?.find(
      (balance) => balance.id === timeOffBalanceId
    );

    return currentBalance;
  };

  const ValidationTimeOffRequestSchema = () => {
    return Yup.object().shape({
      timeOffBalanceId: Yup.number()
        .nullable()
        .required(t("common:validation_fieldIsRequired") as string),
      dateRange: Yup.array()
        .min(1, t("common:validation_fieldIsRequired") as string)
        .nullable()
        .required(t("common:validation_fieldIsRequired") as string),
      note: Yup.mixed().when("timeOffBalanceId", {
        is: (timeOffBalanceId: number | null) => {
          if (requestData) {
            return requestData.isNoteMandatory;
          }

          if (!timeOffBalanceId) return false;

          return (
            findCurrentBalanceInfo(timeOffBalanceId)?.isNoteMandatory || false
          );
        },
        then: Yup.mixed()
          .required(t("common:validation_fieldIsRequired") as string)
          .test(
            "lengthCheck",
            t("common:lengthDesc_error") as string,
            (value: DeltaStatic) => {
              if (value && value.length) {
                const length = value.length() - 1;
                return length <= MAX_LENGTH_NOTE_MSG;
              }
              return true;
            }
          ),
        otherwise: Yup.mixed(),
      }),
    });
  };

  const requiredNoteLabel = (isNoteMandatory: boolean) => {
    if (!isNoteMandatory) {
      return <span className="label-title">{t("timeOffPolicies:note")}</span>;
    }

    return <RequiredFieldLabel labelTitle={t("timeOffPolicies:note")} />;
  };

  const handleSubmit = async (
    values: TimeOffRequestValues,
    { setErrors }: FormikHelpers<any>
  ) => {
    const { dateRange, note, timeOffBalanceId } = values;
    if (!dateRange || !timeOffBalanceId) return;

    // for correct calculate deduct days, we need info about policy type config,
    // so if we edit request, we take policyType from UserTimeOffRequestDto, if we create request, we take info from current user balances
    const currentPolicyType = requestData
      ? requestData.policyType
      : findCurrentBalanceInfo(timeOffBalanceId)?.policyType;
    const daysList = getDaysList(dateRange, locale, currentPolicyType);
    const totalUsedDays = getTotalUsedDays(daysList);

    const toastMessage = requestId
      ? t("timeOffEditRequest")
      : t("timeOffCreateRequest");
    const toastErrMsg = requestId
      ? t("timeOffErrorEditRequest")
      : t("timeOffErrorCreatingRequest");

    const requestDateRange: TimeOffRequestDateRange = {
      fromDate: toDateOnly(dateRange[0]) || "",
      toDate: toDateOnly(dateRange[1]) || "",
    };

    try {
      const requestBody: CreateTimeOffRequestRequest = {
        dateRange: requestDateRange,
        daysTotal: totalUsedDays,
        note: note ? JSON.stringify(note) : null,
        timeOffBalanceId,
      };

      const creatorUserId = requestData?.user.id;

      if (requestId && creatorUserId) {
        await editTimeOffRequest(creatorUserId, requestId, requestBody);
      } else {
        await createTimeOffRequest(requestBody);
      }

      // this form used on User profile and on Request management page, so on profile we must refresh time off profile data,
      // on Request management content of table with all requests
      if (userId) {
        dispatch(resetTimeOffBacklogList());
        dispatch(getTimeOffProfilePageData(+userId));
      } else {
        dispatch(refreshTimeOffRequests());
      }

      toastSuccess(toastMessage);
      handleClose();
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || toastErrMsg);
      setErrors(handleServerError(error || {}));
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={ValidationTimeOffRequestSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(props: FormikProps<TimeOffRequestValues>) => {
        const { isSubmitting, values, setFieldValue } = props;
        const { dateRange, timeOffBalanceId } = values;
        const isNoteMandatory = requestData
          ? requestData.isNoteMandatory
          : findCurrentBalanceInfo(timeOffBalanceId)?.isNoteMandatory || false;

        return (
          <Form className="form-style popup-panel-form" autoComplete="off">
            <div className="wrap">
              <AggregateErrorMessage component="p" className="error" />

              <label className="label">
                <RequiredFieldLabel labelTitle={t("timeOffType")} />
                <Field
                  name="timeOffBalanceId"
                  component={CustomSelect}
                  isSearchable
                  options={policyTypeOptions}
                  placeholder={t("common:select_placeholder") as string}
                  isDisabled={!!requestId}
                />
                <ErrorMessage
                  name="timeOffBalanceId"
                  component="p"
                  className="error"
                />
              </label>
              <label className="label">
                <RequiredFieldLabel labelTitle={t("timeOffDateRange")} />

                <DateRangePicker
                  name="dateRange"
                  value={dateRange}
                  onChange={(value) => {
                    setFieldValue("dateRange", value);
                  }}
                  className="date-range-picker"
                  size="sm"
                  placeholder={t("timeOffDateRange_placeholder")}
                  character=" — "
                  format="MM/dd/yyyy"
                  caretAs={DatePickersCustomIcon}
                />
                <ErrorMessage
                  name="dateRange"
                  component="p"
                  className="error"
                />
                <ErrorMessage
                  name="dateRange.dateFrom"
                  component="p"
                  className="error"
                />
                <ErrorMessage
                  name="dateRange.dateTo"
                  component="p"
                  className="error"
                />
              </label>

              {dateRange && (
                <TimeOffRequestDaysList
                  timeOffBalanceId={timeOffBalanceId}
                  dateRange={dateRange}
                />
              )}

              <label className="label">
                {requiredNoteLabel(isNoteMandatory)}
                <Field
                  name="note"
                  component={CustomRichTextComponent}
                  placeholder={
                    t("common:message_message_placeholder", {
                      count: MAX_LENGTH_NOTE_MSG,
                    }) as string
                  }
                  maxLength={MAX_LENGTH_NOTE_MSG}
                />
              </label>
            </div>

            <div className="popup-footer">
              <div className="group-btn d-flex">
                <button
                  className="btn-tertiary main-close"
                  type="button"
                  onClick={handleClose}
                >
                  {t("common:form_cancel")}
                </button>
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <ButtonLoader /> : t("common:form_save")}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditTimeOffRequestForm;
