import Hotjar from "@hotjar/browser";
import { hotjar_version } from "appConsts";
import { config } from "utils/config";

export default function setupHotjar(): void {
  const { hotjarTrackingEnabled, hotjarSiteId } = config;

  if (!hotjarTrackingEnabled) {
    console.log("Hotjar disabled");
    return;
  }

  if (!hotjarSiteId) {
    throw new Error("Hotjar site Id environment variable is not present");
  }

  Hotjar.init(+hotjarSiteId, hotjar_version);
}
