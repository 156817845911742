import { FC, ReactNode, useEffect, useState } from "react";
import AccordionItem from "./AccordionItem";

export type AccordionEl = {
  id: number;
  title: string;
  content: ReactNode;
};

type AccordionProps = {
  data: AccordionEl[];
  isModalOpen?: boolean;
};

const Accordion: FC<AccordionProps> = ({ data, isModalOpen }) => {
  const [clicked, setClicked] = useState<null | number>(null);

  useEffect(() => {
    if (!isModalOpen) setClicked(null);
  }, [isModalOpen]);

  const handleToggle = (index: number) => {
    if (clicked === index) {
      setClicked(null);
    } else {
      setClicked(index);
    }
  };

  return (
    <ul className="accordion">
      {data.map((item, index) => (
        <AccordionItem
          key={item.id}
          item={item}
          active={clicked === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </ul>
  );
};

export default Accordion;
