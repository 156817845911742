import classnames from "classnames";
import { FC, ReactNode } from "react";

type UserAvatarProps = {
  isOnline?: boolean;
  avatarImageUrl?: string | null;
  avatarSign: string;
  children?: ReactNode;
  className?: string;
};

const UserAvatar: FC<UserAvatarProps> = ({
  isOnline,
  avatarImageUrl,
  avatarSign,
  children,
  className,
}) => {
  return (
    <div
      className={
        className ||
        classnames("user-avatar activeStatusIndicator", {
          online: isOnline,
        })
      }
    >
      {avatarImageUrl ? (
        <img src={avatarImageUrl} alt="avatar" />
      ) : (
        <span className="avatarSign">{avatarSign}</span>
      )}
      {children}
    </div>
  );
};

export default UserAvatar;
