import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  getProfile,
  loginUserWithAzureToken,
} from "../../store/thunks/authThunks";
import { AxiosError } from "axios";
import { loginWithAzureTokenRequest } from "../../api/types/auth";
import { serverError } from "../../types/serverError";
import AuthMessage from "../AuthMessage";
import Loader from "../../utils/components/Loader";
import { clearStoredPath, getStoredPath, isValidUrl } from "./LocalRedirectUrlStorage";

const AzureTokenPage = () => {
  const { t } = useTranslation("auth");
  const { t: tCommon } = useTranslation("common");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<string | null>();

  let localUrl = getStoredPath();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const getTokensFromApi = async (idToken: string) => {
    const params: loginWithAzureTokenRequest = {
      tokenOpenId: idToken,
    };

    try {
      await dispatch(loginUserWithAzureToken(params));
      await dispatch(getProfile());

      if (!isValidUrl(localUrl)) {
        console.warn("Invalid local redirect URL, redirecting to root instead");
        localUrl = "/news";
      }

      clearStoredPath();
      navigate(localUrl || "/news", { replace: true });
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      setServerError(
        error?.message || t("pleaseContactEsteemeSupportToResolveIssue")
      );
    }
  };

  useEffect(() => {
    if (account) {
      (async () => {
        await instance.initialize();
        instance
          .acquireTokenSilent({
            scopes: ["User.Read"],
            account: account,
          })
          .then((response) => {
            const idToken = response.idToken;

            getTokensFromApi(idToken);
          });
      })();
    }
  }, [instance, account]);

  if (serverError) {
    return (
      <AuthMessage title={tCommon("errorOccurred")} message={serverError} />
    );
  }

  return (
    <div className="page-loader">
      <Loader />
    </div>
  );
};

export default AzureTokenPage;
