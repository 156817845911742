import { FieldProps } from "formik";
import { FC } from "react";
import { DatePicker } from "rsuite";
import { DatePickersCustomIcon } from "./DatePickersCustomIcon";
import { useTranslation } from "react-i18next";
import { Placement } from "../../api/types/commonTypes";

interface CustomDatePickerProps extends FieldProps {
  placement?: Placement;
  disabledDate?: (date: Date) => boolean;
  disabled?: boolean;
  cleanable?: boolean;
}
export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  field,
  form,
  placement,
  disabledDate = () => false,
  disabled = false,
  cleanable = true,
}) => {
  const { t } = useTranslation("common");

  return (
    <DatePicker
      name={field.name}
      value={field.value}
      onChange={(value) => form.setFieldValue(field.name, value)}
      className="date-picker"
      size="lg"
      placeholder={t("datePickerPlaceholder")}
      format="MM/dd/yyyy"
      oneTap
      shouldDisableDate={(date) => disabledDate(date)}
      placement={placement || "bottomEnd"}
      caretAs={disabled ? null : DatePickersCustomIcon}
      disabled={disabled}
      cleanable={disabled ? false : cleanable}
    />
  );
};

export default CustomDatePicker;
