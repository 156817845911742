import { MerchDocumentDto } from "api/types/merchStoreTypes";

export const getCoverImageUrl = (
  documents: MerchDocumentDto[],
  coverImageId: number
): string => {
  const coverImage = documents.find(
    ({ isImage, id }) => isImage && id === coverImageId
  );
  if (coverImage) {
    return coverImage.fileUrl;
  }

  return documents.find(({ isImage }) => isImage)?.fileUrl || "";
};
