import { MerchOrderDto } from "api/types/merchDeliveryTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { getCoverImageUrl } from "utils/methods/getCoverImageUrl";

type OrderViewMerchInfoProps = {
  order: MerchOrderDto;
};

export const OrderViewMerchInfo: FC<OrderViewMerchInfoProps> = ({ order }) => {
  const { t: tMerchStore } = useTranslation("merchStore");
  const { documents, name, category, coverImageId } = order.merchItem;
  const coverImageUrl = getCoverImageUrl(documents, coverImageId);

  return (
    <div className="merchBlock">
      <img src={coverImageUrl} alt="merch" className="merchBlock__img" />
      <span className="merchBlock__name">{name}</span>
      <span className="merchBlock__category">{category}</span>
      <span className="merchBlock__coins">
        {tMerchStore("coins", { count: order.coins })}
      </span>
    </div>
  );
};
