import { AxiosPromise } from "axios";
import {
  EmojiReactionsUsersODataDto,
  UpdateEmojiReactionRequest,
} from "./types/emojiTypes";
import { axiosInstance } from "./instance";
import { base_url } from "../appConsts";

export const loadAchievementReactions = (
  achievementId: number
): AxiosPromise<EmojiReactionsUsersODataDto> =>
  axiosInstance.get(
    `${base_url}/api/emoji/reactions/users/odata?$filter=AchievementId eq ${achievementId}`
  );

export const changeEmojiReaction = (
  body: UpdateEmojiReactionRequest
): AxiosPromise<EmojiReactionsUsersODataDto> =>
  axiosInstance.post(`${base_url}/api/emoji/reactions`, body);
