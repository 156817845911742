import { FC, useRef } from "react";
import classNames from "classnames";
import { Icon } from "../Icon";
import { AccordionEl } from "./Accordion";

type AccordionItemProps = {
  item: AccordionEl;
  active: boolean;
  onToggle: () => void;
};

const AccordionItem: FC<AccordionItemProps> = ({ item, active, onToggle }) => {
  const contentEl = useRef<HTMLDivElement | null>(null);

  return (
    <li
      className={classNames("accordion__item", {
        active: active,
      })}
    >
      <button className="accordion__button" onClick={onToggle}>
        <span>{item.title}</span>

        <div className="accordion__control">
          <Icon svgClass="accordion__chevron" href="#chevron-down" />
        </div>
      </button>

      <div
        className="accordion__wrapper"
        ref={contentEl}
        style={
          active
            ? { height: contentEl.current?.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="accordion__content">{item.content}</div>
      </div>
    </li>
  );
};

export default AccordionItem;
