import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AchievementCategorySearchDto,
  AchievementEventDetailedDto,
  AchievementSearchDto,
  AchievementsEventsFilters,
  AchievementsEventsStatuses,
  DateSortDirectionAchievementsEvents,
} from "../../api/types/achievementEventsTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { AchievementsEventsState } from "../types";
import { logoutUserAction } from "./authReducer";

export const achievementEventsInitialState: AchievementsEventsState = {
  achievementEventsDropdownsData: {
    achievementsCategories: defaultListState,
    achievements: defaultListState,
  },
  achievementsEventsData: {
    achievementsEventsList: defaultStateWithInfiniteLoading,
    filters: {
      achievementsEventsStatus: AchievementsEventsStatuses.OnReview,
      achievementsCategoriesIds: [],
      achievementsIds: [],
    },
    dateSortDirection: DateSortDirectionAchievementsEvents.dsc,
  },
};

const achievementsEventsReducer = createSlice({
  initialState: achievementEventsInitialState,
  name: "achievementsEvents",
  reducers: {
    // AchievementsEvents dropdowns filters data
    setLoadingAchievementsCategoriesFilters: (state) => ({
      ...state,
      achievementEventsDropdownsData: {
        ...state.achievementEventsDropdownsData,
        achievementsCategories: setStateLoading(
          state.achievementEventsDropdownsData.achievementsCategories
        ),
      },
    }),
    setAchievementsCategoriesFilters: (
      state,
      action: PayloadAction<AchievementCategorySearchDto[]>
    ) => ({
      ...state,
      achievementEventsDropdownsData: {
        ...state.achievementEventsDropdownsData,
        achievementsCategories: setStateData(
          state.achievementEventsDropdownsData.achievementsCategories,
          action.payload
        ),
      },
    }),
    setErrorAchievementsCategoriesFilters: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementEventsDropdownsData: {
        ...state.achievementEventsDropdownsData,
        achievementsCategories: setStateError(
          state.achievementEventsDropdownsData.achievementsCategories,
          action.payload
        ),
      },
    }),
    setLoadingAchievementsFilters: (state) => ({
      ...state,
      achievementEventsDropdownsData: {
        ...state.achievementEventsDropdownsData,
        achievements: setStateLoading(
          state.achievementEventsDropdownsData.achievements
        ),
      },
    }),
    setAchievementsFilters: (
      state,
      action: PayloadAction<AchievementSearchDto[]>
    ) => ({
      ...state,
      achievementEventsDropdownsData: {
        ...state.achievementEventsDropdownsData,
        achievements: setStateData(
          state.achievementEventsDropdownsData.achievements,
          action.payload
        ),
      },
    }),
    setErrorAchievementsFilters: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementEventsDropdownsData: {
        ...state.achievementEventsDropdownsData,
        achievements: setStateError(
          state.achievementEventsDropdownsData.achievements,
          action.payload
        ),
      },
    }),
    // Achievements Events filters
    setAchievementsEventsFilters: (
      state,
      action: PayloadAction<AchievementsEventsFilters>
    ) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        filters: action.payload,
      },
    }),
    // Achievement Events date sorting
    setAchievementsEventsSortDirection: (
      state,
      action: PayloadAction<DateSortDirectionAchievementsEvents>
    ) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        dateSortDirection: action.payload,
      },
    }),
    //  AchievementsEvents list
    setLoadingAchievementsEventsList: (state) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        achievementsEventsList: setInfiniteLoadLoading(
          state.achievementsEventsData.achievementsEventsList
        ),
      },
    }),
    setAchievementsEventsList: (
      state,
      action: PayloadAction<{
        achievementsEventsList: AchievementEventDetailedDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let achievementsEventsList =
        state.achievementsEventsData.achievementsEventsList.data || [];
      achievementsEventsList = [
        ...achievementsEventsList,
        ...action.payload.achievementsEventsList,
      ];

      return {
        ...state,
        achievementsEventsData: {
          ...state.achievementsEventsData,
          achievementsEventsList: setInfiniteLoadData(
            state.achievementsEventsData.achievementsEventsList,
            achievementsEventsList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorAchievementsEventsList: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        achievementsEventsList: setInfiniteLoadStateErrorMessage(
          state.achievementsEventsData.achievementsEventsList,
          action.payload
        ),
      },
    }),
    resetAchievementsEventsList: (state) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        achievementsEventsList: defaultStateWithInfiniteLoading,
      },
    }),
    resetAchievementsEventsState: () => achievementEventsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => achievementEventsInitialState);
  },
});

export default achievementsEventsReducer.reducer;

export const {
  setLoadingAchievementsCategoriesFilters,
  setAchievementsCategoriesFilters,
  setErrorAchievementsCategoriesFilters,
  setLoadingAchievementsFilters,
  setAchievementsFilters,
  setErrorAchievementsFilters,
  setLoadingAchievementsEventsList,
  setAchievementsEventsList,
  setErrorAchievementsEventsList,
  setAchievementsEventsFilters,
  setAchievementsEventsSortDirection,
  resetAchievementsEventsList,
  resetAchievementsEventsState,
} = achievementsEventsReducer.actions;
