import classNames from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import HasPermissions from "../../auth/HasPermissions";
import { useAppSelector } from "../../store";
import Icon from "../../utils/components/Icon";
import { AppPermissions, Features } from "../../utils/consts";
import { useCompanyFeatureCheck } from "../../utils/hooks";
import NavigationLink from "./NavigationLink";
import { groupLinksMap } from "./layoutUtils";

type SidebarLinksProps = {
  closeSidebar: () => void;
};

export type GroupLink = {
  path: string;
  linkKey: string;
  permissions?: AppPermissions[];
};

enum GroupLinkName {
  events = "events",
  merch = "merch",
  people = "people",
  settings = "settings",
  timeOff = "timeOff",
}

const SidebarLinks: FC<SidebarLinksProps> = ({ closeSidebar }) => {
  const { t } = useTranslation("layout");
  const { t: tCommon } = useTranslation("common");

  const userPermissions = useAppSelector(
    (state) => state.auth.profile?.permissions
  );
  const locationPath = useLocation().pathname;
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const isTimeOffAvailable = useCompanyFeatureCheck(Features.TimeOff);
  const detectActiveGroupLinks = (groupName: GroupLinkName) => {
    return groupLinksMap[groupName].some((link) =>
      locationPath.includes(link.path)
    );
  };

  const [groupLinks, setGroupLinks] = useState<Record<GroupLinkName, boolean>>({
    people: detectActiveGroupLinks(GroupLinkName.people),
    merch: detectActiveGroupLinks(GroupLinkName.merch),
    events: detectActiveGroupLinks(GroupLinkName.events),
    timeOff: detectActiveGroupLinks(GroupLinkName.timeOff),
    settings: detectActiveGroupLinks(GroupLinkName.settings),
  });

  const isAnalyticsPermissionGranted =
    isGamificationAvailable &&
    userPermissions &&
    [
      AppPermissions.ViewCompanysAnalytics,
      AppPermissions.Achievements_ViewAnalytics,
      AppPermissions.Activity_ViewAnalytics,
    ].some((permission) =>
      userPermissions.includes(
        AppPermissions[permission as keyof typeof AppPermissions]
      )
    );

  const handleGroupLinks = (groupName: GroupLinkName) => {
    const copyGroupLinks = { ...groupLinks };

    for (let key in copyGroupLinks) {
      if (key === groupName) {
        copyGroupLinks[key] = !copyGroupLinks[key];
      } else {
        copyGroupLinks[key as GroupLinkName] = false;
      }
    }

    setGroupLinks(copyGroupLinks);
  };

  const navLinkHandler = () => {
    setGroupLinks({
      people: false,
      merch: false,
      events: false,
      timeOff: false,
      settings: false,
    });
    closeSidebar();
  };

  return (
    <nav className="menu">
      <ul>
        <li className="menu__item">
          <NavLink
            to="/news"
            className={({ isActive }) =>
              classNames("menu__link", { active: isActive })
            }
            onClick={() => navLinkHandler()}
          >
            <div className="menu__itemContent">
              <Icon href="#home" svgClass="menu__icon" />
              <span className="menu__linkName">{t("navigation__home")}</span>
            </div>
          </NavLink>
        </li>

        {isGamificationAvailable ? (
          <>
            <li className="menu__item">
              <div
                className="menu__link d-flex"
                onClick={() => handleGroupLinks(GroupLinkName.events)}
              >
                <div className="menu__itemContent">
                  <Icon href="#calendar" svgClass="menu__icon" />
                  <span className="menu__linkName">
                    {t("navigation_events")}
                  </span>
                </div>
                <Icon
                  href="#chevron-down"
                  svgClass={classNames("arrowIcon", {
                    active: groupLinks.events,
                  })}
                />
              </div>

              {groupLinks.events && (
                <div className="menu__navLinks">
                  {groupLinksMap.events.map((link) => (
                    <NavigationLink
                      key={link.path}
                      path={link.path}
                      handleClick={() => closeSidebar()}
                      title={link.linkKey}
                      permissions={link.permissions}
                    />
                  ))}
                </div>
              )}
            </li>

            {userPermissions &&
            (userPermissions.includes(
              AppPermissions.MerchOrders_ViewAndManageOrders
            ) ||
              userPermissions.includes(
                AppPermissions.Merch_ViewMerchDashboard
              )) ? (
              <li className="menu__item">
                <div
                  className="menu__link d-flex"
                  onClick={() => handleGroupLinks(GroupLinkName.merch)}
                >
                  <div className="menu__itemContent">
                    <Icon href="#merchBox" svgClass="menu__icon" />
                    <span className="menu__linkName">
                      {t("navigation_merchGroup")}
                    </span>
                  </div>
                  <Icon
                    href="#chevron-down"
                    svgClass={classNames("arrowIcon", {
                      active: groupLinks.merch,
                    })}
                  />
                </div>

                {groupLinks.merch && (
                  <div className="menu__navLinks">
                    {groupLinksMap.merch.map((link) => (
                      <NavigationLink
                        key={link.path}
                        path={link.path}
                        handleClick={() => closeSidebar()}
                        title={link.linkKey}
                        permissions={link.permissions}
                      />
                    ))}
                  </div>
                )}
              </li>
            ) : (
              <li className="menu__item">
                <NavLink
                  to="/merch-store"
                  className={({ isActive }) =>
                    classNames("menu__link", { active: isActive })
                  }
                  onClick={() => navLinkHandler()}
                >
                  <div className="menu__itemContent">
                    <Icon href="#merchBox" svgClass="menu__icon" />
                    <span className="menu__linkName">
                      {t("navigation_merchStore")}
                    </span>
                  </div>
                </NavLink>
              </li>
            )}

            <li className="menu__item">
              <NavLink
                to="/leaderboard"
                className={({ isActive }) =>
                  classNames("menu__link", { active: isActive })
                }
                onClick={() => navLinkHandler()}
              >
                <div className="menu__itemContent">
                  <Icon href="#leaderboard" svgClass="menu__icon" />
                  <span className="menu__linkName">
                    {t("navigation_leaderboard")}
                  </span>
                </div>
              </NavLink>
            </li>
          </>
        ) : null}

        {userPermissions &&
          (userPermissions.includes(AppPermissions.ManageBadges) ||
            userPermissions.includes(AppPermissions.AddRemoveBadges)) && (
            <li className="menu__item">
              <NavLink
                to="/manage-badges"
                className={({ isActive }) =>
                  classNames("menu__link", { active: isActive })
                }
                onClick={() => navLinkHandler()}
              >
                <div className="menu__itemContent">
                  <Icon href="#BadgeMessageIcon" svgClass="menu__icon" />
                  <span className="menu__linkName">
                    {t("navigation_badges")}
                  </span>
                </div>
              </NavLink>
            </li>
          )}

        {isTimeOffAvailable &&
        userPermissions &&
        userPermissions.includes(
          AppPermissions.TimeOff_ViewAddEditDeletePolicies
        ) ? (
          <>
            <li className="menu__item">
              <div
                className="menu__link d-flex"
                onClick={() => handleGroupLinks(GroupLinkName.timeOff)}
              >
                <div className="menu__itemContent">
                  <Icon href="#timeOff" svgClass="menu__icon" />
                  <span className="menu__linkName">
                    {t("navigation_timeOff")}
                  </span>
                </div>
                <Icon
                  href="#chevron-down"
                  svgClass={classNames("arrowIcon", {
                    active: groupLinks.timeOff,
                  })}
                />
              </div>

              {groupLinks.timeOff && (
                <div className="menu__navLinks">
                  {groupLinksMap.timeOff.map((link) => (
                    <NavigationLink
                      key={link.path}
                      path={link.path}
                      handleClick={() => closeSidebar()}
                      title={link.linkKey}
                      permissions={link.permissions}
                    />
                  ))}
                </div>
              )}
            </li>
          </>
        ) : null}

        {isAnalyticsPermissionGranted && (
          <li className="menu__item">
            <NavLink
              to="/analytics"
              className={({ isActive }) =>
                classNames("menu__link", { active: isActive })
              }
              onClick={() => navLinkHandler()}
            >
              <div className="menu__itemContent">
                <Icon href="#analytics" svgClass="menu__icon" />
                <span className="menu__linkName">
                  {tCommon("analyticsTitle")}
                </span>
              </div>
            </NavLink>
          </li>
        )}

        <HasPermissions
          requiredPermissions={[AppPermissions.ViewCompanyOrgStructure]}
        >
          <li className="menu__item">
            <div
              className="menu__link d-flex"
              onClick={() => handleGroupLinks(GroupLinkName.people)}
            >
              <div className="menu__itemContent">
                <Icon href="#people" svgClass="menu__icon" />
                <span className="menu__linkName">{t("navigation_people")}</span>
              </div>
              <Icon
                href="#chevron-down"
                svgClass={classNames("arrowIcon", {
                  active: groupLinks.people,
                })}
              />
            </div>

            {groupLinks.people && (
              <div className="menu__navLinks">
                {groupLinksMap.people.map((link) => (
                  <NavigationLink
                    key={link.path}
                    path={link.path}
                    handleClick={() => closeSidebar()}
                    title={link.linkKey}
                  />
                ))}
              </div>
            )}
          </li>
        </HasPermissions>

        <HasPermissions
          requiredPermissions={[
            AppPermissions.AddEditRemoveCompanyMember,
            AppPermissions.ChangeCompanysLogoAndColors,
            AppPermissions.ViewAndEditCompanyData,
            AppPermissions.RolesManagement,
            AppPermissions.ManageIntegrations,
            AppPermissions.UserGroupsManagement_ViewAddEditDeleteUserGroups,
          ]}
        >
          <li className="menu__item">
            <div
              className="menu__link d-flex"
              onClick={() => handleGroupLinks(GroupLinkName.settings)}
            >
              <div className="menu__itemContent">
                <Icon href="#settings" svgClass="menu__icon" />
                <span className="menu__linkName">
                  {t("navigation_settings")}
                </span>
              </div>
              <Icon
                href="#chevron-down"
                svgClass={classNames("arrowIcon", {
                  active: groupLinks.settings,
                })}
              />
            </div>

            {groupLinks.settings && (
              <div className="menu__navLinks">
                {groupLinksMap.settings.map((link) => (
                  <NavigationLink
                    key={link.path}
                    path={link.path}
                    handleClick={() => closeSidebar()}
                    title={link.linkKey}
                    permissions={link.permissions}
                  />
                ))}
              </div>
            )}
          </li>
        </HasPermissions>
      </ul>
    </nav>
  );
};

export default SidebarLinks;
