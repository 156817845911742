import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TimeOffPolicyListItemDto,
  TimeOffPolicyTypeDto,
  TimeOffPolicyTypeListItemDto,
} from "api/types/timeOff/timeOffPolicyTypes";
import { defaultListState } from "appConsts";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "store/handleUpdateState";
import { TimeOffPolicyTypesState } from "store/types";
import { logoutUserAction } from "../authReducer";

const initialState: TimeOffPolicyTypesState = {
  timeOffPolicyTypesList: defaultListState,
  timeOffPolicyType: defaultListState,
  timeOffPolicies: {
    isEnabledStatus: true,
    policiesList: defaultListState,
  },
};

const timeOffPolicyTypesReducer = createSlice({
  name: "timeOffPolicyTypes",
  initialState,
  reducers: {
    // List of Policy Types
    setLoadingTimeOffPolicyTypesList: (state) => ({
      ...state,
      timeOffPolicyTypesList: setStateLoading(state.timeOffPolicyTypesList),
    }),
    setTimeOffPolicyTypesList: (
      state,
      action: PayloadAction<TimeOffPolicyTypeListItemDto[]>
    ) => ({
      ...state,
      timeOffPolicyTypesList: setStateData(
        state.timeOffPolicyTypesList,
        action.payload
      ),
    }),
    setErrorTimeOffPolicyTypesList: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffPolicyTypesList: setStateError(
        state.timeOffPolicyTypesList,
        action.payload
      ),
    }),
    // Info about one Policy Type
    setLoadingTimeOffPolicyType: (state) => ({
      ...state,
      timeOffPolicyType: setStateLoading(state.timeOffPolicyType),
    }),
    setTimeOffPolicyType: (
      state,
      action: PayloadAction<TimeOffPolicyTypeDto>
    ) => ({
      ...state,
      timeOffPolicyType: setStateData(state.timeOffPolicyType, action.payload),
    }),
    setErrorTimeOffPolicyType: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffPolicyType: setStateError(state.timeOffPolicyType, action.payload),
    }),
    resetTimeOffPolicyTypesList: (state) => ({
      ...state,
      timeOffPolicyTypesList: defaultListState,
    }),
    // Policies list of chosen Policy Type
    setTimeOffStatusPoliciesFilter: (
      state,
      action: PayloadAction<null | boolean>
    ) => ({
      ...state,
      timeOffPolicies: {
        ...state.timeOffPolicies,
        isEnabledStatus: action.payload,
      },
    }),
    setLoadingTimeOffPoliciesList: (state) => ({
      ...state,
      timeOffPolicies: {
        ...state.timeOffPolicies,
        policiesList: setStateLoading(state.timeOffPolicies.policiesList),
      },
    }),
    setTimeOffPoliciesList: (
      state,
      action: PayloadAction<TimeOffPolicyListItemDto[]>
    ) => ({
      ...state,
      timeOffPolicies: {
        ...state.timeOffPolicies,
        policiesList: setStateData(
          state.timeOffPolicies.policiesList,
          action.payload
        ),
      },
    }),
    setErrorTimeOffPoliciesList: (state, action: PayloadAction<string>) => ({
      ...state,
      timeOffPolicies: {
        ...state.timeOffPolicies,
        policiesList: setStateError(
          state.timeOffPolicies.policiesList,
          action.payload
        ),
      },
    }),
    resetTimeOffPoliciesList: (state) => ({
      ...state,
      timeOffPolicies: {
        ...state.timeOffPolicies,
        policiesList: defaultListState,
      },
    }),
    resetTimeOffPolicyTypesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default timeOffPolicyTypesReducer.reducer;
export const {
  setLoadingTimeOffPolicyTypesList,
  setTimeOffPolicyTypesList,
  setErrorTimeOffPolicyTypesList,
  setLoadingTimeOffPolicyType,
  setTimeOffPolicyType,
  setErrorTimeOffPolicyType,
  resetTimeOffPolicyTypesState,
  resetTimeOffPolicyTypesList,
  setLoadingTimeOffPoliciesList,
  setTimeOffStatusPoliciesFilter,
  setTimeOffPoliciesList,
  setErrorTimeOffPoliciesList,
  resetTimeOffPoliciesList,
} = timeOffPolicyTypesReducer.actions;
