import { AxiosError } from "axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toastError, toastSuccess } from "utils/toasts";
import {
  deleteMerchDeliveryInfoHistoryRecord,
  loadMerchDeliveryInfoHistory,
} from "../../../../api/employeePageApi";
import { HistoryRecordMerchDeliveryInfo } from "../../../../api/types/employeePageTypes";
import { Icon } from "../../../../utils/components";
import Loader, { LoaderSize } from "../../../../utils/components/Loader";
import MerchDeliveryDetails from "../../../../utils/components/MerchDeliveryDetails";
import Accordion from "../../../../utils/components/accordion/Accordion";
import {
  getAvatarSign,
  getDateTimeString,
  getErrorMessage,
  getFullName,
} from "../../../../utils/methods";
import LayoutConfirmationPopup from "../../../layout/LayoutConfirmationPopup";
import LayoutRightPanel from "../../../layout/LayoutRightPanel";
import useLocale from "localization/useLocale";
import UserAvatar from "../../../../utils/components/UserAvatar";

type MerchDeliveryHistoryPanelProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

function compareRecords(
  a: HistoryRecordMerchDeliveryInfo,
  b: HistoryRecordMerchDeliveryInfo
) {
  const dateA = new Date(a.createdDate).getTime();
  const dateB = new Date(b.createdDate).getTime();
  return dateB - dateA;
}

const MerchDeliveryInfoHistoryDetails: FC<{
  record: HistoryRecordMerchDeliveryInfo;
  onDelete: (v: number) => void;
}> = ({ record, onDelete }) => {
  const { t } = useTranslation("employeePage");
  const { id, isCurrent, merchDeliveryInfo, createdBy } = record;

  return (
    <div className="merchDeliveryHistoryPanel__content">
      {!isCurrent && (
        <button
          type="button"
          className="btn-regular actionBtn-regular merchDeliveryHistoryPanel__delete"
          onClick={() => onDelete(id)}
        >
          <Icon svgClass="buttonIcon" href="#delet" />
        </button>
      )}

      {isCurrent && (
        <h5 className="merchDeliveryHistoryPanel__currentTitle">
          {t("currentVersion")}
        </h5>
      )}

      {createdBy && (
        <div className="merchDeliveryBlock__item m-btm">
          <p className="merchDeliveryBlock__label">{t("versionCreatedBy")}</p>

          <div className="userBlock d-flex">
            <UserAvatar
              avatarImageUrl={createdBy.avatarImageUrl}
              avatarSign={getAvatarSign(
                createdBy.firstName,
                createdBy.familyName
              )}
              className="user-avatar userBlock__avatar"
            />
            <span className="user-name">
              {getFullName(createdBy.firstName, createdBy.familyName)}
            </span>
          </div>
        </div>
      )}
      <div className="merchDeliveryBlock">
        <MerchDeliveryDetails merchDelivery={merchDeliveryInfo} />
      </div>
    </div>
  );
};

const MerchDeliveryHistoryPanel: FC<MerchDeliveryHistoryPanelProps> = ({
  isModalOpen,
  onCloseModal,
}) => {
  const { t } = useTranslation("employeePage");
  const { t: tCommon } = useTranslation("common");
  const locale = useLocale();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [idRecordToDelete, setIdRecordToDelete] = useState<null | number>(null);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [errorHistory, setErrorHistory] = useState("");
  const [merchDeliveryInfoHistory, setMerchDeliveryInfoHistory] = useState<
    HistoryRecordMerchDeliveryInfo[] | null
  >(null);
  const { userId } = useParams();

  const onDelete = (id: number) => {
    setIdRecordToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const accordionData = merchDeliveryInfoHistory
    ? merchDeliveryInfoHistory
        .sort((record1, record2) => compareRecords(record1, record2))
        .map((record) => ({
          id: record.id,
          title: getDateTimeString(record.createdDate, "fullDate", locale),
          content: (
            <MerchDeliveryInfoHistoryDetails
              onDelete={onDelete}
              record={record}
            />
          ),
        }))
    : [];

  const getMerchDeliveryInfoHistory = async () => {
    if (!userId) {
      return;
    }

    setIsLoadingHistory(true);

    try {
      const response = await loadMerchDeliveryInfoHistory(+userId);
      setMerchDeliveryInfoHistory(response.data.items);
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      setErrorHistory(errorMessage);
    } finally {
      setIsLoadingHistory(false);
    }
  };

  useEffect(() => {
    if (isModalOpen && userId) {
      getMerchDeliveryInfoHistory();
    }
  }, [isModalOpen, userId]);

  const handleDeleteRecord = async () => {
    if (userId && idRecordToDelete) {
      try {
        await deleteMerchDeliveryInfoHistoryRecord(+userId, idRecordToDelete);
        await getMerchDeliveryInfoHistory();
        toastSuccess(t("merchDeliveryInfoRecordSuccessfullyDeleted"));
      } catch (e) {
        const errorMessage = getErrorMessage(
          e as AxiosError,
          t("errorDeletingMerchDeliveryInfoRecord") as string
        );
        toastError(errorMessage);
      } finally {
        setIsDeleteModalOpen(false);
      }
    } else return;
  };

  const closeModal = () => {
    setIsLoadingHistory(false);
    setMerchDeliveryInfoHistory(null);
    setErrorHistory("");
    setIdRecordToDelete(null);
    onCloseModal();
  };

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={closeModal}
      title={t("historyMerchDeliveryInfo")}
      additionalClassName="popup-small"
    >
      <div className="wrap merchDeliveryHistoryPanel">
        {isLoadingHistory && (
          <div className="loader-container">
            <Loader size={LoaderSize.Small} />
          </div>
        )}
        {errorHistory && (
          <div className="new-wrapper error">{errorHistory}</div>
        )}
        {merchDeliveryInfoHistory && (
          <Accordion data={accordionData} isModalOpen={isModalOpen} />
        )}
      </div>

      <div className="popup-footer">
        <button className="btn-primary" type="button" onClick={onCloseModal}>
          {tCommon("form_close")}
        </button>
      </div>

      <LayoutConfirmationPopup
        isModalOpen={isDeleteModalOpen}
        onCloseModal={() => {
          setIdRecordToDelete(null);
          setIsDeleteModalOpen(false);
        }}
        confirmHandler={handleDeleteRecord}
        title={t("deleteMerchDeliveryInfoRecord")}
        question={t("confirmDeleteMerchDeliveryInfoRecord")}
      />
    </LayoutRightPanel>
  );
};

export default MerchDeliveryHistoryPanel;
