import { TFunction } from "i18next";
import { config } from "utils/config";
import { countries } from "./countries";

export const formatCountriesList = (t: TFunction) => {
  const { availableDeliveryCountries } = config;
  if (!availableDeliveryCountries) return;

  const translatedCountries = availableDeliveryCountries
    .split(",")
    .map((countryKey) => t(`countries:${countryKey}`));

  if (translatedCountries.length > 1) {
    const lastCountry = translatedCountries.pop();
    const andWord = t("auth:and_join");
    return `${translatedCountries.join(", ")} ${andWord} ${lastCountry}`;
  }
  return translatedCountries[0];
};

export const getReceiverCountry = (receiverCountry?: string) => {
  if (!receiverCountry) return "";

  const foundCountry = countries.find(
    (country) => country.value === receiverCountry
  );

  return foundCountry ? receiverCountry : "";
};

export const getLocalizedCountries = (t: TFunction) => {
  const { availableDeliveryCountries } = config;
  const availableCountries = availableDeliveryCountries
    ? availableDeliveryCountries.split(",")
    : [];

  return countries.map((country) => {
    const isDisabled = availableDeliveryCountries
      ? !availableCountries.includes(String(country.value))
      : false;

    return {
      ...country,
      label: t(`countries:${country.value}`),
      isDisabled,
    };
  });
};
