import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { resetPersonInfoPageState } from "store/reducers/employeePageReducers/employeePersonalInfoReducer";
import { getEmployeePageData } from "store/thunks/employeePage/employeePageThunks";
import { AppPermissions } from "utils/consts";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { usePermissionCheck } from "utils/hooks/usePermissionCheck";
import BasicInfoBlock from "./basicInfo/BasicInfoBlock";
import ContactsBlock from "./contacts/ContactsBlock";
import SignificantDatesBlock from "./significantDates/SignificantDatesBlock";
import AccessDenied from "../../../auth/AccessDenied";
import StructureBlock from "./StructureBlock";
import { ListPersonGroups } from "./person-groups/ListPersonGroups";
import { getGroupsByUser } from "store/thunks/groupManagementThunks";
import { resetGroupsBytUserData } from "store/reducers/groupManagementReducer";

const PersonInfoPage = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const navigate = useNavigate();
  const isCurrentUser = useIsCurrentUser();

  const isUserCanViewPersonalInfo = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
    AppPermissions.EmployeeCard_ViewUsersEmployeeCard,
  ]);
  const isShowListPersonGroups = usePermissionCheck([
    AppPermissions.UserGroupsManagement_ViewAddEditDeleteUserGroups,
  ]);
  const isAccessToPersonalInfo = isCurrentUser || isUserCanViewPersonalInfo;
  const listPersonGroupsComponent = isShowListPersonGroups ? (
    <ListPersonGroups />
  ) : null;

  useEffect(() => {
    if (userId) {
      dispatch(getEmployeePageData(+userId));
      isShowListPersonGroups && dispatch(getGroupsByUser(+userId));
    } else {
      console.error("Unknown user id");
      navigate("/news");
    }

    return () => {
      dispatch(resetPersonInfoPageState());
      isShowListPersonGroups && dispatch(resetGroupsBytUserData());
    };
  }, [userId]);

  if (!isAccessToPersonalInfo) {
    return <AccessDenied />;
  }

  return (
    <div className="personInfoPage">
      <div className="personInfoPage__main">
        <div>
          <BasicInfoBlock />
          <div className="blockDesktop">
            <StructureBlock />
            {listPersonGroupsComponent}
          </div>
        </div>
        <div>
          <SignificantDatesBlock />
          <ContactsBlock />
          <div className="blockTablet">
            <StructureBlock />
            {listPersonGroupsComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonInfoPage;
