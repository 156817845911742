import { EmojiReactionUsersItemDto } from "../../../api/types/emojiTypes";
import { FC, useRef } from "react";
import { useAppSelector } from "../../../store";
import classNames from "classnames";
import { Tooltip, Whisper } from "rsuite";
import { UserOfSearch } from "../../../api/types/usersTypes";
import { getAvatarSign, getFullName } from "../../methods";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import { OverlayTriggerHandle } from "rsuite/esm/internals/Overlay/OverlayTrigger";
import { Placement } from "../../../api/types/commonTypes";

const availableEmojisIds = [
  "U+1F44D",
  "U+1F60D",
  "U+1F60E",
  "U+1F62E",
  "U+263A",
  "U+2764",
];

type EmojiBlockProps = {
  reactions: EmojiReactionUsersItemDto[];
  changeReaction: (emojiId: string | null) => void;
  placementEmojiTooltip?: Placement;
};

const EmojiBlock: FC<EmojiBlockProps> = ({
  reactions,
  changeReaction,
  placementEmojiTooltip,
}) => {
  const reactionsSortedByCount = [...reactions].sort(
    (r1, r2) => r2.users.length - r1.users.length
  );
  const currentUserId = useAppSelector((state) => state.auth.profile?.id);
  const triggerRef = useRef<OverlayTriggerHandle | null>(null);

  const usersTooltip = (users: UserOfSearch[]) => (
    <Tooltip className="custom-tooltip usersReactionsTooltip" arrow={false}>
      {users.map((user: UserOfSearch) => {
        const avatarSign = getAvatarSign(user?.firstName, user?.familyName, "");
        const fullName = getFullName(user?.firstName, user?.familyName);

        return (
          <Link
            to={`/employee/${user.id}`}
            key={user.id}
            className="usersReactionsTooltip__user"
          >
            <div className="user-avatar usersReactionsTooltip__userAvatar">
              {user.avatarImageUrl ? (
                <img src={user.avatarImageUrl} alt="userAvatar" />
              ) : (
                <span className="avatarSign">{avatarSign}</span>
              )}
            </div>
            <div className="usersReactionsTooltip__userName">{fullName}</div>
          </Link>
        );
      })}
    </Tooltip>
  );

  const emojisTooltip = (
    <Tooltip className="custom-tooltip emojisTooltip" arrow={false}>
      <div className="emojisTooltip__wrap">
        {availableEmojisIds.map((emoji) => (
          <button
            key={emoji}
            type="button"
            className="emojisTooltip__btn"
            onClick={() => {
              changeReaction(emoji);
              triggerRef.current?.close();
            }}
          >
            <img
              src={`/img/emojis/${emoji}.png`}
              alt="emoji"
              className="emojisTooltip__img"
            />
          </button>
        ))}
      </div>
    </Tooltip>
  );

  return (
    <div className="emojiBlock">
      <div className="emojiBlock__wrap">
        {reactionsSortedByCount.map((reaction) => {
          const selectedEmoji = reaction.users.find(
            (user) => user.id === currentUserId
          );

          return (
            <Whisper
              speaker={usersTooltip(reaction.users)}
              placement="bottomStart"
              trigger="hover"
              enterable
              key={reaction.emojiId}
            >
              <button
                type="button"
                className={classNames("emojiBlock__emoji", {
                  selected: selectedEmoji,
                })}
                onClick={() => {
                  if (selectedEmoji) {
                    changeReaction(null);
                  } else {
                    changeReaction(reaction.emojiId);
                  }
                }}
              >
                <img
                  src={`/img/emojis/${reaction.emojiId}.png`}
                  alt="emoji"
                  className="emojiBlock__img"
                />
                <span className="emojiBlock__count">{reaction.count}</span>
              </button>
            </Whisper>
          );
        })}
        <Whisper
          ref={triggerRef}
          speaker={emojisTooltip}
          placement={placementEmojiTooltip || "bottomEnd"}
          trigger="click"
          enterable
        >
          <button type="button" className="emojiBlock__addEmoji">
            <Icon href="#addEmoji" svgClass="emojiBlock__addEmojiIcon" />
          </button>
        </Whisper>
      </div>
    </div>
  );
};

export default EmojiBlock;
