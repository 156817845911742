import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  MerchOrderDto,
  MerchOrdersStatsResponse,
  OrderStatuses,
  OrdersFilters,
} from "../../api/types/merchDeliveryTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { MerchDeliveryState } from "../types";
import { logoutUserAction } from "./authReducer";

export const merchDeliveryInitialState: MerchDeliveryState = {
  orderStatistics: defaultListState,
  ordersData: {
    ordersList: defaultStateWithInfiniteLoading,
    filters: {
      status: OrderStatuses.Created,
      departaments: [],
      employees: [],
      dateRange: null,
    },
    dropdownsData: defaultListState,
  },
};

const merchDeliverySlice = createSlice({
  initialState: merchDeliveryInitialState,
  name: "merchDelivery",
  reducers: {
    // Orders statistics blocks
    setLoadingOrderStatistics: (state) => ({
      ...state,
      orderStatistics: setStateLoading(state.orderStatistics),
    }),
    setOrderStatistics: (
      state,
      action: PayloadAction<MerchOrdersStatsResponse>
    ) => ({
      ...state,
      orderStatistics: setStateData(state.orderStatistics, action.payload),
    }),
    setErrorOrderStatistics: (state, action: PayloadAction<string>) => ({
      ...state,
      orderStatistics: setStateError(state.orderStatistics, action.payload),
    }),
    // Filter dropdowns data (array of departments)
    setLoadingFiltersDropdownsData: (state) => ({
      ...state,
      ordersData: {
        ...state.ordersData,
        dropdownsData: setStateLoading(state.ordersData.dropdownsData),
      },
    }),
    setFiltersDropdownsData: (
      state,
      action: PayloadAction<string[]>
    ) => ({
      ...state,
      ordersData: {
        ...state.ordersData,
        dropdownsData: setStateData(
          state.ordersData.dropdownsData,
          action.payload
        ),
      },
    }),
    setFiltersDropdownsError: (state, action: PayloadAction<string>) => ({
      ...state,
      ordersData: {
        ...state.ordersData,
        dropdownsData: setStateError(
          state.ordersData.dropdownsData,
          action.payload
        ),
      },
    }),
    // Order filters
    setOrderFilters: (state, action: PayloadAction<OrdersFilters>) => ({
      ...state,
      ordersData: {
        ...state.ordersData,
        filters: action.payload,
      },
    }),

    // Orders table
    setLoadingOrdersList: (state) => ({
      ...state,
      ordersData: {
        ...state.ordersData,
        ordersList: setInfiniteLoadLoading(state.ordersData.ordersList),
      },
    }),
    setOrdersList: (
      state,
      action: PayloadAction<{
        ordersList: MerchOrderDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let ordersList = state.ordersData.ordersList.data || [];
      ordersList = [...ordersList, ...action.payload.ordersList];

      return {
        ...state,
        ordersData: {
          ...state.ordersData,
          ordersList: setInfiniteLoadData(
            state.ordersData.ordersList,
            ordersList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorOrdersList: (state, action: PayloadAction<string>) => ({
      ...state,
      ordersData: {
        ...state.ordersData,
        ordersList: setInfiniteLoadStateErrorMessage(
          state.ordersData.ordersList,
          action.payload
        ),
      },
    }),
    resetOrdersInfo: (state) => ({
      ...state,
      orderStatistics: defaultListState,
      ordersData: {
        ...state.ordersData,
        ordersList: defaultStateWithInfiniteLoading,
      },
    }),
    resetMerchDeliveryState: () => merchDeliveryInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => merchDeliveryInitialState);
  },
});

export default merchDeliverySlice.reducer;

export const {
  setLoadingOrderStatistics,
  setOrderStatistics,
  setErrorOrderStatistics,
  resetMerchDeliveryState,
  setLoadingOrdersList,
  setOrdersList,
  setErrorOrdersList,
  setLoadingFiltersDropdownsData,
  setFiltersDropdownsData,
  setFiltersDropdownsError,
  setOrderFilters,
  resetOrdersInfo,
} = merchDeliverySlice.actions;
