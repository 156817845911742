import { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Features } from "../../utils/consts";
import { Icon } from "../../utils/components";
import { useCompanyFeatureCheck } from "../../utils/hooks";
import { useNavigate } from "react-router-dom";
import FeedbackRightPanel from "../employeeCard/employee-achievements/FeedbackRightPanel";

// we repeat gradient every 7 min (60000 milliseconds = 1 minute)
const repeatGradientTime = 60000 * 7;
const gradientAnimationDuration = 3000;

const MagicButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["layout", "employeeAchievements"]);
  const [isMagicOptionsVisible, setIsMagicOptionsVisible] = useState(false);
  const [isSendCheersModalOpen, setIsSendCheersModalOpen] = useState(false);
  const [isGradientActive, setIsGradientActive] = useState(false);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setIsGradientActive(true);

      setTimeout(() => setIsGradientActive(false), gradientAnimationDuration);
    }, repeatGradientTime);

    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  const toggleMagicOptions = () => {
    setIsMagicOptionsVisible((prevState) => !prevState);
  };

  const magicOptions = [
    {
      iconKey: "#earnCoinsOption",
      title: t("earnCoins"),
      isVisible: useCompanyFeatureCheck(Features.Gamification),
      onOptionClick: () => {
        toggleMagicOptions();
        navigate("/achievements");
      },
    },
    {
      iconKey: "#BadgeMessageIcon",
      title: t("employeeAchievements:sendFeedbackTitle"),
      isVisible: true,
      onOptionClick: () => {
        toggleMagicOptions();
        setIsSendCheersModalOpen(true);
      },
    },
    // TODO remove comment after implement RequestTimeOff functionality
    // {
    //   iconKey: "#requestTimeOff",
    //   title: t("requestTimeOff"),
    //   isVisible: useCompanyFeatureCheck(Features.TimeOff),
    //   onOptionClick: () => {
    //     toggleMagicOptions();
    //     // TODO open request time off popup
    //   }
    // },
  ];

  return (
    <div>
      <button
        className={classNames("magicButton", {
          active: isMagicOptionsVisible,
          gradient: isGradientActive,
        })}
        onClick={toggleMagicOptions}
      />

      <div
        className={classNames("magicButton__content", {
          fadeInUpMagicOptions: isMagicOptionsVisible,
        })}
      >
        {magicOptions.map(
          ({ title, iconKey, isVisible, onOptionClick }) =>
            isVisible && (
              <button
                key={iconKey}
                className="magicOption"
                onClick={onOptionClick}
              >
                <Icon href={iconKey} svgClass="magicOptionIcon" />
                <span>{title}</span>
              </button>
            )
        )}
      </div>

      <FeedbackRightPanel
        isModalOpen={isSendCheersModalOpen}
        onCloseModal={() => setIsSendCheersModalOpen(false)}
      />
    </div>
  );
};

export default MagicButton;
