import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ManageAutoEventsState } from "../types";
import { defaultListState } from "../../appConsts";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { AutoCoinEventListItemDto } from "../../api/types/manageAutoEventsTypes";
import { logoutUserAction } from "./authReducer";

const initialState: ManageAutoEventsState = {
  autoEventsList: defaultListState,
};

const manageAutoEventSlice = createSlice({
  initialState,
  name: "manageAutoEvents",
  reducers: {
    setLoadingAutoEventsList: (state) => ({
      ...state,
      autoEventsList: setStateLoading(state.autoEventsList),
    }),
    setAutoEventsList: (
      state,
      action: PayloadAction<AutoCoinEventListItemDto[]>
    ) => ({
      ...state,
      autoEventsList: setStateData(state.autoEventsList, action.payload),
    }),
    setErrorAutoEventsList: (state, action: PayloadAction<string>) => ({
      ...state,
      autoEventsList: setStateError(state.autoEventsList, action.payload),
    }),
    resetAutoEventsList: (state) => ({
      ...state,
      autoEventsList: defaultListState,
    }),
    resetManageAutoEventsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default manageAutoEventSlice.reducer;

export const {
  setLoadingAutoEventsList,
  setAutoEventsList,
  setErrorAutoEventsList,
  resetAutoEventsList,
  resetManageAutoEventsState,
} = manageAutoEventSlice.actions;
