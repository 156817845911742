import { AxiosError } from "axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { registerMicrosoftIntegration } from "../../../../api/integrationsApi";
import Loader from "../../../../utils/components/Loader";
import { getErrorMessage } from "../../../../utils/methods";
import { microsoftIntegrationInfo } from "../integrationInfos";

const MicrosoftConnectPage: FC = () => {
  const { t } = useTranslation("integrations");

  const integrationInfo = microsoftIntegrationInfo;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [serverError, setServerError] = useState<string | null>(null);

  const onMicrosoftDomain = searchParams.get("state");

  const activateIntegration = async (domain: string) => {
    try {
      await registerMicrosoftIntegration({ onMicrosoftDomain: domain });

      navigate(integrationInfo.routeLink);
    } catch (e) {
      const errorMessage = getErrorMessage(
        e as AxiosError,
        t("errorActivatingMicrosoftIntegration") as string
      );
      setServerError(errorMessage);
    }
  };

  useEffect(() => {
    if (onMicrosoftDomain) {
      activateIntegration(onMicrosoftDomain);
    }
  }, [onMicrosoftDomain]);

  if (serverError) {
    return (
      <div className="integration-details">
        <h1 className="main-title m-btm">{t("errorConnectingToMicrosoft")}</h1>
        <div className="wrap">
          <div>
            <p>
              {t("contactUsIfYouNeedAssistance")}{" "}
              <a href="mailto:support@esteeme.net">support@esteeme.net</a>
            </p>
          </div>
          <div className="line" />
          <div className="d-flex">
            <Link to={integrationInfo.routeLink} className="btn btn-details">
              {t("navigateBack")}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="integration-details box">
      <div className="page-loader">
        <Loader />
      </div>
    </div>
  );
};

export default MicrosoftConnectPage;
