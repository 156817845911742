import { EmployeePageState } from "../../types";
import { defaultListState } from "../../../appConsts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setStateData,
  setStateError,
  setStateLoading,
} from "../../handleUpdateState";
import { UserCardDto } from "../../../api/types/employeePageTypes";
import { logoutUserAction } from "../authReducer";
import { User, UserProfile } from "../../../api/types/usersTypes";

const initialState: EmployeePageState = {
  userData: defaultListState,
  employeeData: defaultListState,
  positionsData: defaultListState,
  departmentsData: defaultListState,
  usersStructureData: {
    filterBy: null,
    userId: null,
    usersList: defaultListState,
  },
};

const employeePageSlice = createSlice({
  initialState,
  name: "employeePage",
  reducers: {
    // User data for Main User block
    setLoadingUserData: (state) => ({
      ...state,
      userData: setStateLoading(state.userData),
    }),
    setUserData: (state, action: PayloadAction<UserProfile>) => ({
      ...state,
      userData: setStateData(state.userData, action.payload),
    }),
    setErrorUserData: (state, action: PayloadAction<string>) => ({
      ...state,
      userData: setStateError(state.userData, action.payload),
    }),
    resetUserData: (state) => ({
      ...state,
      userData: defaultListState,
    }),
    // Basic Info about Employee
    setLoadingEmployeeData: (state) => ({
      ...state,
      employeeData: setStateLoading(state.employeeData),
    }),
    setEmployeeData: (state, action: PayloadAction<UserCardDto>) => ({
      ...state,
      employeeData: setStateData(state.employeeData, action.payload),
    }),
    setErrorEmployeeData: (state, action: PayloadAction<string>) => ({
      ...state,
      employeeData: setStateError(state.employeeData, action.payload),
    }),
    resetEmployeeData: (state) => ({
      ...state,
      employeeData: defaultListState,
    }),
    // Positions for Edit basic info
    setLoadingPositions: (state) => ({
      ...state,
      positionsData: setStateLoading(state.positionsData),
    }),
    setPositions: (state, action: PayloadAction<string[]>) => ({
      ...state,
      positionsData: setStateData(state.positionsData, action.payload),
    }),
    setErrorPositions: (state, action: PayloadAction<string>) => ({
      ...state,
      positionsData: setStateError(state.positionsData, action.payload),
    }),
    // Departments for Edit basic info
    setLoadingDepartments: (state) => ({
      ...state,
      departmentsData: setStateLoading(state.departmentsData),
    }),
    setDepartments: (state, action: PayloadAction<string[]>) => ({
      ...state,
      departmentsData: setStateData(state.departmentsData, action.payload),
    }),
    setErrorDepartments: (state, action: PayloadAction<string>) => ({
      ...state,
      departmentsData: setStateError(state.departmentsData, action.payload),
    }),
    // Users filter for tabs of Structure block
    setUsersStructureBlockFilters: (
      state,
      action: PayloadAction<{ filterBy: string[]; userId: number }>
    ) => ({
      ...state,
      usersStructureData: {
        usersList: defaultListState,
        filterBy: action.payload.filterBy,
        userId: action.payload.userId,
      },
    }),
    // Users for chosen tab of Structure block
    setLoadingUsersStructureData: (state) => ({
      ...state,
      usersStructureData: {
        ...state.usersStructureData,
        usersList: setStateLoading(state.usersStructureData.usersList),
      },
    }),
    setUsersStructureData: (state, action: PayloadAction<User[]>) => ({
      ...state,
      usersStructureData: {
        ...state.usersStructureData,
        usersList: setStateData(
          state.usersStructureData.usersList,
          action.payload
        ),
      },
    }),
    setErrorUsersStructureData: (state, action: PayloadAction<string>) => ({
      ...state,
      usersStructureData: {
        ...state.usersStructureData,
        usersList: setStateError(
          state.usersStructureData.usersList,
          action.payload
        ),
      },
    }),
    resetEmployeePageState: () => initialState,
    resetPersonInfoPageState: (state) => ({
      ...state,
      employeeData: defaultListState,
      positionsData: defaultListState,
      departmentsData: defaultListState,
      usersStructureData: {
        filterBy: null,
        userId: null,
        usersList: defaultListState,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default employeePageSlice.reducer;

export const {
  setLoadingUserData,
  setUserData,
  setErrorUserData,
  resetUserData,
  setLoadingEmployeeData,
  setEmployeeData,
  setErrorEmployeeData,
  resetEmployeeData,
  setLoadingPositions,
  setPositions,
  setErrorPositions,
  setLoadingDepartments,
  setDepartments,
  setErrorDepartments,
  setUsersStructureBlockFilters,
  setLoadingUsersStructureData,
  setUsersStructureData,
  setErrorUsersStructureData,
  resetEmployeePageState,
  resetPersonInfoPageState,
} = employeePageSlice.actions;
