import { FC, useEffect } from "react";
import { useAppSelector } from "../store";
import { Outlet, useNavigate } from "react-router-dom";
import Loader from "../utils/components/Loader";

type RequireCompanyFeaturesGuardProps = {
  features: string[];
};

const RequireCompanyFeaturesGuard: FC<RequireCompanyFeaturesGuardProps> = ({
  features,
}) => {
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.auth.profile);
  const companyFeatures = profile?.companyAvailableFeatures || [];
  const isFeatureAvailable = companyFeatures.some((feature) =>
    features.includes(feature)
  );

  useEffect(() => {
    if (!isFeatureAvailable) {
      navigate("/news");
    }
  }, [profile]);

  if (profile) {
    if (isFeatureAvailable) {
      return <Outlet />;
    }
  }

  return (
    <div className="box">
      <div className="loader-container">
        <Loader />
      </div>
    </div>
  );
};

export default RequireCompanyFeaturesGuard;
