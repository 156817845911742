import { Dispatch } from "redux";
import {
  resetEmployeeData,
  resetUserData,
  setDepartments,
  setEmployeeData,
  setErrorDepartments,
  setErrorEmployeeData,
  setErrorPositions,
  setErrorUserData,
  setErrorUsersStructureData,
  setLoadingDepartments,
  setLoadingEmployeeData,
  setLoadingPositions,
  setLoadingUserData,
  setLoadingUsersStructureData,
  setPositions,
  setUserData,
  setUsersStructureData,
} from "../../reducers/employeePageReducers/employeePersonalInfoReducer";
import {
  loadBasicEmployeeInfo,
  loadUsersStructureData,
} from "../../../api/employeePageApi";
import { getErrorMessage } from "../../../utils/methods";
import { AxiosError } from "axios";
import {
  loadUserInfo,
  searchDepartments,
  searchPositions,
} from "../../../api/usersApi";
import { store } from "../../index";

// info for main user block (avatar, position, location)
export const getUserData =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingUserData());

    try {
      const response = await loadUserInfo(userId);
      dispatch(setUserData(response.data));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorUserData(errorMessage));
    }
  };

export const refreshUserData =
  (userId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetUserData());
    dispatch(getUserData(userId));
  };

// info for Personal info tab + Merch delivery tab (Delivery info, Sizes blocks)
export const getEmployeePageData =
  (userId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingEmployeeData());

    try {
      const response = await loadBasicEmployeeInfo(userId);
      dispatch(setEmployeeData(response.data));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorEmployeeData(errorMessage));
    }
  };

export const getPositionsForBasicInfo =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingPositions());

    try {
      const response = await searchPositions();
      dispatch(setPositions(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorPositions(errorMessage));
    }
  };

export const refreshEmployeePageData =
  (userId: number) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(resetEmployeeData());
    dispatch(getEmployeePageData(userId));
  };

export const getDepartmentsForBasicInfo =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingDepartments());

    try {
      const response = await searchDepartments();
      dispatch(setDepartments(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorDepartments(errorMessage));
    }
  };

export const getBasicInfoFormData =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    await Promise.all([
      dispatch(getPositionsForBasicInfo()),
      dispatch(getDepartmentsForBasicInfo()),
    ]);
  };

// info for Structure block on Personal info tab (direct reports, my team)
export const getUsersStructureData =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const { filterBy, userId } =
      store.getState().employeePage.usersStructureData;

    if (!filterBy || !userId) return;

    dispatch(setLoadingUsersStructureData());

    try {
      const response = await loadUsersStructureData(filterBy, userId);
      dispatch(setUsersStructureData(response.data.items));
    } catch (e) {
      const errorMessage = getErrorMessage(e as AxiosError);
      dispatch(setErrorUsersStructureData(errorMessage));
    }
  };
