import { useAppDispatch, useAppSelector } from "../../../store";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { getEssentialsMerch } from "../../../store/thunks/newsThunks";
import EssentialMerchCard from "./EssentialMerchCard";

const EssentialsMerch = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("news");
  const { t: tCommon } = useTranslation("common");
  const { isLoading, data, hasMoreData, errorMessage } = useAppSelector(
    (state) => state.news.essentialsMerch
  );

  const fetchData = async () => {
    await dispatch(getEssentialsMerch());
  };

  return (
    <div>
      {isLoading && !data && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="error">{errorMessage}</div>}
      {data && !data.length && (
        <p className="no-content-message">{t("noEssentialsMerchToDisplay")}</p>
      )}
      {data?.length ? (
        <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMoreData}
          loader={
            <div className="loader-container">
              <Loader size={LoaderSize.Small} />
            </div>
          }
          endMessage={
            <p className="end-message">{tCommon("infiniteScrollEndMessage")}</p>
          }
          style={{ overflow: "visible" }}
        >
          {data.map((merch) => {
            return (
              <EssentialMerchCard
                key={merch.id}
                merch={merch}
              />
            );
          })}
        </InfiniteScroll>
      ) : null}
    </div>
  );
};

export default EssentialsMerch;
