import { AxiosPromise } from "axios";
import { base_url, countItemsPerPage } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  CreateUserResponse,
  EditUserResponse,
  ImportParseFileResponse,
  ImportUsersRequest,
  ImportUsersResponse,
  SearchCriteriaResponse,
  SearchUsersRequest,
  UserProfile,
  UserSearchResponse,
  UsersPageDto,
} from "./types/usersTypes";
import { QueryParams, SearchRequest } from "./types/commonTypes";

export const loadUserInfo = (id: number): AxiosPromise<UserProfile> =>
  axiosInstance.get(`${base_url}/api/users/${id}`);

export const loadUsersOfSearch = (
  body: SearchUsersRequest
): AxiosPromise<UserSearchResponse> =>
  axiosInstance.post(`${base_url}/api/users/search`, body);

export const addNewCompanyMember = (
  body: FormData
): AxiosPromise<CreateUserResponse> =>
  axiosInstance.post(`${base_url}/api/users`, body);

export const sendInvite = (userId: number): AxiosPromise =>
  axiosInstance.post(`${base_url}/api/users/${userId}/reset-password`);

export const deleteUser = (userId: number): AxiosPromise =>
  axiosInstance.delete(`${base_url}/api/users/${userId}`);

export const editCompanyMember = (
  userId: number,
  body: FormData
): AxiosPromise<EditUserResponse> =>
  axiosInstance.put(`${base_url}/api/users/${userId}`, body);

export const searchDepartments = (
  body?: SearchRequest
): AxiosPromise<SearchCriteriaResponse> =>
  axiosInstance.post(`${base_url}/api/users/search/departaments`, body || {});

export const searchPositions = (
  body?: SearchRequest
): AxiosPromise<SearchCriteriaResponse> =>
  axiosInstance.post(`${base_url}/api/users/search/positions`, body || {});

export const searchLocations = (
  body?: SearchRequest
): AxiosPromise<SearchCriteriaResponse> =>
  axiosInstance.post(`${base_url}/api/users/search/locations`, body || {});

export const searchCountries = (
  body?: SearchRequest
): AxiosPromise<SearchCriteriaResponse> =>
  axiosInstance.post(`${base_url}/api/users/search/countries`, body || {});

export const importParseFile = (
  body: FormData
): AxiosPromise<ImportParseFileResponse> =>
  axiosInstance.post(`${base_url}/api/users/import/parse-file`, body);

export const importUsers = (
  body: ImportUsersRequest
): AxiosPromise<ImportUsersResponse> =>
  axiosInstance.post(`${base_url}/api/users/import`, body);

export const loadMembers = (
  body: QueryParams
): AxiosPromise<UsersPageDto> => axiosInstance.post(`${base_url}/api/users/list`, body);
