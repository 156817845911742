import { useState, FC, useMemo, Children, cloneElement } from "react";
import Select, {
  components,
  GroupBase,
  GroupHeadingProps,
  StylesConfig,
} from "react-select";
import { FieldProps, useFormikContext } from "formik";
import Icon from "./Icon";

export type OptionType = { label: string; value: string | number };
export interface GroupedOptionType extends GroupBase<OptionType> {}

interface CustomSelectCollapseProps extends FieldProps {
  groupedOptions: GroupedOptionType[];
  isMulti?: boolean;
  placeholder?: string;
  selectClassName?: string;
  optionFieldMapping: Record<string, string>;
}

const customStyles: StylesConfig<OptionType, false> = {
  option: (provided) => ({
    ...provided,
    paddingLeft: "40px !important",
  }),
  group: (provided) => ({
    ...provided,
    padding: "0 !important",
  }),
};

const CollapseArrow: FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => (
  <Icon
    href="#arrow-bold"
    svgClass={`ic-arrowBold ${!isCollapsed ? "down" : ""}`}
  />
);

const HideGroupHeading: FC<
  GroupHeadingProps<OptionType> & {
    toggleCollapse: () => void;
    isCollapsed: boolean;
  }
> = ({ toggleCollapse, isCollapsed, ...props }) => (
  <div onClick={toggleCollapse} className="select-collapse-header">
    <CollapseArrow isCollapsed={isCollapsed} />
    <components.GroupHeading {...props} />
  </div>
);

const MenuList: FC<any> = ({
  toggleGroupCollapse,
  collapsedGroups,
  ...props
}) => (
  <components.MenuList {...props}>
    {Children.map(props.children, (child) => {
      if (child && (child as any).type === components.Group) {
        const groupId = (child as any).key.toString();
        const isCollapsed = !!collapsedGroups[groupId];
        const toggleCollapse = () => toggleGroupCollapse(groupId);
        return cloneElement(child as any, {
          ...child.props,
          Heading: (headingProps: GroupHeadingProps<OptionType>) => (
            <HideGroupHeading
              {...headingProps}
              isCollapsed={isCollapsed}
              toggleCollapse={toggleCollapse}
            />
          ),
          children: isCollapsed ? null : child.props.children,
        });
      }
      return child;
    })}
  </components.MenuList>
);

const CustomSelectCollapse: FC<CustomSelectCollapseProps> = ({
  field,
  form,
  groupedOptions,
  isMulti = true,
  placeholder,
  selectClassName,
  optionFieldMapping,
}) => {
  const [collapsedGroups, setCollapsedGroups] = useState<
    Record<string, boolean>
  >({});

  const toggleGroupCollapse = (groupId: string) => {
    setCollapsedGroups((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }));
  };

  const { setFieldValue } = useFormikContext();

  const selectedOptions = useMemo(() => {
    return groupedOptions.flatMap((group) =>
      group.options.filter((option) => {
        if (!group.label) {
          return false;
        }
        const fieldName = optionFieldMapping[group.label];
        if (!fieldName) {
          return false;
        }
        const fieldPath = fieldName.split(".");
        const fieldValues =
          fieldPath.length > 1
            ? form.values[fieldPath[0]]?.[fieldPath[1]]
            : form.values[fieldName];
        return Array.isArray(fieldValues)
          ? fieldValues.includes(option.value)
          : fieldValues === option.value;
      })
    );
  }, [form.values, groupedOptions, optionFieldMapping]);

  const onChange = (selected: OptionType | OptionType[]) => {
    const selectedValues = Array.isArray(selected)
      ? selected.map((o) => o.value)
      : [selected].map((o) => o.value);

    Object.keys(optionFieldMapping).forEach((groupLabel) => {
      const fieldName = optionFieldMapping[groupLabel];
      const groupOptions =
        groupedOptions.find((group) => group.label === groupLabel)?.options ||
        [];
      const newValues = groupOptions
        .filter((option) => selectedValues.includes(option.value))
        .map((option) => option.value);

      setFieldValue(fieldName, isMulti ? newValues : newValues[0] || "");
    });
  };

  return (
    <Select
      {...field}
      options={groupedOptions}
      isMulti={isMulti}
      placeholder={placeholder}
      onChange={onChange as any}
      components={{
        GroupHeading: components.GroupHeading,
        MenuList: (props) => (
          <MenuList
            {...props}
            toggleGroupCollapse={toggleGroupCollapse}
            collapsedGroups={collapsedGroups}
          />
        ),
      }}
      classNamePrefix="select"
      className={`select__container ${selectClassName}`}
      value={selectedOptions}
      styles={customStyles}
    />
  );
};

export default CustomSelectCollapse;
