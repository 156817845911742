import classNames from "classnames";
import { FC } from "react";
import icons from "../../../sprite/templateIcons.svg";
import Loader from "../../../utils/components/Loader";

type RequestFeedbackAnimationProps = {
  isAnimationActive: boolean;
};

// TODO maybe it is better to use this library for loading in future
// https://github.com/derrickpelletier/react-loading-overlay

const RequestFeedbackAnimation: FC<RequestFeedbackAnimationProps> = ({
  isAnimationActive,
}) => (
  <>
    <div className="preloader-wrap">
      <Loader />
    </div>
    <div
      className={classNames("success-message", { active: isAnimationActive })}
    >
      <div className="plane-wrap">
        <div className="icon plane">
          <svg className="svg-icon">
            <use xlinkHref={`${icons}#plane`}></use>
          </svg>
        </div>

        <div className="air">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="icon star-1">
          <svg className="svg-icon">
            <use xlinkHref={`${icons}#star-1`}></use>
          </svg>
        </div>

        <div className="icon star-2">
          <svg className="svg-icon">
            <use xlinkHref={`${icons}#star-2`}></use>
          </svg>
        </div>

        <div className="icon star-3">
          <svg className="svg-icon">
            <use xlinkHref={`${icons}#star-3`}></use>
          </svg>
        </div>
      </div>
    </div>
  </>
);

export default RequestFeedbackAnimation;
