import { requestFeedback } from "../../api/employeePageApi";
import { getErrorMessage } from "./hangleServerError";
import { AxiosError } from "axios";
import { toastError } from "../toasts";
import i18next from "i18next";

export const handleRequestFeedback = async (
  userId: number,
  toggleAnimation: (v: boolean) => void
) => {
  document.body.classList.add("preloader");
  try {
    await requestFeedback({ userId: userId });
    setTimeout(() => {
      document.body.classList.remove("preloader");
      toggleAnimation(true);

      setTimeout(() => toggleAnimation(false), 3000);
    }, 300);
  } catch (e) {
    const errorMessage = getErrorMessage(
      e as AxiosError,
      i18next.t("errorRequestingFeedback", {
        ns: "employeeAchievements",
      }) as string
    );
    document.body.classList.remove("preloader");
    toastError(errorMessage);
  }
};
