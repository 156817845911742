import { UserCardContactsInfoDto } from "api/types/employeePageTypes";
import { FC } from "react";
import Icon from "utils/components/Icon";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { useEmployeeCardViewsPermissions } from "../hooks/useEmployeeCardViewsPermissions";
import facebookIcon from "../../../../sprite/contactsIcons/facebook.svg";
import instagramIcon from "../../../../sprite/contactsIcons/instagram.svg";
import slackIcon from "../../../../sprite/contactsIcons/slack.svg";
import CompanyHasIntegration from "../../../../auth/CompanyHasIntegration";
import { IntegrationTypes } from "../../../../utils/consts";
import { formatPhoneNumber } from "utils/components/CustomPhoneInput/phoneInputHelpers";

type Props = {
  contacts: UserCardContactsInfoDto;
};

const ContactsInfo: FC<Props> = ({ contacts }) => {
  const currentUser = useIsCurrentUser();
  const permissions = useEmployeeCardViewsPermissions();
  const shouldShowPhoneNumber = currentUser || permissions.isViewEmployeePhone;
  const shouldShowFacebook =
    (currentUser || permissions.isViewEmployeeSocialNetworks) &&
    contacts.facebook;
  const shouldShowInstagram =
    (currentUser || permissions.isViewEmployeeSocialNetworks) &&
    contacts.instagram;
  const shouldShowLinkedin =
    (currentUser || permissions.isViewEmployeeSocialNetworks) &&
    contacts.linkedIn;
  const shouldShowTwitch =
    (currentUser || permissions.isViewEmployeeSocialNetworks) &&
    contacts.twitch;
  const shouldShowSlack =
    (currentUser || permissions.isViewEmployeeSocialNetworks) && contacts.slack;

  return (
    <div className="employeePage__item">
      <p className="employeePage__label">
        <Icon href="#mailBox" svgClass="contactsBlock__icon" />
      </p>
      <p className="employeePage__value">{contacts.email}</p>

      <p className="employeePage__label">
        <Icon href="#phone" svgClass="contactsBlock__icon" />
      </p>

      {shouldShowPhoneNumber ? (
        <p className="employeePage__value">
          {formatPhoneNumber(contacts.phoneNumber)}
        </p>
      ) : null}

      <CompanyHasIntegration requiredIntegration={IntegrationTypes.Slack}>
        {shouldShowSlack ? (
          <a
            className="employeePage__value"
            href={contacts.slack || ""}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={slackIcon}
              alt="facebook"
              className="contactsBlock__icon contactsBlock__icon--big"
            />
          </a>
        ) : null}
      </CompanyHasIntegration>

      <div className="contactsBlock__icons-block">
        {shouldShowFacebook ? (
          <a
            className="employeePage__value"
            href={contacts.facebook || ""}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={facebookIcon}
              alt="facebook"
              className="contactsBlock__icon contactsBlock__icon--big"
            />
          </a>
        ) : null}

        {shouldShowTwitch ? (
          <a
            className="employeePage__value"
            href={contacts.twitch || ""}
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              href="#twitter"
              svgClass="contactsBlock__icon contactsBlock__icon--big"
            />
          </a>
        ) : null}

        {shouldShowLinkedin ? (
          <a
            className="employeePage__value"
            href={contacts.linkedIn || ""}
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              href="#linkedIn"
              svgClass="contactsBlock__icon contactsBlock__icon--big"
            />
          </a>
        ) : null}

        {shouldShowInstagram ? (
          <a
            className="employeePage__value"
            href={contacts.instagram || ""}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={instagramIcon}
              alt="facebook"
              className="contactsBlock__icon contactsBlock__icon--big"
            />
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default ContactsInfo;
