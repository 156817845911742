import { EmploymentTypes } from "api/types/employeePageTypes";
import { TFunction } from "i18next";

export function getEmploymentTypeNameLocalized(
  t: TFunction<"employeePage">,
  type: EmploymentTypes | string | null
): string {
  if (type) {
    return t([`employmentType_${type}`, type]);
  }

  return "";
}
