import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MerchItemDto } from "api/types/merchStoreTypes";
import { getCoverImageUrl } from "utils/methods/getCoverImageUrl";

type EssentialMerchCardProps = {
  merch: MerchItemDto;
};

const EssentialMerchCard: FC<EssentialMerchCardProps> = ({ merch }) => {
  const { t } = useTranslation("events");
  const { id, name, coins, documents, coverImageId } = merch;
  const coverImageUrl = getCoverImageUrl(documents, coverImageId);

  return (
    <Link to={`/merch-store/merches/${id}`} className="box essentialCard">
      <img
        src={coverImageUrl}
        alt="event"
        className="essentialCard__img essentialCard__img--merch"
      />
      <div className="essentialCard__info">
        <div className="d-flex ">
          <p className="essentialCard__name not-mb">{name}</p>

          <div className="d-flex">
            <div className="achievementCard__label achievementCard__coins">
              {`${t("achievementCoins", { count: coins })}`}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EssentialMerchCard;
