import { useAppSelector } from "../../store";

export const usePermissionCheck = (permissions: string[]): boolean => {
  const profilePermissions =
    useAppSelector((state) => state.auth.profile?.permissions) || [];

  return profilePermissions.some((profilePermission) =>
    permissions.includes(profilePermission)
  );
};
