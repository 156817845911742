import { updateContacts } from "api/employeePageApi";
import { UpdateContactsRequest } from "api/types/employeePageTypes";
import { AxiosError } from "axios";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { refreshEmployeePageData } from "store/thunks/employeePage/employeePageThunks";
import { serverError } from "types/serverError";
import {
  AggregateErrorMessage,
  CustomPhoneInput,
  Icon,
} from "utils/components";
import { ButtonLoader } from "utils/components";
import { disableUserFieldForEdit, handleServerError } from "utils/methods";
import * as Yup from "yup";
import { toastError, toastSuccess } from "utils/toasts";
import facebookIcon from "../../../../sprite/contactsIcons/facebook.svg";
import instagramIcon from "../../../../sprite/contactsIcons/instagram.svg";
import slackIcon from "../../../../sprite/contactsIcons/slack.svg";
import CompanyHasIntegration from "../../../../auth/CompanyHasIntegration";
import { IntegrationTypes } from "../../../../utils/consts";
import { validatePhoneNumber } from "utils/components/CustomPhoneInput/phoneInputHelpers";
import { useDisableEditDeleteUser } from "utils/hooks/useDisableEditDeleteUser";

export type ContactsValues = {
  email: string;
  phoneNumber: string;
  linkedIn: string;
  instagram: string;
  facebook: string;
  twitch: string;
  slack: string;
};

type ContactsFormProps = {
  initialValues: ContactsValues;
  userId: number;
  setEditMode: (v: false) => void;
};

const ContactsForm: FC<ContactsFormProps> = ({
  initialValues,
  setEditMode,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const { t: tCommon } = useTranslation("common");
  const { t, i18n } = useTranslation("employeePage");
  const { t: tAccountSettings } = useTranslation("accountSettings");
  const { data } = useAppSelector((state) => state.employeePage.employeeData);
  const isEditDisabled = useDisableEditDeleteUser(data?.basicInfo.source);

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(tCommon("validation_email") as string)
      .required(tCommon("validation_fieldIsRequired") as string),
    phoneNumber: Yup.string()
      .nullable()
      .test({
        name: "isValidPhoneNumber",
        exclusive: false,
        message: tCommon("invalid_phone_number") as string,
        test: (value) => {
          return !value || validatePhoneNumber(value);
        },
      }),
  });
  const handleSubmit = async (
    values: ContactsValues,
    { setErrors }: FormikHelpers<ContactsValues>
  ) => {
    const requestBody: UpdateContactsRequest = {
      email: values.email,
      phoneNumber: values.phoneNumber || null,
      facebook: values.facebook || null,
      instagram: values.instagram || null,
      linkedIn: values.linkedIn || null,
      twitch: values.twitch || null,
      slack: values.slack || null,
    };

    try {
      await updateContacts(userId, requestBody);
      toastSuccess(tAccountSettings("userUpdatedToast"));
      dispatch(refreshEmployeePageData(userId));
      setEditMode(false);
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("incorrectData"));
      setErrors(handleServerError(error || {}));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {(props: FormikProps<ContactsValues>) => {
        const { isSubmitting } = props;

        return (
          <Form className="form-style">
            <AggregateErrorMessage component="div" className="error" />

            <div className="employeePage__item contactsBlock__form-item">
              <Icon href="#mailBox" svgClass="contactsBlock__icon" />
              <div>
                <Field
                  type="email"
                  name="email"
                  placeholder={t("email_placeholder")}
                  className="form-input personInfoPage__input"
                  disabled={disableUserFieldForEdit("email", isEditDisabled)}
                />
                <ErrorMessage component="p" name="email" className="error" />
              </div>

              <Icon href="#phone" svgClass="contactsBlock__icon" />
              <div>
                <Field
                  id="phoneNumber"
                  type="text"
                  component={CustomPhoneInput}
                  name="phoneNumber"
                  placeholder={t("receiverPhone")}
                  className="form-input personInfoPage__input"
                  lang={i18n.language}
                  disabled={disableUserFieldForEdit(
                    "phoneNumber",
                    isEditDisabled
                  )}
                />
                <ErrorMessage
                  component="p"
                  name="phoneNumber"
                  className="error"
                />
              </div>
              <CompanyHasIntegration
                requiredIntegration={IntegrationTypes.Slack}
              >
                <img
                  src={slackIcon}
                  alt="slack"
                  className="contactsBlock__icon"
                />
                <div>
                  <Field
                    type="url"
                    name="slack"
                    placeholder="https://workspace.slack.com/team/profileId"
                    className="form-input personInfoPage__input"
                    disabled={disableUserFieldForEdit("slack", isEditDisabled)}
                  />
                  <ErrorMessage component="p" name="slack" className="error" />
                </div>
              </CompanyHasIntegration>

              <img
                src={facebookIcon}
                alt="facebook"
                className="contactsBlock__icon"
              />
              <div>
                <Field
                  type="url"
                  name="facebook"
                  placeholder="https://facebook.com"
                  className="form-input personInfoPage__input"
                  disabled={disableUserFieldForEdit("facebook", isEditDisabled)}
                />
                <ErrorMessage component="p" name="facebook" className="error" />
              </div>

              <Icon href="#twitter" svgClass="contactsBlock__icon" />
              <div>
                <Field
                  type="url"
                  name="twitch"
                  placeholder="https://twitter.com"
                  className="form-input personInfoPage__input"
                  disabled={disableUserFieldForEdit("twitch", isEditDisabled)}
                />
                <ErrorMessage component="p" name="twitch" className="error" />
              </div>

              <Icon href="#linkedIn" svgClass="contactsBlock__icon" />
              <div>
                <Field
                  type="url"
                  name="linkedIn"
                  placeholder="https://linkedIn.com"
                  className="form-input personInfoPage__input"
                  disabled={disableUserFieldForEdit("linkedIn", isEditDisabled)}
                />
                <ErrorMessage component="p" name="linkedIn" className="error" />
              </div>

              <img
                src={instagramIcon}
                alt="instagram"
                className="contactsBlock__icon"
              />
              <div>
                <Field
                  type="url"
                  name="instagram"
                  placeholder="https://instagram.com"
                  className="form-input personInfoPage__input"
                  disabled={disableUserFieldForEdit(
                    "instagram",
                    isEditDisabled
                  )}
                />
                <ErrorMessage
                  component="p"
                  name="instagram"
                  className="error"
                />
              </div>
            </div>

            <div className="formButtons">
              <button
                type="button"
                className="btn-tertiary formButtons__formBtn"
                disabled={isSubmitting}
                onClick={() => setEditMode(false)}
              >
                {tCommon("form_cancel")}
              </button>
              <button
                type="submit"
                className="btn-primary formButtons__formBtn"
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoader /> : tCommon("form_save")}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactsForm;
