import { UserListItemDto, User, UserProfile } from "../../api/types/usersTypes";
import { getDateTimeString } from "./getDateTimeString";
import { microsoftEntraIdUserFields } from "../consts";

export const getUserDetails = (user: Partial<UserProfile>) => {
  const displayedFullName = getFullName(user.firstName, user.familyName, "...");
  const receivedBadges =
    typeof user.receivedBadges === "number" ? user.receivedBadges : "...";
  const position = user.position ? user.position : "";
  const getAvatarSignFromProfile = (): string => {
    return getAvatarSign(user.firstName, user.familyName);
  };
  const avatarSign = getAvatarSignFromProfile();

  return {
    displayedFullName,
    receivedBadges,
    position,
    avatarSign,
  };
};

export const getAccountUserDetails = (
  user: UserListItemDto,
  locale: Locale
) => {
  const { manager } = user;
  const displayedFullName = getFullNameFromUser(user);
  const displayedManagerFullName = manager
    ? getFullName(manager.firstName, manager.familyName)
    : "";
  const position = user.position || "";
  const hireDate = user.hireDate
    ? getDateTimeString(user.hireDate, "fullDate", locale)
    : "";
  const lastVisit = user.lastVisit
    ? getDateTimeString(user.lastVisit, "fullDate", locale)
    : "";
  const department = user.departament || "";

  return {
    displayedFullName,
    displayedManagerFullName,
    position,
    hireDate,
    lastVisit,
    department,
  };
};

export const getFullNameFromUser = (
  user: {
    firstName: string | null | undefined;
    familyName: string | null | undefined;
  },
  emptyNamePlaceholder?: string | undefined
) => {
  return getFullName(user.firstName, user.familyName, emptyNamePlaceholder);
};

export const getFullName = (
  firstName: string | null | undefined,
  familyName: string | null | undefined,
  emptyNamePlaceholder?: string | undefined
) => {
  emptyNamePlaceholder = emptyNamePlaceholder ?? "";

  const name = firstName ? `${firstName}` : "";
  const surname = familyName ? `${familyName}` : "";
  const fullName = name + " " + surname;
  return fullName.trim() ? fullName : emptyNamePlaceholder;
};

export const getAvatarSignFromUser = (
  user: User,
  emptySignPlaceholder?: string | undefined
): string => {
  return getAvatarSign(user.firstName, user.familyName, emptySignPlaceholder);
};

export const getAvatarSign = (
  firstName: string | null | undefined,
  familyName: string | null | undefined,
  emptySignPlaceholder?: string | undefined
) => {
  emptySignPlaceholder = emptySignPlaceholder ?? "U";

  if (
    firstName &&
    familyName &&
    firstName.length > 0 &&
    familyName.length > 0
  ) {
    return `${firstName[0]}${familyName[0]}`;
  }

  if (firstName && firstName.length > 0) {
    return firstName[0];
  }

  if (familyName && familyName.length > 0) {
    return familyName[0];
  }

  return emptySignPlaceholder;
};

export const disableUserFieldForEdit = (
  fieldName: string,
  isEditDisabled: boolean
): boolean => {
  if (!microsoftEntraIdUserFields.hasOwnProperty(fieldName)) return false;

  return (
    microsoftEntraIdUserFields[
      fieldName as keyof typeof microsoftEntraIdUserFields
    ] && isEditDisabled
  );
};
