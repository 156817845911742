import { useAppDispatch, useAppSelector } from "../../../store";
import Loader, { LoaderSize } from "../../../utils/components/Loader";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { getEssentialsAchievements } from "../../../store/thunks/newsThunks";
import EssentialAchievementCard from "./EssentialAchievementCard";

const EssentialsAchievements = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("news");
  const { t: tCommon } = useTranslation("common");
  const { isLoading, data, hasMoreData, errorMessage } = useAppSelector(
    (state) => state.news.essentialsAchievements
  );

  const fetchData = async () => {
    await dispatch(getEssentialsAchievements());
  };

  return (
    <div>
      {isLoading && !data && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="error">{errorMessage}</div>}
      {data && !data.length && (
        <p className="no-content-message">
          {t("noEssentialsAchievementsToDisplay")}
        </p>
      )}
      {data?.length ? (
        <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMoreData}
          loader={
            <div className="loader-container">
              <Loader size={LoaderSize.Small} />
            </div>
          }
          endMessage={
            <p className="end-message">{tCommon("infiniteScrollEndMessage")}</p>
          }
          style={{ overflow: "visible" }}
        >
          {data.map((achievement) => {
            return (
              <EssentialAchievementCard
                key={achievement.id}
                achievement={achievement}
              />
            );
          })}
        </InfiniteScroll>
      ) : null}
    </div>
  );
};

export default EssentialsAchievements;
