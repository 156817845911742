import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { Icon, Loader, LoaderSize } from "utils/components";
import { AppPermissions } from "utils/consts";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { usePermissionCheck } from "utils/hooks";
import BasicInfoForm, { BasicInfoValues } from "./BasicInfoForm";
import BasicInfoList from "./BasicInfoList";

const BasicInfoBlock = () => {
  const { t } = useTranslation("employeePage");
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.employeePage.employeeData
  );
  const isUserHavePermissions = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
  ]);
  const currentUser = useIsCurrentUser();

  const [editMode, setEditMode] = useState(false);
  const initialFormValues: BasicInfoValues = {
    firstName: data?.basicInfo.firstName || "",
    familyName: data?.basicInfo.familyName || "",
    pronouns: data?.basicInfo.pronouns || "",
    position: data?.basicInfo.position || "",
    new_position: "",
    departament: data?.basicInfo.departament || "",
    new_departament: "",
    country: data?.basicInfo.country || "",
    location: data?.basicInfo.location || "",
    managerId: data?.basicInfo.manager
      ? {
          value: data.basicInfo.manager.id,
          label: `${data.basicInfo.manager.firstName} ${data.basicInfo.manager.familyName}`,
        }
      : null,
    employmentType: data?.basicInfo.employmentType || null,
  };

  const isHaveAccessToEdit = data && (currentUser || isUserHavePermissions);

  return (
    <div className="basicInfo">
      <div className="pageTitleBlock">
        <h1 className="main-title">{t("basicInfo_title")}</h1>

        {isHaveAccessToEdit && (
          <button
            type="button"
            className="btn-tertiary actionBtn-tertiary"
            onClick={() => setEditMode((prevState) => !prevState)}
          >
            <Icon
              href={editMode ? "#close-popup" : "#edite"}
              svgClass="buttonIcon"
            />
          </button>
        )}
      </div>

      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}

      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}

      {data && (
        <>
          {editMode ? (
            <BasicInfoForm
              userId={data.id}
              company={data.basicInfo.companyName}
              employmentType={data.basicInfo.employmentType}
              initialValues={initialFormValues}
              setEditMode={setEditMode}
            />
          ) : (
            <div className="employeePage__item">
              <BasicInfoList isShowPronouns={true} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BasicInfoBlock;
