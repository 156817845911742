import { useTranslation } from "react-i18next";
import enUs from "rsuite/locales/en_US";
import ruRu from "rsuite/locales/ru_RU";
import { default as ukUa } from "./rsuite/locales/uk_UA";

export default function useRsuiteLocale() {
  const { i18n } = useTranslation("common");

  const currentLanguage = i18n.language;
  switch (currentLanguage) {
    case "ru": {
      return ruRu;
    }
    case "uk": {
      return ukUa;
    }
    case "en":
    default: {
      return enUs;
    }
  }
}
