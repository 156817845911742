import { AchievementFilterStatus } from "api/types/achievementTypes";

export const getCoinsComplete = (filterStatus: AchievementFilterStatus) => {
  if (filterStatus !== AchievementFilterStatus.applied) {
    return;
  }
  
  return {
    className: "achievementCard__label--complete",
    renderCompleteIcon: true,
  };
};
