import { TFunction } from "i18next";
import { getDateTimeString } from "utils/methods";

export const getAchievementTimeline = (
  t: TFunction<"events">,
  startDate: string | null,
  activeUntil: string | null,
  locale: Locale | undefined
): string => {
  if (!locale) return "";

  let shortStartDate = "";
  let shortUntilDate = "";

  if (startDate) {
    shortStartDate = getDateTimeString(
      startDate,
      "fullDate",
      locale
    ).replace(/[,.]/g, "");
  }

  if (activeUntil) {
    shortUntilDate = getDateTimeString(
      activeUntil,
      "fullDate",
      locale
    ).replace(/[,.]/g, "");
  }

  if (startDate && activeUntil) {
    return `${shortStartDate} - ${shortUntilDate}`;
  }

  if (startDate && !activeUntil) {
    return `${t("achievement_from")} ${shortStartDate}`;
  }

  if (!startDate && activeUntil) {
    return `${t("achievement_until")} ${shortUntilDate}`;
  }

  return "";
};
