import _debounce from "lodash/debounce";
import { SearchUsersRequest } from "../../api/types/usersTypes";
import { loadUsersOfSearch } from "../../api/usersApi";
import { getErrorMessage } from "./hangleServerError";
import { AxiosError } from "axios";

export const searchUsersOptions = _debounce(
  (
    inputValue: string,
    callback: (options: { value: number; label: string }[]) => void,
    setError: (errorMessage: string) => void,
    userId: number | null
  ) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }
    const body: SearchUsersRequest = {
      query: inputValue,
      filterCurrentUser: false,
    };
    loadUsersOfSearch(body)
      .then((response) => {
        const options = response.data.items
          .filter((user) => !userId || user.id !== userId)
          .map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.familyName}`,
          }));
        callback(options);
      })
      .catch((e) => {
        const errorMessage = getErrorMessage(e as AxiosError);
        setError(errorMessage);
        callback([]);
      });
  },
  750
);
