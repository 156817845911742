import { FC, ReactNode } from "react";
import { UserOfSearch } from "../../../api/types/usersTypes";
import { getAvatarSign, getFullName } from "../../../utils/methods";
import { AppPermissions } from "../../../utils/consts";
import { Whisper } from "rsuite";
import Icon from "../../../utils/components/Icon";
import HasPermissions from "../../../auth/HasPermissions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { Placement } from "../../../api/types/commonTypes";

type StructureCardProps = {
  children?: ReactNode;
  user: UserOfSearch;
  isMenuRender: boolean;
  additionalUserInfo?: string | null;
  placementPopover?: Placement;
};

const getWhisperPlacement = (windowWidth: number): Placement => {
  if (windowWidth > 800 && windowWidth < 1500) return "bottomEnd";

  return "bottomStart";
};

const StructureCard: FC<StructureCardProps> = ({
  user,
  isMenuRender,
  children,
  additionalUserInfo,
  placementPopover,
}) => {
  const { width } = useWindowSize();
  const whisperPlacement = getWhisperPlacement(width);
  const navigate = useNavigate();
  const { t: tOrgChart } = useTranslation("orgChart");
  const { t: tCommon } = useTranslation("common");
  const { id, avatarImageUrl, firstName, familyName, position } = user;

  const speaker = (
    <div className="customPopover structureCard__popover">
      <HasPermissions
        requiredPermissions={[AppPermissions.ViewCompanyOrgStructure]}
      >
        <button
          type="button"
          className="customPopover__btn"
          onClick={() => navigate(`/org-structure/${id}`)}
        >
          <Icon svgClass="btnIcon" href="#orgChart" />
          <p>{tOrgChart("seeAtOrgChart")}</p>
        </button>
      </HasPermissions>

      <HasPermissions
        requiredPermissions={[
          AppPermissions.ViewAndEditUserEmployeeCards,
          AppPermissions.EmployeeCard_ViewUsersEmployeeCard,
        ]}
      >
        <button
          type="button"
          className="customPopover__btn"
          onClick={() => navigate(`/employee/${id}`)}
        >
          <Icon svgClass="btnIcon" href="#user" />
          <p>{tCommon("viewProfile")}</p>
        </button>
      </HasPermissions>

      {children}
    </div>
  );

  return (
    <div className="box structureCard">
      <div className="structureCard__avatarPlaceholder">
        {avatarImageUrl ? (
          <img
            src={avatarImageUrl}
            alt="user"
            className="structureCard__avatar"
          />
        ) : (
          <span className="avatarSign">
            {getAvatarSign(firstName, familyName)}
          </span>
        )}
      </div>

      <div className="structureCard__userInfo">
        <p className="structureCard__name">
          {getFullName(firstName, familyName)}
        </p>

        {position && <p className="structureCard__position">{position}</p>}
        {additionalUserInfo && (
          <p className="structureCard__position">{additionalUserInfo}</p>
        )}
      </div>

      {isMenuRender && (
        <Whisper speaker={speaker} trigger="click" placement={placementPopover || whisperPlacement}>
          <button
            type="button"
            className="btn-regular actionBtn-tertiary structureCard__menuBtn"
          >
            <Icon href="#dots" svgClass="ic-dots" />
          </button>
        </Whisper>
      )}
    </div>
  );
};

export default StructureCard;
