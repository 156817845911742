import {
  AchievementsAnalyticsState,
  PeopleEngagementFilters,
  PeopleOverEventsFilters,
} from "../types";
import {
  defaultLastMonthRange,
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import {
  AchievementAnalyticsSortBy,
  AchievementMostActiveUsersItemDto,
  AchievementsInsightsDto,
  AchievementsParticipantsRatingItemDto,
  CategoryWithAchievementsDto,
  CategoryWithParticipantDto,
  EmployeeEngagementStatuses,
  PeopleEventsEngagementStatsDto,
  PeopleOverEventsItemDto,
  PeopleOverEventsMainFilters,
} from "../../api/types/achievementsAnalyticsTypes";
import { logoutUserAction } from "./authReducer";
import { ClientDateRange, SortDirections } from "api/types/commonTypes";

const initialState: AchievementsAnalyticsState = {
  categoryAchievementsCountPeople: defaultListState,
  categoriesWithAchievementsCount: defaultListState,
  peopleAchievementsEngagement: {
    filters: {
      dateRange: defaultLastMonthRange,
      engagementStatus: EmployeeEngagementStatuses.all,
    },
    chartData: defaultListState,
  },
  achievementsRatingList: defaultListState,
  achievementsInsights: defaultListState,
  achievementsMostActiveUsers: {
    mostActiveUsersFilters: {
      dateRange: defaultLastMonthRange,
    },
    sortingActiveUsers: {
      sortBy: AchievementAnalyticsSortBy.TotalCoins,
      sortDirection: SortDirections.desc,
    },
    mostActiveUsersList: defaultStateWithInfiniteLoading,
  },
  peopleOverEvents: {
    peopleOverEventsFilters: {
      dateRange: defaultLastMonthRange,
      tabFilter: PeopleOverEventsMainFilters.position,
    },
    peopleOverEventsList: defaultStateWithInfiniteLoading,
    sortingPeopleOverEvents: {
      sortBy: AchievementAnalyticsSortBy.TotalCoins,
      sortDirection: SortDirections.desc,
    },
  },
};

const achievementsAnalyticsSlice = createSlice({
  initialState,
  name: "achievementsAnalytics",
  reducers: {
    // Data for chart: categories of achievements by count of people
    setLoadingCategoryAchievementsCountPeople: (state) => ({
      ...state,
      categoryAchievementsCountPeople: setStateLoading(
        state.categoryAchievementsCountPeople
      ),
    }),
    setCategoryAchievementsCountPeople: (
      state,
      action: PayloadAction<CategoryWithParticipantDto[]>
    ) => ({
      ...state,
      categoryAchievementsCountPeople: setStateData(
        state.categoryAchievementsCountPeople,
        action.payload
      ),
    }),
    setErrorCategoryAchievementsCountPeople: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      categoryAchievementsCountPeople: setStateError(
        state.categoryAchievementsCountPeople,
        action.payload
      ),
    }),
    // Data for pie chart: categories by count of achievements
    setLoadingCategoriesWithAchievementsCount: (state) => ({
      ...state,
      categoriesWithAchievementsCount: setStateLoading(
        state.categoriesWithAchievementsCount
      ),
    }),
    setCategoriesWithAchievementsCount: (
      state,
      action: PayloadAction<CategoryWithAchievementsDto[]>
    ) => ({
      ...state,
      categoriesWithAchievementsCount: setStateData(
        state.categoriesWithAchievementsCount,
        action.payload
      ),
    }),
    setErrorCategoriesWithAchievementsCount: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      categoriesWithAchievementsCount: setStateError(
        state.categoriesWithAchievementsCount,
        action.payload
      ),
    }),
    // Data for People over events table
    setLoadingPeopleOverEventsList: (state) => ({
      ...state,
      peopleOverEvents: {
        ...state.peopleOverEvents,
        peopleOverEventsList: setInfiniteLoadLoading(
          state.peopleOverEvents.peopleOverEventsList
        ),
      },
    }),
    setPeopleOverEventsList: (
      state,
      action: PayloadAction<{
        peopleOverEventsData: PeopleOverEventsItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let peopleList = state.peopleOverEvents.peopleOverEventsList.data || [];
      peopleList = [...peopleList, ...action.payload.peopleOverEventsData];

      return {
        ...state,
        peopleOverEvents: {
          ...state.peopleOverEvents,
          peopleOverEventsList: setInfiniteLoadData(
            state.peopleOverEvents.peopleOverEventsList,
            peopleList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorPeopleOverEventsList: (state, action: PayloadAction<string>) => ({
      ...state,
      peopleOverEvents: {
        ...state.peopleOverEvents,
        peopleOverEventsList: setInfiniteLoadStateErrorMessage(
          state.peopleOverEvents.peopleOverEventsList,
          action.payload
        ),
      },
    }),
    setPeopleOverEventsListSorting: (
      state,
      action: PayloadAction<[AchievementAnalyticsSortBy, SortDirections]>
    ) => ({
      ...state,
      peopleOverEvents: {
        ...state.peopleOverEvents,
        sortingPeopleOverEvents: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    setPeopleOverEventsFilters: (
      state,
      action: PayloadAction<PeopleOverEventsFilters>
    ) => ({
      ...state,
      peopleOverEvents: {
        ...state.peopleOverEvents,
        peopleOverEventsFilters: {
          tabFilter: action.payload.tabFilter,
          dateRange: action.payload.dateRange,
        },
      },
    }),
    resetPeopleOverEventsList: (state) => ({
      ...state,
      peopleOverEvents: {
        ...state.peopleOverEvents,
        peopleOverEventsList: defaultStateWithInfiniteLoading,
      },
    }),
    //Data for area chart: people achievements engagement
    setEmployeeEngagementFilter: (
      state,
      action: PayloadAction<PeopleEngagementFilters>
    ) => ({
      ...state,
      peopleAchievementsEngagement: {
        ...state.peopleAchievementsEngagement,
        filters: {
          dateRange: action.payload.dateRange,
          engagementStatus: action.payload.engagementStatus,
        },
      },
    }),
    setLoadingPeopleAchievementsEngagement: (state) => ({
      ...state,
      peopleAchievementsEngagement: {
        ...state.peopleAchievementsEngagement,
        chartData: setStateLoading(
          state.peopleAchievementsEngagement.chartData
        ),
      },
    }),
    setPeopleAchievementsEngagement: (
      state,
      action: PayloadAction<PeopleEventsEngagementStatsDto[]>
    ) => ({
      ...state,
      peopleAchievementsEngagement: {
        ...state.peopleAchievementsEngagement,
        chartData: setStateData(
          state.peopleAchievementsEngagement.chartData,
          action.payload
        ),
      },
    }),
    setErrorPeopleAchievementsEngagement: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      peopleAchievementsEngagement: {
        ...state.peopleAchievementsEngagement,
        chartData: setStateError(
          state.peopleAchievementsEngagement.chartData,
          action.payload
        ),
      },
    }),
    // Achievements participants rating
    setLoadingAchievementsRatingList: (state) => ({
      ...state,
      achievementsRatingList: setStateLoading(state.achievementsRatingList),
    }),
    setAchievementsRatingList: (
      state,
      action: PayloadAction<AchievementsParticipantsRatingItemDto[]>
    ) => ({
      ...state,
      achievementsRatingList: setStateData(
        state.achievementsRatingList,
        action.payload
      ),
    }),
    setErrorAchievementsRatingList: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementsRatingList: setStateError(
        state.achievementsRatingList,
        action.payload
      ),
    }),
    // Achievements insights
    setLoadingAchievementsInsights: (state) => ({
      ...state,
      achievementsInsights: setStateLoading(state.achievementsInsights),
    }),
    setAchievementsInsights: (
      state,
      action: PayloadAction<AchievementsInsightsDto>
    ) => ({
      ...state,
      achievementsInsights: setStateData(
        state.achievementsInsights,
        action.payload
      ),
    }),
    setErrorAchievementsInsights: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementsInsights: setStateError(
        state.achievementsInsights,
        action.payload
      ),
    }),
    setLoadingAchievementsMostActiveUsers: (state) => ({
      ...state,
      achievementsMostActiveUsers: {
        ...state.achievementsMostActiveUsers,
        mostActiveUsersList: setInfiniteLoadLoading(
          state.achievementsMostActiveUsers.mostActiveUsersList
        ),
      },
    }),
    setAchievementsMostActiveUsers: (
      state,
      action: PayloadAction<{
        achievementsMostActiveUsers: AchievementMostActiveUsersItemDto[];
        hasMoreData: boolean;
        page: number;
      }>
    ) => {
      let usersList =
        state.achievementsMostActiveUsers.mostActiveUsersList.data || [];
      usersList = [...usersList, ...action.payload.achievementsMostActiveUsers];

      return {
        ...state,
        achievementsMostActiveUsers: {
          ...state.achievementsMostActiveUsers,
          mostActiveUsersList: setInfiniteLoadData(
            state.achievementsMostActiveUsers.mostActiveUsersList,
            usersList,
            action.payload.hasMoreData,
            action.payload.page
          ),
        },
      };
    },
    setErrorAchievementsMostActiveUsers: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementsMostActiveUsers: {
        ...state.achievementsMostActiveUsers,
        mostActiveUsersList: setInfiniteLoadStateErrorMessage(
          state.achievementsMostActiveUsers.mostActiveUsersList,
          action.payload
        ),
      },
    }),
    setMostActiveUsersListSorting: (
      state,
      action: PayloadAction<[AchievementAnalyticsSortBy, SortDirections]>
    ) => ({
      ...state,
      achievementsMostActiveUsers: {
        ...state.achievementsMostActiveUsers,
        sortingActiveUsers: {
          sortBy: action.payload[0],
          sortDirection: action.payload[1],
        },
      },
    }),
    setMostActiveUsersFilters: (
      state,
      action: PayloadAction<{ dateRange: ClientDateRange }>
    ) => ({
      ...state,
      achievementsMostActiveUsers: {
        ...state.achievementsMostActiveUsers,
        mostActiveUsersFilters: {
          ...state.achievementsMostActiveUsers.mostActiveUsersFilters,
          dateRange: action.payload.dateRange,
        },
      },
    }),
    resetAchievementsMostActiveUsers: (state) => ({
      ...state,
      achievementsMostActiveUsers: {
        ...state.achievementsMostActiveUsers,
        mostActiveUsersList: defaultStateWithInfiniteLoading,
      },
    }),
    resetAchievementsAnalyticsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => initialState);
  },
});

export default achievementsAnalyticsSlice.reducer;

export const {
  setLoadingCategoryAchievementsCountPeople,
  setCategoryAchievementsCountPeople,
  setErrorCategoryAchievementsCountPeople,
  setLoadingCategoriesWithAchievementsCount,
  setCategoriesWithAchievementsCount,
  setErrorCategoriesWithAchievementsCount,
  setLoadingPeopleOverEventsList,
  setPeopleOverEventsList,
  setErrorPeopleOverEventsList,
  setPeopleOverEventsListSorting,
  setPeopleOverEventsFilters,
  resetPeopleOverEventsList,
  setEmployeeEngagementFilter,
  setLoadingPeopleAchievementsEngagement,
  setPeopleAchievementsEngagement,
  setErrorPeopleAchievementsEngagement,
  setLoadingAchievementsRatingList,
  setAchievementsRatingList,
  setErrorAchievementsRatingList,
  setLoadingAchievementsInsights,
  setAchievementsInsights,
  setErrorAchievementsInsights,
  resetAchievementsAnalyticsState,
  setLoadingAchievementsMostActiveUsers,
  setAchievementsMostActiveUsers,
  setErrorAchievementsMostActiveUsers,
  setMostActiveUsersListSorting,
  setMostActiveUsersFilters,
  resetAchievementsMostActiveUsers,
} = achievementsAnalyticsSlice.actions;
