import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { getUserBadges } from "store/thunks/employeePage/employeeAchievementsThunks";
import { ScrollDirection } from "utils/consts";
import Loader, { LoaderSize } from "utils/components/Loader";
import { Icon } from "utils/components";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import classNames from "classnames";

const BadgesBlock = () => {
  const { t } = useTranslation("employeeAchievements");
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading, data, errorMessage, hasMoreData } = useAppSelector(
    (state) => state.employeeAchievements.userBadgesData
  );
  const isCurrentUser = useIsCurrentUser();

  const {
    data: totalBadgesCount,
    isLoading: isLoadingBadgesCount,
    errorMessage: errorMessageBadgesCount,
  } = useAppSelector((state) => state.employeeAchievements.totalBadgesCount);
  const scroller = useRef<HTMLDivElement | null>(null);
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(true);
  const [rightButtonDisabled, setRightButtonDisabled] = useState(false);
  const errMessage = errorMessage || errorMessageBadgesCount;
  const fetchData = async () => {
    if (!userId) return;
    await dispatch(getUserBadges(+userId));
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollLeft, clientWidth, scrollWidth } =
        scroller.current as HTMLDivElement;
      setLeftButtonDisabled(!scrollLeft);
      setRightButtonDisabled(scrollLeft + clientWidth === scrollWidth);

      if (
        scrollLeft + clientWidth >= scrollWidth - 20 &&
        hasMoreData &&
        !isLoading
      ) {
        fetchData();
      }
    };

    scroller.current?.addEventListener("scroll", handleScroll);
    return () => {
      scroller.current?.removeEventListener("scroll", handleScroll);
    };
  }, [fetchData, hasMoreData]);

  const handleBtnScroll = (direction: ScrollDirection) => {
    if (!scroller.current) return;

    let left = scroller.current.scrollLeft;
    const clientScrollerWidth = scroller.current.clientWidth;

    if (direction === ScrollDirection.left) {
      scroller.current?.scrollTo({
        left: left - clientScrollerWidth,
        behavior: "smooth",
      });
    } else {
      scroller.current?.scrollTo({
        left: left + clientScrollerWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="badgesBlock customCarousel">
      <div className="d-flex m-btm customCarousel__menu">
        <div className="badgesBlock__title-block">
          <h1 className="main-title">
            {isCurrentUser ? t("myBadgesTitle") : t("badgesTitle")}
          </h1>
          {totalBadgesCount && (
            <div className="badge-label-primary">{totalBadgesCount}</div>
          )}
        </div>

        <div className="customCarousel__scrollBtnsWrap">
          <button
            type="button"
            className="actionBtn-tertiary customCarousel__scrollBtn"
            onClick={() => handleBtnScroll(ScrollDirection.left)}
            disabled={leftButtonDisabled}
          >
            <Icon href="#chevron-down" svgClass="arrowIconLeft" />
          </button>

          <button
            type="button"
            className="actionBtn-tertiary customCarousel__scrollBtn"
            onClick={() => handleBtnScroll(ScrollDirection.right)}
            disabled={rightButtonDisabled}
          >
            <Icon href="#chevron-down" svgClass="arrowIconRight" />
          </button>
        </div>
      </div>

      {(isLoading || isLoadingBadgesCount) && !data && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errMessage && <div className="error">{errMessage}</div>}
      {data && !data.length && (
        <p className="no-content-message">
          {isCurrentUser
            ? t("noUserBadgesToDisplay")
            : t("noOtherUsersBadgesToDisplay")}
        </p>
      )}
      {data?.length ? (
        <div
          className={classNames("customCarousel__carousel", {
            carouselLeftScroll: !leftButtonDisabled,
            carouselRightScroll: !rightButtonDisabled,
          })}
        >
          <div className="customCarousel__wrap" ref={scroller}>
            {data.map(({ id, imageUrl, count }) => {
              const notExistsBadgeCount = 0;
              const isLock = count === notExistsBadgeCount;

              return (
                <div className="box boxItemImg customCarouselItem" key={id}>
                  <div className="badgesBlock__image-wrap">
                    <div
                      className={classNames("boxItemImg__imgWrap", {
                        lock: isLock,
                      })}
                    >
                      {imageUrl && <img src={imageUrl} alt="badge" />}
                    </div>
                    {isLock && (
                      <div className="lockWrapIcon">
                        <Icon href="#lock" svgClass="lockIcon" />
                      </div>
                    )}
                  </div>

                  {!isLock && (
                    <div className="customCarouselItem__label">{`x${count}`}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default BadgesBlock;
