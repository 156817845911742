import { AppPermissions } from "utils/consts";
import { usePermissionCheck } from "utils/hooks";

const viewEmployeeIdPermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewEmployeeId,
];
const viewEmployeeLocationPermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewLocation,
];
const viewEmployeeEmploymentTypePermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewEmploymentType,
];
const viewEmployeeDateOfBirthPermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewDateOfBirth,
];
const viewEmployeeHireDatePermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewHireDate,
];
const viewEmployeeViewTenurePermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewTenure,
];
const viewEmployeePhonePermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewUserPhone,
];
const viewEmployeeSocialNetworksPermissions = [
  AppPermissions.ViewAndEditUserEmployeeCards,
  AppPermissions.EmployeeCard_ViewUsersEmployeeCard_ViewUserSocialNetworks,
];

export const useEmployeeCardViewsPermissions = () => {
  return {
    isViewEmployeeId: usePermissionCheck(viewEmployeeIdPermissions),
    isViewEmployeeLocation: usePermissionCheck(viewEmployeeLocationPermissions),
    isViewEmployeeEmploymentType: usePermissionCheck(
      viewEmployeeEmploymentTypePermissions
    ),
    isViewEmployeeDateOfBirth: usePermissionCheck(
      viewEmployeeDateOfBirthPermissions
    ),
    isViewEmployeeHireDate: usePermissionCheck(viewEmployeeHireDatePermissions),
    isViewEmployeeTenure: usePermissionCheck(viewEmployeeViewTenurePermissions),
    isViewEmployeePhone: usePermissionCheck(viewEmployeePhonePermissions),
    isViewEmployeeSocialNetworks: usePermissionCheck(
      viewEmployeeSocialNetworksPermissions
    ),
  };
};
