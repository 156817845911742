import { useFormikContext } from "formik";
import toPath from "lodash/toPath";
import React, { FC } from "react";

type AggregateErrorMessageProps = {
  name?: string | undefined;
  className?: string;
  component?: string | React.ComponentType;
};

/**
 * Component inspired by formik ErrorMessage.
 * https://github.com/jaredpalmer/formik/blob/b9cc2536a1edb9f2d69c4cd20ecf4fa0f8059ade/packages/formik/src/ErrorMessage.tsx
 * Default ErrorMessage is not displaying custom keys, which are not present in formik Values model, so we need special components which will display errors with custom keys. We use "_" as key dedicated for aggregate errors
 */
export const AggregateErrorMessage: FC<AggregateErrorMessageProps> = ({
  component,
  name,
  ...rest
}) => {
  name = name || "";

  const aggregatePropName = name ? name + "._" : "_";
  const aggregatePropPath = toPath(aggregatePropName);

  const { errors } = useFormikContext();

  let p: number = 0;
  let errToDisplay = errors as any;
  while (errToDisplay && p < aggregatePropPath.length) {
    errToDisplay = errToDisplay[aggregatePropPath[p++]];
  }

  if (!errToDisplay) {
    return null;
  }

  if (typeof errToDisplay === "string") {
    return (
      <>
        {component
          ? React.createElement(component, rest as any, errToDisplay)
          : errToDisplay}
      </>
    );
  } else {
    console.error(
      `Cannot display aggregate error as ${errToDisplay} is not a string. Please, path string property instead`
    );
    return null;
  }
};

export default AggregateErrorMessage;
